import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../_app";
import { FaLevelUpAlt } from "react-icons/fa";
import { NavOptions } from "../../component";

const activeStyle = {
  color: "#495057 !important",
  borderBottom: "3px solid #25b481",
};

const SelfServiceHeader = () => {
  const permission = useAppSelector((state) => state.user.permission);
  const location = useLocation();
  const navigate = useNavigate();

  const onClickLeaveOption = (props: any) => {
    navigate(props.navigate);
  };

  return (
    <div className="employee-header">
      <NavLink
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className="employee-sublink"
        to="/dashboard/employee-payroll"
      >
        <FaLevelUpAlt />
      </NavLink>
      <NavLink
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className="employee-sublink"
        to="/dashboard/self-service/employee-users"
      >
        Employee Users
      </NavLink>
      <NavOptions
        label="Settings"
        val={location.pathname.slice(24)}
        name={(_: any) => null}
        values={[
          {
            displayName: "general",
            name: "General",
            navigate: "settings/general",
          },
          {
            displayName: "leave",
            name: "Leave",
            navigate: "settings/leave",
          },
        ]}
        onChange={onClickLeaveOption}
      />

      {/* <NavLink
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className="employee-sublink"
        to="/dashboard/employee-list"
      >
        Employees List
      </NavLink>

      {permission === "full" && (
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="/dashboard/add-employee"
        >
          Add New Employees
        </NavLink>
      )}

      <NavLink
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className="employee-sublink"
        to="/dashboard/leave-overview"
      >
        Leave Overview
      </NavLink> */}
    </div>
  );
};

export default SelfServiceHeader;
