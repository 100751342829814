import Modal from "react-modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { GoCalendar } from "react-icons/go";
import { BsPlusCircleFill } from "react-icons/bs";

import "./add-company.css";
import { IconContext } from "react-icons";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

interface DateListModalProps {
  open: boolean;
  handleClose: () => void;
  updateData: (date: string) => void;
  data?: string[];
}

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
  },
};

const DateListModal = (props: DateListModalProps) => {
  const { handleClose, open, updateData, data } = props;

  const handleUpdateData = (date: string) => {
    updateData(date);
    handleClose();
  };
  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Add Company"
      ref={(e) => e}
      ariaHideApp={false}
    >
      <div className="add-company-modal">
        <div className="header">
          <h2>Select Payslip</h2>
          <p className="close-icon" onClick={handleClose}>
            <IconContext.Provider value={{ color: "#f00", size: "30px" }}>
              <AiOutlineCloseCircle />
            </IconContext.Provider>
          </p>
        </div>

        <div className="line" />
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: "10px",
            marginLeft: "30px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              border: "none",
              backgroundColor: "transparent",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconContext.Provider value={{ color: "#000", size: "20px" }}>
              <BsPlusCircleFill />
            </IconContext.Provider>
            <NavLink className="modal-link" to="">
              Manually Add Payslip
            </NavLink>
          </div>
        </div>
        <Wrap className="body">
          {data?.map((item) => {
            return (
              <div
                className="payslip-date"
                onClick={() => handleUpdateData(item)}
                key={item}
              >
                <IconContext.Provider value={{ color: "#000" }}>
                  <GoCalendar />
                </IconContext.Provider>
                <p className="date-text">{item}</p>
              </div>
            );
          })}
        </Wrap>
      </div>
    </Modal>
  );
};

export default DateListModal;

const Wrap = styled.div`
  overflow-y: scroll;
  max-width: 50vw;
  max-height: 60vh;
`;
