import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../assets/Logo.png";

const AuthHeader = () => {
  const location = useLocation();

  return (
    <div
      style={{
        borderBottom: "1px solid gray",
        height: "3rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#283A44"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: ".5rem",
          width: 250,
        }}
      >
        <img style={{ width: "100%" }} src={Logo} alt="salary pay logo" />
      </div>
      {location.pathname !== "/" && (
        <div>
          <Link to={"/"}>Log in</Link>
          {/* <Link to={"/sign-in"}>Sign up</Link> */}
        </div>
      )}
    </div>
  );
};

export default AuthHeader;

const Link = styled(NavLink)`
  border: 3px solid #dee2e6;
  color: #283A44;
  font-size: 1rem;
  padding: 6px 1rem;
  margin: 0 0.5rem;
  border-radius: 10px;
  text-decoration: none;
  background-color: #fff;
  &:hover {
    border: 3px solid #283A44;
  }
`;
