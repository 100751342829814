import styled from "styled-components";
import { DEVICE } from "../../GlobalStyles";

interface ButtonProps {
  title: string;
  onClick: () => void;
}

const Button = (props: ButtonProps) => {
  const { onClick, title } = props;
  return <ButtonContainer onClick={onClick}>{title}</ButtonContainer>;
};

export default Button;

const ButtonContainer = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #fff;
  padding: 6px 12px;
  font-size: 1rem;
  margin: 16px 0 0 0;
  border: 1px solid #087ca7;
  background: #087ca7;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    border: 1px solid #087ca7;
    color: #fff;
    background: #066182;
    transition: 0.3s all;
  }

  @media ${DEVICE.mobileL} {
    height: 30px;
    font-size: 16px;
    transition: 0.3s all;
  }
  @media ${DEVICE.tablet} {
    max-width: 100%;
    height: 40px;
    padding: 8px 5px;
    font-size: 18px;
    margin: 8px 0 0 0;
    transition: 0.3s all;
  }
`;
