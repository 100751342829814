import { useEffect, useState } from "react";
import { useAppSelector } from "../../_app";
import { PayslipsService } from "../../_service";
import { Constant } from "../../config";
import { Notify, Utils } from "../../utils";
import {
  CPFReturnContainer,
  CPFReturnHeading,
  MonthContainer,
  MonthHeading,
  MonthHeadingContainer,
} from "../filing/CPFFilingStyles";
import { NavLink } from "react-router-dom";
import { LoaderContainer } from "../../CommonStyles";
import { Loader, PayslipModalPDF } from "../../components";
import EmployeePayslipModal from "./EmployeePayslipModal";
import { H3Heading } from "../appSettings/AppSettingsStyles";

const EmployeePayslips = () => {
  const [payslipData, setPayslipData] = useState<any>({});
  const [yearData, setYearData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPayslipData, setSelectedPayslipData] = useState<any>([]);
  const [showPayslipModal, setShowPayslipModal] = useState(false);
  const user = useAppSelector((state) => state.user);
  const getPayslipsForEmployee = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await PayslipsService._getPayslipsForEmployee(
        user.defaultCompnayId
      );

      if (status === 200) {
        setPayslipData(() => data.data);
        const dates = Object.keys(data.data);
        const years = dates.map((dat) => dat.split("-")[0]);
        const yearSet = new Set(years);
        const yearArr = Array.from(yearSet);
        setYearData(() => yearArr);
      }
    } catch (err) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    getPayslipsForEmployee();
  }, []);

  return isLoading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <div>
      <H3Heading>Employee Payslips</H3Heading>
      {yearData.length ? (
        <MonthContainer>
          {yearData.map((year: any) => (
            <>
              <MonthHeadingContainer onClick={() => {}}>
                <MonthHeading>{year}</MonthHeading>
              </MonthHeadingContainer>
              <CPFReturnContainer style={{ display: "flex", flexDirection: "column", gap: 10}}>
                {Object.keys(payslipData).map(
                  (payslipDate) =>
                    payslipDate.split("-")[0] === year && (
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPayslipModal(() => true);
                          setSelectedPayslipData(
                            () => payslipData[payslipDate]
                          );
                        }}
                      >
                        {Utils._dateToShow(payslipDate)}
                      </a>
                    )
                )}
              </CPFReturnContainer>
              <EmployeePayslipModal
                documents={selectedPayslipData}
                isOpen={showPayslipModal}
                handleClose={() => setShowPayslipModal(() => false)}
              />
            </>
          ))}
        </MonthContainer>
      ) : (
        <p>No records found</p>
      )}
    </div>
  );
};

export default EmployeePayslips;
