import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";

import { ManageCPFCal } from "../../_service";
import { Notify } from "../../utils";
import { useLocation } from "react-router";
import { PageHeading } from "./Styles";
import { CustomSelectField } from "../../components/customFormField";
import { CustomButton } from "../../components/customButton";

interface ResultProps {
  dob?: string;
  yoc?: string;
  emp_ow?: string;
  emp_aw?: string;
  emp_ordinary_salary?: string;
  emp_additional_Salary?: string;
  employee_contribution?: string;
  age_group?: string;
  total_cpf_contribution?: string;
  employer_contribution?: string;
}

const CPFCal = () => {
  let location = useLocation();

  const [empDob, setEmpDob] = useState("");
  const [epmContriYear, setEpmContriYear] = useState("");

  const [ordinaryWage, setOrdinaryWage] = useState({
    value: "",
    formated: "",
  });
  const [additionalWage, setAdditionalWage] = useState({
    value: "",
    formated: "",
  });

  const [result, setResult] = useState<ResultProps>({});
  const [code, setCode] = useState("");

  const handleCalculateData = async () => {
    const data = {
      code,
      dob: `${empDob}-10`,
      yoc: `${epmContriYear}-11`,
      emp_ow: ordinaryWage.value,
      emp_aw: additionalWage.value,
    };
    try {
      const res = await ManageCPFCal._getCpfCal(data);
      if (res.status === 200) {
        Notify("Calculated Successfully");
        setResult(res.data.data);
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  useEffect(() => {
    setCode(location.pathname.slice(19));
  }, [location]);

  return (
    <>
      <PageHeading>
        {code === "Code5" &&
          "Singapore Citizens / 3rd year Singapore Permanent Residents rates"}
        {code === "Code4" &&
          "CPF for 1st year Singapore Permanent Residents rates"}
        {code === "Code3" &&
          "CPF for 2nd year Singapore Permanent Residents rates"}
      </PageHeading>
      <p className="grey">
        {code === "Code5" &&
          "CPF Calculator / Singapore Citizens / 3rd year Singapore Permanent Residents rates"}
        {code === "Code4" &&
          "CCPF Calculator / CPF for 1st year Singapore Permanent Residents rates"}
        {code === "Code3" &&
          "CPF Calculator / CPF for 2nd year Singapore Permanent Residents rates"}
      </p>
      <CustomSelectField
        title="Select Employment Type"
        defaultValue="empType"
        option={[
          {
            key: "Private Sector / Non-Pensionable Government Employees",
            value: "empType",
          },
        ]}
      />
      <div className="cal-wrapper">
        <div>
          <div className="cal-row-wrap">
            <p className="cal-step">Step 1</p>
            <label>
              Please select your month and year of birth:
              <span>*</span>
            </label>

            <input
              className="cal-input"
              type="month"
              onChange={(e) => setEmpDob(e.target.value)}
            />
          </div>
          <div className="cal-row-wrap">
            <p className="cal-step">Step 2</p>
            <label>
              Please select your month and year of Contribution: <span>*</span>
            </label>
            <input
              className="cal-input"
              type="month"
              onChange={(e) => setEpmContriYear(e.target.value)}
            />
          </div>
          <div className="cal-row-wrap">
            <p className="cal-step">Step 3</p>
            <p>Please fill in your monthly wages.</p>

            <p className="grey info-wrap">
              For more information, please refer to the{" "}
              <a
                href="https://www.cpf.gov.sg/employer/employer-obligations/what-payments-attract-cpf-contributions"
                target="_blank"
                rel="noreferrer"
              >
                definition of wages
              </a>{" "}
              and its{" "}
              <a
                href="https://www.cpf.gov.sg/employer/employer-obligations/what-payments-attract-cpf-contributions#section-header-1659668379"
                target="_blank"
                rel="noreferrer"
              >
                classification into Ordinary and Additional wages.
              </a>
            </p>
            <label>
              Ordinary Wage <span>*</span>
            </label>
            <CurrencyFormat
              value={ordinaryWage.formated}
              thousandSeparator={true}
              prefix={"$"}
              placeholder="$0.00"
              onValueChange={(values: any) => {
                const { formattedValue, value } = values;
                setOrdinaryWage({
                  value: value,
                  formated: formattedValue,
                });
              }}
            />

            <label>
              Additional Wage<span>*</span>
            </label>
            <CurrencyFormat
              value={additionalWage.formated}
              thousandSeparator={true}
              prefix={"$"}
              placeholder="$0.00"
              onValueChange={(values: any) => {
                const { formattedValue, value } = values;
                setAdditionalWage({
                  value: value,
                  formated: formattedValue,
                });
              }}
            />
          </div>
          <CustomButton title="Calculate" onClick={handleCalculateData} />
        </div>
        <div>
          <div className="result-wrap">
            <p className="heading">Result</p>
            <div className="result-row-wrap">
              <p className="keys">Ordinary Wage</p>
              <CurrencyFormat
                value={
                  result?.emp_ow ? parseFloat(result?.emp_ow).toFixed(2) : ""
                }
                displayType="text"
                thousandSeparator
                renderText={(val) => <p className="values">{val}</p>}
              />
            </div>
            <div className="result-row-wrap">
              <p className="keys">Additional Wage</p>
              <CurrencyFormat
                value={
                  result?.emp_aw ? parseFloat(result?.emp_aw).toFixed(2) : ""
                }
                displayType="text"
                thousandSeparator
                renderText={(val) => <p className="values">{val}</p>}
              />
            </div>
            <div className="result-row-wrap">
              <p className="keys">Age Group</p>
              <p className="values">
                {result?.age_group ? result?.age_group : ""}
              </p>
            </div>
            <div className="result-row-wrap">
              <p className="keys">Total CPF Contribution</p>
              <CurrencyFormat
                value={
                  result?.total_cpf_contribution
                    ? parseFloat(result?.total_cpf_contribution).toFixed(2)
                    : ""
                }
                displayType="text"
                prefix="$"
                thousandSeparator
                renderText={(val) => <p className="values">{val}</p>}
              />
            </div>
            <div className="result-row-wrap">
              <p className="keys">Employer's Share of CPF Contribution</p>
              <CurrencyFormat
                value={
                  result?.employer_contribution
                    ? parseFloat(result?.employer_contribution).toFixed(2)
                    : ""
                }
                displayType="text"
                prefix="$"
                thousandSeparator
                renderText={(val) => <p className="values">{val}</p>}
              />
            </div>
            <div className="result-row-wrap">
              <p className="keys">Employee's Share of CPF Contribution</p>
              <CurrencyFormat
                value={
                  result?.employee_contribution
                    ? parseFloat(result?.employee_contribution).toFixed(2)
                    : ""
                }
                displayType="text"
                prefix="$"
                thousandSeparator
                renderText={(val) => <p className="values">{val}</p>}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CPFCal;
