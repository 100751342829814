import { ChangeEvent, useState, useEffect } from "react";
import { Box, Typography, TextField, styled, List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useQuery, useMutation } from "react-query";
import { AxiosError } from "axios";
import { green } from "@mui/material/colors";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../_app";
import { CompanyService } from "../../_service";
import { updateAppSettingId, updateUserIntialState } from "../../_features";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../components/customButton";
import { CommonModal, CustomeCkeckboxField, CustomSelectField, Loader, Tooltip } from "../../components";
import { TableHeader } from './../../components/table';
import { IRASTable } from "./../filing/CPFFilingStyles";
import { TableRow } from "./../payslip/PayslipStyles";
import { Utils, Notify } from "../../utils";
import { FaCheck } from "react-icons/fa";
import { AiFillDelete, AiOutlineReload, AiFillEdit } from "react-icons/ai";
import { LoaderContainer } from "../../CommonStyles";
import { Constant } from "../../config";
import { ServiceCodeService } from "./../../_service"
import useFirebaseAnalytics from "../../hooks/useFirebaseAnalytics";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";

const Row = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: "240px",
  },
}));

const InputField = styled(TextField)(() => ({
  background: "white",
  textTransform: "none",
}));

const ErrorTop = styled(Box)(() => ({
  background: "#f8d7da",
  color: "#721c24",
  padding: "12px 20px",
}));

const SuccessTop = styled(Typography)(() => ({
  background: green[300],
  color: "#000",
  padding: "12px 20px",
}));

const ErrorList = styled(List)(() => ({
  marginTop: "10px",
  marginLeft: "28px",
}));

const ErrorContainer = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

interface formErrorInterface {
  name: string;
  address_l1: string;
  address_code: string;
  payrun_start: string;
}

interface FormDataInterface {
  name: string;
  address_l1: string;
  address_l2: string;
  address_l3: string;
  address_code: "" | number;
  logo: string;
  payrun_start_date: string | null;
}

interface serviceCodesInterface {
  id: number;
  name: string;
  fixed_amount: number;
  variable_amount: number;
}

const EmployeeDetails = () => {
  const navigation = useNavigate();
  const theme = useTheme();
  const date = new Date();

  const logFirebaseEvent = useFirebaseAnalytics();

  const dispatch = useDispatch();
  const id = useAppSelector((state) => state.employee.appSettingId);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState(date.getFullYear());
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isPayrunReset, setIsPayrunReset] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState<FormDataInterface>({
    name: "",
    address_l1: "",
    address_l2: "",
    address_l3: "",
    address_code: "",
    logo: "",
    payrun_start_date: "",
  });
  const [serviceCodes, setServiceCodes] = useState<serviceCodesInterface[]>([])
  const [serviceCodesList, setServiceCodesList] = useState<serviceCodesInterface[]>([])
  const [selectedServiceCode, setSelectedServiceCode] = useState<serviceCodesInterface | null>(null)

  const [showSuccess, setShowSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState<formErrorInterface>({
    name: "",
    address_l1: "",
    address_code: "",
    payrun_start: "",
  });
  const [responseErrors, setResponseErrors] = useState<any>({});

  const serviceCodeTableHeaders = [
    {
      name: "Service code",
      width: "15%",
    },
    {
      name: "Fixed amount",
      width: "7%",
    },
    {
      name: "Amount per employee",
      width: "12%",
    },
    {
      name: "Action",
      width: "10%",
    },
    {
      name: "Enable",
      width: "10%",
    },
  ];

  const yearsTillNow = [
    date.getFullYear() - 1,
    date.getFullYear(),
    date.getFullYear() + 1,
  ];
  const monthsOptions = Utils.MONTH_OPTIONS;
  const user = useAppSelector((state) => state.user);

  // const id = useAppSelector((state) => state.user.defaultCompnayId);

  const payFrequency = useMutation({
    mutationFn: CompanyService._createpayFrequency,
  });
  const employeeNumber = useMutation({
    mutationFn: CompanyService._addEmployeeNumber,
  });
  const mutation = useMutation({
    mutationFn: isEdit
      ? CompanyService._patchCompany
      : CompanyService._addCompany,
    onSuccess: (res) => {
      !isEdit && resetForm();
      setShowSuccess(true);

      if (!isEdit) {

        // firebase log here
        let key = "create_company";
        let data = {
          user_id: user.id,
          name: user.name,
          // device_fingerprint: "using_client_js",
          event_date_time: moment().utc().format("DD/MM/YYYY hh:mm A"),
          platform: "web",
        };
        logFirebaseEvent(key, data);

        const id = res.data.id;
        payFrequency.mutate({ id });
        employeeNumber.mutate({ id });
        dispatch(updateAppSettingId(id));
        navigation("/dashboard/app-settings/employer-filing-details");
      } else {
        navigation("/dashboard/app-settings/employer-details");
        setIsConfirmModal(() => false);
        setIsPayrunReset(() => false);
        dispatch(
          updateUserIntialState({
            ...user,
            current_payrun_date:
              res.data?.current_payrun_date ||
              res.data?.payrun_start_date ||
              null,
          })
        );
      }
    },
    onError: (err: AxiosError) => {
      if (err.response) {
        setResponseErrors(err.response.data);
      }
      setIsConfirmModal(() => false);
      setIsPayrunReset(() => false);
    },
  });

  const { data, isLoading, isError } = useQuery(
    "company_details",
    () => CompanyService._getSingleCompany({ id }),
    {
      enabled: Boolean(id),
      cacheTime: 0,
    }
  );

  const resetForm = () => {
    setMonth(() => "");
    setYear(() => date.getFullYear());
    setFormData({
      name: "",
      address_l1: "",
      address_l2: "",
      address_l3: "",
      address_code: "",
      logo: "",
      payrun_start_date: "",
    });
  };

  const getServiceCodesList = async () => {
    try {
      const { data, status } = await ServiceCodeService._getServiceCodeList();
      if (status === 200) {
        setServiceCodesList(() =>
          data.data.map((dataObj: any) => ({
            id: dataObj.id,
            name: dataObj.name,
            fixed_amount: dataObj.fixed_amount,
            variable_amount: dataObj.variable_amount,
          }))
        );
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }

  };

  useEffect(() => {
    let formattedDate = "";
    if (isEdit) {
      if (!!month && isConfirmed) {
        formattedDate = Utils.getLastDayOfMonth(year, month);
      }
    } else {
      if (!!month) {
        formattedDate = Utils.getLastDayOfMonth(year, month);
      }
    }
    handleInputChange(formattedDate, "payrun_start_date");
  }, [month, year]);

  useEffect(() => {

    getServiceCodesList()

    if (id) {
      if (!isError && data) {
        setFormData(data);
        setServiceCodes(data.services);
        setYear(() =>
          data?.payrun_start_date
            ? data?.payrun_start_date?.split("-")[0]
            : date.getFullYear()
        );
        setMonth(() =>
          data?.payrun_start_date
            ? `${parseInt(data.payrun_start_date?.split("-")[1])}`
            : ""
        );
        setIsConfirmed(() => (data?.payrun_start_date ? true : false));
      }
      setIsEdit(true);
    }
  }, [data, id, isError]);

  const handleMonthChange = (value: any) => {
    setMonth(() => value);
  };

  const handleInputChange = (value: string, target: string) => {
    setShowSuccess(false);

    setFormData({
      ...formData,
      [target]: value,
    });
    setFormErrors({ ...formErrors, [target]: "" });
  };

  const confirmPayrunDate = (isReset = false) => {
    if(!isReset) {
      setIsConfirmed(() => true);
    }
    if(isReset) {
      mutation.mutate({
        id,
        payrun_start_date: null,
        current_payrun_date: null
      });
    } else {
      mutation.mutate({
        id,
        payrun_start_date: Utils.getLastDayOfMonth(year, month),
      });
    }

    if(isReset) {
      setIsPayrunReset(() => false)
    }
    // mutation.mutate({ id, payrun_start_date: null});
  };

  const saveData = () => {
    setShowSuccess(false);
    let errors: formErrorInterface = {
      name: "",
      address_l1: "",
      address_code: "",
      payrun_start: "",
    };
    if (!formData.name) {
      errors.name = "Name is required";
    }
    if (!formData.address_l1) {
      errors.address_l1 = "Address_l1 is required";
    }
    if (formData.address_code === "" || formData.address_code === null) {
      errors.address_code = "Address Code is required";
    } else {
      if (isNaN(formData.address_code)) {
        errors.address_code = "Address Code should be integer";
      }
    }
    if (!formData.payrun_start_date) {
      if (isEdit) {
        errors.payrun_start =
          "Payrun Start Date must be selected and confirmed";
      } else {
        errors.payrun_start = "Payrun Start Date must be selected";
      }
    }

    setFormErrors(errors);

    if (Object.values(errors).every((value) => value === "")) {
      mutation.mutate(formData);
    }
  };

  const handleRemoveService = async (serviceId: any) => {
    try {
      const payload = {
        company_id: id,
        service_id: serviceId,
      };
      const { status } = await ServiceCodeService._manageService(
        payload,
        "unsubscribe"
      );
      if (status === 200) {
        setServiceCodes(
          serviceCodes.filter((service) => service.id !== serviceId)
        );
        Notify("Service code removed Successfully", 1);
      }
    } catch (error: any) {
      Notify("Something went wrong", 0);
    }
  };

  const handleToggleService = async (serviceId: any, isCheck:boolean) => {
    try {
      const payload = {
        company_id: id,
        service_id: serviceId,
      };
      const { status } = await ServiceCodeService._manageService(
        payload,
        isCheck ? "activate" : "deactivate"
      );
      if (status === 200) {
        let data:any = cloneDeep(serviceCodes);
        let index = data?.findIndex((item:any) => item.id === serviceId);
          if(index >= 0 ){
            data[index].is_active = isCheck
          }
        setServiceCodes(data);
        Notify(isCheck ? "Service code enabled" : "Service code disabled", 1);
      }
    } catch (error: any) {
      Notify("Something went wrong", 0);
    }
  };

  const handleAddService = async (serviceId: any) => {
    if (serviceId === null || serviceId === undefined) {
      Notify("No service selected", 0);
      return;
    }

    try {
      const payload = {
        company_id: id,
        service_id: serviceId,
      };
      const { status } = await ServiceCodeService._manageService(
        payload,
        "subscribe"
      );
      if (status === 200 && selectedServiceCode) {
        setServiceCodes((prevServiceCodes) => [
          ...prevServiceCodes,
          {...selectedServiceCode, is_active: true},
        ]);
        setSelectedServiceCode(null);
        Notify("Added service code Successfully", 1);
      }
    } catch (error: any) {
      Notify("Something went wrong", 0);
    }
  };

console.log("serviceCodes", serviceCodes, selectedServiceCode)  
  
  
    return (
      <Box
        sx={{
          [theme.breakpoints.up("md")]: {
            paddingRight: "14vw",
          },
          pointerEvents: mutation?.isLoading || isLoading ? "none" : "auto",
        }}
      >
        {(mutation?.isLoading || isLoading) && <Loader />}
        {mutation.isError && (
          <ErrorTop>
            <Typography fontWeight="bold" fontSize={22}>
              {Object.keys(responseErrors).length} error
              {Object.keys(responseErrors).length > 1 ? "s" : ""} prohibited
              this client from being saved
            </Typography>
            <Typography marginTop="6px">
              There were problems with the following fields:
            </Typography>
            <ErrorList>
              {Object.keys(formErrors).length > 0 &&
                Object.keys(formErrors).map((key) => (
                  <>
                    {responseErrors[key] &&
                      responseErrors[key].map((error: string) => (
                        <li key={error}>
                          <span>{key}</span> - <span>{error}</span>
                        </li>
                      ))}
                  </>
                ))}
            </ErrorList>
          </ErrorTop>
        )}
        {showSuccess && (
          <SuccessTop>
            Employer details {isEdit ? "Updated" : "Added"} successfully.{" "}
            {!isEdit && "Please add the filing details"}
          </SuccessTop>
        )}
        <Row>
          <Label>Trading Name</Label>
          <Box sx={{ width: "100%" }}>
            <InputField
              id="outlined-basic"
              variant="outlined"
              size="small"
              fullWidth
              value={formData?.name}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.value, "name")
              }
              onBlur={() => setFormErrors({ ...formErrors, name: "" })}
              inputProps={{ style: { textTransform: "none" } }}
            />
            {formErrors.name && (
              <ErrorContainer>{formErrors.name}</ErrorContainer>
            )}
          </Box>
        </Row>
        <Row>
          <Label>Payrun Start Month and Year</Label>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            {/* <CustomDateField date={Utils._date()} margin="0rem"/> */}
            <CustomSelectField
              margin="0rem"
              placeholder="Select Month"
              value={month}
              onChangeOption={handleMonthChange}
              option={monthsOptions.map((month: any) => ({
                key: month.key,
                value: month.value,
              }))}
              disabled={isConfirmed}
              isDate={true}
              width="150px"
            />
            <CustomSelectField
              margin="0rem"
              placeholder="Select Year"
              value={year}
              onChangeOption={(value: any) => setYear(value)}
              option={yearsTillNow.map((years) => ({
                key: years,
                value: years,
              }))}
              disabled={isConfirmed}
              isDate={true}
              width="150px"
            />
            {!isConfirmed && !!month && isEdit && (
              <Tooltip text="Confirm">
                <CustomButton
                  type="secondary"
                  size="small"
                  width="30px"
                  onClick={() => setIsConfirmModal(() => true)}
                  title={""}
                  icon={<FaCheck />}
                />
              </Tooltip>
            )}
            {!isConfirmed && !!month && isEdit && (
              <Tooltip text="Delete" del>
                <CustomButton
                  type={"secondary"}
                  size="small"
                  del
                  width="30px"
                  title={""}
                  onClick={() => setMonth("")}
                  icon={<AiFillDelete />}
                />
              </Tooltip>
            )}
            {user.groups.length === 0 && isEdit && !!month && isConfirmed && (
              <Tooltip text="Reset">
                <CustomButton
                  type={"secondary"}
                  size="small"
                  width="30px"
                  title={""}
                  onClick={() => setIsPayrunReset(() => true)}
                  icon={<AiOutlineReload />}
                />
              </Tooltip>
            )}
          </Box>
        </Row>
        <p style={{ color: "gray" }}>
          <em>
            Once you confirm, you cannot change the Payrun start date and month
          </em>
        </p>
        {formErrors.payrun_start && (
          <ErrorContainer>{formErrors.payrun_start}</ErrorContainer>
        )}
        <Box sx={{ mt: 2.5 }}>
          <Typography color="#204ca0" sx={{ fontWeight: "bold" }}>
            Physical Address
          </Typography>
        </Box>
        <Row>
          <Label>Line 1</Label>
          <Box sx={{ width: "100%" }}>
            <InputField
              id="address_li"
              variant="outlined"
              size="small"
              fullWidth
              value={formData?.address_l1}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.value, "address_l1")
              }
              onBlur={() => setFormErrors({ ...formErrors, address_l1: "" })}
              inputProps={{ style: { textTransform: "none" } }}
            />
            {formErrors.address_l1 && (
              <ErrorContainer>{formErrors.address_l1}</ErrorContainer>
            )}
          </Box>
        </Row>
        <Row>
          <Label>Line 2</Label>
          <InputField
            id="address_l2"
            variant="outlined"
            size="small"
            fullWidth
            value={formData?.address_l2}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.value, "address_l2")
            }
            inputProps={{ style: { textTransform: "none" } }}
          />
        </Row>
        <Row>
          <Label>Line 3</Label>
          <InputField
            id="address_l3"
            variant="outlined"
            size="small"
            fullWidth
            value={formData?.address_l3}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.value, "address_l3")
            }
            inputProps={{ style: { textTransform: "none" } }}
          />
        </Row>
        <Row>
          <Label>Postal</Label>
          <Box sx={{ width: "100%" }}>
            <InputField
              id="address_code"
              variant="outlined"
              size="small"
              fullWidth
              value={formData?.address_code}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.value, "address_code")
              }
              onBlur={() => setFormErrors({ ...formErrors, address_code: "" })}
              inputProps={{ style: { textTransform: "none" } }}
            />
            {formErrors.address_code && (
              <ErrorContainer>{formErrors.address_code}</ErrorContainer>
            )}
          </Box>
        </Row>
        <Box
          sx={{
            [theme.breakpoints.down("md")]: {
              marginTop: 2.5,
            },
          }}
        >
          <Typography color="#204ca0" sx={{ fontWeight: "bold" }}>
            Optional
          </Typography>
        </Box>
        <Row>
          <Label>Logo</Label>

          <Box sx={{ width: "100%" }}>
            <input style={{ border: 0, width: "100%" }} type="file" />
            <br />
            <span>In .jpg or .png format</span>
          </Box>
        </Row>

        <Row style={{ marginTop: "50px" }}>
          <Label>
            <Typography color="#204ca0" sx={{ fontWeight: "bold" }}>
              Service Code
            </Typography>
          </Label>

          <Box sx={{ width: "100%" }}>
            {serviceCodes.length > 0 && (
              <div style={{ marginBottom: "30px" }}>
                <IRASTable>
                  <TableHeader
                    headerColumns={serviceCodeTableHeaders}
                    variant="filled"
                    styles={{ padding: "7px" }}
                  />
                  <tbody>
                    {serviceCodes?.map((service: any) => (
                      <TableRow key={service.id}>
                        <td style={{ padding: "5px" }}>{service.name}</td>
                        <td style={{ textAlign: "center", padding: "5px" }}>
                          {Utils.getCommanSepartedNumericStringWithDollar(service.fixed_amount)}
                        </td>
                        <td style={{ textAlign: "center", padding: "5px" }}>
                          {Utils.getCommanSepartedNumericStringWithDollar(service.variable_amount)}
                        </td>
                        <td style={{ padding: "5px" }}>
                          <div style={{ display: "flex" }}>
                            <Tooltip text="Remove" del>
                              <CustomButton
                                type={"secondary"}
                                size="small"
                                del
                                width="30px"
                                title={""}
                                onClick={() => handleRemoveService(service.id)}
                                icon={<AiFillDelete />}
                              />
                            </Tooltip>
                          </div>
                        </td>
                        <td style={{ textAlign: "left", padding: "5px" }}>
                        <input
                          type='checkbox'
                          checked={service?.is_active}
                          onChange={(e:any) => handleToggleService(service?.id , e.target.checked)}
                          style={{
                            marginRight: 'auto',
                            borderRadius: '12px',
                            width: '1.7rem',
                            height: '1.7rem',
                            marginTop: '0.5rem'
                          }}
                        />
                        </td>
                      </TableRow>
                    ))}
                  </tbody>
                </IRASTable>
              </div>
            )}

            {/* ---- second part --- */}
            

            {
              <>
              <div
                style={{
                  marginBottom: "30px",
                  display: "flex",
                  alignItems: "center",
                  gap: "18px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label style={{ marginBottom: "3px", fontSize: "15px" }}>
                    Service Code
                  </label>
                  <select
                    value={selectedServiceCode?.id || ""}
                    onChange={(e) => {
                      const selectedId = Number(e.target.value);
                      const selectedService =
                        serviceCodesList.find((service) => service.id === selectedId) ||
                        null;
                      setSelectedServiceCode(selectedService);
                    }}
                    style={{
                      padding: "5px",
                      width: "200px",
                      borderRadius: "0.2rem",
                      border: "1px solid #ccc",
                    }}
                  >
                    <option value="" disabled>
                      Select service code
                    </option>
                    {serviceCodesList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
            
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label style={{ marginBottom: "3px", fontSize: "15px" }}>
                    Fixed Amount
                  </label>
                  <input
                    type="number"
                    value={selectedServiceCode?.fixed_amount || ""}
                    disabled={true}
                    style={{
                      padding: "5px",
                      width: "200px",
                      borderRadius: "0.2rem",
                      border: "1px solid #ccc",
                      textAlign: "right",
                    }}
                  />
                </div>
            
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label style={{ marginBottom: "3px", fontSize: "15px" }}>
                    Amount per Employee
                  </label>
                  <input
                    type="number"
                    value={selectedServiceCode?.variable_amount || ""}
                    disabled={true}
                    style={{
                      padding: "5px",
                      width: "200px",
                      borderRadius: "0.2rem",
                      border: "1px solid #ccc",
                      textAlign: "right",
                    }}
                  />
                </div>
            
                {selectedServiceCode && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label
                      style={{
                        marginBottom: "3px",
                        fontSize: "12px",
                        color: "#F9F9F9",
                      }}
                    >
                      Action
                    </label>
                    <CustomButton
                      title="Add"
                      onClick={() => handleAddService(selectedServiceCode?.id)}
                      size="small"
                      width="70px"
                    />
                  </div>
                )}
              </div>
            </>
            

            }
          </Box>
        </Row>

        

        <Box sx={{ marginTop: 2.5, display: "flex" }}>
          <CustomButton
            title="Cancel"
            type="secondary"
            onClick={() => navigation("/dashboard/app-settings")}
          />
          <CustomButton title="Save" onClick={saveData} />
        </Box>
        <CommonModal
          handleClose={() => setIsConfirmModal(false)}
          open={isConfirmModal}
          updateData={() => confirmPayrunDate()}
          body={`Are you sure want to make ${Utils._dateToShow(
            Utils.getLastDayOfMonth(year, month)
          )} as your Payrun start date? You cannot undo this action.`}
          heading="Cofnrim Payrun Date"
          conformButtonText="Confirm"
          cancelButtonText="Cancel"
        />
        <CommonModal
          handleClose={() => setIsPayrunReset(false)}
          open={isPayrunReset}
          updateData={() => confirmPayrunDate(true)}
          body={`Are you sure want to reset your Payrun start date?`}
          heading="Reset Payrun Date"
          conformButtonText="Reset"
          cancelButtonText="Cancel"
        />
      </Box>
    );
  
};

export default EmployeeDetails;
