import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";

import "./add-company.css";
import { IconContext } from "react-icons";
import styled from "styled-components";

interface BulkUnFinalizeModalProps {
  number: number;
  open: boolean;
  handleClose: () => void;
  updateData: () => void;
}

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #eee",
    borderRadius: "10px",
    maxWidth: "500px",
    padding: 0,
  },
};

const BulkUnFinalizeModal = (props: BulkUnFinalizeModalProps) => {
  const { handleClose, open, number, updateData } = props;

  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Add Company"
      ref={(e) => e}
      ariaHideApp={false}
    >
      <div>
        <Header>
          <h3>Please confirm</h3>
          <span onClick={handleClose}>
            <IconContext.Provider value={{ color: "#999", size: "20px" }}>
              <AiOutlineClose />
            </IconContext.Provider>
          </span>
        </Header>
        <Body>
          <p>
            {number} payslip(s) will be unfinalised in bulk. Please only use
            this option if you need to change the majority of your payslips for
            the current period. You are not allowed to make changes to older pay
            run. If you are at all uncertain, we would strongly advise that you
            unfinalise payslips individually to prevent any unexpected changes.
            Are you sure you want to continue?
          </p>
        </Body>
        <Footer>
          <ConformButton onClick={updateData}>Confirm</ConformButton>
          <CloseButton onClick={handleClose}>Close</CloseButton>
        </Footer>
      </div>
    </Modal>
  );
};

export default BulkUnFinalizeModal;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #eee;
  padding: 10px 20px 20px 20px;
  h3 {
    color: #404040;
  }
  span {
    cursor: pointer;
  }
`;
const Body = styled.div`
  padding: 40px;
  p {
    color: grey;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: "60px";
  background-color: #f5f5f5;
  padding: 14px 15px 15px;
`;
const ConformButton = styled.button`
  color: #fff;
  background-color: #999;
  border: 1px solid #999;
  padding: 5px 14px 6px;
  font-size: 13px;
  margin-right: 15px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: #333;
  }
`;
const CloseButton = styled(ConformButton)`
  color: #333;
  background-color: #ccc;
  border: 1px solid #ccc;
  cursor: pointer;
`;
