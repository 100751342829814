import { useEffect, useState } from "react";
import {
  CustomButton,
  CustomTextField,
  CustomNumberField,
  NavigateBack,
} from "../../../components";
import { Heading } from "../AppSettingsStyles";
import { Notify } from "../../../utils";
import { Constant } from "../../../config";
import { ServiceCodeService } from "../../../_service";
import { useLocation, useNavigate } from "react-router-dom";

const AddOrEditServiceCode = () => {
  const [servicecode, setServiceCode] = useState<string>("");
  const [fixedAmount, setFixedAmount] = useState<string>("");
  const [variableAmount, setVariableAmount] = useState<string>("");
  const [heading, setHeading] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();

  const addServiceCode = async () => {
    try {
      const { status } = await ServiceCodeService._addServiceCode({
        name: servicecode,
        fixed_amount: fixedAmount,
        variable_amount: variableAmount,
      });
      if (status === 201) {
        Notify("Service code added successfully", 1);
        navigate("/dashboard/manage-service-code");
      }
    } catch (error: any) {
      if (error?.response?.data?.name?.message) {
        Notify(error.response.data.name.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
  };

  const editServiceCode = async () => {
    try {
      const { status } = await ServiceCodeService._editServiceCode(
        {
          name: servicecode,
          fixed_amount: fixedAmount,
          variable_amount: variableAmount,
        },
        location.state.id
      );
      if (status === 200) {
        Notify("Service code edited successfully", 1);
        navigate("/dashboard/manage-service-code");
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        if (error?.response?.data?.name?.message) {
          Notify(error.response.data.name.message, 0);
        } else {
          Notify(Constant.common.something_went_wrong, 0);
        }
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
  };

  const saveButtonClick = () => {
    if (location.pathname.split("/").pop() === "edit") {
      editServiceCode();
    } else {
      addServiceCode();
    }
  };

  useEffect(() => {
    if (location.pathname.split("/").pop() === "edit") {
      setServiceCode(() => location.state.name);
      setFixedAmount(() => location.state.fixedAmount);
      setVariableAmount(() => location.state.variableAmount);
      setHeading("Edit Service Code");
    } else {
      setHeading("Add Service Code");
    }
  }, []);
  return (
    <div>
      <NavigateBack />
      <Heading>{heading}</Heading>
      <div style={{ margin: "40px 16px" }}>
        <CustomTextField
          title="Service Code"
          value={servicecode}
          onChangeText={setServiceCode}
          error={servicecode ? "" : "* Service code is required"}
        />

        <CustomNumberField
          title="Fixed amount"
          value={fixedAmount}
          onChangeText={setFixedAmount}
          error={
            fixedAmount
              ? isNaN(Number(fixedAmount)) || parseFloat(fixedAmount) <= 0
                ? "* Fixed amount must be a positive number and more than 0"
                : ""
              : "* Please enter the fixed amount"
          }

        />

        <CustomNumberField
          title="Amount per employee"
          value={variableAmount}
          onChangeText={setVariableAmount}
          error={
            variableAmount
              ? isNaN(Number(variableAmount)) || parseFloat(variableAmount) <= 0
                ? "* Amount per employee must be a positive number and more than 0"
                : ""
              : "* Please enter the amount per employee"
          }

        />
      </div>
      <CustomButton
        title="Save"
        onClick={saveButtonClick}
        disable={
          !servicecode ||
          !fixedAmount ||
          !variableAmount ||
          isNaN(Number(fixedAmount)) ||
          isNaN(Number(variableAmount)) ||
          parseFloat(fixedAmount) <= 0 ||
          parseFloat(variableAmount) <= 0
        }
      />
    </div>
  );
};

export default AddOrEditServiceCode;
