export const Colors = {
  WHITE: "#ffffff",
  WHITE_1: "#f9f9f9",
  WHITE_2: "#dee2e6",

  BLUE: "#0000ff",
  BLUE_1: "#059bbf",
  BLUE_2: "#476575",
  BLUE_3: "#007bff",
  BLUE_4: "#066182",
  BLUE_5: "#283a44",
  BLUE_6: "#087ca7",
  BLUE_7: "#476575",

  GREEN_1: "#28a745",
  GREEN_2: "#155724",
  GREEN_3: "#d4edda",

  BLACK: "#000000",
  BLACK_1: "#333333",

  BLACK_SHADOW: "rgba(0, 0, 0, 0.3)",

  GRAY: "#545b62",
  GRAY_1: "#868e96",
  GRAY_2: "#6c757d",
  GRAY_3: "#ECECEC",
  GRAY_4: "#4a5e71",
  GRAY_5: "#e0e0e0",
  GRAY_6: "#333",

  RED: "#ff0000",
  RED_1: "#dc3545",

  VOLLET: "#676ed1",
};
