import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import LogoutMessageContainer from "./LogoutModalStyles";

interface DeactivateModalProps {
  closeModal: () => void;
  approveAction: any;
  open: boolean;
  variant?:
    | "deactivate"
    | "reactivate"
    | "delete"
    | "deleteLeave"
    | "resendActivationLink"
    | "deleteGroup"
    | "deleteHrTemplate"
    | "deleteEntitlement"
    | "deleteServiceCode"
    | "deleteActiveEntitlement";
}

const modalMessage = {
  deactivate: {
    heading: "Deactivate User",
    message: "Do you want to Deactivate the user?",
    actionButtonMessage: "Deactivate",
  },
  reactivate: {
    heading: "Activate User",
    message: "Do you want to re-activate the user?",
    actionButtonMessage: "Reactivate",
  },
  delete: {
    heading: "Delete User",
    message: "Do you want to Delete the user? You can deactivate it instead",
    actionButtonMessage: "Delete",
  },
  deleteLeave: {
    heading: "Delete Leave",
    message: "Do you want to Delete the leave?",
    actionButtonMessage: "Delete",
  },
  deleteEntitlement: {
    heading: "Delete Entitlement Policy",
    message: "Do you want to Delete the entitlement policy?",
    actionButtonMessage: "Delete",
  },
  deleteActiveEntitlement: {
    heading: "Delete Active Entitlement Policy",
    message: "Do you want to Delete the active entitlement policy?",
    actionButtonMessage: "Delete",
  },
  resendActivationLink: {
    heading: "Resend Activation Link",
    message: "Are you sure you want to re-send the activation Link?",
    actionButtonMessage: "Resend Link",
  },
  deleteGroup: {
    heading: "Delete Group",
    message: "Do you want to Delete the Group?",
    actionButtonMessage: "Delete",
  },
  deleteHrTemplate: {
    heading: "Delete Template",
    message: "Do you want to Delete the HR Template?",
    actionButtonMessage: "Delete",
  },
  deleteServiceCode: {
    heading: "Delete Service Code",
    message: "Do you want to Delete the Service Code?",
    actionButtonMessage: "Delete",
  },
};

const DeactivateModal = (props: DeactivateModalProps) => {
  const { closeModal, approveAction, open, variant = "deactivate" } = props;

  return (
    <Modal open={open} onClose={closeModal}>
      <Box>
        <LogoutMessageContainer>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalMessage[variant]["heading"]}
          </Typography>
          <Box sx={{ margin: "12px -16px" }}>
            <Divider light={true} />
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
            {modalMessage[variant]["message"]}
          </Typography>
          <Box sx={{ margin: "0 -16px 32px -16px" }}>
            <Divider light={true} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "16px",
            }}
          >
            <Box>
              <Button onClick={closeModal} variant="contained">
                Cancel
              </Button>
              <Button onClick={() => approveAction()} sx={{ ml: 0.8 }}>
                {modalMessage[variant]["actionButtonMessage"]}
              </Button>
            </Box>
          </Box>
        </LogoutMessageContainer>
      </Box>
    </Modal>
  );
};

export default DeactivateModal;
