import { useEffect, useState } from "react";
import {
  CustomButton,
  CustomNumberField,
  CustomSelectField,
  DateField,
  NavigateBack,
} from "../../../components";
import { H3Heading } from "../../appSettings/AppSettingsStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { CPFService } from "../../../_service/CPFService";
import { Notify, Utils } from "../../../utils";
import { Constant } from "../../../config";
import { useAppSelector } from "../../../_app";
import { EmployeeService } from "../../../_service";
import moment from "moment";

const old_keyFieldMap = {
  ow: "OW (Salary OT)",
  bonus: "AW (Bonus)",
  commission: "AW (commission)",
  others: "AW (others)",
  aw: "AW (total)",
  // total_income: "Income",
  transport: "Transport",
  entertainment: "Entertainment",
  other_allowance: "Other Allowances",
  // total_allowance: "Total Allowances",
  cpf_employee: "CPF Employee",
  cdac: "CDAC",
  sinda: "Sinda",
  ecf: "Ecf",
  mbmf: "Mbmf",
  oth_deduction: "Other Deductions",
  // total_deduction: "Total Deduction",
  total_reimburesement: "Total Reimbursement",
  // net_pay: "Net Pay",
  cpf_employer: "CPF Employer",
  sdl: "SDL",
  // total_cpf: "Total CPF",
  mosque_building_fund: "Mosque Building Fund",
  director_fees: "Director's Fee",
  donations: "Donations",
} as any;

const keyFieldMap = {
  ow: "OW (Salary OT)",
  bonus: "AW (Bonus)",
  commission: "AW (commission)",
  // others: "AW (others)",
  // aw: "AW (total)",
  // total_income: "Income",
  director_fees: "Director's Fee",
  transport: "Transport",
  entertainment: "Entertainment",
  other_allowance: "Other Allowances",
  gross_salary: "Gross Salary",
  // total_allowance: "Total Allowances",
  cpf_employee: "CPF Employee",
  cdac: "CDAC",
  sinda: "Sinda",
  ecf: "ECF",
  mbmf: "Mendaki + MBMF",
  oth_deduction: "Other Deductions",
  // total_deduction: "Total Deduction",
  total_reimburesement: "Total Reimbursement",
  // mosque_building_fund: "Mosque Building Fund",
  net_pay: "Net Pay",
  // cpf_employer: "CPF Employer",
  // sdl: "SDL",
  // total_cpf: "Total CPF",
  // donations: "Donations",
} as any;

const disabledKeys = [
  // "total_cpf",
  // "total_income",
  "total_aw",
  // "total_allowances",
  // "total_deduction",
  "net_pay",
  "gross_salary"
];

const AddFiling = () => {
  const [taxInfo, setTaxInfo] = useState<any>({
    // total_income: "",
    // total_deduction: "",
    // net_pay: "",
    total_employer_contribution: "",
    total_reimburesement: "",
    total_benefit: "",
    // total_allowance: "",
    bonus: "",
    transport: "",
    entertainment: "",
    other_allowance: "",
    commission: "",
    others: "",
    oth_deduction: "",

    ow: "",
    aw: "",
    cpf_employee: "",
    cpf_employer: "",
    sinda: "",
    ecf: "",
    cdac: "",
    mbmf: "",
    sdl: "",
    mosque_building_fund: "",
    director_fees: "",
    donations: "",
    gross_salary: "",
  });

  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const date = new Date();
  const [month, setMonth] = useState<string>("1");
  const [year, setYear] = useState<string>(`${date.getFullYear()}`);
  // const [year, setYear] = useState<string>(`${user?.defaultCompanyPayrunStartDate?.split('-')[0]}`);
  const monthsOptions = Utils.MONTH_OPTIONS;

  // defaultCompanyPayrunStartDate

  const handleTaxInfoChange = (
    target: string,
    value: string | number | boolean
  ) => {
    setTaxInfo((prev: any) => ({ ...prev, [target]: value }));
  };
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);

  const handleAdd = async () => {
    let payload = { ...taxInfo };
    console.log("thePayload", payload);
    Object.keys(payload).forEach((key) => {
      if (payload[key] === "") {
        payload[key] = 0;
      }
    });

    let lastDateOfMonth = moment([year, parseInt(month) - 1]).endOf('month').format('YYYY-MM-DD');
    console.log('finalDate', lastDateOfMonth)

    if(payload?.net_pay < 0) {
      Notify("Net Pay cannot be negative!", 0);
    } else {
      try {
        const { status } = await CPFService._addManualFiling({
          ...payload,
          company: user?.defaultCompnayId,
          employee: selectedEmployee,
          payslip_date: lastDateOfMonth,
        });
  
        if (status === 200) {
          Notify("Ir8A added successfully", 1);
          navigate(-1);
        }
      } catch (error) {
        Notify(Constant.common.something_went_wrong, 0);
        navigate(-1);
      }
    }

  };

  const { state } = useLocation();

  //   useEffect(() => {
  //     setTaxInfo(() => ({
  //       total_income: state.filing.total_income,
  //       total_deduction: state.filing.total_deduction,
  //       net_pay: state.filing.net_pay,
  //       total_employer_contribution: state.filing.total_employer_contribution,
  //       total_reimburesement: state.filing.total_reimburesement,
  //       total_benefit: state.filing.total_benefit,
  //       total_allowance: state.filing.total_allowance,
  //       bonus: state.filing.bonus,
  //       transport: state.filing.transport,
  //       entertainment: state.filing.entertainment,
  //       other_allowance: state.filing.other_allowance,
  //       commission: state.filing.commission,
  //       others: state.filing.others,
  //       ow: state.filing.ow,
  //       aw: state.filing.aw,
  //       cpf_employee: state.filing.cpf_employee,
  //       cpf_employer: state.filing.cpf_employer,
  //       sinda: state.filing.sinda,
  //       ecf: state.filing.ecf,
  //       cdac: state.filing.cdac,
  //       mbmf: state.filing.mbmf,
  //       sdl: state.filing.sdl,
  //     }));
  //   }, []);

  const getEmployeeList = async (statusValue: string) => {
    try {
      const { data, status } = await EmployeeService._getEmployeeListByCompany(
        user.defaultCompnayId,
        statusValue
      );

      if (status === 200) {
        setEmployeeList(() => data.data);
        console.log("empList", data?.data);
        setSelectedEmployee(data?.data[0]?.id);
        // const empData = data.data.map((emp: any) => emp.id);
        // setSelectedEmployeeList(() => empData);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  useEffect(() => {
    getEmployeeList("1");
  }, []);

  console.log("selected M-Y-Date",month,year, moment([parseInt(year), parseInt(month) - 1]).endOf('month').format('YYYY-MM-DD'))
  

  let SumA =
    (parseFloat(taxInfo?.ow) || 0) +
    (parseFloat(taxInfo?.bonus) || 0) +
    (parseFloat(taxInfo?.commission) || 0) +
    (parseFloat(taxInfo?.director_fees) || 0) +
    (parseFloat(taxInfo?.transport) || 0) +
    (parseFloat(taxInfo?.entertainment) || 0) +
    (parseFloat(taxInfo?.other_allowance) || 0);
  let SumB =
    (parseFloat(taxInfo?.cpf_employee) || 0) +
    (parseFloat(taxInfo?.cdac) || 0) +
    (parseFloat(taxInfo?.sinda) || 0) +
    (parseFloat(taxInfo?.ecf) || 0) +
    (parseFloat(taxInfo?.mbmf) || 0)+
    (parseFloat(taxInfo?.oth_deduction) || 0);
    // (parseFloat(taxInfo?.total_reimburesement) || 0) +
    // (parseFloat(taxInfo?.mosque_building_fund) || 0);
  

  useEffect(()=>{
    let calc = ((SumA - SumB) + (parseFloat(taxInfo?.total_reimburesement || 0)) ) || 0;
    setTaxInfo((prev: any) => ({ ...prev, ['net_pay']: calc, ['gross_salary']: SumA }));
    
  },[SumA, SumB, taxInfo?.total_reimburesement])


  return (
    <div>
      <NavigateBack />
      <H3Heading>Add Manual IR8A</H3Heading>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 60,
          color: "#000000d9",
          marginBottom: 10,
          width: 'full'
        }}
      >
        <p>
          <b>Company Name:</b> {user?.defaultCompany || ""}
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
            gap: 10,
          }}
        >
          {/* <b>Employee Name:</b> {state?.filing?.employee_name || ""} */}
          <b>Employee Name:</b>
          <CustomSelectField
            // title={"Employee Name :"}
            value={selectedEmployee}
            onChangeOption={(value) => {
              setSelectedEmployee(() => value);
              console.log("value", value);
            }}
            // onChangeOption={(value) => console.log('onChange', value)}
            option={employeeList?.map((emp: any) => {
              return {
                value: emp?.id,
                key: emp?.user?.name,
              };
            })}
          />
        </div>

        <div
          style={{
            display: "flex",
            width: "70%",
            gap: 20,
            alignItems: "center",
          }}
        >
          <p style={{ color: "#333", fontWeight: 'bold'}}>Select Month & Year</p>
          <div>
            <CustomSelectField
              value={month}
              onChangeOption={(value) => {
                sessionStorage.setItem("manualMonth", value);
                setMonth(value);
              }}
              option={monthsOptions.map((month) => ({
                key: month.key,
                value: month.value,
              }))}
              isDate={true}
              margin="0rem"
            />
          </div>
          <div>
            <CustomSelectField
              value={year}
              onChangeOption={(value) => {
                sessionStorage.setItem("manualYear", value);
                setYear(value);
              }}
              // option={Utils.generateYearArrayTillNow(2022).map((year) => ({
              option={Utils.generateYearArrayTillNow(parseInt(user?.defaultCompanyPayrunStartDate?.split('-')[0]) - 3).map((year) => ({
                key: year,
                value: year,
              }))}
              isDate={true}
              margin="0rem"
            />
          </div>
        </div>
        <p>
          {/* <b>Payslip Date:</b> {Utils._dateToShow(state?.filing?.payslip_date)} */}
        </p>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        {Object.keys(keyFieldMap).map((key: any) => (
          <>
          <CustomNumberField
            title={keyFieldMap[key]}
            value={taxInfo[key]}
            onChangeText={(value) => handleTaxInfoChange(key, value)}
            disabled={disabledKeys.includes(key)}
            />
            {/* {keyFieldMap[key] === "Other Allowances" && <br />} */}
            </>
        ))}
      </div>
      <div style={{ display: "flex", gap: 20, marginTop: 20 }}>
        <CustomButton title="Add" onClick={handleAdd} />
        {/* <CustomButton
          title="Add"
          type="secondary"
          success
          onClick={() => handleAdd()}
        /> */}
      </div>
    </div>
  );
};

export default AddFiling;
