import { Typography } from "@mui/material";

const PayFrequencies = () => {
  return (
    <div>
      <Typography
        mt={2.5}
        variant="h5"
        fontSize="20px"
        component="h5"
        sx={{ color: "#059bbf" }}
      >
        Monthly, ending on the 31st
      </Typography>

      <div
        style={{
          padding: "0.75rem 1rem",
          border: "1px solid #059bbf",
          width: "fit-content",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          marginTop: "2rem",
          maxWidth: "60%",
          fontSize: "1rem",
          borderRadius: '12px'
        }}
      >
        <p
          style={{
            fontWeight: 600,
          }}
        >
          Note: Pay frequencies refer to when employees are paid. (Either
          monthly or weekly, on what day and how often).
        </p>

        <p>
          At this stage, system is designed to pay "The last day of the month
          for which all employees will be paid." This is not necessary, the same
          day when you receive the salary payment. The company may pay early on
          25th of the month instead on 31st day of the month.
        </p>

        <p>In the future, system may add new pay frequencies such as:</p>

        <p>1. Twice a month</p>
        <p>2. Every two weeks</p>
        <p>3. Weekly pay</p>
      </div>
    </div>
  );
};

export default PayFrequencies;
