import { useState } from "react";
import {
  CustomButton,
  CustomNumberField,
  NavigateBack,
} from "../../../components";
import { CPFService } from "../../../_service/CPFService";
import { useAppSelector } from "../../../_app";
import { Notify, Utils } from "../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Constant } from "../../../config";
import { H3Heading } from "../../appSettings/AppSettingsStyles";

const AddPenalty = () => {
  const [penaltyInterest, savePenaltyInterest] = useState("");
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);

  const { state } = useLocation();
  const navigate = useNavigate();

  const addCPFPenalty = async () => {
    const monthNumber = Utils.monthNameNumberMap[state.month];
    if (!penaltyInterest.length) {
      Notify("Please enter penalty interest", 0);
      return;
    }

    try {
      const penaltyData = {
        value: penaltyInterest,
        month: monthNumber,
        year: state.year,
      };
      const { status } = await CPFService._addCPFPenalty(
        companyId,
        penaltyData,
      );
      if (status === 201) {
        Notify("e-CPF Penalty added successfully", 1);
        navigate("/dashboard/filing/monthly");
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  return (
    <div>
      <NavigateBack />
      <H3Heading>CPF Return Inputs - {Utils._dateToShow(Utils.getLastDayOfMonth(state.year, Utils.monthNameNumberMap[state.month]))}</H3Heading>
      <CustomNumberField
        title="CPF Penalty Interest"
        value={penaltyInterest}
        onChangeText={savePenaltyInterest}
      />
      <CustomButton title="Save" onClick={() => addCPFPenalty()} />
    </div>
  );
};

export default AddPenalty;
