import { URL } from "../config";
import apiClient from "./BaseService";

const _getLeaveTypes = async (company: number) =>
  apiClient.get(`${URL.GET_LEAVE_TYPES}?company=${company}`);

const _getCycleStartDateOptions = async () =>
  apiClient.get(URL.GET_CYCLE_START_DATE_OPTIONS);

const _addLeaveType = async (data: any) =>
  apiClient.post(URL.GET_LEAVE_TYPES, data);

const _editLeaveType = async (data: any, leaveId: number) =>
  apiClient.patch(`${URL.GET_LEAVE_TYPES}${leaveId}/`, data);

const _deleteLeaveType = async (id: any) =>
  apiClient.delete(`${URL.GET_LEAVE_TYPES}${id}`);

const _getIndividualLeave = async (id: number) =>
  apiClient.get(`${URL.GET_LEAVE_TYPES}${id}/`);

const _getAllEntitlementPolicies = async (leaveId: number) =>
  apiClient.get(`${URL.GET_ALL_ENTITLEMENT_POLICIES}?leave_type_id=${leaveId}`);

const _addEntitlementPolicy = async (data: any) =>
  apiClient.post(URL.GET_ALL_ENTITLEMENT_POLICIES, data);

const _editEntitlementPolicy = async (data: any, entitlementId: number) =>
  apiClient.patch(`${URL.GET_ALL_ENTITLEMENT_POLICIES}${entitlementId}/`, data);

const _deleteEntitlementPolicy = async (entitlementId: any) =>
  apiClient.delete(`${URL.GET_ALL_ENTITLEMENT_POLICIES}${entitlementId}/`);

const _getAllActiveEntitlementPolicies = async (leaveId: number) =>
  apiClient.get(
    `${URL.GET_ALL_ACTIVE_ENTITLEMENT_POLICIES}?leave_type_id=${leaveId}`
  );

const _addActiveEntitlementPolicy = async (data: any) =>
  apiClient.post(URL.GET_ALL_ACTIVE_ENTITLEMENT_POLICIES, data);

const _editActiveEntitlementPolicy = async (entitlementId: any, data: any) =>
  apiClient.patch(
    `${URL.GET_ALL_ACTIVE_ENTITLEMENT_POLICIES}${entitlementId}/`, data
  );

const _deleteActiveEntitlementPolicy = async (entitlementId: any) =>
  apiClient.delete(
    `${URL.GET_ALL_ACTIVE_ENTITLEMENT_POLICIES}${entitlementId}/`
  );

// Dashboard leave
const _getAdjustmentActivity = async (com_id: any, emp_id: any) =>
  apiClient.get(
    URL.GET_LEAVE_ADJUSTMENT_ACTIVITY + `?company=${com_id}&employee=${emp_id}`
  );

const _postAdjustmentActivity = async (data: {
  company: any;
  employee: any;
  leave_type: any;
  date: any;
  days: any;
  description: any;
  expiry_date: any;
}) => apiClient.post(URL.GET_LEAVE_ADJUSTMENT_ACTIVITY, data);

const _deleteAdjustmentActivity = async (
  data: {
    company: any;
    employee: any;
    leave_type: any;
    date: any;
    days: any;
    description: any;
    expiry_date: any;
  },
  id: any
) => apiClient.delete(URL.GET_LEAVE_ADJUSTMENT_ACTIVITY + `${id}/`, { data });

const _editAdjustmentActivity = async (
  data: {
    company: any;
    employee: any;
    leave_type: any;
    date: any;
    days: any;
    description: any;
    expiry_date: any;
  },
  id: any
) => apiClient.patch(URL.GET_LEAVE_ADJUSTMENT_ACTIVITY + `${id}/`, data);

const _getLeaveActivity = async (params: {
  emp_id: any;
  from: any;
  to: any;
  leave_type?: any;
}) => {
  const endPoint = params.leave_type
    ? `?employee=${params.emp_id}&from_date=${params.from}&to_date=${params.to}&leave_type=${params.leave_type}`
    : `?employee=${params.emp_id}&from_date=${params.from}&to_date=${params.to}`;
  return apiClient.get(URL.GET_LEAVE_ACTIVITY + endPoint);
};
const _postLeaveRecordTaken = async (data: any) =>
  apiClient.post(URL.LEAVE_RECORD_TAKEN, data);

const _patchLeaveRecordTaken = async (data: any, id: any) =>
  apiClient.patch(URL.LEAVE_RECORD_TAKEN + `${id}/`, data);

const _deleteLeaveRecordTaken = async (data: any, id: any) =>
  apiClient.delete(URL.LEAVE_RECORD_TAKEN + `${id}/`, data);

const _getLeaveRecordTaken = async (emp_id: any, from: any, to: any) =>
  apiClient.get(
    URL.LEAVE_RECORD_TAKEN +
      `?employee=${emp_id}&from_date=${from}&to_date=${to}`
  );
const _getLeaveBalance = async (
  emp_id: any,
  from: any,
  to: any,
  com_id: any,
  leave_type?: number
) =>
  apiClient.get(
    URL.LEAVE_RECORD_TAKEN_BALANCE +
      `employee=${emp_id}&from_date=${from}&to_date=${to}&company=${com_id}` +
      `${leave_type ? "&leave_type=" + leave_type : ""}`
  );

const _getLeaveOverviewByCompany = async (com_id: any, from: any, to: any) =>
  apiClient.get(
    URL.GET_LEAVE_OVERVIEW + `from_date=${from}&to_date=${to}&company=${com_id}`
  );

const _getPendingLeaves = async (
  com_id: any,
  from: any,
  to: any,
  status: any
) =>
  apiClient.get(
    URL.GET_UNAPPROVED_LEAVES +
      `from_date=${from}&to_date=${to}&company=${com_id}&status=${status}`
  );

const _approveLeave = async (data: any, leaveId: number) =>
  apiClient.patch(`${URL.LEAVE_RECORD_TAKEN}${leaveId}/mark_approve/`, data);

const _getRecentActivity = async (company: string, employee: string) =>
  apiClient.get(
    `${URL.GET_RECENT_ACTIVITY}company=${company}&employee${employee}`
  );

export const LeaveService = {
  _getLeaveTypes,
  _getCycleStartDateOptions,
  _addLeaveType,
  _deleteLeaveType,
  _getIndividualLeave,
  _getAllEntitlementPolicies,
  _addEntitlementPolicy,
  _getAllActiveEntitlementPolicies,
  _addActiveEntitlementPolicy,
  _editLeaveType,
  _editEntitlementPolicy,
  _getAdjustmentActivity,
  _postAdjustmentActivity,
  _deleteAdjustmentActivity,
  _editAdjustmentActivity,
  _getLeaveActivity,
  _getLeaveRecordTaken,
  _getLeaveBalance,
  _getLeaveOverviewByCompany,
  _postLeaveRecordTaken,
  _patchLeaveRecordTaken,
  _deleteLeaveRecordTaken,
  _getPendingLeaves,
  _approveLeave,
  _deleteEntitlementPolicy,
  _deleteActiveEntitlementPolicy,
  _getRecentActivity,
  _editActiveEntitlementPolicy
};
