import { useState } from "react";

import { Notify } from "../../utils";
import { ManageSDLProps } from "../../_types";
import { ManageSDLService } from "../../_service";
import { CustomTextField } from "../customFormField";
import { CustomButton } from "../customButton";

interface ManageSDLTableRowProps {
  s_no: number;
  data: ManageSDLProps;
  syncData: () => void;
}

const ManageSDLTableRow = (props: ManageSDLTableRowProps) => {
  const { data, s_no, syncData } = props;

  const [edit, setEdit] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);

  const [amount, setAmount] = useState(data.max_sdl);
  const [minSdl, setMinSdl] = useState(data.min_sdl);
  const [min_amount, setMin_amount] = useState(data.min_amount);
  const [max_amount, setMax_amount] = useState(data.max_amount);
  const [percetage, setPercetage] = useState(data.percentage);
  const toggle = () => setEdit((prev) => !prev);

  const handleSDLTableDataUpdate = async () => {
    setUpdating(true);
    const formData = {
      ...data,
      max_amount,
      min_amount,
      amount,
      percetage,
      min_sdl: minSdl
    };
    const res = await ManageSDLService._updateSDL({
      id: data.id,
      data: formData,
    });

    if (res.status === 200) {
      syncData();
      Notify("Data Updated Successfully", 1);
    } else {
      Notify("Data Updating Failed", 0);
    }

    setUpdating(false);
    setEdit(false);
  };
  return (
    <tr>
      {edit ? (
        <>
          <td>{s_no + 1}</td>
          <td>
            <CustomTextField
              width="100px"
              type="number"
              value={min_amount}
              onChangeText={setMin_amount}
            />
          </td>
          <td>
            <CustomTextField
              width="100px"
              type="number"
              value={max_amount}
              onChangeText={setMax_amount}
            />
          </td>
          <td>
            <CustomTextField
              width="100px"
              type="number"
              value={percetage}
              onChangeText={setPercetage}
            />
          </td>
          <td>
            <CustomTextField
              width="100px"
              type="number"
              value={minSdl}
              onChangeText={setMinSdl}
            />
          </td>
          <td>
            <CustomTextField
              width="100px"
              type="number"
              value={amount}
              onChangeText={setAmount}
            />
          </td>
          <td>
            <CustomButton
              size="small"
              width="70px"
              title="Update"
              onClick={handleSDLTableDataUpdate}
              disable={updating}
            />
          </td>
        </>
      ) : (
        <>
          <td>{s_no + 1}</td>
          <td>
            {`> `}
            {data.min_amount}
          </td>
          <td>
            {`<= `}
            {data.max_amount}
          </td>
          <td>
            {data.percentage}
            {` %`}
          </td>
          <td>
            {`$ `}
            {parseFloat(data.min_sdl).toFixed(2)}
          </td>
          <td>
            {`$ `}
            {parseFloat(data.max_sdl).toFixed(2)}
          </td>
          <td>
            <CustomButton
              type="secondary"
              size="small"
              width="50px"
              title="Edit"
              onClick={toggle}
            />
          </td>
        </>
      )}
    </tr>
  );
};

export default ManageSDLTableRow;
