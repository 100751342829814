import apiClient from "./BaseService";
import { URL } from "../config";

const _getAllTemplates = async (companyId: any) =>
  apiClient.get(`${URL.GET_ALL_TEMPLATES}?company_id=${companyId}`);

const _createTemplate = async (data: any) =>
  apiClient.post(URL.GET_ALL_TEMPLATES, data);

const _deleteTemplate = async (templateId: any) =>
  apiClient.delete(`${URL.GET_ALL_TEMPLATES}${templateId}`);

const _updateTemplate = async (templateId: number, data: any) =>
  apiClient.patch(`${URL.GET_ALL_TEMPLATES}${templateId}/`, data);

export const TemplateService = {
  _getAllTemplates,
  _createTemplate,
  _updateTemplate,
  _deleteTemplate,
};
