import styled from "styled-components";

const PrevNextButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;

  &:disabled {
    cursor: not-allowed;
  }
`;

export { PrevNextButton };
