import React from "react";
import styled from "styled-components";

interface MessageProps {
  data: { message: string; type: string } | undefined;
  close: () => void;
}

const Message = (props: MessageProps) => {
  const { data, close } = props;
  return !!data?.message ? (
    <Container type={data.type}>
      <Text type={data.type}>{data?.message}</Text>
      <Button type={data.type} onClick={close}>
        x
      </Button>
    </Container>
  ) : null;
};

export default Message;

const Container = styled.div<{ type: string }>`
  display: flex;
  background-color: ${(props) =>
    props.type === "error" ? "#f5c6cb" : "#d4edda"};
  padding: 0.75rem 1.25rem;
  margin-bottom: 2rem;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0.25rem;
`;
const Text = styled.div<{ type: string }>`
  color: ${(props) => (props.type === "error" ? "#721c24" : "#155724")};
  font-size: 1rem;
  font-weight: bolder;
  line-height: 1.5;
  width: 90%;
`;

const Button = styled.p<{ type: string }>`
  width: 10%;
  font-weight: bolder;
  font-size: 1.5rem;
  text-align: end;
  position: relative;
  top: -15px;
  left: 10px;
  color: ${(props) => (props.type === "error" ? "#721c24" : "#155724")};
  cursor: pointer;
  &:hover {
    color: #000;
  }
`;
