import { red } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Palette {
    button: string;
    green: string;
  }
  interface PaletteOptions {
    button: string;
    green: string;
  }
}

export const palatte = {
  primary: {
    // main: "#4a5e71",
    main: "#087ca7",
  },
  secondary: {
    main: "#19857b",
  },
  error: {
    main: red.A400,
  },
  button: "#28a745",
  green: "#25b481",
};
export default palatte;
