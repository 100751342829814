import styled from "styled-components";

interface HeaderProps {
  variant: "filled" | "outline";
}

const TableHeaderContainer = styled.thead<HeaderProps>`
  ${({ theme, variant }) =>
    variant === "filled" &&
    `
  background-color: ${theme.GRAY_4};
  `}
`;

const TableHeaderColumn = styled.th<HeaderProps>`
  text-align: left;
  padding: .3rem;
  border-top: 1px solid ${({ theme }) => theme.WHITE_2};
  font-size: .8125rem;
  ${({ theme, variant }) =>
    variant === "filled" &&
    `
  color: ${theme.WHITE};
  `}
`;

export { TableHeaderColumn, TableHeaderContainer };
