import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { PayrollDashboardProps } from "../../_types";

// initial state of user's data
const initialState: PayrollDashboardProps = {
  currentEmployee: {
    first_name: "",
    last_name: "",
    id: "",
    name: "",
    showPayroll: true,
  },
  deduction_info: {},
  employee_info: {},
  month_salary_info: {},
  payslip_month_list: [],
  salary_info: {},
  payslip_calculation_items: [],
  payslipCurrentMonth: "",
  regular_working_hours: false,
  appSettingId: "",
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    // reset the state
    resetEmployee: () => initialState,
    updateCurrentEmployee: (
      state: PayrollDashboardProps,
      action: PayloadAction<any>,
    ) => {
      state = Object.assign(state, action.payload);
    },
    // update intial state of user data
    updateEmployeeIntialState: (
      state: PayrollDashboardProps,
      action: PayloadAction<any>,
    ) => {
      current(state);
      state = Object.assign(state, action.payload);
    },
    updateCurrentPayslipDate: (
      state: PayrollDashboardProps,
      action: PayloadAction<any>,
    ) => {
      state.payslipCurrentMonth = action.payload;
    },
    updateAppSettingId: (
      state: PayrollDashboardProps,
      action: PayloadAction<any>,
    ) => {
      state.appSettingId = action.payload;
    },
  },
});

export interface Activity {
  id: number;
  full_name: string;
  serial_no: string;
  time: string;
  action_type: string;
  related_to: string;
  description: string;
  object_id: number;
  field_name: string | null;
  changed_from: any;
  changed_to: any; 
  json_data: any;
  action_taken_by: number;
  content_type: number;
}

export const {
  updateEmployeeIntialState,
  resetEmployee,
  updateCurrentEmployee,
  updateCurrentPayslipDate,
  updateAppSettingId,
} = employeeSlice.actions;
export default employeeSlice.reducer;
