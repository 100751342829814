import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DullButton from "../../../components/buttons/DullButton";
import FormButton from "../../../components/buttons/FormButton";
import {
  CustomDateField,
  CustomSelectField,
} from "../../../components/customFormField";
import { Constant } from "../../../config";
import { Notify, Utils } from "../../../utils";
import { useAppSelector } from "../../../_app";
import { CompanyPayrollService } from "../../../_service";
import { ButtonContainer } from "../AppSettingsStyles";
import { CustomButton } from "../../../components/customButton";

interface ListObject {
  item_code: string;
  item_name: string;
}

interface CpfOptions {
  listForYearOne: ListObject[];
  listForSubsequentYears: ListObject[];
}

const CPFPayrollSettings = () => {
  const [cpfOptions, setCpfOptions] = useState<CpfOptions>();
  const [yearOneSelectedOption, setYearOneSelectedOption] = useState(
    "project_using_current_ow",
  );
  const [subsequentYearSelectedOption, setSubsequentYearSelectedOption] =
    useState("use_previous_year_ow");
  const [effectiveFrom, setEffectiveFrom] = useState(Utils._date());

  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  const getCpfOptions = async () => {
    try {
      const { data, status } = await CompanyPayrollService._getCpfOptions();

      if (status === 200) {
        setCpfOptions({
          listForYearOne: data.data.list_for_year_one,

          listForSubsequentYears: data.data.list_for_subsequent_year,
        });
      }
    } catch (error) {
      Notify("Some Error Occurred", 0);
    }
  };

  const addCpfSettings = async () => {
    const formData = new FormData();

    const settingToSave = {
      list_for_year_one: yearOneSelectedOption,
      list_for_subsequent_year: subsequentYearSelectedOption,
      effective_from: effectiveFrom,
    };

    formData.append("company", user.defaultCompnayId);
    formData.append("code", "payroll_calc_cpf");
    formData.append("name", "Payroll Calculation - CPF");
    formData.append("setting", JSON.stringify(settingToSave));

    try {
      const { status } =
        await CompanyPayrollService._addBasicPaySetting(formData);

      if (status >= 200 && status < 300) {
        Notify("Setting added successfully", 1);
        navigate("/dashboard/app-settings");
      }
    } catch (error) {
      Notify("Something went wrong, please try again", 1);
      setYearOneSelectedOption(() => "project_using_current_ow");
      setSubsequentYearSelectedOption(() => "use_previous_year_ow");
      setEffectiveFrom(() => Utils._date());
    }
  };

  const getCpfDetails = async () => {
    try {
      if (user?.defaultCompnayId) {
        const { status, data } =
          await CompanyPayrollService._getPayrollSettings(
            "payroll_calc_cpf",
            parseInt(user?.defaultCompnayId),
          );
        if (status === 200) {
          if (data.data?.id) {
            setYearOneSelectedOption(
              data.data.setting?.list_for_year_one ||
                "project_using_current_ow",
            );
            setSubsequentYearSelectedOption(
              data.data.setting?.list_for_subsequent_year ||
                "use_previous_year_ow",
            );
            setEffectiveFrom(
              data.data.setting?.effective_from || Utils._date(),
            );
          }
        }
      }
    } catch (error) {
      Notify("Something went wrong, please try again", 1);
      setYearOneSelectedOption("project_using_current_ow");
      setSubsequentYearSelectedOption("use_previous_year_ow");
      setEffectiveFrom(Utils._date());
    }
  };

  useEffect(() => {
    getCpfOptions();
    getCpfDetails();
  }, []);

  return (
    <div>
      <CustomSelectField
        title={Constant.settings.cpfPayrollSettings.yearOne}
        value={yearOneSelectedOption}
        onChangeOption={(value) => setYearOneSelectedOption(() => value)}
        option={cpfOptions?.listForYearOne.map((yearOneOption) => ({
          key: yearOneOption.item_name,
          value: yearOneOption.item_code,
        }))}
      />
      <CustomSelectField
        title={Constant.settings.cpfPayrollSettings.subsequentYears}
        value={subsequentYearSelectedOption}
        onChangeOption={(value) => setSubsequentYearSelectedOption(() => value)}
        option={cpfOptions?.listForSubsequentYears.map(
          (subsequentYearOption) => ({
            key: subsequentYearOption.item_name,
            value: subsequentYearOption.item_code,
          }),
        )}
      />

      <hr />
      <CustomDateField
        label={Constant.settings.cpfPayrollSettings.effectiveFrom}
        date={effectiveFrom}
        setDate={(date: any) => setEffectiveFrom(() => date)}
      />
      <ButtonContainer>
        <CustomButton
          title={Constant.common.cancel}
          type="secondary"
          onClick={() => navigate("/dashboard/app-settings")}
        />
        <CustomButton title={Constant.common.save} onClick={addCpfSettings} />
      </ButtonContainer>
    </div>
  );
};

export default CPFPayrollSettings;
