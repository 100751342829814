import styled from "styled-components";

const EntitlmentPolicyTableHeading = styled.th`
  width: 200px;
  text-align: left;
`;

const EntitlmentPolicyTableRow = styled.tr`
  &:nth-child(2n + 1) {
    background-color: ${({ theme }) => theme.GRAY_3};
  }
`;
const EntitlmentPolicyTableColumn = styled.td`
  padding: 5px;
`;

const LeaveRecordTakenText = styled.p`
  padding: 10px;
`;
const BackButtonContainer = styled.div`
  margin-top: 20px;
`;
const AllEntitlementSubHeading = styled.h4`
  font-size: 20px;
  font-weight: 500;
  color: #333;
  padding-top: 0px;
`;

export {
  EntitlmentPolicyTableHeading,
  EntitlmentPolicyTableRow,
  EntitlmentPolicyTableColumn,
  LeaveRecordTakenText,
  BackButtonContainer,
  AllEntitlementSubHeading,
};
