import { IconContext } from "react-icons";
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi";
import { TiMediaPlay, TiMediaPlayReverse } from "react-icons/ti";
import { PrevNextButton } from "./ComponentStyles";

interface TablePaginationHeaderProps {
  name: string;
  pageInfo: {
    currentPageNumber: number;
    dataPerPage: number;
    totalLength: number;
  };
  handlePageValueChange: (pageNo: number) => void;
}
const TablePaginationHeader = (props: TablePaginationHeaderProps) => {
  const { handlePageValueChange, name, pageInfo } = props;

  const totalPages = Math.ceil(pageInfo.totalLength / pageInfo.dataPerPage);

  return (
    <div
      style={{
        display: "flex",
        alignContent: "space-between",
        width: "100%",
        border: "1px solid gray",
        padding: "3px .6rem",
        borderTopLeftRadius: ".3rem",
        borderTopRightRadius: ".3rem",
        fontSize: '0.8125rem'
      }}
    >
      <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
        {name}:{" "}
        {`${
          pageInfo.totalLength === 0
            ? 0
            : pageInfo.currentPageNumber * pageInfo.dataPerPage -
              pageInfo.dataPerPage +
              1
        }-${
          pageInfo.currentPageNumber * pageInfo.dataPerPage >
          pageInfo.totalLength
            ? pageInfo.totalLength
            : pageInfo.currentPageNumber * pageInfo.dataPerPage
        }`}{" "}
        / {pageInfo.totalLength}
      </div>
      <div
        style={{
          width: "55%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <PrevNextButton
          onClick={() => handlePageValueChange(1)}
          disabled={pageInfo.currentPageNumber === 1}
        >
          <IconContext.Provider
            value={{
              color: pageInfo.currentPageNumber === 1 ? "#545b62" : "#000",
              size: "25px",
            }}
          >
            <BiSkipPrevious />
          </IconContext.Provider>
        </PrevNextButton>
        <PrevNextButton
          onClick={() => handlePageValueChange(pageInfo.currentPageNumber - 1)}
          disabled={pageInfo.currentPageNumber === 1}
        >
          <IconContext.Provider
            value={{
              color: pageInfo.currentPageNumber === 1 ? "#545b62" : "#000",
              size: "20px",
            }}
          >
            <TiMediaPlayReverse />
          </IconContext.Provider>
        </PrevNextButton>
        Page
        <select
          style={{
            border: "none",
            padding: 0,
            margin: ".2rem",
            cursor: "pointer",
            fontSize: "0.8125rem"
          }}
          value={pageInfo.currentPageNumber}
          onChange={(e) => handlePageValueChange(parseInt(e.target.value))}
        >
          {Array(totalPages)
            .fill(0)
            .map((_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
        </select>
        of {totalPages}
        <PrevNextButton
          onClick={() => handlePageValueChange(pageInfo.currentPageNumber + 1)}
          disabled={
            pageInfo.currentPageNumber === totalPages || totalPages === 0
          }
        >
          <IconContext.Provider
            value={{
              color:
                pageInfo.currentPageNumber === totalPages || totalPages === 0
                  ? "#545b62"
                  : "#000",
              size: "20px",
            }}
          >
            <TiMediaPlay />
          </IconContext.Provider>
        </PrevNextButton>
        <PrevNextButton
          onClick={() => handlePageValueChange(totalPages)}
          disabled={
            pageInfo.currentPageNumber === totalPages || totalPages === 0
          }
        >
          <IconContext.Provider
            value={{
              color:
                pageInfo.currentPageNumber === totalPages || totalPages === 0
                  ? "#545b62"
                  : "#000",
              size: "25px",
            }}
          >
            <BiSkipNext />
          </IconContext.Provider>
        </PrevNextButton>
      </div>
      <div style={{ width: "100%" }}></div>
    </div>
  );
};

export default TablePaginationHeader;
