import React from "react";
import styled from "styled-components";

const Require = () => {
  return <Span>*</Span>;
};

export default Require;
const Span = styled.span`
  color: red;
  font-size: 0.7rem;
`;
