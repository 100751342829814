import { useEffect, useState } from "react";
import { RequestTable } from "../RequestTable";
import { useAppSelector } from "../../../_app";
import { DashboardService } from "../../../_service/DashboardService";
import { Notify } from "../../../utils";
import { Constant } from "../../../config";
import { LoaderContainer } from "../../../CommonStyles";
import { Loader } from "../../../components";

const CompletedDashboard = () => {
  const [requestData, setRequestData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const user = useAppSelector((state) => state.user);

  const getSubmittedRequests = async () => {
    console.log("parasite")
    setIsLoading(() => true);
    try {
      const { status, data } = await DashboardService._getCompletedRequests(
        user.defaultCompnayId,
        "Completed"
      );
      if (status === 200) {
        setRequestData(() => data.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    getSubmittedRequests();
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <RequestTable data={requestData} />
      )}
    </div>
  );
};

export default CompletedDashboard;
