import React from "react";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../_app";

import { EmployeeService } from "../../../_service";
import { Activity } from "./../../../_features/employee/index";
import "./payroll.css";
import { Utils } from "../../../utils";
import { LoaderContainer } from "../../../CommonStyles";
import { Loader } from "../../../components";
import moment from "moment";

const EmployeeActivity = () => {
  const emp = useAppSelector((state) => state.employee.currentEmployee);
  const [activityList, setActivityList] = useState<Activity[]>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(true);

  const getEmployeeActivityList = async () => {
    setIsLoading(true);
    const empId = emp.id;
    if (empId) {
      try {
        const response = await EmployeeService._getEmployeeChangeLogs(empId);
        setActivityList(response.data.data);
        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching activity list:", error);
        setIsLoading(false)
      }
    }
  };

  useEffect(() => {
    getEmployeeActivityList();
  }, [emp.id]);

  return (
    <>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <div>
            <Heading className="payroll-header-h2">
              Activity - {`${emp?.name}`}
            </Heading>
          </div>
          <div className="employee-payroll">
            <ul>
              {activityList?.length >= 1 && activityList?.map((activity) => (
                <li key={activity.id}>
                  {/* {activity.description} ({Utils.formatTimestamp(activity.time)}{" "}
                  by {activity.full_name}) */}
                  {/* {activity.description} ({moment(activity.time).format("DD-MM-YYYY hh:mm A")} by {activity.full_name}) */}
                  {activity.description} ({moment(moment.tz(activity.time, 'Asia/Singapore')).format("DD-MM-YYYY hh:mm A")} by {activity.full_name})
                  
                </li>
              ))}
            </ul>
            <NavLink
              className="link"
              to={{
                pathname: "/dashboard/payroll/emp",
              }}
            >
              Back to employee
            </NavLink>
          </div>
        </>
      )}
    </>
  );
};

export default EmployeeActivity;

const Heading = styled.h2`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;
