import { useEffect, useState } from "react";

import { ManageSDLService } from "../../_service";
import { ManageSDLTableRow } from "../../components/dashboard";
import { ManageSDLProps } from "../../_types";
import { Loader } from "../../components";

const ManageSDL = () => {
  const [sdlData, setSdlData] = useState<ManageSDLProps[]>([]);
  const [loading, setLoading] = useState(false);

  const get = async () => {
    try {
      setLoading(true);

      const res = await ManageSDLService._getSDL();
      if (res.status === 200) {
        setSdlData(() => res.data);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      if (e.response.status === 401) {
        // disaptch(resetUser());
      }
    }
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <div
      className="cpf-container"
      style={{ position: "relative", maxHeight: "100vh", overflow: "scroll" }}
    >
      {loading && <Loader />}
      <div className="manage-pf-wraps">
        <h2> SDL Payable</h2>
        <p className="grey"> SDL Payable </p>
      </div>
      <div className="manage-pf-wrap-select">
        <div className="table">
          <table className="company-table">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Min Salary</th>
                <th>Max Salary</th>
                <th>Percentage</th>
                <th>Min SDL</th>
                <th>Max SDL</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sdlData?.map((item, index) => (
                <ManageSDLTableRow
                  key={item.id}
                  data={item}
                  s_no={index}
                  syncData={get}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ManageSDL;
