import { ColorRing } from "react-loader-spinner";

const CustomLoader = (props:any) => {

  let colorCode: any = props.color || "#fff"
  return (
    <div>
      <ColorRing
        visible={true}
        height="23"
        width="23"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        // colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
        colors={[
          `${colorCode}`,
          `${colorCode}`,
          `${colorCode}`,
          `${colorCode}`,
          `${colorCode}`,
        ]}
      />
    </div>
  );
};

export default CustomLoader;
