import React, { useEffect, useRef, useState } from "react";
import { H3Heading } from "../../appSettings/AppSettingsStyles";
import { useAppSelector } from "../../../_app";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ApprovalDetailsContainer,
  LeaveApprovalHeading,
  LeaveApprovalOptions,
  LeaveContainerHeading,
  LeaveDetailBigHeading,
  LeaveDetailContent,
  LeaveDetailWrapper,
  LeaveDetailsContainer,
  LeaveRecordSpan,
  LeaveTable,
} from "../ApprovalStyles";
import { Notify, Utils } from "../../../utils";
import { LeaveService } from "../../../_service";
import { Constant } from "../../../config";
import { LoaderContainer } from "../../../CommonStyles";
import { CustomButton, Loader, Tooltip } from "../../../components";
import { FaFileUpload } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";

const LeaveApprovalDetails = () => {
  const user = useAppSelector((state) => state.user);
  const { state } = useLocation();
  const navigate = useNavigate();

  const [leaveBalancesList, setLeaveBalancesList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [fileUrl, setFileUrl] = useState<any>(null);
  const [currentApprovalStatus, setCurrentApprovalStatus] = useState(
    state.data.is_approved
      ? "approve"
      : state.data.is_approved === null
      ? "decision"
      : "deny"
  );
  const [recentActivity, setRecentActivity] = useState<any>([]);
  const [approvalComment, setApprovalComment] = useState("");

  const fileInputRef = useRef(null);

  const handleFileChange = () => {
    const file = (fileInputRef.current as any).files[0];
    if (file) {
      setFileUrl(() => file);
    }
  };

  const getLeaveBalance = async () => {
    setIsLoading(true);
    try {
      const res = await LeaveService._getLeaveBalance(
        state.data.employee,
        `${state.from.split("-")[0]}-01-01`,
        `${state.to.split("-")[0]}-12-31`,
        state.data.company
      );
      if (res.status === 200) {
        const _leaveBalanceList: any = Object.entries(res.data.data).map(
          (item) => item[1]
        );
        setLeaveBalancesList(() => _leaveBalanceList.flat());
      }
    } catch (e: any) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  const handleRadioChange = (event: any) => {
    setCurrentApprovalStatus(event.target.id);
  };

  const handleApproveReject = async () => {
    try {
      const approveStatus =
        currentApprovalStatus === "approve"
          ? true
          : currentApprovalStatus === "deny"
          ? false
          : null;
      const updatedData = new FormData();

      updatedData.append("is_approve", `${approveStatus}`)
      updatedData.append("comment", `${approvalComment}`)
      updatedData.append("approval_document", fileUrl)

      const { status } = await LeaveService._approveLeave(
        updatedData,
        state.data.id
      );

      if (status === 200) {
        navigate(-1);
        Notify(
          `Leave ${approveStatus === true ? "Approved" : approveStatus === false ? "Rejected" : "Updated"} successfully`,
          1
        );
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const getRecentActivity = async () => {
    setIsLoading(true);
    try {
      const res = await LeaveService._getRecentActivity(
        state.data.company,
        state.data.employee
      );
      if (res.status === 200) {
        setRecentActivity(() => res.data.data);
      }
    } catch (e: any) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    getLeaveBalance();
    getRecentActivity();
    if(state.data.approval_document) {
      setIsFileUpload(() => true);
    }
  }, []);

  return isLoading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <div>
      <H3Heading>Leave Request - {user.name}</H3Heading>
      <p>
        Click <Link to="/dashboard/leave-overview">here</Link> to view Leave
        Overview Calendar
      </p>
      <ApprovalDetailsContainer>
        <LeaveDetailsContainer>
          <LeaveContainerHeading>Request Information</LeaveContainerHeading>
          <LeaveDetailWrapper>
            <LeaveDetailBigHeading> Days Requested </LeaveDetailBigHeading>
            <LeaveDetailContent>
              {state.data.leave_name}: {Utils._dateToShow(state.data.date_from)}{" "}
              to {Utils._dateToShow(state.data.date_to)} ({" "}
              {state.data.date_json.length} working days)
            </LeaveDetailContent>
            <LeaveDetailBigHeading> State </LeaveDetailBigHeading>
            <LeaveDetailContent>
              {state.data?.is_approved
                ? "Approved"
                : state.data?.is_approved === null
                ? "Pending"
                : "Rejected"}
            </LeaveDetailContent>
          </LeaveDetailWrapper>
        </LeaveDetailsContainer>
        <LeaveDetailsContainer>
          <LeaveContainerHeading>Leave Balances</LeaveContainerHeading>
          <LeaveDetailWrapper>
            <div style={{ padding: "1rem" }}>
              <LeaveTable>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Days</th>
                    <th>Last Updated</th>
                  </tr>
                </thead>
                <tbody>
                  {leaveBalancesList.map((item: any) => {
                    return (
                      <tr>
                        <td>{item.leave_name}</td>
                        <td>{item.leave_balance}</td>
                        <td>{Utils._dateToShow(item.last_updated)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </LeaveTable>
              <LeaveRecordSpan>
                These balances take into account days included in pending leave
                requests.
              </LeaveRecordSpan>
            </div>
          </LeaveDetailWrapper>
        </LeaveDetailsContainer>
        <LeaveDetailsContainer>
          <LeaveContainerHeading>
            Projected Leave Balances
          </LeaveContainerHeading>
          <LeaveDetailWrapper>
            <p>
              The following table shows how this request will affect this
              employee's leave balances.
            </p>
            <div style={{ padding: "1rem" }}>
              <LeaveTable>
                <thead>
                  <tr>
                    <th style={{ width: "70%" }}>Date</th>
                    <th> {state.data.leave_name}</th>
                  </tr>
                </thead>
                <tbody>
                  {state.data.date_json.map((item: any, index: number) => {
                    return (
                      <tr>
                        <td>{Utils._dateToShow(item.date)}</td>
                        <td style={{ color: "#dc3545"}}>{(-1* (index+1)).toFixed(2)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </LeaveTable>
              <LeaveRecordSpan>
                These balances take into account days included in pending leave
                requests.
              </LeaveRecordSpan>
            </div>
          </LeaveDetailWrapper>
        </LeaveDetailsContainer>
      </ApprovalDetailsContainer>
      <ApprovalDetailsContainer>
        <LeaveDetailsContainer>
          <LeaveContainerHeading>Actions</LeaveContainerHeading>
          <LeaveDetailWrapper>
            <LeaveApprovalHeading> Supporting Documents </LeaveApprovalHeading>
            <CustomButton
              title="Upload New Files"
              onClick={() => setIsFileUpload(() => true)}
              width="150px"
              styles={{ fontSize: 14, margin: 0, marginTop: 20 }}
            />
            <p style={{ margin: "20px 0" }}>
              {!fileUrl && !isFileUpload && "No file uploaded"}
            </p>
            {isFileUpload && (
              <div
                style={{
                  display: "flex",
                  marginTop: "0.8rem",
                  gap: "0.25rem",
                  alignItems: "center",
                }}
              >
                <p style={{ color: "#495057", width: "30%", maxWidth: 250 }}>
                  Choose file
                </p>
                <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                  <input
                    type="file"
                    name="manual-file"
                    onChange={() => handleFileChange()}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                  <Tooltip text={fileUrl ? "Change" : "Upload"}>
                    <CustomButton
                      size="small"
                      width="30px"
                      onClick={() => (fileInputRef.current as any).click()}
                      title=""
                      icon={<FaFileUpload />}
                    />
                  </Tooltip>
                  <span>{fileUrl?.name || state?.data?.approval_document?.split("/")?.pop() || ""}</span>
                  {fileUrl && (
                    <Tooltip text="Delete" del>
                      <CustomButton
                        type={"secondary"}
                        size="small"
                        del
                        width="30px"
                        title={""}
                        onClick={() => {
                          setFileUrl(() => null);
                          (fileInputRef.current as any).value = "";
                        }}
                        icon={<AiFillDelete />}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
            {state.data.is_approved === null && (
              <>
                <LeaveApprovalHeading> Respond </LeaveApprovalHeading>
                <LeaveApprovalOptions>
                  <input
                    type="radio"
                    name="status"
                    id="approve"
                    onChange={handleRadioChange}
                    checked={currentApprovalStatus === "approve"}
                  />
                  <p>Approve</p>
                </LeaveApprovalOptions>
                <LeaveApprovalOptions>
                  <input
                    type="radio"
                    name="status"
                    id="deny"
                    onChange={handleRadioChange}
                    checked={currentApprovalStatus === "deny"}
                  />
                  <p>Deny</p>
                </LeaveApprovalOptions>
                <LeaveApprovalOptions>
                  <input
                    type="radio"
                    name="status"
                    id="decision"
                    onChange={handleRadioChange}
                    checked={currentApprovalStatus === "decision"}
                  />
                  <p>No decision</p>
                </LeaveApprovalOptions>
              </>
            )}
            <p style={{ fontWeight: 400, fontSize: 20, marginTop: 20 }}>
              Comment:{" "}
            </p>
            <textarea
              cols={150}
              rows={3}
              onChange={(e) => setApprovalComment(e.target.value)}
            ></textarea>
            <CustomButton
              width="100px"
              title="Save"
              onClick={handleApproveReject}
              styles={{ margin: 0, marginTop: 20 }}
            />
          </LeaveDetailWrapper>
        </LeaveDetailsContainer>
      </ApprovalDetailsContainer>
      <ApprovalDetailsContainer>
        <LeaveDetailsContainer>
          <LeaveContainerHeading>Recent Activity</LeaveContainerHeading>
          <LeaveDetailWrapper>
            {recentActivity.map((activity: any) => (
              <div style={{ display: "flex", gap: 10 }}>
                <div>
                  {Utils._dateToShow(activity.updated_at)}
                </div>
                <div>
                  {activity.employee_name}
                </div>
                <div>
                  {activity.is_approved ? "Approved": state.data.is_approved === null ? "Request Submitted": "Rejected"}
                </div>
              </div>
            ))}
          </LeaveDetailWrapper>
        </LeaveDetailsContainer>
      </ApprovalDetailsContainer>
    </div>
  );
};

export default LeaveApprovalDetails;
