import ReactPDF from "@react-pdf/renderer";
import { useAppSelector } from "../../../_app";
import { ReportService } from "../../../_service/ReportService";
import { Constant } from "../../../config";
import { Notify, Utils } from "../../../utils";
import GeneralReportFilterPage from "../GeneralReportFilterPage";
import PayslipExportPdf from "../ReportPdf/PayslipExportPdf";
import JSZip from "jszip";
import PayslipExportExtraComp from "./PayslipExportExtraComp";

const PayslipExport = () => {
  const user = useAppSelector((state) => state.user);

  const generatePdf = async (
    data: any,
    query: string,
    combinePayslip: boolean
  ) => {
    const pattern = /date_from=(\d{4}-\d{2}-\d{2})&date_to=(\d{4}-\d{2}-\d{2})/;
    const match = pattern.exec(query) as string[];
    const dateFrom = match[1];
    const dateTo = match[2];
    const zip = new JSZip();

    if (combinePayslip) {
      const pdfBlob = await ReactPDF.pdf(
        <PayslipExportPdf
          documents={data}
          query={`${Utils._dateToShow(dateFrom)} to ${Utils._dateToShow(
            dateTo
          )}`}
        />
      ).toBlob();
      const url = URL.createObjectURL(pdfBlob as any);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${
        user.defaultCompany.split(" ")[0]
      }_payslip_export_report.pdf`; // Set the desired filename for the downloaded file
      document.body.appendChild(a);
      a.click();
    } else {
      for (let i = 0; i < data.length; i++) {
        const dataToSend = [data[i]];
        const pdfContent = await ReactPDF.pdf(
          <PayslipExportPdf
            documents={dataToSend}
            query={`${Utils._dateToShow(dateFrom)} to ${Utils._dateToShow(
              dateTo
            )}`}
          />
        ).toBlob();
        zip.file(
          `document_${data[i].employee.user.name.split(" ")[0]}.pdf`,
          pdfContent
        );
      }
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const url = URL.createObjectURL(zipBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${user.defaultCompany.split(" ")[0]}_payslip_export.zip`;

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document body
      document.body.removeChild(link);
    }
  };
  const getPayslipExportData = async (
    searchQuery: string,
    combinePayslip: boolean
  ) => {
    try {
      const { status, data } = await ReportService._payslipExportData(
        searchQuery
      );

      if (status === 200) {
        generatePdf(data.data, searchQuery, combinePayslip);
      }
    } catch (err) {
      console.log(err);
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  return (
    <div>
      <PayslipExportExtraComp downloadFunc={getPayslipExportData} />
    </div>
  );
};

export default PayslipExport;
