import { useEffect, useState } from "react";

import { ManageCPFCal } from "../../_service";
import { CPFLimitDataProps } from "../../_types";
import { CustomButton, Loader } from "../../components";
import { useAppSelector } from "../../_app";
import { useNavigate } from "react-router-dom";
import ManageCPFLimitRow from "./ManageCPFLimitRow";
import { H3Heading } from "../appSettings/AppSettingsStyles";
import ManageAWLimitRow from "./ManageAWLimitRow";

const ManageCPFLimit = () => {
  const [cpfData, setCpfData] = useState<CPFLimitDataProps[]>([]);
  const [awData, setAwData] = useState<CPFLimitDataProps[]>([]);
  const [loading, setLoading] = useState(false);

  const user = useAppSelector((state) => state.user);

  const navigate = useNavigate();

  const getCPFDataByCategory = async () => {
    try {
      setLoading(true);

      const res = await ManageCPFCal._getCPfLimit();
      if (res.status === 200) {
        setCpfData(() => res.data);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);

      if (e.response.status === 401) {
        // disaptch(resetUser());
      }
    }
  };

  const getAwDataByCategory = async () => {
    try {
      setLoading(true);

      const res = await ManageCPFCal._getAwLimit();
      if (res.status === 200) {
        setAwData(() => res.data);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);

      if (e.response.status === 401) {
        // disaptch(resetUser());
      }
    }
  };

  useEffect(() => {
    getCPFDataByCategory();
    getAwDataByCategory();
  }, []);

  return (
    <div className="cpf-container" style={{ position: "relative" }}>
      {loading && <Loader />}
      <div className="cpf-limit-heading-container">
        <div className="manage-pf-wraps">
          <H3Heading>Select the OW Limit to update values</H3Heading>
          <p className="grey">Manage OW Limit / Edit OW Limit</p>
        </div>
        <CustomButton
          title="Add OW Limit"
          onClick={() => navigate("add-ow")}
          styles={{ height: 40 }}
        />{" "}
      </div>
      <div className="table">
        <table className="company-table">
          <thead className="company-table-head">
            <tr>
              <th>Effective From</th>
              <th>Effective To</th>
              <th style={{'width':'25%' , 'paddingLeft' :'0px'}}>Max OW</th>
              {user.permission === "full" && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {cpfData.map(
              (item, index) =>
                item.ow ? (
                  <ManageCPFLimitRow
                    key={item.id}
                    data={item}
                    syncData={getCPFDataByCategory}
                  />
                ) : null
            )}
          </tbody>
        </table>
      </div>
      <div className="cpf-limit-heading-container" style={{ marginTop: 20}}>
        <div className="manage-pf-wraps">
          <H3Heading>{`Select Total Wage (OW + AW) Limit to update values`}</H3Heading>
          <p className="grey">{`Manage Total Wage (OW + AW) Limit Limit`}</p>
        </div>
        <CustomButton
          title={`Add Total Wage (OW + AW) Limit`}
          onClick={() => navigate("add-aw")}
          styles={{ height: 40 }}
          width="full"
        />{" "}
      </div>
      <div className="table">
        <table className="company-table">
          <thead className="company-table-head">
            <tr>
              <th>Effective From</th>
              <th >Effective To</th>
              <th style={{'width':'25%' ,'paddingLeft' :'0px' }}>Total Wage (OW + AW) Max</th>
              {user.permission === "full" && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {awData.map(
              (item, index) =>
                item.aw ? (
                  <ManageAWLimitRow
                    key={item.id}
                    data={item}
                    syncData={getAwDataByCategory}
                  />
                ) : null
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageCPFLimit;
