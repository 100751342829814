import { useEffect, useState } from "react";

import { ManageDeductionService } from "../../_service";
import { ManageDeductionTableRow } from "../../components/dashboard";
import { DeductionTableDataType, DeductionTypeProps } from "../../_types";
import { CustomSelectField } from "../../components/customFormField";
import { CustomButton, Loader } from "../../components";
import { useAppSelector } from "../../_app";
import { useNavigate } from "react-router-dom";

const ManageDeduction = () => {
  const [deductionTypeData, setDeductionTypeData] = useState<
    DeductionTypeProps[]
  >([]);
  const [deductionTableData, setDeductionTableData] = useState<
    DeductionTableDataType[]
  >([]);

  const [deductionType, setDeductionType] = useState(-1);
  const [loading, setLoading] = useState(false);

  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const getDeductionTableData = async () => {
    try {
      setLoading(true);

      const res =
        await ManageDeductionService._getDeductionDataByCategory(deductionType);
      if (res.status === 200) {
        setDeductionTableData(() => res.data);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      if (e.response.status === 401) {
        // disaptch(resetUser());
      }
    }
  };
  const getDeductionCategory = async () => {
    try {
      setLoading(true);

      const res = await ManageDeductionService._getDeductionCategory();
      if (res.status === 200) {
        setDeductionTypeData(() => res.data);
        setDeductionType(() => res.data[0].id);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      if (e.response.status === 401) {
        // disaptch(resetUser());
      }
    }
  };
  const handleDeductionTypeChange = (e: any) => {
    setDeductionType(() => e);
  };

  useEffect(() => {
    getDeductionTableData();
  }, [deductionType]);

  useEffect(() => {
    getDeductionCategory();
  }, []);

  return (
    <div
      className="cpf-container"
      style={{ position: "relative", maxHeight: "100vh", overflow: "scroll" }}
    >
      {loading && <Loader />}
      <div className="manage-pf-wraps" style={{
        marginBottom: '0.5rem'
      }}>
        <h2>Manage Deduction</h2>
        <p className="grey">Manage Deduction</p>
      </div>

      <div >
        <div className="manage-pf-wrap-select" style={{ display: 'flex', justifyContent: "center", alignItems: 'center', marginTop:0 }}>
          <CustomSelectField
            title="Select Deduction Category"
            value={deductionType}
            onChangeOption={(text: string) => setDeductionType(parseInt(text))}
            option={deductionTypeData?.map((item) => ({
              key: item.race,
              value: item.id,
            }))}
          />

          <CustomButton
            title="Add Deduction"
            onClick={() => navigate("add")}
            styles={{ height: 40 }}
          />
        </div>

        <div className="table">
          <table className="company-table">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Min Amount</th>
                <th>Max Amount</th>
                <th>Deduction Amount</th>
                {deductionType === 3 && <th>MBMF</th>}
                {deductionType === 3 && <th>Mendaki</th>}
                {user.permission === "full" && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {deductionTableData.map((item, index) => (
                <ManageDeductionTableRow
                  key={item.id}
                  data={item}
                  s_no={index}
                  syncData={getDeductionTableData}
                  isMalay={deductionType === 3}
               
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ManageDeduction;
