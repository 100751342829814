import axios from "axios";

import { store } from "../_app/store";
import { resetUser, updateUserIntialState } from "../_features";

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    const timeElapsed = Date.now() - store.getState().user.lastLoggedInTime;
    if (
      timeElapsed > 7200000 &&
      store.getState().user.lastLoggedInTime !== null
    ) {
      store.dispatch(resetUser());
    }
    if (
      store.getState().user.permission === "read" &&
      (config.method === "post" ||
        config.method === "patch" ||
        config.method === "delete")
    ) {
      throw new axios.Cancel("You are not allowed this operation");
    }
    if (store.getState().user.access) {
      config.headers["Authorization"] = `Bearer ${
        store.getState().user.access
      }`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },

  async function (error) {
    if (error.response.status === 401) {
      store.dispatch(updateUserIntialState({ isSessionActive: false }));
      // if (store.getState().user?.access) {
      // 	try {
      // 		const tokenResponse = await AuthService._refressToken();
      // 		const payloadData = {
      // 			access: tokenResponse.data.access,
      // 			refresh: tokenResponse.data.refresh,
      // 		};
      // 		store.dispatch(updateUserIntialState(payloadData));
      // 	} catch (error) {
      // 		window.location.href = "/";
      // 	}
      // } else {
      // 	window.location.href = "/";
      // }
    }

    return Promise.reject(error);
  }
);

export default axios;
