import { Document, Page, StyleSheet } from "@react-pdf/renderer";

import { Font } from "@react-pdf/renderer";
import NotoSansRegular from "../../../fonts/NotoSans-Bold.ttf";
import NotoSansBold from "../../../fonts/NotoSans-Regular.ttf";

Font.register({
  family: "Noto Sans",
  fonts: [
    {
      src: NotoSansRegular,
      fontWeight: 400,
    },
    {
      src: NotoSansBold,
      fontWeight: 700,
      fontStyle: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    // backgroundColor: "#d11fb6",
    color: "black",
    fontSize: "8px",
    padding: "20px 40px 40px 40px",
  },
  section: {
    margin: 10,
    padding: 10,
  },
});

// Create Document Component
function LeaveDaysReportPdfDoc({
  LeaveReportDocument = null,
}: {
  LeaveReportDocument?: React.ReactElement | null;
}) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {LeaveReportDocument}
      </Page>
    </Document>
  );
}
export default LeaveDaysReportPdfDoc;
