import styled from "styled-components";

const ButtonContainer = styled.button`
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.BLUE_6};
  background-color: ${({ theme }) => theme.BLUE_6};
  color: ${({ theme }) => theme.WHITE};
  padding: 7px;
  border-radius: 5px;
`;

const ErrorButtonContainer = styled.button`
  border: 1px solid ${({ theme }) => theme.RED_1};
  background-color: ${({ theme }) => theme.WHITE};
  cursor: pointer;
  padding: 7px;
  border-radius: 5px;
  color: ${({ theme }) => theme.RED_1};
  &:hover {
    background-color: ${({ theme }) => theme.RED_1};
    color: ${({ theme }) => theme.WHITE};
  }
`;

const DullButtonContainer = styled.button`
  cursor: pointer;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.GRAY};
  background-color: ${({ theme }) => theme.GRAY};
  color: ${({ theme }) => theme.WHITE};
`;

const SuccessButtonContainer = styled.button`
  cursor: pointer;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.GREEN_1};
  background-color: ${({ theme }) => theme.GREEN_1};
  color: ${({ theme }) => theme.WHITE};
`;

export {
  ButtonContainer,
  ErrorButtonContainer,
  DullButtonContainer,
  SuccessButtonContainer,
};
