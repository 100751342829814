import React, { useState } from "react";
import { Modal, Box, Typography, Divider, Button } from "@mui/material";

import { PayrunService } from "../../_service";
import { Notify, Utils } from "../../utils";
import { Constant } from "../../config";
import { useAppSelector } from "../../_app";
import { useDispatch } from "react-redux";
import { updateUserIntialState } from "../../_features";
import { ColorRing, } from "react-loader-spinner";
import CustomLoader from "../CustomLoader";

interface CreatePayrunModalProps {
  open: boolean;
  handleClose: () => void;
  clearPreviousState: () => void;
  date: string;
  companyId: number;
  pendingCount: number;
}

const style = {
  position: "absolute",
  top: "40px",
  left: "50%",
  transform: "translateX(-50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "4px",
  p: 2,
  border: "none",
  "&:focus": {
    outline: "none",
  },
};

const warning = {
  background: "#fff3cd",
  color: "#856404",
  padding: "10px 16px",
};

function CreatePayrunModal({
  open,
  handleClose,
  companyId,
  pendingCount,
  date,
  clearPreviousState,
}: CreatePayrunModalProps) {
  const [checked, setChecked] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false)

  const user = useAppSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleCreatePayrun = async () => {
    setLoading(true);
    try {
      const { status } = await PayrunService._createPayrun({
        company: `${companyId}`,
        payslip_date: date,
      });
      if (status >= 200 && status < 300) {
        setLoading(false);
        clearPreviousState();
        handleClose();
        const { nextYear, nextMonth } = Utils.getNextMonthYear(
          user.current_payrun_date
        );
        const nextMonthDate = Utils.getLastDayOfMonth(nextYear, nextMonth);
        dispatch(
          updateUserIntialState({
            ...user,
            current_payrun_date: nextMonthDate,
          })
        );
      }
    } catch (error: any) {
      setLoading(false);
      handleClose();
      if (error?.response?.data?.Payrun) {
        Notify(error?.response?.data?.Payrun, 0);
      } else if (error?.response?.data?.payslip_date) {
        Notify(error?.response?.data?.payslip_date, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
    
  };

  let disabled = false;
  if (pendingCount > 0) {
    if (!checked) {
      disabled = true;
    } else {
      disabled = false;
    }
  } else {
    disabled = false;
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create Pay Run: Monthly - {date}
        </Typography>
        <Box sx={{ margin: "12px -16px" }}>
          <Divider light={true} />
        </Box>
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
          Ready to CLOSE current payroll and CREATE  Next Month Payroll?
        </Typography>
        {pendingCount > 0 && (
          <Box sx={warning}>
            <Typography sx={{ marginBottom: "14px" }}>
              Warning: There are 2 pending payslips that will be excluded from
              this pay run. If you confirm you will need to create another pay
              run later after finalising them.
            </Typography>
            <Box sx={{ display: "flex" }}>
              <input
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                type="checkbox"
              />
              <Typography sx={{ marginLeft: "4px" }}>
                Continue without the pending payslips?
              </Typography>
            </Box>
          </Box>
        )}
        <Box sx={{ margin: "0 -16px 32px -16px" }}>
          <Divider light={true} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", marginTop: "16px" }}>
          <Box>
            <Button
              onClick={handleCreatePayrun}
              disabled={disabled || loading}
              variant="contained"
            >
              {loading && <CustomLoader />}
              Yes,Please Create Next Month Payroll
            </Button>
            <Button onClick={handleClose} sx={{ ml: 0.8 }}>
              NO-Stop
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default CreatePayrunModal;
