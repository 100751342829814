import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";

// User data types
interface DataType {
  message: string;
  type: string;
}
interface Props {
  login: DataType;
  resetPassword: DataType;
  createPassword: DataType;
  verifyPassword: DataType;
}

// initial state of user's data
const initialState: Props = {
  login: { message: "", type: "error" },
  resetPassword: { message: "", type: "error" },
  createPassword: { message: "", type: "error" },
  verifyPassword: { message: "", type: "error" },
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    // reset the state
    resetMessages: () => initialState,
    // update intial state of user data
    updateMessage: (
      state: Props,
      action: PayloadAction<{ page: string; type: string; message: string }>
    ) => {
      current(state);
      const { message, page, type } = action.payload;
      switch (page) {
        case "login": {
          state.login = { message, type };
          break;
        }
        case "resetPassword": {
          state.resetPassword = { message, type };
          break;
        }
        case "createPassword": {
          state.createPassword = { message, type };
          break;
        }
        case "verifyPassword": {
          state.verifyPassword = { message, type };
          break;
        }
        default: {
          break;
        }
      }
    },
  },
});

export const { resetMessages, updateMessage } = messagesSlice.actions;
export default messagesSlice.reducer;
