import apiClient from "./BaseService";
import { URL } from "../config";

const _getPendingApprovalList = async (id: string, payslipDate: any) =>
  apiClient.get(
    URL.GET_PENDING_APPROVAL_LIST + id + `&payslip_date=${payslipDate}`
  );

const _checkCPFReturnStatus = async (id: string, payslipDate: any) =>
  apiClient.get(
    URL.CHECK_CPF_RETURN_STATUS + id + `&payslip_date=${payslipDate}`
  );

const _reverseApproval = async (id: string, payslipDate: any) =>
  apiClient.get(
    URL.REVERSE_APPROVAL + id + `&payslip_date=${payslipDate}`
  );

const _approvePayslip = async (data: any) =>
  apiClient.post(URL.APPROVE_PAYSLIP, data);

export const TellBossService = {
  _getPendingApprovalList,
  _approvePayslip,
  _checkCPFReturnStatus,
  _reverseApproval,
};
