import { TableHeader } from "../../../components/table";
import { Navlink } from "../../dashboard/Styles";
import {
  IRASPdfContainer,
  IRASTableCol,
  TaxCertificateContainer,
} from "../CPFFilingStyles";
import { IRASTable } from "../CPFFilingStyles";
import PdfIcon from "../../../assets/pdf-icon.png";
import { Notify, Utils } from "../../../utils";
import { Constant } from "../../../config";
import { CPFService } from "../../../_service/CPFService";
import { useAppSelector } from "../../../_app";
import { useEffect, useState } from "react";
import { CustomSelectField, Loader } from "../../../components";
import { LoaderContainer } from "../../../CommonStyles";
import { useDispatch } from "react-redux";
import { updateUserIntialState } from "../../../_features";
import moment from "moment";

const IRASFilingIR21 = () => {
  const appUser = useAppSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [selectedSession, setSelectedSession] = useState<string>(
  //   appUser?.filing_date || moment(`${moment().year() - 1}-12-31`).format('YYYY-MM-DD')
  // );
  // const [selectedSession, setSelectedSession] = useState<string>(
  //   moment(`${moment().year()}-12-31`).format('YYYY-MM-DD')
  // );

  const currentPayrunDate = useAppSelector(
    (state) => state.user.current_payrun_date
  );
  const [selectedSession, setSelectedSession] = useState<string>(
    moment(`${currentPayrunDate?.split('-')[0]}-12-31`, "YYYY-MM-DD").subtract(1, 'year').format("YYYY-MM-DD")
  );
  
  const [ir21EmployeeList, setIr21EmployeeList] = useState<any>([]);

  const dispatch = useDispatch();

  

  const IRASHeading = [
    {
      name: "Employee",
      width: "40%",
    },
    {
      name: "Number",
      width: "15%",
    },
    {
      name: "Resigned Date",
      width: "15%",
    },
    {
      name: "Previous Year",
      width: "15%",
    },
    {
      name: "Type",
      width: "15%",
    },
    {
      name: "View",
      width: "15%",
    },
  ];

  function generateDateList() {
    let currentYear = moment().year();

    let datesList = [];

    for (let year = currentYear - 3; year <= currentYear + 7; year++) {
        let date = moment(`${year}-12-31`).format('YYYY-MM-DD');
        datesList.push(date);
    }

    return datesList;
}

// const sessionArray = ["2023-12-31", "2022-12-31"];
const sessionArray = generateDateList();

  const getIrasPdf = async (id: any, name: string) => {
    try {
      const response = await CPFService._getPdfIRAS(id);
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${name}_iras.pdf`; // Set the desired filename for the downloaded file
        document.body.appendChild(a);
        a.click();
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const handlePDFDownload = async (id: any, name: string) => {
    try {
      const response = await CPFService._getPdfIR21(id);
      if (response.status >= 200 && response.status < 300) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${name}_IR21.pdf`; // Set the desired filename for the downloaded file
        document.body.appendChild(a);
        a.click();
      }
    } catch (error) {
      // Notify(Constant.common.something_went_wrong, 0);
      Notify("No PDF Available", 0);
    }
  };

  
  const getAllIr21Filing = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await CPFService._getAllIr21Filing(
        selectedSession,
        appUser.defaultCompnayId
      );

      if (status >= 200 && status < 300) {
        setIr21EmployeeList(() => data?.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    getAllIr21Filing();
    dispatch(
      updateUserIntialState({
        ...appUser,
        filing_date: selectedSession,
      })
    );
  }, [selectedSession]);

  return isLoading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <div>
      {/* <div
        style={{
          display: "flex",
          width: "70%",
          gap: 20,
          alignItems: "center",
        }}
      >
        <p style={{ color: "#333", marginTop: 10 }}>Select Month and Year</p>
        <div>
          <CustomSelectField
            value={month}
            onChangeOption={(value) => setMonth(value)}
            option={monthsOptions.map((month) => ({
              key: month.key,
              value: month.value,
            }))}
            disabled={isLoading}
            isDate={true}
          />
        </div>
        <div>
          <CustomSelectField
            value={year}
            onChangeOption={(value) => setYear(value)}
            option={Utils.generateYearArrayTillNow(2022).map((year) => ({
              key: year,
              value: year,
            }))}
            disabled={isLoading}
            isDate={true}
          />
        </div>
      </div> */}
      <CustomSelectField
        title="IR21 Submission Year"
        value={selectedSession}
        onChangeOption={(value) => setSelectedSession(value)}
        option={sessionArray.map((session) => ({
          // key: Utils._dateToShow(session),
          key: Utils._dateToShow(session),
          value: session,
        }))}
        isDate
        width="38rem"
      />
      
      <div style={{ marginTop: 20 }}>
        {ir21EmployeeList.length > 0 ? (
          <IRASTable>
            <TableHeader headerColumns={IRASHeading} />
            <tbody>
              {ir21EmployeeList?.sort((a:any, b:any) => a?.user?.name?.toLowerCase().localeCompare(b?.user?.name?.toLowerCase())).map((report: any) => (
                <tr key={report?.id}>
                  <IRASTableCol>
                    <p>{report?.employee_name || ""}</p>
                  </IRASTableCol>
                  <IRASTableCol>
                    {report?.emp_number || ""}
                  </IRASTableCol>
                  <IRASTableCol>
                    {Utils._dateToShow(report?.last_date_of_service) || ""}
                  </IRASTableCol>
                  <IRASTableCol>
                    {/* Add this from response instead of calculating with moment */}
                    {/* {moment(report?.previous_year).year() || ""} */}
                    {Utils._dateToShow(report?.previous_year) || ""}
                  </IRASTableCol>
                  <IRASTableCol>IR21</IRASTableCol>
                  <IRASTableCol>
                    <a
                      onClick={() => 
                        handlePDFDownload(
                          report.id,
                          report?.employee_name || ""
                        )
                        
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <IRASPdfContainer>
                        <img
                          src={PdfIcon}
                          alt="PDF Icon"
                          style={{ width: "100%" }}
                        />
                      </IRASPdfContainer>
                    </a>
                    
                  </IRASTableCol>
                </tr>
              ))}
            </tbody>
          </IRASTable>
        ) : (
          <p style={{fontSize: '1rem', fontWeight: 600}}>No IR21 report present for the selected submission year.</p>
        )}
      </div>
      <TaxCertificateContainer>
        {/* <Navlink to="">
          <IRASLogo src={PdfIcon} alt="PDF Icon" />
        </Navlink> */}
        {/* <Navlink to="">All Tax Certifcates (2)</Navlink> */}
        {/* <Navlink to="">
          <IRASLogo src={ExcelIcon} alt="Excel Icon" />
        </Navlink> */}
      </TaxCertificateContainer>
      {/* <ReconciliationHeading>Reconciliation Report</ReconciliationHeading>
      <Navlink to="" style={{ padding: 0 }}>
        Excel
      </Navlink> */}
    </div>
  );
};

export default IRASFilingIR21;
