import { useEffect, useRef, useState } from "react";
import {
  CustomButton,
  CustomNumberField,
  CustomTextField,
} from "../../components";
import { AuthHeader } from "../../components/header";
import { Heading } from "../appSettings/AppSettingsStyles";
import { Notify } from "../../utils";
import {
  EditorContainer,
  EditorLabel,
} from "../template/HRTemplate/HRTemplateStyles";
import TextEditor from "../../components/editor";
import { Constant } from "../../config";
import { useNavigate } from "react-router-dom";
import {
  RequestDetail,
  RequestDetailInputContainer,
} from "../requestdashboard/ViewRequest/ViewRequestStyles";

import useDebounce from "../../hooks/useDebounce";
import { AuthService, CompanyService } from "../../_service";
import {
  SearchCompanyDropdown,
  SearchList,
  SearchListButton,
} from "../requestdashboard/AddRequest/AddRequestStyles";
import moment from "moment";
import styled from "styled-components";

const LoginRequest = () => {
  const [requestData, setRequestData] = useState<any>({
    request_type: "DD",
    email: "",
    name: "",
    message: "",
    contact_number: "",
    company: "",
  });
  const [companyCharToSearch, setCompanyCharToSearch] = useState<string>("");
  const [companyList, setCompanyList] = useState<any>([]);
  const [openSearchBox, setOpenSearchBox] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [isClickedResult, setIsClickedResult] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [sending, setSending] = useState(false);
  const [submissionId, setSubmissionId] = useState(null);

  const handleInputChange = (value: any, target: any) => {
    setRequestData({
      ...requestData,
      [target]: value,
    });
  };

  const handleFileChange = () => {
    const file = (fileInputRef.current as any).files[0];
    if (file) {
      handleInputChange(file, "file_upload");
    }
  };

  const addRequest = async () => {
    setSending(true);
    const formData = new FormData();

    for (const key in requestData) {
      if (requestData.hasOwnProperty(key)) {
        formData.append(key, requestData[key]);
      }
    }

    formData.set("company", selectedCompanyId);

    try {
      const { status, data } = await AuthService._loginAddRequest(formData);

      if (status >= 200 && status < 300) {
        // Notify("Request Added Successfully", 1);
        setRequestData({
          request_type: "DD",
          email: "",
          name: "",
          message: "",
          contact_number: "",
          company: "",
        });
        setCompanyCharToSearch("");
        setSending(false);
        setShowSuccessMessage(true);
        setSubmissionId(data?.data[0]?.card_id);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
      setSending(false);
    }
  };

  const searchCompany = async () => {
    try {
      const { status, data } = await AuthService._companyAddRequest(
        companyCharToSearch
      );

      if (status >= 200 && status < 300) {
        setCompanyList(() => data);
        setIsTyping(false);
      }
    } catch (err) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const searchCompanyName = useDebounce(() => searchCompany());

  const handleSearchClick = (companyName: string, companyId: any) => {
    setCompanyCharToSearch(companyName);
    setSelectedCompanyId(companyId);
    setOpenSearchBox(false);
    setIsClickedResult(true);
  };

  useEffect(() => {
    if (!companyCharToSearch?.length) {
      setCompanyList([]);
      setOpenSearchBox(false);
    } else {
      setIsTyping(true);
      if (isClickedResult) {
        setOpenSearchBox(false);
        setIsClickedResult(false);
      } else {
        setOpenSearchBox(true);
      }
      searchCompanyName();
    }
  }, [companyCharToSearch]);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div style={{ width: "100%" }}>
        <AuthHeader />
      </div>

      {showSuccessMessage ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
            width: "100vw",
            height: "80vh",
          }}
        >
          <img
            style={{ width: "10rem", cursor: "pointer" }}
            src="/success-green.svg"
          />
          <p
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              fontWeight: 500,
            }}
          >
            Your request has been submitted successfully!
          </p>
          <p
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              fontWeight: 500,
            }}
          >
            {`Your submission ID is: ${submissionId || ""}`}
          </p>
        </div>
      ) : (
        <FormContainer>
          <div
            style={{
              justifyContent: "center",
              justifyItems: "center",
              margin: "5px",
              backgroundColor: "rgb(5, 155, 191)",
              height: "56px",
            }}
          >
            <Heading
              style={{
                color: "white",
                fontFamily: "Noto Sans",
                fontWeight: 700,
                fontSize: "20px",
                paddingLeft: "16px",
                paddingTop: "16px",
                paddingBottom: "16px",
              }}
            >
              Add New Request
            </Heading>
          </div>
          <div>
            <RequestDetailInputContainer>
              <RequestDetail>
                <div style={{ position: "relative" }}>
                  <CustomTextField
                    title="Company Name"
                    value={companyCharToSearch}
                    onChangeText={setCompanyCharToSearch}
                    require
                    isColumn={true}
                    inputWidth="90%"
                    onFocus={() => setOpenSearchBox(() => true)}
                  />
                  {companyCharToSearch?.length > 0 && openSearchBox && (
                    <SearchCompanyDropdown>
                      <SearchList>
                        {companyList?.data?.length > 0 &&
                          !isTyping &&
                          companyList?.data?.map((company: any) => (
                            <li>
                              <SearchListButton
                                onClick={() => {
                                  handleSearchClick(company?.name, company?.id);
                                }}
                              >
                                {company?.name}
                              </SearchListButton>
                            </li>
                          ))}
                        {companyList.length === 0 && !isTyping && (
                          <li>
                            <SearchListButton disabled>
                              No Search Result found
                            </SearchListButton>
                          </li>
                        )}
                        {isTyping && (
                          <li>
                            <SearchListButton disabled>
                              Loading...
                            </SearchListButton>
                          </li>
                        )}
                      </SearchList>
                    </SearchCompanyDropdown>
                  )}
                </div>
              </RequestDetail>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  fontSize: "1rem",
                  font: "Noto Sans",
                  marginTop: "1.25rem",
                }}
              >
                <p>Request Type</p>
                <input
                  style={{
                    padding: "4px",
                    marginTop: "0.25rem",
                    border: "1px solid #ced4da",
                    width: "fit-content",
                    borderRadius: "0.2rem",
                    background: "#efefef",
                    color: "#495057",
                  }}
                  value={"delete data"}
                  disabled
                ></input>
              </div>

              <RequestDetail>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    fontSize: "1rem",
                    font: "Noto Sans",
                    marginTop: "1.25rem",
                  }}
                >
                  <p style={{ color: "#495057" }}>Created at </p>
                  <p
                    style={{
                      padding: "4px",
                      marginTop: "0.25rem",
                      border: "1px solid #ced4da",
                      width: "fit-content",
                      borderRadius: "0.2rem",
                      background: "#efefef",
                      color: "#495057",
                    }}
                  >
                    {moment().format("DD/MM/YYYY hh:mm A")}
                  </p>
                </div>
              </RequestDetail>
            </RequestDetailInputContainer>
            <RequestDetailInputContainer>
              <RequestDetail>
                <CustomTextField
                  title="Email Address"
                  value={requestData.email}
                  onChangeText={(val) => handleInputChange(val, "email")}
                  require
                  isColumn={true}
                  inputWidth="90%"
                />
              </RequestDetail>
              <RequestDetail>
                <CustomTextField
                  title="Customer Name"
                  value={requestData.name}
                  onChangeText={(val) => handleInputChange(val, "name")}
                  require
                  isColumn={true}
                  inputWidth="90%"
                />
              </RequestDetail>
              <RequestDetail>
                <CustomNumberField
                  title="Contact Number"
                  placehoder=""
                  value={requestData.contact_number}
                  onChangeText={(val) => {
                    if (val.length <= 8) {
                      handleInputChange(val, "contact_number");
                    }
                  }}
                  isColumn={true}
                  inputWidth="90%"
                />
              </RequestDetail>
            </RequestDetailInputContainer>
            <div style={{ marginLeft: "0.4rem", marginTop: "1rem" }}>
              {/* <RequestDetail style={{ padding:1 }}>
          <EditorContainer>
            <EditorLabel>Message</EditorLabel>
            <TextEditor 
            isLogin={true}
              handleChange={(val: any) => handleInputChange(val, "message")}
              value={requestData.message}
              placeholder="Enter Request Description"
            />
          </EditorContainer>
        </RequestDetail> */}
              <EditorLabel>Message</EditorLabel>
              <StyledTextarea
                placeholder="Enter Request Description"
                value={requestData.message}
                onChange={(e) => handleInputChange(e.target.value, "message")}
              />
            </div>
            <CustomButton
              title="Submit"
              onClick={() => addRequest()}
              styles={{ marginTop: 10, marginBottom: 10 }}
              loading={sending}
            />
          </div>
        </FormContainer>
      )}
    </div>
  );
};

const FormContainer = styled.div`
  width: 60vw;
  padding: 0.625rem;
  border: 1px solid black;
  margin: auto;
  margin-top: 3rem;

  @media (max-width: 768px) {
    width: 95vw;
    margin-top: 1rem;
  }
`;

const StyledTextarea = styled.textarea`
  width: 99%;
  min-height: 15rem;
  padding: 0.5rem;
  outline: none;
  text-decoration: none;
  border: 1px solid #ced4da;

  @media (max-width: 768px) {
    min-height: 8rem;
  }
`;

export default LoginRequest;

// style={{
//   width: "1000px",
//   padding: "10px",
//   border:" 1px solid",
//   justifyContent:"center",
//   justifyItems:"center",
//   marginLeft: "auto",
//   marginRight: "auto",
//   height:"620px",
//   marginTop:"3rem"
//   }}
