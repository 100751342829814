import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";

import { ConfirmationModalInterface } from "../../_types";
import { CustomButton } from "../customButton";

function ConfirmationModal({
  confirmationModalContent,
}: {
  confirmationModalContent: ConfirmationModalInterface;
}) {
  const { title, content, onConfirm, resetContent, cancelText } =
    confirmationModalContent;
  const [regularText, boldText] = content.split("*");
  return (
    <Dialog
      open={true}
      keepMounted
      onClose={resetContent}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {regularText}
          <Typography component="span" fontWeight="bold">
            {boldText}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelText && (
          <CustomButton
            type="secondary"
            title={cancelText}
            onClick={resetContent}
          />
        )}
        {title && (
          <CustomButton
            title={title}
            onClick={() => {
              resetContent();
              onConfirm && onConfirm();
            }}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationModal;
