import { Text, View, StyleSheet } from "@react-pdf/renderer";

import { Font } from "@react-pdf/renderer";
import NotoSansRegular from "../../fonts/NotoSans-Bold.ttf";
import NotoSansBold from "../../fonts/NotoSans-Regular.ttf";
import { PaymentTypeInterface } from "../../_types";
import { Utils } from "../../utils";

Font.register({
  family: "Noto Sans",
  fonts: [
    {
      src: NotoSansRegular,
      fontWeight: 400,
    },
    {
      src: NotoSansBold,
      fontWeight: 700,
      fontStyle: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
    marginTop: "20px",
  },

  employeeBasicInfo: {
    display: "flex",
    flexDirection: "row",
  },
  boldText: {
    fontFamily: "Noto Sans",
    fontWeight: "bold",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid black",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-start",
    borderBottom: "1px solid black",
    backgroundColor: "#ddd",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
   justifyContent: "flex-start",
  },
});

// Create Document Component
function PaymentContent({
  cashes,
  type,
}: {
  cashes: PaymentTypeInterface[];
  type: string;
}) {
  const totalNetPay: any = cashes?.reduce(
    (accumulator, cash) =>
      parseFloat(
        Utils.isNumberWithComma(`${cash?.payslip_info?.net_pay}`)
          ? Utils.getPriceFromString(`${cash?.payslip_info?.net_pay}`)
          : "0.00"
      ) + accumulator,
    0
  );

  return (
    <View>
      <View style={styles.header}>
        <Text style={[{ fontSize: "12px" }]}>
          {cashes?.[0]?.employee?.company?.name || ""}
        </Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0px 4px 20px 4px",
          width: "60%",
        }}
      >
        <View>
          <View style={styles.employeeBasicInfo}>
            <Text style={[{ fontSize: "12px" }]}>Payment Information</Text>
          </View>
          <View style={styles.employeeBasicInfo}>
            <Text style={[{ fontSize: "12px" }]}>Employees:</Text>
            <Text style={[{ fontSize: "12px", marginLeft: "5px" }]}>
              Monthly, ending on the 31st
            </Text>
          </View>
          <View style={styles.employeeBasicInfo}>
            <Text style={[{ fontSize: "12px" }]}>Payment Method:</Text>
            <Text style={[{ fontSize: "12px", marginLeft: "5px" }]}>
              {type}
            </Text>
          </View>
          <View style={styles.employeeBasicInfo}>
            <Text style={[{ fontSize: "12px" }]}>Pay Point: </Text>
            <Text style={[{ fontSize: "12px", marginLeft: "5px" }]}>
              Unassigned
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                // marginRight: "12px",
                // flex: 1,
                width:
                type==="GIRO"
                ?"28%"
                :"33%",
              },
            ]}
          >
            <Text
              style={[
                styles.boldText,
                {
                  // borderLeft: "1px solid black",
                  padding: 4,
                },
              ]}
            >
              Name
            </Text>
          </View>
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginRight: "12px",
                flex: 1,
                width: 
                type==="GIRO"
                ?`${72/6}%`
                :"33%",
              },
            ]}
          >
            <Text
              style={[
                styles.boldText,
                {
                  borderLeft: "1px solid black",
                  padding: 4,
                },
              ]}
            >
              Number
            </Text>
          </View>
          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                marginRight: "12px",
                flex: 1,
                width: 
                type==="GIRO"
                ?`${72/6}%`
                :"33%",
              },
            ]}
          >
            <Text
              style={[
                styles.boldText,
                {
                  borderLeft: "1px solid black",
                  padding: 4,
                },
              ]}
            >
              Net Pay
            </Text>
          </View>
          {type === "GIRO" ? (
            <>
              <View
                style={[
                  {
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "12px",
                    flex: 1,
                    width: 
                    type==="GIRO"
                ?`${72/6}%`
                :"33%",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.boldText,
                    {
                      borderLeft: "1px solid black",
                      padding: 4,
                    },
                  ]}
                >
                  Bank
                </Text>
              </View>
              <View
                style={[
                  {
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "12px",
                    flex: 1,
                    width:
                    type==="GIRO"
                    ?`${72/6}%`
                    :"33%",
                  },
                ]}
            >
                <Text
                  style={[
                    styles.boldText,
                    {
                      borderLeft: "1px solid black",
                      padding: 4,
                    },
                  ]}
                >
                  Account Type
                </Text>
              </View>
              <View
                style={[
                  {
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "12px",
                    flex: 1,
                    width: 
                    type==="GIRO"
                   ?`${72/6}%`
                :"33%",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.boldText,
                    {
                      borderLeft: "1px solid black",
                      padding: 4,
                    },
                  ]}
                >
                  Account Number
                </Text>
              </View>
              <View
                style={[
                  {
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "12px",
                    flex: 1,
                    width:
                    type==="GIRO"
                ?`${72/6}%`
                :"33%",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.boldText,
                    {
                      borderLeft: "1px solid black",
                      padding: 4,
                    },
                  ]}
                >
                  Branch Code
                </Text>
              </View>
            </>
          ) : null}
        </View>

        {cashes.sort((a: any,b: any) => a.employee.user.name.localeCompare(b.employee.user.name))?.map((cash) => {
          return (
            <View style={[styles.row]}>
            <View style={[{
              width: 
              type==="GIRO"
                        ?`28%`
                        :"33%"
            }]}>
              <Text
                style={{
                  flex: 1,
                  // borderRight: "1px solid black",
                  padding: 4,
                  width: "100px"
                }}
              >
                {cash.employee.user.name}
              </Text>
              </View>
              <View
                style={[
                      {
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "12px",
                        flex: 1,
                        width: type==="GIRO"
                        ?`${72/6}%`
                        :"33%"
                    }]}
              >
                <Text
                  style={[
                    {
                      borderLeft: "1px solid black",
                      padding: 4,
                    },
                  ]}
                >
                  {cash.employee.emp_number}
                </Text>
              </View>
              <View
                    style={[
                      {
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "12px",
                        flex: 1,
                        width: type==="GIRO"
                        ?`${72/6}%`
                        :"33%"
                    }]}
              >
                <Text
                  style={[
                    {
                      borderLeft: "1px solid black",
                      padding: 4,
                      paddingRight: 0,
                      paddingLeft: 8,
                      width: "100%",
                      textAlign: "right"
                    },
                  ]}
                >
                  $ {cash.payslip_info.net_pay}
                </Text>
              </View>

              {type === "GIRO" ? (
                <>
                  <View
                    style={[
                      {
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "12px",
                        flex: 1,
                        width: type==="GIRO"
                        ?`${72/6}%`
                        :"33%"
                    }]}
                  >
                    <Text
                      style={[
                        {
                          borderLeft: "1px solid black",
                          padding: 4,
                        },
                      ]}
                    >
                      {cash?.employee?.bank_detail?.[0]?.bank_name || "-"}
                    </Text>
                  </View>
                  <View
                       style={[
                        {
                          display: "flex",
                          flexDirection: "row",
                          marginRight: "12px",
                          flex: 1,
                          width: type==="GIRO"
                          ?`${72/6}%`
                          :"33%"
                      }]}
                  >
                    <Text
                      style={[
                        {
                          borderLeft: "1px solid black",
                          padding: 4,
                        },
                      ]}
                    >
                      Savings
                    </Text>
                  </View>
                  <View
                    style={[
                      {
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "12px",
                        flex: 1,
                        width: type==="GIRO"
                        ?`${72/6}%`
                        :"33%"
                    }]}
                  >
                    <Text
                      style={[
                        {
                          borderLeft: "1px solid black",
                          padding: 4,
                        },
                      ]}
                    >
                      {cash?.employee?.bank_detail?.[0]?.bank_account_number ||
                        "-"}{" "}
                    </Text>
                  </View>
                  <View
                    style={[
                      {
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "12px",
                        flex: 1,
                        width: type==="GIRO"
                        ?`${72/6}%`
                        :"33%"
                    }]}
                  >
                    <Text
                      style={[
                        styles.boldText,
                        {
                          borderLeft: "1px solid black",
                          padding: 4,
                        },
                      ]}
                    >
                      {cash?.employee?.bank_detail?.[0]?.bank_branch_code ||
                        "-"}
                    </Text>
                  </View>
                </>
              ) : null}
            </View>
          );
        })}
      </View>
      <View style={{ marginTop: "16px" }}>
        <Text>
          Total Nett Pay: ${" "}
          {parseFloat(totalNetPay)?.toLocaleString("en", {
            minimumFractionDigits: 2,
          }) || "0.00"}
        </Text>
      </View>
    </View>
  );
}
export default PaymentContent;
