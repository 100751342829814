import styled from "styled-components";

export const EmployeeUserTableHeader = styled.thead`
  background-color: #4a5e71;
`;
export const EmployeeUserTableHeaderColumn = styled.th`
  padding: 10px;
  color: white;
  text-align: left;
  width: 30%;
`;
export const EmployeeUserTableHeaderSmallColumn = styled.th`
  padding: 10px;
  color: white;
  text-align: left;
  width: 20%;
`;
export const EmployeeTableActionButton = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 15px;
`;
export const EmployeeUserTableHeaderEnableCol = styled.div`
  display: flex;
  gap: 10px;
`;
export const EnableAllNoneButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  color: white;
`;
export const GeneralSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 15px;
`;
export const LeaveSettingsHeading = styled.h2`
  font-weight: 500;
  color: #333;
  font-size: 1.5rem;
  margin-top: 15px;
`;
export const LeaveSettingsSubHeading = styled.h3`
  color: #6c757d;
  font-size: 13px;
  font-weight: 500;
  margin-top: 8px;
`;
export const AllNoneContainer = styled.div`
  display: flex;
  gap: 15px;
`;
export const AllNoneButton = styled.button`
  cursor: pointer;
  color: #059bbf;
  font-size: 16px;
  gap: 15px;
  background: none;
  border: none;
  margin-bottom: 0;
  margin-top: 8px;

  &:hover {
    text-decoration: underline;
  }
`;
