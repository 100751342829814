import apiClient from "./BaseService";
import { URL } from "../config";

const _getEftList = async () => apiClient.get(URL.GET_EFT_LIST);

const _addGiroEft = async (data: any) => apiClient.post(URL.ADD_EFT, data);

const _addBasicPaySetting = async (data: any) =>
  apiClient.post(URL.ADD_EMPLOYEE_NUMBER, data);

const _getCpfOptions = async () => apiClient.get(URL.GET_CPF_OPTIONS);

const _getPayrollSettings = async (code: string, company: number) =>
  apiClient.get(`${URL.GET_COMPANY_SETTINGS}?code=${code}&company=${company}`);

const _getGiroSettings = async (company: number) =>
  apiClient.get(`${URL.GET_GIRO_DETAILS}?&company=${company}`);

export const CompanyPayrollService = {
  _getEftList,
  _addGiroEft,
  _addBasicPaySetting,
  _getCpfOptions,
  _getPayrollSettings,
  _getGiroSettings,
};
