import { useState } from "react";

import { ManageCPFCal } from "../../_service";
import { Notify, Utils } from "../../utils";
import { useAppSelector } from "../../_app";
import {
  CustomButton,
  CustomDateField,
  CustomNumberField,
  CustomTextField,
} from "../../components";
import { CPFLimitDataProps } from "../../_types";

interface ManageCPFLimitRowProps {
  data: CPFLimitDataProps;
  syncData: () => void;
}

const ManageCPFLimitRow = (props: ManageCPFLimitRowProps) => {
  const { data, syncData } = props;

  const [edit, setEdit] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);

  const [effectiveFrom, setEffectiveFrom] = useState<string>(
    data.effective_from
  );
  const [effectiveTo, setEffectiveTo] = useState<string>(data.effective_to);
  const [maxOw, setMaxOw] = useState<string>(data.ow);
  const [maxAw, setMaxAw] = useState<string>(data.aw);

  const user = useAppSelector((state) => state.user);

  const toggle = () => setEdit((prev) => !prev);

  const handleCpfTableDataUpdate = async () => {
    try {
        setUpdating(true);
      const formData = {
        effective_from: effectiveFrom,
        effective_to: effectiveTo,
        ow: maxOw,
        aw: maxAw,
      };
      if (parseInt(maxAw) > 102000) {
        Notify("Max AW can't be greater than 102000", 0);
        setUpdating(false);
      } else {
        const res = await ManageCPFCal._updateCpfLimitData(
          data.id as string,
          formData
        );

        if (res.status === 200) {
          syncData();
          Notify("Data Updated Successfully", 1);
        } 
        setUpdating(false);
        setEdit(false);
      }

    } catch (e: any) {
      Notify(e.response.data.message,0);
    }
  };


  return (
    <tr>
      {edit ? (
        <>
          <td>
            <div style={{ width: 150 }}>
              <CustomDateField
                date={effectiveFrom}
                setDate={setEffectiveFrom}
                startYear={new Date().getFullYear() - 1}
                endYear={new Date().getFullYear() + 10}
                openAsModal={true}
              />
            </div>
          </td>
          <td>
            <div style={{ width: 150 }}>
              <CustomDateField
                startYear={new Date().getFullYear() - 1}
                endYear={new Date().getFullYear() + 10}
                date={effectiveTo}
                setDate={setEffectiveTo}
                openAsModal={true}
              />
            </div>
          </td>
          <td>
            <CustomNumberField
              width="80px"
              value={maxOw}
              onChangeText={setMaxOw}
            />
          </td>
          {/* <td>
            <CustomNumberField
              width="80px"
              value={maxAw}
              onChangeText={setMaxAw}
            />
          </td> */}
          <td>
            <CustomButton
              size="small"
              width="70px"
              title="Update"
              onClick={handleCpfTableDataUpdate}
              disable={updating}
            />
          </td>
        </>
      ) : (
        <>
          <td>{Utils._dateToShow(data.effective_from)}</td>
          <td>{Utils._dateToShow(data.effective_to)}</td>
          <td>{data.ow}</td>
          {/* <td>{data.aw}</td> */}
          {user.permission === "full" && (
            <td>
              <CustomButton
                type="secondary"
                size="small"
                width="50px"
                title="Edit"
                onClick={toggle}
              />
            </td>
          )}
        </>
      )}
    </tr>
  );
};

export default ManageCPFLimitRow;
