import { useState } from "react";
import {
  CustomButton,
  CustomDateField,
  CustomNumberField,
  CustomSelectField,
  NavigateBack,
} from "../../components";
import { Notify, Utils } from "../../utils";
import { H3Heading } from "../appSettings/AppSettingsStyles";
import { ManageCPFCal } from "../../_service";
import { useNavigate } from "react-router-dom";
import { Constant } from "../../config";

const AddMaxAw = () => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = currentYear - 1; i <= currentYear + 10; i++) {
    years.push(i);
  }
  // const [effectiveFrom, setEffectiveFrom] = useState(`${currentYear}`);
  // const [effectiveTo, setEffectiveTo] = useState(`${currentYear}`);
  const [maxAw, setMaxAw] = useState<any>("0.00");


  const [effectiveFrom, setEffectiveFrom] = useState(Utils._date());
  const [effectiveTo, setEffectiveTo] = useState(Utils._date());

  const navigate = useNavigate();

  const addCpfLimit = async () => {
    try {
      const formData = new FormData();
      formData.append("effective_from", `${effectiveFrom}`);
      formData.append("effective_to", `${effectiveTo}`);
      formData.append("aw", maxAw);
      const { status } = await ManageCPFCal._addAwLimit(formData);
      if (status === 201) {
        Notify("CPF Limit Added Successfully", 1);
        navigate(-1);
      }
    } catch (e: any) {
      Notify(e.response.data.message,0);
      // if (e?.response?.data?.detail) {
      //   Notify(e.response.data.detail, 0);
      // } else {
      //   Notify(Constant.common.something_went_wrong, 0);
      // }
    }
  };

  return (
    <div>
      <NavigateBack />
      <H3Heading>{`Add Total Wage (OW + AW) Limit`}</H3Heading>
      {/* <CustomSelectField
        title="Effective From"
        value={effectiveFrom}
        onChangeOption={(value) => setEffectiveFrom(() => value)}
        option={years?.map((option) => ({
          key: option,
          value: option,
        }))}
      />
      <CustomSelectField
        title="Effective To"
        value={effectiveTo}
        onChangeOption={(value) => setEffectiveTo(() => value)}
        option={years?.map((option) => ({
          key: option,
          value: option,
        }))}
      /> */}
      <CustomDateField
        label="Effective From"
        date={effectiveFrom}
        setDate={setEffectiveFrom}
        startYear={new Date().getFullYear() - 1}
        endYear={new Date().getFullYear() + 10}
      />
      <CustomDateField
        label="Effective To"
        date={effectiveTo}
        setDate={setEffectiveTo}
        startYear={new Date().getFullYear() - 1}
        endYear={new Date().getFullYear() + 10}
      />
      <CustomNumberField
        title="Max AW"
        value={maxAw}
        placehoder="0.00"
        onChangeText={setMaxAw}
        onBlur={() => setMaxAw(() => Utils.addDecimalAndZeroes(maxAw))}
      />
      <CustomButton
        title={`Add Total Wage (OW + AW)`}
        onClick={() => addCpfLimit()}
        styles={{ marginTop: 50, marginLeft: 0, }}
        width="full"
      />
    </div>
  );
};

export default AddMaxAw;
