import { useRef, useCallback } from "react";

/**
 * Hook to call a callback function after a wait time is passed
 *
 * @param func - The function to be called
 *
 * @param delay - Wait period after function hasn't been called for
 *
 * @returns function to be called
 */
const useDebounce = (func: () => void, delay = 500): (() => void) => {
  /* 
    Use a ref to store the timeout between renders
    and prevent changes to it from causing re-renders
    */
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  return useCallback(() => {
    const callProvidedFunction = () => {
      clearTimeout(timeout.current);
      func();
    };

    clearTimeout(timeout.current);
    timeout.current = setTimeout(callProvidedFunction, delay);
  }, [func, delay]);
};

export default useDebounce;
