import { useEffect, useRef, useState } from "react";
import {
  CustomButton,
  CustomDateField,
  CustomNumberField,
  CustomSelectField,
  CustomTextField,
  NavigateBack,
  Tooltip,
} from "../../../components";
import { Heading } from "../../appSettings/AppSettingsStyles";
import { Notify, Utils } from "../../../utils";
import {
  EditorContainer,
  EditorLabel,
} from "../../template/HRTemplate/HRTemplateStyles";
import TextEditor from "../../../components/editor";
import { DashboardService } from "../../../_service/DashboardService";
import { Constant } from "../../../config";
import { FaFileUpload } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../_app";
import {
  RequestDetail,
  RequestDetailContainer,
  RequestDetailInputContainer,
} from "../ViewRequest/ViewRequestStyles";
import useDebounce from "../../../hooks/useDebounce";
import { CompanyService } from "../../../_service";
import {
  SearchCompanyDropdown,
  SearchList,
  SearchListButton,
} from "./AddRequestStyles";
import moment from "moment";

const AddRequest = () => {
  const [requestData, setRequestData] = useState<any>({
    request_type: "CC",
    email: "",
    name: "",
    message: "",
    contact_number: "",
    file_upload: null,
    company: null,
  });
  const [companyCharToSearch, setCompanyCharToSearch] = useState<string>("");
  const [companyList, setCompanyList] = useState<any>([]);
  const [openSearchBox, setOpenSearchBox] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [isClickedResult, setIsClickedResult] = useState(false);
  const fileInputRef = useRef(null);

  const [requestTypes, setRequestTypes] = useState<any>({});
  const navigate = useNavigate();

  const handleInputChange = (value: any, target: any) => {
    setRequestData({
      ...requestData,
      [target]: value,
    });
  };

  const handleFileChange = () => {
    const file = (fileInputRef.current as any).files[0];
    if (file) {
      handleInputChange(file, "file_upload");
    }
  };

  const getRequestTypes = async () => {
    try {
      const { data, status } = await DashboardService._getRequestTypes();

      if (status === 200) {
        setRequestTypes(() => data.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const addRequest = async () => {
    const formData = new FormData();

    for (const key in requestData) {
      if (key !== "file_upload") {
        if (requestData.hasOwnProperty(key)) {
          formData.append(key, requestData[key]);
        }
      } else {
        if (requestData.file_upload) {
          formData.append("file_upload", requestData["file_upload"]);
        }
      }
    }

    if (selectedCompanyId === "") {
      Notify("Please select a company from dropdown", 0);
      return;
    }
    formData.append("company", selectedCompanyId);

    try {
      const { status } = await DashboardService._submitRequest(formData);

      if (status === 201) {
        Notify("Request Added Successfully", 1);
        navigate(-1);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const searchCompany = async () => {
    try {
      const { status, data } = await CompanyService._getAllCompanyByChar(
        companyCharToSearch
      );

      if (status === 200) {
        setCompanyList(() => data);
        setIsTyping(false);
      }
    } catch (err) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const searchCompanyName = useDebounce(() => searchCompany());

  const handleSearchClick = (companyName: string, companyId: any) => {
    setCompanyCharToSearch(companyName);
    setSelectedCompanyId(companyId);
    setOpenSearchBox(false);
    setIsClickedResult(true);
  };

  useEffect(() => {
    if (!companyCharToSearch.length) {
      setCompanyList([]);
      setOpenSearchBox(false);
    } else {
      setIsTyping(true);
      if (isClickedResult) {
        setOpenSearchBox(false);
        setIsClickedResult(false);
      } else {
        setOpenSearchBox(true);
      }
      searchCompanyName();
    }
  }, [companyCharToSearch]);

  useEffect(() => {
    getRequestTypes();
  }, []);
  return (
    <div>
      <NavigateBack />
      <Heading>Add New Request</Heading>
      <div>
        <RequestDetailInputContainer>
          <RequestDetail>
            <div style={{ position: "relative" }}>
              <CustomTextField
                title="Company Name"
                value={companyCharToSearch}
                onChangeText={setCompanyCharToSearch}
                require
                isColumn={true}
                inputWidth="90%"
                onFocus={() => setOpenSearchBox(() => true)}
              />
              {companyCharToSearch.length > 0 && openSearchBox && (
                <SearchCompanyDropdown>
                  <SearchList>
                    {companyList.length > 0 &&
                      !isTyping &&
                      companyList?.map((company: any) => (
                        <li>
                          <SearchListButton
                            onClick={() => {
                              handleSearchClick(company?.name, company?.id);
                            }}
                          >
                            {company?.name}
                          </SearchListButton>
                        </li>
                      ))}
                    {companyList.length === 0 && !isTyping && (
                      <li>
                        <SearchListButton disabled>
                          No Search Result found
                        </SearchListButton>
                      </li>
                    )}
                    {isTyping && (
                      <li>
                        <SearchListButton disabled>Loading...</SearchListButton>
                      </li>
                    )}
                  </SearchList>
                </SearchCompanyDropdown>
              )}
            </div>
          </RequestDetail>
          <RequestDetail>
            <CustomSelectField
              title="Request Type"
              value={requestData.request_type}
              onChangeOption={(val) => handleInputChange(val, "request_type")}
              option={Object?.keys(requestTypes)?.map((key) => ({
                value: key,
                key: requestTypes[key],
              }))}
              require
              isColumn={true}
              inputWidth="90%"
            />
          </RequestDetail>
          <RequestDetail>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                fontSize: "1rem",
                font: "Noto Sans",
                marginTop: "1.25rem",
              }}
            >
              <p style={{ color: "#495057" }}>Created at </p>
              <p
                style={{
                  padding: "4.5px",
                  marginTop: "0.25rem",
                  border: "1px solid #ced4da",
                  width: "fit-content",
                  borderRadius: "0.2rem",
                  background: "#efefef",
                  color: "#495057",
                }}
              >
                {moment().format("DD/MM/YYYY hh:mm A")}
              </p>
            </div>
          </RequestDetail>
        </RequestDetailInputContainer>
        <RequestDetailInputContainer>
          <RequestDetail>
            <CustomTextField
              title="Email Address"
              value={requestData.email}
              onChangeText={(val) => handleInputChange(val, "email")}
              require
              isColumn={true}
              inputWidth="90%"
            />
          </RequestDetail>
          <RequestDetail>
            <CustomTextField
              title="Customer Name"
              value={requestData.name}
              onChangeText={(val) => handleInputChange(val, "name")}
              require
              isColumn={true}
              inputWidth="90%"
            />
          </RequestDetail>
          <RequestDetail>
            <CustomNumberField
              title="Contact Number"
              placehoder=""
              value={requestData.contact_number}
              onChangeText={(val) => {
                if (val.length <= 8) {
                  handleInputChange(val, "contact_number");
                }
              }}
              isColumn={true}
              inputWidth="90%"
            />
          </RequestDetail>
        </RequestDetailInputContainer>
        <RequestDetail style={{ padding: 10 }}>
          <EditorContainer>
            <EditorLabel>Message</EditorLabel>
            <TextEditor
              handleChange={(val: any) => handleInputChange(val, "message")}
              value={requestData.message}
              placeholder="Enter Request Description"
            />
          </EditorContainer>
        </RequestDetail>
        <RequestDetailInputContainer>
          <div
            style={{
              display: "flex",
              marginTop: "0.8rem",
              gap: "0.25rem",
              alignItems: "center",
            }}
          >
            <p style={{ color: "#495057", width: "30%", maxWidth: 250 }}>
              Choose file
            </p>
            <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
              <input
                type="file"
                name="manual-file"
                onChange={() => handleFileChange()}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <Tooltip text={requestData?.file_upload ? "Change" : "Upload"}>
                <CustomButton
                  size="small"
                  width="30px"
                  onClick={() => (fileInputRef.current as any).click()}
                  title=""
                  icon={<FaFileUpload />}
                />
              </Tooltip>
              <span>{requestData?.file_upload?.name || ""}</span>
              {requestData?.file_upload && (
                <Tooltip text="Delete" del>
                  <CustomButton
                    type={"secondary"}
                    size="small"
                    del
                    width="30px"
                    title={""}
                    onClick={() => {
                      handleInputChange(null, "file_upload");
                      (fileInputRef.current as any).value = "";
                    }}
                    icon={<AiFillDelete />}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </RequestDetailInputContainer>
        <CustomButton
          title="Submit"
          onClick={() => addRequest()}
          styles={{ marginTop: 20 }}
        />
      </div>
    </div>
  );
};

export default AddRequest;
