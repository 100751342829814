import { useState } from "react";
import { CustomButton, CustomDateField, NavigateBack } from "../../components";
import { Heading } from "../appSettings/AppSettingsStyles";
import { Utils } from "../../utils";
import { GiroExportText } from "./GiroExportStyles";
import { Link, useLocation } from "react-router-dom";

const GiroExport = () => {
  const [valueDate, setValueDate] = useState<string>(Utils._date());

  const { state } = useLocation();
  return (
    <>
      <NavigateBack />
      <Heading>GIRO Export for the month ending {state.payrunDate}</Heading>
      <GiroExportText>
        The following settings will be used for the GIRO export. If they are not
        correct, you change them on the{" "}
        <Link
          to="/dashboard/app-settings/giro-settings"
          style={{ textDecoration: "none" }}
        >
          GIRO Settings{" "}
        </Link>{" "}
        page.
      </GiroExportText>
      <GiroExportText>
        <strong>GIRO File Format: </strong>Standard Chartered (lite format with
        SWIFT)
      </GiroExportText>
      <GiroExportText>
        The following information is required for this payment run:
      </GiroExportText>
      <CustomDateField
        label="Value Date"
        date={valueDate}
        setDate={(value: any) => setValueDate(() => value)}
      />
      <CustomButton title="Download" onClick={() => console.log("Check")} />
    </>
  );
};

export default GiroExport;
