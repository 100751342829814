import { ChipButton } from "./ChipStyles";

interface ChipButtonProps {
  title: string;
  onClick: (e: any) => void;
  isSelected?: boolean;
}

const Chip = (props: ChipButtonProps) => {
  const { title, onClick, isSelected = false } = props;

  return (
    <ChipButton onClick={onClick} isSelected={isSelected}>
      {title}
    </ChipButton>
  );
};

export default Chip;
