import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  CustomButton,
  CustomDateField,
  CustomSelectField,
  DeactivateModal,
  Loader,
  NavigateBack,
} from "../../../components";
import { Constant } from "../../../config";
import { Notify, Utils } from "../../../utils";
import { LeaveService } from "../../../_service";
import {
  ActiveEntitlementPolicyHeader,
  ActiveEntitlementPolicyHeaderLarge,
  ActiveEntitlementText,
  AddActiveEntitlementColumn,
  DetailedLeaveDivider,
  DetailedLeaveHeading,
  DetailedLeaveSubHeading,
  LeaveButtonContainer,
  LeaveDetailContainer,
  LeaveDetailText,
  LeaveListItem,
  LeaveName,
} from "../AppSettingsStyles";
import { useAppSelector } from "../../../_app";
import {
  ActiveEntitlementPolicy,
  EntitlementPolicy,
  LeaveDetails,
} from "../../../_types/LeaveInterface";
import { LoaderContainer } from "../../../CommonStyles";
import { MdDelete } from "react-icons/md";

const DetailedLeaveComponent = () => {
  const { state } = useLocation();
  const { leaveId } = state;

  const user = useAppSelector((state) => state.user);

  const [leaveDetails, setLeaveDetails] = useState<LeaveDetails | null>(null);
  const [entitlementPolicy, setEntitlementPolicy] = useState<
    EntitlementPolicy[]
  >([]);
  const [activeEntitlementPolicy, setActiveEntitlementPolicy] = useState<
    ActiveEntitlementPolicy[]
  >([]);
  const [addActiveEntitlement, setAddActiveEntitlement] =
    useState<boolean>(false);
  const [selectedEntitlement, setSelectedEntitlement] = useState<number>(0);
  const [effectiveFrom, setEffectiveFrom] = useState<string>("");
  const [effectiveUntil, setEffectiveUntil] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEntitlementDeletePopupActive, setIsEntitlementDeletePopupActive] =
    useState<boolean>(false);
  const [entitlementPolicyToDelete, setEntitlementPolicyToDelete] =
    useState<any>();
  const [
    isActiveEntitlementDeletePopupActive,
    setIsActiveEntitlementDeletePopupActive,
  ] = useState<boolean>(false);
  const [activeEntitlementPolicyToDelete, setActiveEntitlementPolicyToDelete] =
    useState<any>();
  const [activeEntitlementPolicyToEdit, setActiveEntitlementPolicyToEdit] =
    useState<any>();
  const [isEditActiveEntitlementPolicy, setIsEditActiveEntitlementPolicy] =
    useState<boolean>(false);

  const navigate = useNavigate();

  /**
   * Function to Get details of individual leave type
   */
  const getIndividualLeaveDetail = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await LeaveService._getIndividualLeave(leaveId);
      if (status === 200) {
        setLeaveDetails(() => data.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  /**
   * Function to Get list of all entitlement plicy
   */
  const getAllEntitlementPolicies = async () => {
    try {
      const { data, status } = await LeaveService._getAllEntitlementPolicies(
        leaveId
      );
      if (status === 200) {
        setEntitlementPolicy(() => data.data);
        setSelectedEntitlement(() => data.data[0]?.id || 0);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  /**
   * Function to list of all active-entitlement-policy
   */
  const getAllActiveEntitlementPolicies = async () => {
    try {
      const { data, status } =
        await LeaveService._getAllActiveEntitlementPolicies(leaveId);
      if (status === 200) {
        setActiveEntitlementPolicy(() => data.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  /**
   * Function to add active-entitlement-policy
   */
  const addActiveEntitlementPolicy = async () => {
    const formData = new FormData();

    if (effectiveFrom) {
      formData.append("effective_from", effectiveFrom);
    }
    if (effectiveUntil) {
      formData.append("effective_until", effectiveUntil);
    }

    formData.append("entitlement_policy", `${selectedEntitlement}`);
    formData.append("company", user?.defaultCompnayId);
    formData.append("leave_type", leaveId);

    try {
      const { data, status } = await LeaveService._addActiveEntitlementPolicy(
        formData
      );
      if (status === 201) {
        Notify("Active Entitlement Policy Created successfully", 1);
        setActiveEntitlementPolicy(() => [
          ...activeEntitlementPolicy,
          data.data,
        ]);
        setAddActiveEntitlement(() => false);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  /**
   * Function to delete entitlement policy
   */
  const deleteEntitlementPolicy = async () => {
    try {
      const { status } = await LeaveService._deleteEntitlementPolicy(
        entitlementPolicyToDelete
      );

      if (status === 200) {
        Notify("Entitlement Policy Deleted Successfully", 0);
        setIsEntitlementDeletePopupActive(() => false);
        const updatedList = entitlementPolicy.filter(
          (entitlement) => entitlement.id !== entitlementPolicyToDelete
        );
        setEntitlementPolicy(() => updatedList);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  /**
   * Function to delete active entitlement policy
   */
  const deleteActiveEntitlementPolicy = async () => {
    try {
      const { status } = await LeaveService._deleteActiveEntitlementPolicy(
        activeEntitlementPolicyToDelete
      );

      if (status === 200) {
        Notify("Active Entitlement Policy Deleted Successfully", 0);
        setIsActiveEntitlementDeletePopupActive(() => false);
        const updatedList = activeEntitlementPolicy.filter(
          (entitlement) => entitlement.id !== activeEntitlementPolicyToDelete
        );
        setActiveEntitlementPolicy(() => updatedList);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const editActiveEntitlementPolicy = async () => {
    try {
      const formData = new FormData();
      if (effectiveFrom) {
        formData.append("effective_from", effectiveFrom);
      }
      if (effectiveUntil) {
        formData.append("effective_until", effectiveUntil);
      }

      formData.append("entitlement_policy", `${selectedEntitlement}`);
      const { status } = await LeaveService._editActiveEntitlementPolicy(
        activeEntitlementPolicyToEdit,
        formData
      );

      if (status === 200) {
        Notify("Active Entitlement Policy Edited Successfully", 1);
        setIsActiveEntitlementDeletePopupActive(() => false);
        setIsEditActiveEntitlementPolicy(() => false);
        setEffectiveFrom(() => "")
        setEffectiveUntil(() => "")
        setSelectedEntitlement(() => 0)
        const updatedList = activeEntitlementPolicy.filter(
          (entitlement) => entitlement.id !== activeEntitlementPolicyToDelete
        );
        setActiveEntitlementPolicy(() => updatedList);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  useEffect(() => {
    getIndividualLeaveDetail();
    getAllEntitlementPolicies();
    getAllActiveEntitlementPolicies();
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <NavigateBack />
          <div>
            <LeaveDetailContainer>
              <DetailedLeaveHeading>{leaveDetails?.name}</DetailedLeaveHeading>
              <LeaveDetailText>Name: {leaveDetails?.name}</LeaveDetailText>
              <LeaveDetailText>
                Cycle Length: {leaveDetails?.cycle_length} months
              </LeaveDetailText>
              <LeaveDetailText>
                Start Date: {leaveDetails?.cycle_start_date.item_name}
              </LeaveDetailText>
              <LeaveDetailText>
                Unpaid: {leaveDetails?.is_unpaid_leaves ? "True" : "False"}
              </LeaveDetailText>
              <LeaveDetailText>
                Set minimum balance rule:
                {leaveDetails?.is_set_min_bal_rule ? "True" : "False"}
              </LeaveDetailText>
            </LeaveDetailContainer>

            <CustomButton
              title="Edit Details"
              onClick={() =>
                navigate("/dashboard/app-settings/leave/edit", {
                  state: {
                    leaveDetails: leaveDetails,
                  },
                })
              }
              width="130px"
            />
          </div>
          <DetailedLeaveDivider />
          <div>
            <LeaveDetailContainer>
              <DetailedLeaveHeading>
                Available Entitlement Policies
              </DetailedLeaveHeading>
              {entitlementPolicy.length ? (
                <ul>
                  {entitlementPolicy.map((entitlementPolicyData) => (
                    <LeaveListItem>
                      <LeaveName>
                        {entitlementPolicyData.is_custom_name
                          ? entitlementPolicyData.custom_name
                          : entitlementPolicyData.name}
                      </LeaveName>
                      <LeaveButtonContainer>
                        <CustomButton
                          title={Constant.common.view}
                          width="75px"
                          onClick={() => {
                            navigate("../entitlement-policy/edit", {
                              state: {
                                entitlementPolicyDetails: entitlementPolicyData,
                                leaveName: leaveDetails?.name,
                              },
                            });
                          }}
                        />
                        {entitlementPolicyData.is_deletable && (
                          <CustomButton
                            title={Constant.common.delete}
                            del={true}
                            width="75px"
                            type="secondary"
                            styles={{ margin: "0" }}
                            onClick={() => {
                              setIsEntitlementDeletePopupActive(() => true);
                              setEntitlementPolicyToDelete(
                                () => entitlementPolicyData.id
                              );
                            }}
                            icon={<MdDelete />}
                          />
                        )}
                      </LeaveButtonContainer>
                    </LeaveListItem>
                  ))}
                  <DeactivateModal
                    open={isEntitlementDeletePopupActive}
                    closeModal={() =>
                      setIsEntitlementDeletePopupActive(() => false)
                    }
                    approveAction={() => deleteEntitlementPolicy()}
                    variant="deleteEntitlement"
                  />
                </ul>
              ) : (
                <LeaveDetailText>
                  No Entitlement Policy exists for this leave type
                </LeaveDetailText>
              )}
            </LeaveDetailContainer>
            <CustomButton
              title="Create Entitlement Policy"
              onClick={() =>
                navigate("../entitlement-policy/new", {
                  state: {
                    leaveName: leaveDetails?.name,
                    leaveId: leaveDetails?.id,
                  },
                })
              }
              width="250px"
            />
          </div>
          <DetailedLeaveDivider />
          <div>
            <LeaveDetailContainer>
              <DetailedLeaveHeading>
                Active Entitlement Policies{" "}
                <DetailedLeaveSubHeading>
                  (company defaults)
                </DetailedLeaveSubHeading>
              </DetailedLeaveHeading>
              <table>
                <thead>
                  <ActiveEntitlementPolicyHeader>
                    <ActiveEntitlementText>
                      Effective From
                    </ActiveEntitlementText>
                  </ActiveEntitlementPolicyHeader>
                  <ActiveEntitlementPolicyHeader>
                    <ActiveEntitlementText>
                      Effective Until
                    </ActiveEntitlementText>
                  </ActiveEntitlementPolicyHeader>
                  <ActiveEntitlementPolicyHeaderLarge>
                    <ActiveEntitlementText>
                      Entitlement policy
                    </ActiveEntitlementText>
                  </ActiveEntitlementPolicyHeaderLarge>
                </thead>
                <tbody>
                  {activeEntitlementPolicy.map((activePolicy) =>
                    isEditActiveEntitlementPolicy &&
                    activeEntitlementPolicyToEdit === activePolicy.id ? (
                      <tr>
                        <AddActiveEntitlementColumn>
                          <CustomDateField
                            date={effectiveFrom}
                            setDate={(date: any) =>
                              setEffectiveFrom(() => date)
                            }
                            margin="0"
                          />
                        </AddActiveEntitlementColumn>
                        <AddActiveEntitlementColumn>
                          <CustomDateField
                            date={effectiveUntil}
                            setDate={(date: any) =>
                              setEffectiveUntil(() => date)
                            }
                            margin="0"
                          />
                        </AddActiveEntitlementColumn>
                        <AddActiveEntitlementColumn>
                          <CustomSelectField
                            value={selectedEntitlement}
                            onChangeOption={(value: any) =>
                              setSelectedEntitlement(() => value)
                            }
                            option={entitlementPolicy.map((policy) => ({
                              key: policy?.is_custom_name
                                ? policy?.custom_name
                                : policy.name,
                              value: policy.id,
                            }))}
                            margin="0"
                          />
                        </AddActiveEntitlementColumn>
                        <AddActiveEntitlementColumn
                          style={{
                            display: "flex",
                            gap: 10,
                            background: "transparent",
                          }}
                        >
                          <CustomButton
                            title="Save"
                            onClick={() => editActiveEntitlementPolicy()}
                            width="70px"
                            size="small"
                          />
                          <CustomButton
                            title="Cancel"
                            type="secondary"
                            onClick={() =>
                              setIsEditActiveEntitlementPolicy(() => false)
                            }
                            width="70px"
                            size="small"
                          />
                        </AddActiveEntitlementColumn>{" "}
                      </tr>
                    ) : (
                      <tr>
                        <AddActiveEntitlementColumn>
                          {activePolicy.effective_from ? Utils._dateToShow(activePolicy.effective_from) : "-"}
                        </AddActiveEntitlementColumn>
                        <AddActiveEntitlementColumn>
                        {activePolicy.effective_until ? Utils._dateToShow(activePolicy.effective_until) : "-"}
                        </AddActiveEntitlementColumn>
                        <AddActiveEntitlementColumn>
                          {entitlementPolicy[
                            entitlementPolicy?.findIndex(
                              (policy) =>
                                policy.id === activePolicy.entitlement_policy
                            )
                          ]?.custom_name ||
                            entitlementPolicy[
                              entitlementPolicy?.findIndex(
                                (policy) =>
                                  policy.id === activePolicy.entitlement_policy
                              )
                            ]?.name}
                        </AddActiveEntitlementColumn>
                        <AddActiveEntitlementColumn
                          style={{
                            display: "flex",
                            gap: 10,
                            background: "transparent",
                          }}
                        >
                          <CustomButton
                            title="Edit"
                            onClick={() => {
                              setIsEditActiveEntitlementPolicy(true);
                              setActiveEntitlementPolicyToEdit(
                                () => activePolicy.id
                              );
                              setAddActiveEntitlement(() => false);
                              setEffectiveFrom(
                                () => activePolicy.effective_from
                              );
                              setEffectiveUntil(
                                () => activePolicy.effective_until
                              );
                              setSelectedEntitlement(() => activePolicy.entitlement_policy)
                            }}
                            width="50px"
                            size="small"
                          />
                          <CustomButton
                            title="Delete"
                            type="secondary"
                            del
                            onClick={() => {
                              setIsActiveEntitlementDeletePopupActive(
                                () => true
                              );
                              setActiveEntitlementPolicyToDelete(
                                () => activePolicy.id
                              );
                            }}
                            width="70px"
                            size="small"
                          />
                        </AddActiveEntitlementColumn>
                      </tr>
                    )
                  )}

                  {addActiveEntitlement ? (
                    <tr>
                      <AddActiveEntitlementColumn>
                        <CustomDateField
                          date={effectiveFrom}
                          setDate={(date: any) => setEffectiveFrom(() => date)}
                          margin="0"
                        />
                      </AddActiveEntitlementColumn>
                      <AddActiveEntitlementColumn>
                        <CustomDateField
                          date={effectiveUntil}
                          setDate={(date: any) => setEffectiveUntil(() => date)}
                          margin="0"
                        />
                      </AddActiveEntitlementColumn>
                      <AddActiveEntitlementColumn>
                        <CustomSelectField
                          value={selectedEntitlement}
                          onChangeOption={(value: any) =>
                            setSelectedEntitlement(() => value)
                          }
                          option={entitlementPolicy.map((policy) => ({
                            key: policy?.is_custom_name
                              ? policy?.custom_name
                              : policy.name,
                            value: policy.id,
                          }))}
                          margin="0"
                        />
                      </AddActiveEntitlementColumn>
                      <AddActiveEntitlementColumn
                        style={{
                          display: "flex",
                          gap: 10,
                          background: "transparent",
                        }}
                      >
                        <CustomButton
                          title="Create"
                          onClick={() => addActiveEntitlementPolicy()}
                          width="70px"
                          size="small"
                        />
                        <CustomButton
                          title="Cancel"
                          type="secondary"
                          onClick={() => setAddActiveEntitlement(() => false)}
                          width="70px"
                          size="small"
                        />
                      </AddActiveEntitlementColumn>
                    </tr>
                  ) : null}
                  <DeactivateModal
                    open={isActiveEntitlementDeletePopupActive}
                    closeModal={() =>
                      setIsActiveEntitlementDeletePopupActive(() => false)
                    }
                    approveAction={() => deleteActiveEntitlementPolicy()}
                    variant="deleteActiveEntitlement"
                  />
                </tbody>
              </table>
            </LeaveDetailContainer>
            <CustomButton
              title="Add Entitlement Policy Range"
              onClick={() => {
                setAddActiveEntitlement(() => true);
                setIsEditActiveEntitlementPolicy(() => false);
              }}
              width="250px"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DetailedLeaveComponent;
