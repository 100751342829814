import { QueryClient, QueryClientProvider } from "react-query";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { ReactQueryDevtools } from "react-query/devtools";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material/styles";

import { authRouter } from "./navigation/AuthNavigation";
import { Colors } from "./config/color";
import { useAppDispatch } from "./_app";
import { resetMessages } from "./_features";
import LoginSessionExpiredModal from "./components/modals/LoginSessionExpiredModal";
import theme from "./theme";

import firebaseApp from "./_service/FirebaseAnalyticsService";
import { FirebaseContext, useFirebase } from "./_service/firebase-context";

// Create a client
export const queryClient = new QueryClient();

function App() {
  const dispatch = useAppDispatch();
  if (window.performance) {
    if (performance.navigation.type === 1) {
      dispatch(resetMessages());
    }
  }

  return (
    <FirebaseContext.Provider value={firebaseApp}>
      <StyledThemeProvider theme={Colors}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={authRouter} />
            <LoginSessionExpiredModal />
            <ToastContainer
              position="top-right"
              autoClose={7000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover
              theme="light"
            />

            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ThemeProvider>
      </StyledThemeProvider>
    </FirebaseContext.Provider>
  );
}

export default App;
