import apiClient from "./BaseService";
import { URL } from "../config";

const _getCPFList = async (companyId: any) =>
  apiClient.get(`${URL.GET_MONTHLY_CPF}${companyId}`);

const _updateCPFStatus = async (companyId: any, cpfId: any) =>
  apiClient.patch(
    `${URL.UPDATE_CPF_STATUS}${companyId}/update-status/${cpfId}`,
    {
      status: "finalized",
    }
  );

const _addCPFPenalty = async (companyId: any, data: any) =>
  apiClient.post(`${URL.ADD_CPF_PENALTY}${companyId}`, data);

const _getAllIr8aFiling = async (date: string, companyId: string) =>
  apiClient.get(`${URL.GET_ALL_IR8A_FILING}?date=${date}&company=${companyId}`);

const _getAllIr21Filing = async (date: string, companyId: string) =>
  apiClient.get(`${URL.GET_ALL_IR21_FILING}?submission_year=${date}&company_id=${companyId}`);

const _editIr8aFiling = async (data: any, filingId: any) =>
  apiClient.patch(`${URL.GET_ALL_IR8A_FILING}${filingId}/`, data);

const _getPdfIRAS = async (id: any) =>
  apiClient.get(`${URL.GET_ALL_IR8A_FILING}${id}/view_pdf`);

const _getPdfIR8A = async (year:any, employeeId: any, companyId:any) =>
  apiClient.get(`${URL.GET_PDF_IR8A_FILING}?year=${year}&company_id=${companyId}&employee_id=${employeeId}`);

const _getPdfIR21 = async (id: any) =>
  apiClient.get(`${URL.GET_PDF_IR21_FILING}${id}/view_ir21_pdf`);

const _updateCPFPenalty = async (cpfId: any, data: any) =>
  apiClient.patch(`${URL.UPDATE_CPF_STATUS}${cpfId}/update-ftp-file/`, data);

const _importManualFilingService = async (data: any) =>
  apiClient.post(URL.GET_MANUAL_IR8A_DATA, data);

const _exportManualFilingService = async (companyId: any) =>
  apiClient.get(`${URL.EXPORT_MANUAL_IR8A_FILE}?company=${companyId}`);

const _getManualFilingData = async (data: any) =>
  apiClient.get(
    `${URL.GET_MANUAL_IR8A_DATA}?company=${data.companyId}&month=${data.month}&year=${data.year}`
  );

const _deleteFiling = async (id: any) =>
  apiClient.delete(`${URL.GET_MANUAL_IR8A_DATA}${id}`);

const _updateManualFiling = async (filingId: any, data: any) =>
  apiClient.patch(`${URL.GET_MANUAL_IR8A_DATA}${filingId}/`, data);

const _addManualFiling = async (data: any) =>
  apiClient.post(`${URL.GET_MANUAL_IR8A_DATA}create_using_form/`, data);

const _bulkApproveIras = async (data: any) =>
  apiClient.post(URL.BULK_APPROVE_IRAS, data);

const _getPdfData = async (id: any) =>
    apiClient.get(`${URL.GET_CPF_PDF}${id}`)

export const CPFService = {
  _getCPFList,
  _updateCPFStatus,
  _addCPFPenalty,
  _getAllIr8aFiling,
  _editIr8aFiling,
  _getAllIr21Filing,
  _addManualFiling,
  _getPdfIRAS,
  _updateCPFPenalty,
  _exportManualFilingService,
  _getManualFilingData,
  _importManualFilingService,
  _deleteFiling,
  _updateManualFiling,
  _bulkApproveIras,
  _getPdfData,
  _getPdfIR8A,
  _getPdfIR21,
};
