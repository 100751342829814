import { useState } from "react";
import {
  CustomButton,
  CustomDateField,
  CustomNumberField,
  NavigateBack,
} from "../../components";
import { Notify, Utils } from "../../utils";
import { H3Heading } from "../appSettings/AppSettingsStyles";
import { ManageCPFCal } from "../../_service";
import { useNavigate } from "react-router-dom";
import { Constant } from "../../config";

const AddCpfLimit = () => {
  const [effectiveFrom, setEffectiveFrom] = useState(Utils._date());
  const [effectiveTo, setEffectiveTo] = useState(Utils._date());
  const [maxOw, setMaxOw] = useState<any>("0.00");
  // const [maxAw, setMaxAw] = useState<any>("0.00");

  const navigate = useNavigate();

  const addCpfLimit = async () => {
    try {
      const formData = new FormData();
      formData.append("effective_from", effectiveFrom);
      formData.append("effective_to", effectiveTo);
      formData.append("ow", maxOw);
      const { status } = await ManageCPFCal._addCpfLimit(formData);
      if (status === 201) {
        Notify("OW Limit Added Successfully", 1);
        navigate(-1);
      }
    } catch (e: any) {
      Notify(e.response.data.message,0);
      // if (e?.response?.data?.detail) {
      //   Notify(e.response.data.detail, 0);
      // } else {
      //   Notify(Constant.common.something_went_wrong, 0);
      // }
    }
  };

  return (
    <div>
      <NavigateBack />
      <H3Heading>Add OW Limit</H3Heading>
      <CustomDateField
        label="Effective From"
        date={effectiveFrom}
        setDate={setEffectiveFrom}
        startYear={new Date().getFullYear() - 1}
        endYear={new Date().getFullYear() + 10}
      />
      <CustomDateField
        label="Effective To"
        date={effectiveTo}
        setDate={setEffectiveTo}
        startYear={new Date().getFullYear() - 1}
        endYear={new Date().getFullYear() + 10}
      />
      <CustomNumberField
        // title="Max OW"
        title="OW Limit (month)"
        value={maxOw}
        placehoder="0.00"
        onChangeText={setMaxOw}
        onBlur={() => setMaxOw(() => Utils.addDecimalAndZeroes(maxOw))}
      />
      {/* <CustomNumberField
        title="Max AW"
        value={maxAw}
        placehoder="0.00"
        onChangeText={setMaxAw}
        onBlur={() => setMaxAw(() => Utils.addDecimalAndZeroes(maxAw))}
      /> */}
      <CustomButton
        title="Add OW Limit"
        onClick={() => addCpfLimit()}
        styles={{ marginTop: 50, marginLeft: 0 }}
      />
    </div>
  );
};

export default AddCpfLimit;
