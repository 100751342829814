import { useState } from "react";
import { IconContext } from "react-icons";
import { AiFillCaretDown } from "react-icons/ai";
import styled from "styled-components";

interface NavOptionsProps {
  label: string;
  val: string;
  values: any;
  onChange: (val: any) => void;
  name: (val: string) => void;
}

const NavOptions = ({
  label,
  val,
  onChange,
  values,
  name,
}: NavOptionsProps) => {
  const [open, setOpen] = useState(false);

  const handleToggleOpen = () => {
    setOpen((p) => !p);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const isActive = values.map((_: any) => _.navigate).includes(val);
  if (isActive) {
    let _name = values.filter((_: any) => _.navigate === val)[0].displayName;
    name(_name);
  }
  const handleChange = (value: any) => {
    if (onChange) onChange(value);
    handleClose();
  };
  return (
    <SelectContainer
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <SelectLabelButton active={isActive} onClick={handleToggleOpen}>
        {label}
        <IconWrap>
          <IconContext.Provider value={{ size: "14px" }}>
            <AiFillCaretDown />
          </IconContext.Provider>
        </IconWrap>
      </SelectLabelButton>

      <DropdownStyle isVisible={open}>
        {values?.map((value: any, index: number) => (
          <DropdownItem
            onClick={() => handleChange(value)}
            active={value.navigate === val}
            key={index}
          >
            {value.name}
          </DropdownItem>
        ))}
      </DropdownStyle>
    </SelectContainer>
  );
};

export default NavOptions;

const SelectLabelButton = styled.button<any>`
  font-size: 1rem;
  min-height: 43px;
  background-color: transparent;
  border: none;
  color: #868e96;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  outline: none;
  ${(p: any) =>
    p.active === true &&
    `
      color: #212529;
      font-size: 1rem;
      border-bottom: 3px solid #25b481;
    `}
`;

const IconWrap = styled.span`
  display: inline-block;
  padding: 0.5rem;
`;
const SelectContainer = styled.div`
  position: relative;
  font-size: 1rem;
  border: none;
  &:hover ${SelectLabelButton} {
    color: #212529;
    font-size: 1rem;
    border-bottom: 3px solid #25b481;
  }
`;

const DropdownStyle = styled.div<any>`
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #fff;
  transition: max-height 2s ease;
  width: 300px;
  border: 1px solid gray;
  z-index: 999;
  ${(p: any) =>
    p.isVisible !== true &&
    `
      max-height: 40px;
      visibility: hidden;
    `}
`;
const DropdownItem = styled.div<any>`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #868e96;
  cursor: pointer;
  ${(p: any) =>
    p.active &&
    `
      color: #000;
      font-weight: 500;
      background-color: #90ee90;
    `}
  &:hover, :focus, :focus:hover {
    background-color: #f5f5f5;
    color: #000;
    outline: none;
  }
`;
