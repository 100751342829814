import { useEffect, useState } from "react";

import { ManageCPFCal } from "../../_service";
import { Notify, Utils } from "../../utils";
import { useAppSelector } from "../../_app";
import {
  CustomButton,
  CustomDateField,
  CustomNumberField,
  CustomTextField,
} from "../../components";
import moment from "moment";

const AnnualIncrementRow = (props: any) => {
  const { data,  onUpdate, isFinalized } = props;

  const [edit, setEdit] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [incrementAmount, setIncrementAmount] = useState<any>(
    data?.increment_amount
  );
  const [incrementPercentage, setIncrementPercentage] = useState<any>(
    data?.increment_percentage
  );

  const user = useAppSelector((state) => state.user);

  const toggle = () => setEdit((prev) => !prev);

  {console.log("inside the row comp", data)}

  //   const handleCpfTableDataUpdate = async () => {
  //     setUpdating(true);
  //     const formData = {
  //       effective_from: effectiveFrom,
  //       effective_to: effectiveTo,
  //       ow: maxOw,
  //       aw: maxAw,
  //     };
  //     if (parseInt(maxAw) > 102000) {
  //       Notify("Max AW can't be greater than 102000", 0);
  //       setUpdating(false);
  //     } else {
  //       const res = await ManageCPFCal._updateCpfLimitData(
  //         data?.id as string,
  //         formData
  //       );

  //       if (res.status === 200) {
  //         syncData();
  //         Notify("Data Updated Successfully", 1);
  //       } else {
  //         Notify("Data Updating Failed", 0);
  //       }
  //       setUpdating(false);
  //       setEdit(false);
  //     }
  //   };

  useEffect(() => {
    setIncrementAmount(data?.increment_amount);
    setIncrementPercentage(data?.increment_percentage);
  }, [data?.increment_amount, data?.increment_percentage]);

  const onCancel = () => {
    toggle();
    setIncrementAmount(data?.increment_amount);
    setIncrementPercentage(data?.increment_percentage);
  }

  return (
    <tr>
      {edit ? (
        <>
          <td>{data?.employee_name}</td>
          <td>{moment(data?.date_joined).format("DD-MM-YYYY")}</td>
          <td>{data?.employee}</td>
          <td style={{ textAlign: "right" }}>
            {Utils.getCommanSepartedNumericStringWithDollar(
              `${data?.basic_salary}`?.replace(/,/g, "")
            )}
          </td>
          <td style={{ textAlign: "right" }}>
            {/* {Utils.getCommanSepartedNumericStringWithDollar(
              data?.increment_amount?.replace(/,/g, "")
            )} */}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "40%",
                marginLeft: "auto",
                alignItems: 'center'
              }}
            >
              <CustomNumberField value={incrementAmount} onChangeText={(value)=>{
                setIncrementAmount(value);
                let newPercentage = (( parseFloat(value) / parseFloat(`${data?.basic_salary}`?.replace(/,/g, "")) ) || 0) * 100;
                setIncrementPercentage(( isFinite(newPercentage) ? newPercentage : 0 ).toFixed(2));
              }} inputWidth={'100%'}   />
            </div>
          </td>
          <td style={{ textAlign: "right" }}>
          <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "40%",
                marginLeft: "auto",
                alignItems: 'center'
              }}
            >
              {/* <CustomNumberField value={incrementPercentage} onChangeText={setIncrementPercentage} inputWidth={'100%'}   /> */}
              {incrementPercentage}
            </div>
          </td>
          <td>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.25rem",
              }}
            >
              <p
                style={{
                  padding: "0.25rem 0.75rem",
                  background: "#059BBF",
                  cursor: "pointer",
                  color: "white",
                  fontWeight: 400,
                  borderRadius: "0.5rem",
                }}
                onClick={()=>{
                  onUpdate(data?.employee, incrementAmount);
                  toggle();
                }}
              >
                {"Update"}
              </p>
              <img
                onClick={onCancel}
                alt="cancel"
                title="Cancel"
                style={{ width: "2rem", cursor: "pointer" }}
                src="/cancel.svg"
              />
            </div>
          </td>
        </>
      ) : (
        <>
          <td>{data?.employee_name}</td>
          <td>{moment(data?.date_joined).format("DD-MM-YYYY")}</td>
          <td>{data?.employee}</td>
          <td style={{ textAlign: "right" }}>
            {Utils.getCommanSepartedNumericStringWithDollar(
              `${data?.basic_salary}`?.replace(/,/g, "")
            )}
          </td>
          <td style={{ textAlign: "right" }}>
            {Utils.getCommanSepartedNumericStringWithDollar(
              `${data?.increment_amount}`.replace(/,/g, "")
              )}
          </td>
          <td style={{ textAlign: "right" }}>{(parseFloat(data?.increment_percentage || 0)).toFixed(2)}</td>
          {!isFinalized && <td>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <p
                style={{
                  padding: "0.25rem 0.75rem",
                  background: "#059BBF",
                  cursor: "pointer",
                  color: "white",
                  fontWeight: 400,
                  borderRadius: "0.25rem",
                }}
                onClick={toggle}
              >
                {"Edit"}
              </p>
            </div>
          </td>}
        </>
      )}
    </tr>
  );
};

export default AnnualIncrementRow;
