import React, { useState } from "react";
import {
  CustomButton,
  CustomDateField,
  CustomNumberField,
  CustomTextField,
} from "../../../components";
import {
  AiFillDelete,
  AiFillEdit,
  AiOutlineCheck,
  AiOutlineClose,
} from "react-icons/ai";
import { Notify, Utils } from "../../../utils";
import LeaveDatePicker from "./LeaveDatePicker";
import { Constant } from "../../../config";

interface LeaveAdjustmentProps {
  data: any;
  onDelete: (data: any) => Promise<boolean>;
  onAddData: (data: any) => Promise<boolean>;
  onUpdate: (data: any) => Promise<boolean>;
  cancelAdd: (data: any) => void;
}

const isGreater = (d1: Date, d2: Date) => {
  let date1 = new Date(d1).getTime();
  let date2 = new Date(d2).getTime();

  if (date1 < date2) {
    return false;
  } else if (date1 > date2) {
    return true;
  } else {
    return false;
  }
};

const LeaveAdjustmentRow = (props: LeaveAdjustmentProps) => {
  let { data, onDelete, onAddData, onUpdate, cancelAdd } = props;

  const [edits, setEdits] = useState(data.edit);
  const [rowData, setRowData] = useState(data);
  const [updating, setUpdating] = useState({
    delete: false,
    update: false,
    add: false,
  });
  const [errors, setErrors] = useState({ date: "", days: "" });
  const handleDelete = async () => {
    setUpdating((prev) => ({ ...prev, delete: true }));
    const succ = await onDelete(rowData);
    if (succ) {
      Notify("Delete successfully", 1);
    } else {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setUpdating((prev) => ({ ...prev, delete: false }));
  };
  const handleAddData = async () => {
    if (Utils.isEmpty(rowData.date)) {
      setErrors((prev) => ({ ...prev, date: "cannot be empty" }));
      return;
    }
    setErrors((prev) => ({ ...prev, date: "" }));
    if (Utils.isEmpty(rowData.days)) {
      setErrors((prev) => ({ ...prev, days: "cannot be empty" }));
      return;
    }
    setErrors((prev) => ({ ...prev, days: "" }));
    setUpdating((prev) => ({ ...prev, add: true }));
    const succ = await onAddData(rowData);
    if (succ) {
      Notify("Added successfully", 1);
    } else {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setUpdating((prev) => ({ ...prev, add: false }));
  };
  const handleUpdate = async () => {
    setUpdating((prev) => ({ ...prev, update: true }));
    const succ = await onUpdate(rowData);
    if (succ) {
      Notify("Update successfully", 1);
      setEdits(() => false);
    } else {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setUpdating((prev) => ({ ...prev, update: false }));
  };
  return edits ? (
    <tr>
      <td style={{width: '15%'}}>
        <CustomDateField
          date={rowData.date}
          setDate={(date: any) =>
            setRowData((prev: any) => ({ ...prev, date: date }))
          }
          error={errors.date}
          margin="0"
        />
      </td>
      <td style={{width: '15%'}}>
        <CustomNumberField
          value={rowData.days}
          onChangeText={(text: string) =>
            setRowData((prev: any) => ({ ...prev, days: text }))
          }
          error={errors.days}
          marginTop="0"
        />
      </td>
      <td style={{width: '45%'}}>
        <CustomTextField
          value={rowData?.description || ""}
          onChangeText={(text: string) =>
            setRowData((prev: any) => ({ ...prev, description: text }))
          }
          error=""
          marginTop="0"
          inputWidth="100%"
        />
      </td>
      <td style={{width: '20%'}}>
        {" "}
        <LeaveDatePicker
          date={rowData.expiry_date}
          setDate={(date: any) =>
            setRowData((prev: any) => ({ ...prev, expiry_date: date }))
          }
          filterDate={(date) =>
            isGreater(new Date(date), new Date(rowData.date || new Date()))
          }
          minDate={rowData.date}
          error=""
          margin="0"
        />
      </td>
      <td
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: ".5rem",
          width: '5%',
        }}
      >
        <CustomButton
          type={"secondary"}
          size="small"
          width="30px"
          title={""}
          onClick={() => {
            setEdits(() => false);
            data?.edit === true ? cancelAdd(data) : setRowData(data);
          }}
          icon={<AiOutlineClose />}
        />
        <CustomButton
          type={"secondary"}
          size="small"
          width="30px"
          title={""}
          onClick={data?.edit === true ? handleAddData : handleUpdate}
          icon={<AiOutlineCheck />}
          loading={data?.edit === true ? updating.add : updating.update}
        />
      </td>
    </tr>
  ) : (
    <tr>
      <td style={{width: '15%'}}>{rowData.date}</td>
      <td style={{width: '15%'}}>{rowData.days}</td>
      <td style={{width: '45%'}}>{rowData.description}</td>
      <td style={{width: '20%'}}>{rowData.expiry_date}</td>
      <td style={{ display: "flex", alignItems: "center", width: '5%' }}>
        <CustomButton
          type={"secondary"}
          size="small"
          width="30px"
          title={""}
          onClick={() => {
            setEdits(() => true);
          }}
          icon={<AiFillEdit />}
        />
        <CustomButton
          type={"secondary"}
          size="small"
          del
          width="30px"
          title={""}
          onClick={handleDelete}
          icon={<AiFillDelete />}
          loading={updating.delete}
        />
      </td>
    </tr>
  );
};

export default LeaveAdjustmentRow;
