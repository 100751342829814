import styled from "styled-components";

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
`;

export const SearchCompanyDropdown = styled.div`
  position: absolute;
  top: 100%;
  width: 90%;
  max-height: 200px;
  background: white;
  border-radius: 5px;
`;
export const SearchListButton = styled.button`
  border: none;
  background: none;
  width: 100%;
  cursor: pointer;
  text-align: left;
  padding: 10px;

  &:hover,
  &:focus {
    background-color: #efefef;
  }

  &:disabled {
    color: gray;
  }
`;
export const SearchList = styled.ul`
  list-style-type: none;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  max-height: 150px;
  overflow: auto;
`;
