import React, { useContext } from "react";
import { FirebaseContext } from "../_service/firebase-context";
import { getAnalytics, logEvent } from "firebase/analytics";

// The data object format
//   {
//     user_id: res.data.user.id,
//     name: res.data.user.name,
//     event_date_time: moment().utc().format("DD/MM/YYYY hh:mm A"),
//     platform: "web"
//   }

const useFirebaseAnalytics = () => {
  const firebaseInstance = useContext(FirebaseContext);
  const analytics = getAnalytics(firebaseInstance);

  const logFirebaseEvent = (key:string, data:any) => {
    data.env = process.env.REACT_APP_SERVER === "production" ? "live" : "staging"; 
    console.log("data inside hook ", {key, data});
    logEvent(analytics, key, data);
  };

  return logFirebaseEvent;
};

export default useFirebaseAnalytics;
