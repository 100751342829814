import { Editor } from "@tinymce/tinymce-react";

const TextEditor = (props: any) => {
  const {
    handleChange,
    value,
  isLogin,
    placeholder = "Enter HR Template Content",
  } = props;
  const apiKey = process.env.TEXT_EDITOR_API_KEY;
  const editorPlugins = [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "code",
    "help",
    "wordcount",
  ];
  const menuBar = "file edit view insert format tools table help";
  const toolBar = `undo redo | bold italic underline strikethrough |
  fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify |
  outdent indent |  numlist bullist | forecolor backcolor removeformat |
  pagebreak | charmap emoticons | fullscreen  preview save print |
  insertfile image media template link anchor codesample | ltr rtl`;

  const init = {
   
    height: isLogin ? 220:300, 
    plugins: editorPlugins,
    menubar: menuBar,
    toolbar: toolBar,
    placeholder: placeholder,
  };

  return (
    <Editor
      apiKey={apiKey}
      value={value}
      onEditorChange={handleChange}
      init={init}
    />
  );
};

export default TextEditor;
