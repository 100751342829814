import { Link } from "react-router-dom";
import { NewRequestConainter } from "./EployeeReqestStyles";
import { H3Heading } from "../appSettings/AppSettingsStyles";
import { IRASTable } from "../filing/CPFFilingStyles";
import { TableHeader } from "../../components/table";
import { Notify, Utils } from "../../utils";
import { Constant } from "../../config";
import { SelfService } from "../../_service/SelfService";
import { useAppSelector } from "../../_app";
import { useEffect, useState } from "react";
import { TableColumn } from "../users/styles";
import { TableRow } from "../payslip/PayslipStyles";
import { LoaderContainer } from "../../CommonStyles";
import { Loader } from "../../components";
import { TableFilterHeader } from "../dashboard/component";

const EmployeeRequests = () => {
  const tableHeader = [
    {
      name: "Requested On",
      width: "20%",
    },
    {
      name: "Type",
      width: "20%",
    },
    {
      name: "Details",
      width: "40%",
    },
    {
      name: "Status",
      width: "20%",
    },
  ];
  const user = useAppSelector((state) => state.user);
  const [submissionRequest, setSubmissionRequest] = useState<any>([]);
  const [filteredSubmissionRequest, setFilteredSubmissionRequest] =
    useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState<{
    pageNumber: number;
    totalLength: number;
    pagePerData: number;
  }>({
    pageNumber: 1,
    totalLength: 10,
    pagePerData: 10,
  });

  const handlePageValueChange = (
    type: "next" | "previous" | "first" | "last"
  ) => {
    if (type === "next") {
      const isLast =
        pageInfo.pageNumber * pageInfo.pagePerData <
          submissionRequest?.length || 0;
      if (isLast) {
        setPageInfo((prev) => ({ ...prev, pageNumber: prev.pageNumber + 1 }));
      }
    }
    if (type === "previous") {
      if (pageInfo.pageNumber > 1) {
        setPageInfo((prev) => ({
          ...prev,
          pageNumber: prev.pageNumber - 1,
        }));
      }
    }
    if (type === "first") {
      setPageInfo((prev) => ({
        ...prev,
        pageNumber: 1,
      }));
    }
    if (type === "last") {
      setPageInfo((prev) => ({
        ...prev,
        pageNumber: Math.ceil(pageInfo.totalLength / pageInfo.pagePerData),
      }));
    }
  };

  const getEmployeeRequests = async () => {
    setIsLoading(() => true);
    console.log('info user', user)
    try {
      const { data, status } = await SelfService._getInfoUpdateRequests(
        user.defaultCompnayId,
        user.id,
        user?.employee_id
      );
      if (status === 200) {
        setSubmissionRequest(
          () =>
            [...data.data?.submission_requests, ...data.data?.leave_records] ||
            []
        );
        setFilteredSubmissionRequest(
          () =>
            [...data.data?.submission_requests, ...data.data?.leave_records] ||
            []
        );
        setPageInfo((prev) => ({
          ...prev,
          totalLength:
            data.data?.submission_requests?.length +
            data.data?.leave_records?.length,
        }));
      }
    } catch (err) {
      Notify(Constant.common.something_went_wrong, 0);
      setPageInfo((prev) => ({
        ...prev,
        totalLength: 0,
      }));
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    const startIndex =
      pageInfo.pageNumber * pageInfo.pagePerData - pageInfo.pagePerData;
    const endIndex =
      pageInfo.pageNumber * pageInfo.pagePerData > submissionRequest?.length
        ? submissionRequest.length
        : pageInfo.pageNumber * pageInfo.pagePerData;
    setFilteredSubmissionRequest(() =>
      submissionRequest?.slice(startIndex, endIndex)
    );
  }, [pageInfo.pageNumber, submissionRequest]);

  useEffect(() => {
    getEmployeeRequests();
  }, []);
  return isLoading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <div>
      <H3Heading>Info Update</H3Heading>
      <TableFilterHeader
        name="Requests"
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        handlePageValueChange={(val) => handlePageValueChange(val)}
      />
      <IRASTable style={{ marginBottom: 20, width: "100%" }}>
        <TableHeader headerColumns={tableHeader} variant="filled" />
        <tbody>
          {filteredSubmissionRequest?.map((request: any) =>
            request?.request_type &&
            Object.keys(request?.request_type)[0] === "IU" ? (
              <TableRow>
                <TableColumn>
                  {Utils._dateToShow(request.created_at)}
                </TableColumn>
                <TableColumn>Info Update</TableColumn>
                <TableColumn>
                  {request?.json_data &&
                    Object.keys(request?.json_data).length !== 0 &&
                    request.json_data.map(
                      (data: any, index: any) =>
                        index !== 0 &&
                        index !== 1 && (
                          <p>
                            <span>
                              {data.name} : {data.new}
                            </span>
                          </p>
                        )
                    )}
                </TableColumn>
                <TableColumn
                  style={{
                    color:
                      request?.status === "Pending" ? "#059bbf" : "#28a745",
                  }}
                >
                  {request.status}
                </TableColumn>
              </TableRow>
            ) : (
              <TableRow>
                <TableColumn>
                  {Utils._dateToShow(request.created_at)}
                </TableColumn>
                <TableColumn>Leave Request</TableColumn>
                <TableColumn>
                  {request.leave_name} :{" "}
                  {new Date(request.date_from).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}{" "}
                  to{" "}
                  {new Date(request.date_to).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                  <br />(
                  {Utils.differenceInDays(request.date_from, request.date_to) +
                    1}{" "}
                  working day(s))
                </TableColumn>
                <TableColumn
                  style={{
                    color: request?.is_approved
                      ? "#28a745"
                      : request?.is_approved === null
                      ? "#059bbf"
                      : "#dc3545",
                  }}
                >
                  {request?.is_approved
                    ? "Approved"
                    : request?.is_approved === null
                    ? "Pending"
                    : "Rejected"}
                </TableColumn>
              </TableRow>
            )
          )}
        </tbody>
      </IRASTable>
      {submissionRequest.length === 0 && (
        <p style={{ margin: "20px auto", textAlign: "center" }}>
          No Records Found
        </p>
      )}
      <NewRequestConainter>
        <p>Submit a new Request: </p>
        <Link to="leave" style={{ width: "fit-content" }}>
          Leave Request
        </Link>
        <Link to="info" style={{ width: "fit-content" }}>
          Info Update Request
        </Link>
      </NewRequestConainter>
    </div>
  );
};

export default EmployeeRequests;
