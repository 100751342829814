import { useEffect, useState } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import { Constant } from "../../../config";
import { Notify } from "../../../utils";
import { useAppSelector } from "../../../_app";
import { LeaveService } from "../../../_service";
import {
  LeaveButtonContainer,
  LeaveListItem,
  LeaveName,
} from "../AppSettingsStyles";
import { LeaveDetails } from "../../../_types/LeaveInterface";
import { CustomButton, DeactivateModal, Loader } from "../../../components";
import { LoaderContainer } from "../../../CommonStyles";
import { MdDelete } from "react-icons/md";

const LeaveList = () => {
  const [leaveList, setLeaveList] = useState<LeaveDetails[]>([]);
  const [leaveDeleteId, setLeaveDeleteId] = useState<any>();
  const [isDeletePopupActive, setIsDeletePopupActive] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  /**
   * Function to get all Leave types
   */
  const getLeaveTypes = async () => {
    setIsLoading(() => true);
    try {
      if (user?.defaultCompnayId) {
        const { data, status } = await LeaveService._getLeaveTypes(
          parseInt(user.defaultCompnayId)
        );

        if (status === 200) {
          setLeaveList(() => data.data);
        }
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  /**
   * Function to delete leave type
   */
  const deleteLeave = async () => {
    try {
      const { status } = await LeaveService._deleteLeaveType(leaveDeleteId);

      if (status === 200) {
        Notify("Leave Deleted Successfully", 0);
        setIsDeletePopupActive(() => false);
        const updatedList = leaveList.filter(
          (leave) => leave.id !== leaveDeleteId
        );
        setLeaveList(() => updatedList);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  useEffect(() => {
    getLeaveTypes();
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          {leaveList.length ? (
            <ul>
              {leaveList.map((leave) => (
                <LeaveListItem>
                  <LeaveName>{leave.name}</LeaveName>
                  <LeaveButtonContainer>
                    <CustomButton
                      title={Constant.common.view}
                      onClick={() =>
                        navigate("view", {
                          state: {
                            leaveId: leave.id,
                          },
                        })
                      }
                      width="75px"
                    />

                    {leave.is_deletable && (
                      <CustomButton
                        title={Constant.common.delete}
                        onClick={() => {
                          setIsDeletePopupActive(() => true);
                          setLeaveDeleteId(() => leave.id);
                        }}
                        type="secondary"
                        del={true}
                        width="75px"
                        styles={{ margin: "0" }}
                        icon={<MdDelete />}
                      />
                    )}
                  </LeaveButtonContainer>
                </LeaveListItem>
              ))}
            </ul>
          ) : (
            <p>{Constant.settings.leaveType.no_leave}</p>
          )}

          <div style={{ margin: "1rem 0px" }}>
            <CustomButton
              type="secondary"
              success
              width="250px"
              title="Add a New Leave Type"
              icon={<BsPlusCircleFill />}
              onClick={() => navigate("new")}
            />
          </div>
          <DeactivateModal
            open={isDeletePopupActive}
            closeModal={() => setIsDeletePopupActive(() => false)}
            approveAction={() => deleteLeave()}
            variant="deleteLeave"
          />
        </>
      )}
    </div>
  );
};

export default LeaveList;
