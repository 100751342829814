import { useEffect, useState } from "react";

import { ManageCPFCal } from "../../_service";
import { ManageCPFTableRow } from "../../components/dashboard";
import { CPFTableDataProps, EmploymentTypeProps } from "../../_types";
import { CustomSelectField } from "../../components/customFormField";
import { CustomButton, Loader,  } from "../../components";
import { useAppSelector } from "../../_app";
import { Notify, Utils } from "../../utils";
import { Constant } from "../../config";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CustomTooltip from "../../components/CustomTooltip";

const ManageCPFTable = () => {
  const [categoryType, setCategoryType] = useState<EmploymentTypeProps[]>([]);
  const [cpfData, setCpfData] = useState<CPFTableDataProps[]>([]);
  const [policyData, setPolicyData] = useState<any>([]);
  const [employeeType, setEmployeeType] = useState<any>("");
  const [selectedPolicy, setSelectedPolicy] = useState("");
  const [loading, setLoading] = useState(false);

  const user = useAppSelector((state) => state.user);

  const navigate = useNavigate();

  const getCpfPolicy = async () => {
    try {
      setLoading(true);

      const { status, data } = await ManageCPFCal._getCPfPolicy();
      if (status === 200) {
        const updatedData = data.data.map((value: any) => {
          if (value === null) {
            return "Global";
          } else {
            return value;
          }
        });
        setPolicyData(() => updatedData);
        // setSelectedPolicy(() => updatedData[0]);

        let sortedData = [...updatedData].sort(
          (a: any, b: any) => new Date(b).getTime() - new Date(a).getTime()
        );

        for (let i = 0; i < sortedData.length; i++) {
          // console.log("value of i", i);
          if (moment(sortedData[i]).isBefore(moment())) {
            setSelectedPolicy(sortedData[i]);
            break;
          }
        }
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      console.log(e);
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const getCPFDataByCategory = async () => {
    try {
      setLoading(true);

      const res = await ManageCPFCal._getCpfCalDataByCategory(employeeType);
      if (res.status === 200) {
        setCpfData(() => res.data);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);

      if (e.response.status === 401) {
        // disaptch(resetUser());
      }
    }
  };

  const getCPFTableCategory = async (queryParam: string) => {
    try {
      setLoading(true);
      const res = await ManageCPFCal._getCpfCalCategory(queryParam);
      if (res.status === 200) {
        setCategoryType(() => res.data);
        var index = res.data.findIndex(
          (data: any) =>
            data.name.toLowerCase() ===
            "singapore citizens / 3rd year spr contribution rates"
        );
        setEmployeeType(() => res.data[index].id);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);

      if (e.response.status === 401) {
        // disaptch(resetUser());
      }
    }
  };

  const handleEmployeeTypeChange = (e: any) => {
    setEmployeeType(() => e);
  };

  useEffect(() => {
    getCpfPolicy();
  }, []);

  useEffect(() => {
    if (selectedPolicy !== "") {
      getCPFTableCategory(
        selectedPolicy === "Global" ? "" : `?effective_from=${selectedPolicy}`
      );
    }
  }, [selectedPolicy]);

  useEffect(() => {
    if (employeeType !== -1 && employeeType !== "") {
      getCPFDataByCategory();
    }
  }, [employeeType]);

  const ToolTipInfo = () => {
    return <div className="warning-wrap" style={{margin: 0}}>
    <p>Note : Do not overwrite AW, OW and TW </p>
    <p className="sub-text">
      (TW = Total Wage, AW = Additional Wage and OW = Ordinary Wage)
    </p>
  </div>
  }

  return (
    <div
      className="cpf-container"
      style={{ position: "relative", maxHeight: "100vh",  }}
    >
      {loading && <Loader />}
      <div className="manage-pf-wraps" style={{ marginBottom: "0px" }}>
        <h2>Select the CPF Sheet to update Formulas</h2>
        <p className="grey">Manage CPF / Edit CPF</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <CustomSelectField
          title="Select Policy Type"
          placeholder="Select an option"
          value={selectedPolicy}
          option={policyData.map((item: any) => ({
            key: item === "Global" ? item : Utils.dayMonthNameFormat(item),
            value: item,
          }))}
          onChangeOption={(val) => setSelectedPolicy(() => val)}
          margin={"0.25rem"}
        />
        <CustomTooltip title={<ToolTipInfo />} placement={"right"}>
          <img src="/info-outline.svg" />
        </CustomTooltip>
        <CustomButton title="Add New CPF Table" onClick={() => navigate("add")} />
      </div>
      <CustomSelectField
        title="Select Employment Type"
        placeholder="Select an option"
        value={employeeType}
        option={categoryType.map((item) => ({
          key: item.name,
          value: item.id,
        }))}
        onChangeOption={handleEmployeeTypeChange}
        margin={"0.25rem"}
      />

      {/* <div className="warning-wrap">
        <p>Note : Do not overwrite AW, OW and TW </p>
        <p className="sub-text">
          (TW = Total Wage, AW = Additional Wage and OW = Ordinary Wage)
        </p>
      </div> */}

      <div className="table" style={{ marginTop: "0.25rem" }}>
        <table className="company-table">
          <thead className="company-table-head">
            <tr>
              <th>S.no</th>
              <th>Min Age (yr)</th>
              <th>Max Age (yr)</th>
              <th>Min Salary ($)</th>
              <th>Max Salary ($)</th>
              <th>Total CPF</th>
              <th>Employee CPF</th>
              {user.permission === "full" && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {cpfData
              .sort((a: any, b: any) => a.age_min - b.age_min)
              .map((item, index) => (
                <ManageCPFTableRow
                  key={item.id}
                  data={item}
                  s_no={index}
                  syncData={getCPFDataByCategory}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageCPFTable;
