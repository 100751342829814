import { useState, ChangeEvent, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { useQuery, useMutation } from "react-query";

import { ConfirmationModal } from "../../components";
import { CompanyService } from "../../_service";
import { useAppSelector } from "../../_app";
import { ConfirmationModalInterface } from "../../_types";
import { CustomButton } from "../../components/customButton";
import { useNavigate } from "react-router-dom";

const Row = styled(Box)(({theme}) => ({
  display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
}))

const Label = styled(Typography)(({theme}) => ({
  [theme.breakpoints.up("md")]: {
    width: "340px",
  }
}))

const InputField = styled(TextField)(() => ({
  background: "white"
}))

const SuccessTop = styled(Typography)(() => ({
  background: green[300],
  color: "#000",
  padding: "12px 20px"
}))

const ErrorContainer = styled(Typography)(({theme}) => ({
  color: theme.palette.error.main
})
)
interface FormDataInterface {
  mode: "Manual" | "Automatic";
  first_emp_no?: string;
  is_sort_by_emp: boolean;
}

interface formErrorInterface {
  first_emp_no: string;
}

const EmployeeNumbers = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const id: string = useAppSelector((state) => state.employee.appSettingId);

  const [formErrors, setFormErrors] = useState<formErrorInterface>({
    first_emp_no: "",
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [confirmationModalContent, setConfirmationModalContent] =
    useState<null | ConfirmationModalInterface>(null);

  const [formData, setFormData] = useState<FormDataInterface>({
    mode: "Automatic",
    first_emp_no: "001",
    is_sort_by_emp: false,
  });

  const { data, refetch } = useQuery(
    "get_employee_number",
    () => CompanyService._getEmployeeNumber(id),
    { enabled: !!id, cacheTime: 0 },
  );

  const employeeNumber = useMutation({
    mutationFn: CompanyService._addEmployeeNumber,
    onSuccess: () => {
      setShowSuccess(true);
      navigate("/dashboard/app-settings");
      refetch();
    },
  });

  useEffect(() => {
    if (id) {
      if (data && Object.keys(data).length) {
        setFormData(data.setting);
      }
    }
  }, [id, data]);

  const handleInputChange = (value: string | boolean, target: string) => {
    setShowSuccess(false);

    setFormData({
      ...formData,
      [target]: value,
    });
  };
  const postData = () => {
    employeeNumber.mutate({
      id,
      setting: formData,
    });
  };
  const saveData = () => {
    setShowSuccess(false);
    if (!id) {
      return;
    }
    if (formData.mode === "Automatic" && !formData.first_emp_no) {
      setFormErrors({ first_emp_no: "This field is required" });
      return;
    }
    if (data) {
      if (data.setting.first_emp_no !== formData.first_emp_no) {
        setConfirmationModalContent({
          title: "Confirm Employee Number Change",
          content: `You are changing the first employee number from ${data.setting.first_emp_no} to ${formData.first_emp_no}. *Warning: All existing employee numbers will be overwritten.`,
          resetContent: () => setConfirmationModalContent(null),
          onConfirm: () => {
            postData();
          },
        });
        return;
      } else if (
        data.setting.first_emp_no === formData.first_emp_no &&
        data.setting.mode === formData.mode &&
        data.setting.is_sort_by_emp === formData.is_sort_by_emp
      ) {
        return;
      }
    }
    postData();
  };

  return (
    <>
      {showSuccess && (
        <SuccessTop>
          Employee number updated successfully.
        </SuccessTop>
      )}
      <Box
        sx={{
          [theme.breakpoints.up("md")]: {
            paddingRight: "14vw",
          },
        }}
      >
        <Row>
          <Label>
            Employee number mode
          </Label>
          <Box sx={{ width: "100%" }}>
            <FormControl sx={{ width: "250px" }} size="small">
              <Select
                value={formData.mode}
                sx={{
                  "&": {
                    background: "white",
                  },
                }}
                onChange={(event: SelectChangeEvent<unknown>) => {
                  handleInputChange(event.target.value as string, "mode");
                }}
                // onBlur={() => setFormErrors({ ...formErrors, id_type: "" })}
              >
                <MenuItem
                  // key={item.item_code}
                  sx={{ background: "" }}
                  value="Manual"
                >
                  {/* {item.item_name} */}
                  Manual
                </MenuItem>
                <MenuItem value="Automatic">Automatic</MenuItem>
              </Select>
            </FormControl>
            {/* {formErrors.id_type && (
            <p className={classes.error}>{formErrors.id_type}</p>
          )} */}
          </Box>
        </Row>

        {formData.mode === "Automatic" && (
          <Row>
            <Label>
              First Employee Number
            </Label>
            <Box sx={{ width: "100%" }}>
              <InputField
                id="first_emp_no"
                variant="outlined"
                size="small"
                fullWidth
                value={formData.first_emp_no}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e.target.value, e.target.id)
                }
                onBlur={() => setFormErrors({ first_emp_no: "" })}
              />
              {formErrors.first_emp_no && (
                <ErrorContainer>{formErrors.first_emp_no}</ErrorContainer>
              )}
            </Box>
          </Row>
        )}
        <Box
          sx={{
            [theme.breakpoints.up("md")]: {
              width: "400px",
            },
            display: "flex",
            mt: 2.5,
          }}
        >
          <input
            type="checkbox"
            id="is_sort_by_emp"
            checked={formData.is_sort_by_emp}
            onChange={(e) => handleInputChange(e.target.checked, e.target.id)}
          />
          <Typography sx={{ ml: 1 }}>Sort employees by number</Typography>
        </Box>
        <Box sx={{ marginTop: 2.5, display: "flex" }}>
          <CustomButton
            title="Cancel"
            type="secondary"
            onClick={() => navigate("/dashboard/app-settings")}
          />
          <CustomButton title="Save" onClick={saveData} />
        </Box>
      </Box>
      {confirmationModalContent && (
        <ConfirmationModal
          confirmationModalContent={confirmationModalContent}
        />
      )}
    </>
  );
};

export default EmployeeNumbers;
