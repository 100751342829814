import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  CustomButton,
  CustomDateField,
  CustomeCkeckboxField,
  CustomNumberField,
  CustomSelectField,
  CustomTextField,
} from "../../../components";
import { Constant } from "../../../config";
import { Notify, Utils } from "../../../utils";
import { useAppSelector } from "../../../_app";
import { LeaveService } from "../../../_service";
import { ButtonContainer, H3Heading } from "../AppSettingsStyles";
import { CycleStartDateResponse } from "../../../_types/LeaveInterface";

const AddLeaveType = () => {
  const [startDateOptions, setStartDateOptions] = useState<
    CycleStartDateResponse[]
  >([]);

  const [leaveName, setLeaveName] = useState<string>("");
  const [cycleLength, setCycleLength] = useState<string>("12");
  const [cycleDateSelectedOption, setCycleDateSelectedOption] =
    useState<string>("1");
  const [allowRuleOverride, setAllowRuleOverride] = useState<string>("");
  const [customDate, setCustomDate] = useState<string>(Utils._date());
  const [isUnpaidLeave, setIsUnpaidLeave] = useState<boolean>(false);
  const [showOnPayslips, setShowOnPayslips] = useState<boolean>(false);
  const [showLeaveExpiryToEmployees, setShowLeaveExpiryToEmployees] =
    useState<boolean>(false);
  const [minBalRule, setMinBalRule] = useState<boolean>(false);
  const [hideBalances, setHideBalances] = useState<boolean>(false);

  const [leaveNameError, setLeaveNameError] = useState<boolean>(false);
  const [cycleLengthError, setCycleLengthError] = useState<boolean>(false);
  const [minimumBalance, setMinimumBalance] = useState<string>("0");
  // const [visibleFor, setVisibleFor] = useState<string>("everyone")

  const { state, pathname } = useLocation();

  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  const ruleOverrideOptions = [
    "Not Allowed",
    "Admins & Leave Admins",
    "Approvers, Admins & Leave Admins",
  ];

  const visibilityOptions = [
    {
      key: "Everyone",
      value: "everyone",
    },
    {
      key: "Employees with a recent, current, or future entitlement",
      value: "entitled_employees",
    },
  ];

  /**
   * Function to handle input of leave name
   */
  const changeLeaveName = (value: string) => {
    setLeaveName(() => value);
    if (!value) {
      setLeaveNameError(() => true);
    } else {
      setLeaveNameError(() => false);
    }
  };

  /**
   * Function to handle input of leave cycle length
   */
  const changeCycleLength = (value: string) => {
    setCycleLength(() => value);
    if (parseInt(value) < 0 || value === "") {
      setCycleLengthError(() => true);
    } else {
      setCycleLengthError(() => false);
    }
  };

  /**
   * Function to get options to select for Cycle start date
   */
  const getCycleStartDateOptions = async () => {
    try {
      const { data, status } = await LeaveService._getCycleStartDateOptions();

      if (status === 200) {
        setStartDateOptions(() => data.data.input_items);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  /**
   * Function to add leave type
   */
  const addLeaveType = async (e: any) => {
    e.preventDefault();

    if (!leaveName) {
      Notify("Please add Leave Name", 0);
      setLeaveNameError(() => true);
      return;
    }

    if (parseInt(cycleLength) <= 0 || cycleLength === "") {
      Notify("Cycle should be of minimum 1 month");
      setCycleLengthError(() => true);
      return;
    }

    const formData = new FormData();

    formData.append("name", leaveName);
    formData.append("company", user?.defaultCompnayId);
    formData.append("cycle_length", cycleLength);
    formData.append("cycle_start_date", cycleDateSelectedOption);
    formData.append("is_deletable", "true");
    if (isUnpaidLeave) {
      formData.append("is_unpaid_leaves", "true");
    }
    if (showOnPayslips) {
      formData.append("is_show_on_payslip", "true");
    }
    if (showLeaveExpiryToEmployees) {
      formData.append("show_leave_expiry_to_employees", "true");
    }
    if (minBalRule) {
      formData.append("is_set_min_bal_rule", "true");
      formData.append("minimum_balance", minimumBalance);
      formData.append("allowed_override_rule_to", allowRuleOverride);
    }
    if (hideBalances) {
      formData.append("is_hide_bal_in_self_service", "true");
    }
    if (parseInt(cycleDateSelectedOption) === 3) {
      formData.append("custom_date", customDate);
    }

    try {
      let response;
      if (pathname.split("/").pop() === "edit") {
        response = await LeaveService._editLeaveType(
          formData,
          state?.leaveDetails.id
        );
      } else {
        response = await LeaveService._addLeaveType(formData);
      }
      const { data, status } = response;

      if (status === 201 || status === 200) {
        Notify(
          `Leave Type ${status === 201 ? "Added" : "Edited"} Successfully`
        );
        navigate(
          status === 201
            ? "/dashboard/app-settings/leave/entitlement-policy/new"
            : "/dashboard/app-settings/leave/view",
          {
            state: {
              leaveName: leaveName,
              leaveId: data.data?.id || state?.leaveDetails?.id,
              default: true,
            },
          }
        );
      }
    } catch (error) {
      Notify("Something went wrong, please try again later", 0);
      setLeaveName(() => "");
    }
  };

  useEffect(() => {
    getCycleStartDateOptions();
    if (state?.leaveDetails) {
      setLeaveName(() => state?.leaveDetails?.name || "");
      setCycleLength(() => state?.leaveDetails?.cycle_length || "12");
      setCycleDateSelectedOption(
        () => `${state?.leaveDetails?.cycle_start_date.item_code}` || "1"
      );
      setCustomDate(() => state?.leaveDetails?.custom_date || Utils._date());
      setIsUnpaidLeave(() => state?.leaveDetails?.is_unpaid_leaves || false);
      setShowOnPayslips(() => state?.leaveDetails?.is_show_on_payslip || false);
      setShowLeaveExpiryToEmployees(
        () => state?.leaveDetails?.show_leave_expiry_to_employees || false
      );
      setMinimumBalance(() => state?.leaveDetails?.minimum_balance || "0");
      setAllowRuleOverride(
        () => state?.leaveDetails?.allowed_override_rule_to || ""
      );
      // setVisibleFor(() => state?.leaveDetails?.visible_for || "everyone")
    }
  }, []);
  return (
    <div>
      <H3Heading>Leave Type</H3Heading>
      <CustomTextField
        title={Constant.settings.leaveType.name}
        value={leaveName}
        onChangeText={changeLeaveName}
        error={
          leaveNameError
            ? Constant.settings.leaveType.empty_name_error
            : undefined
        }
      />
      <CustomNumberField
        title={Constant.settings.leaveType.cycle_length}
        value={cycleLength}
        onChangeText={changeCycleLength}
        error={
          cycleLengthError
            ? Constant.settings.leaveType.cycle_length_error
            : undefined
        }
      />
      <CustomSelectField
        title={Constant.settings.leaveType.cycle_start_date}
        value={cycleDateSelectedOption}
        onChangeOption={(value) => setCycleDateSelectedOption(() => value)}
        option={startDateOptions?.map((option) => ({
          key: option.item_name,
          value: option.item_code,
        }))}
      />
      {/* <CustomSelectField
        title="Visible for"
        value={visibleFor}
        onChangeOption={(value) => setVisibleFor(() => value)}
        option={visibilityOptions?.map((option) => ({
          key: option.key,
          value: option.value,
        }))}
      /> */}
      {cycleDateSelectedOption === "3" && (
        <CustomDateField
          label={Constant.settings.leaveType.custom_date}
          date={customDate}
          setDate={(date: any) => setCustomDate(() => date)}
        />
      )}

      <CustomeCkeckboxField
        title={Constant.settings.leaveType.unpaid_leave}
        value={isUnpaidLeave}
        onChange={() => setIsUnpaidLeave(() => !isUnpaidLeave)}
      />
      <CustomeCkeckboxField
        title={Constant.settings.leaveType.show_payslips}
        value={showOnPayslips}
        onChange={() => setShowOnPayslips(() => !showOnPayslips)}
      />
      {showOnPayslips && (
        <div style={{ marginLeft: 20 }}>
          <CustomeCkeckboxField
            title="Show leave expiry to employees (if applicable)"
            value={showLeaveExpiryToEmployees}
            onChange={() =>
              setShowLeaveExpiryToEmployees(() => !showLeaveExpiryToEmployees)
            }
          />
        </div>
      )}
      <CustomeCkeckboxField
        title={Constant.settings.leaveType.min_bal}
        value={minBalRule}
        onChange={() => setMinBalRule(() => !minBalRule)}
      />
      {minBalRule && (
        <div style={{ width: "50%" }}>
          <CustomNumberField
            title="Minimum Balance"
            value={minimumBalance}
            onChangeText={setMinimumBalance}
          />
          <CustomSelectField
            title="Allow rule override"
            value={allowRuleOverride}
            placeholder="Select an option"
            onChangeOption={(value) => setAllowRuleOverride(() => value)}
            option={ruleOverrideOptions?.map((option) => ({
              key: option,
              value: option,
            }))}
          />
        </div>
      )}
      <CustomeCkeckboxField
        title={Constant.settings.leaveType.hide_balances}
        value={hideBalances}
        onChange={() => setHideBalances(() => !hideBalances)}
      />
      <ButtonContainer>
        <CustomButton
          title={Constant.common.cancel}
          onClick={() => navigate("/dashboard/app-settings/leave")}
          type="secondary"
        />
        <CustomButton title={Constant.common.save} onClick={addLeaveType} />
      </ButtonContainer>
    </div>
  );
};

export default AddLeaveType;
