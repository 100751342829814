import React from "react";
import { IconContext } from "react-icons";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const NavigateBack = (props: any) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(props?.url || -1);
  };
  return (
    <div
      onClick={handleGoBack}
      style={{ cursor: "pointer", width: "fit-content" }}
    >
      <IconContext.Provider value={{ color: "#059bbf", size: "56px" }}>
        <BiArrowBack />
      </IconContext.Provider>
    </div>
  );
};

export default NavigateBack;
