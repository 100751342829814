import styled from "styled-components";

const LogoutMessageContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  border: none;
  &:focus: {
    outline: none;
  }
`;

export default LogoutMessageContainer;
