import { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { GrNext, GrPrevious } from "react-icons/gr";
import { IoChevronUpOutline } from "react-icons/io5";
import styled, { keyframes } from "styled-components";

import { CustomButton } from "../../../components/customButton";
import { LeaveService } from "../../../_service";
import { useAppSelector } from "../../../_app";
import { LeaveActivityTable } from "../component";

const Date = [
  {
    id: 1,
    name: "01-01-2023 to 31-12-2023",
    from: "2023-01-01",
    to: "2023-12-31",
  },
  {
    id: 2,
    name: "01-01-2024 to 31-12-2024",
    from: "2024-01-01",
    to: "2024-12-31",
  },
];
const LeaveActivity = () => {
  const empId = useAppSelector((state) => state.employee.employee_info.id);

  const [openIndex, setOpenIndex] = useState(0);
  const [toggle, setToggle] = useState(true);

  const [dataList, setDataList] = useState({});
  const [leaveType, setLeaveType] = useState<{ [key: string]: any }>({});

  const [dateSelected, setDateSelected] = useState({
    name: "01-01-2024 to 31-12-2024",
    from: "2024-01-01",
    to: "2024-12-31",
    id: 2,
  });

  const handleDateUpdate = (e: any, leaveName: any) => {
    const _ = Date.filter((item) => item.name === e.target.value);
    if (leaveName) {
      get(_?.[0]?.from, _?.[0]?.to, leaveName);
    }
    if (_?.length > 0) {
      setDateSelected(() => _?.[0]);
    }
  };

  const get = async (
    from: any = dateSelected.from,
    to: any = dateSelected.to,
    leaveName?: string,
  ) => {
    try {
      const type = leaveType[`${leaveName}`];
      const endPoint = type
        ? {
            emp_id: empId,
            from: from,
            to: to,
            leave_type: type,
          }
        : { emp_id: empId, from: from, to: to };
      const res = await LeaveService._getLeaveActivity(endPoint);
      if (res.status === 200) {
        if (type) {
          let val: any = Object.entries(res.data.data || {}).filter(
            (item) => item[0] === leaveName,
          );
          if (val?.length > 0) {
            val = val?.[0]?.[1]?.flat();
          }

          let _: { [key: string]: any } = JSON.parse(JSON.stringify(dataList));
          _[`${leaveName}`] = val;

          setDataList(() => _);
        } else {
          let obj: { [key: string]: any } = {};
          Object.entries(res.data.data || {}).forEach((item: any) => {
            obj[item[0]] = item?.[1]?.[0]?.leave_type;
          });
          setLeaveType(() => obj);
          setDataList(() => res.data.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    get();
  }, []);

  const updateDate = (type: "next" | "previous", leaveName: any) => {
    if (type === "next") {
      if (leaveName) {
        get(Date[1]?.from, Date[1]?.to, leaveName);
      }
      setDateSelected(() => Date[1]);
    }
    if (type === "previous") {
      if (leaveName) {
        get(Date[0]?.from, Date[0]?.to, leaveName);
      }
      setDateSelected(() => Date[0]);
    }
  };

  return (
    <div>
      <Heading>Activity Per Cycle</Heading>
      {Object.entries(dataList || {})?.map((item: any, index: number) => (
        <Card key={item[0]}>
          <CardTitle
            onClick={() => {
              setOpenIndex(index);
              if (openIndex === index) {
                setToggle((_) => !_);
              } else {
                setToggle(true);
              }
            }}
          >
            {item?.[0]}
            <IconWrap
              style={{
                position: "absolute",
                right: 10,
              }}
              open={index === openIndex && toggle}
            >
              <IconContext.Provider value={{ size: "30px" }}>
                <IoChevronUpOutline />
              </IconContext.Provider>
            </IconWrap>
          </CardTitle>
          <CardBody open={index === openIndex && toggle}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomButton
                title=""
                size="small"
                type="secondary"
                width="30px"
                onClick={() => updateDate("previous", item?.[0])}
                icon={<GrPrevious />}
                disable={dateSelected?.name === Date[0].name}
              />
              <select
                value={dateSelected.name}
                onChange={(e: any) => {
                  handleDateUpdate(e, item?.[0]);
                }}
              >
                {Date?.map(
                  (
                    item: { name: string; from: string; to: string },
                    i: number,
                  ) => (
                    <option key={item.name} value={item.name}>
                      {item.name}
                    </option>
                  ),
                )}
              </select>
              <CustomButton
                title=""
                size="small"
                type="secondary"
                width="30px"
                onClick={() => updateDate("next", item?.[0])}
                icon={<GrNext />}
                disable={dateSelected?.name === Date[1].name}
              />
            </div>
            <div>
              {item?.[1] && item?.[1]?.length > 0 && (
                <LeaveActivityTable data={item[1]} />
              )}
            </div>
          </CardBody>
        </Card>
      ))}
      {Object.entries(dataList)?.length < 1 && <p>No Record founds</p>}
    </div>
  );
};

export default LeaveActivity;
const Heading = styled.h3`
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.5rem;
  color: #476575;
  margin-bottom: 1rem;
`;

const Card = styled.div`
  background-color: #fff;
  margin-bottom: 0.2rem;
  border-radius: 0.3rem;
`;
const CardTitle = styled.p`
  cursor: pointer;
  padding: 12px 20px;
  font-size: 1.2rem;
  font-weight: 500;
  position: relative;
  color: #fff;
  background-color: #476575;
`;
const IconWrap = styled.span<any>`
  transition: 0.3s all;
  transform: ${(props) => (!props.open ? "rotate(-180deg)" : "rotate(0deg)")};
`;

const SlideDown = keyframes`
  0% {
    transform: translateY(-80px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CardBody = styled.div<any>`
  border: 1px solid gray;
  padding: 12px 20px;
  transition: 1s all;
  animation: ${SlideDown} 0.5s ease;
  display: ${(props) => (props.open ? "block" : "none")};
`;
