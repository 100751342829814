import styled from "styled-components";
import { DEVICE } from "../../GlobalStyles";
import { NavLink } from "react-router-dom";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.WHITE};
  transition: 0.3s all;

  @media ${DEVICE.laptop} {
    flex-wrap: wrap;
    transition: 0.3s all;
  }
`;

const Heading = styled.h2`
  font-size: 1.5rem;
  text-transform: capitalize;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.BLUE_2};
  transition: 0.3s all;

  @media ${DEVICE.mobileL} {
    font-size: 1.5rem;
    transition: 0.3s all;
  }
  @media ${DEVICE.tablet} {
    font-size: 1.5rem;
    transition: 0.3s all;
  }
`;

const H3Heading = styled.h2`
  font-size: 1.5rem;
  text-transform: capitalize;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.BLUE_2};
  transition: 0.3s all;

  @media ${DEVICE.mobileL} {
    font-size: 1.5rem;
  }
  @media ${DEVICE.tablet} {
    font-size: 1.5rem;
  }
`;

const Link = styled(NavLink)`
  color: #868e96;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  &:hover {
    color: #212529;
    font-size: 1rem;
    font-weight: 400;
    border-bottom: 3px solid #25b481;
  }
  &.active {
    color: #212529;
    border-bottom: 3px solid #25b481;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 50px;
`;

const DropdownButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const LeaveDetailText = styled.p`
  margin-bottom: 16px;
  margin-left: 0;
  font-size: 16px;
  color: ${({ theme }) => theme.GRAY_6};
`;

const LeaveListItem = styled.li`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 3.2px;
  align-items: center;
`;

const LeaveDetailContainer = styled.div`
  margin-left: 20px;
`;

const DefaultEntitlementMessage = styled.p`
  padding: 10px;
  background-color: ${({ theme }) => theme.GREEN_3};
  color: ${({ theme }) => theme.GREEN_2};
  margin-bottom: 30px;
`;

const DefaultEntitlementButton = styled.p`
  color: ${({ theme }) => theme.BLUE_1};
  cursor: pointer;
  border: none;
  background: transparent;
`;

const LeaveButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const LeaveName = styled.div`
  width: 160px;
  font-size: 16px;
`;
const ActiveEntitlementText = styled.p`
  width: 90%;
`;
const AddActiveEntitlementColumn = styled.td`
  background-color: #f0f0f0;
  padding: 5px 10px;
`;

const DetailedLeaveHeading = styled.h2`
  color: ${({ theme }) => theme.BLUE_7};
  margin-top: 5px;
  margin-bottom: 10px;
`;

const DetailedLeaveSubHeading = styled.span`
  color: ${({ theme }) => theme.GRAY_2};
  font-size: 14px;
`;
const ActiveEntitlementPolicyHeader = styled.th`
  width: 150px;
  text-align: left;
`;

const ActiveEntitlementPolicyHeaderLarge = styled.th`
  width: 180px;
`;
const DetailedLeaveDivider = styled.hr`
  margin-top: 20px;
`;

export {
  Heading,
  Link,
  ButtonContainer,
  DropdownButtonContainer,
  LeaveDetailContainer,
  LeaveListItem,
  LeaveDetailText,
  DefaultEntitlementMessage,
  DefaultEntitlementButton,
  LeaveButtonContainer,
  LeaveName,
  ActiveEntitlementText,
  AddActiveEntitlementColumn,
  DetailedLeaveHeading,
  DetailedLeaveSubHeading,
  ActiveEntitlementPolicyHeader,
  ActiveEntitlementPolicyHeaderLarge,
  DetailedLeaveDivider,
  H3Heading,
};
