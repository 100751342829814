import { Text, View, StyleSheet } from "@react-pdf/renderer";

import { Font } from "@react-pdf/renderer";
import NotoSansRegular from "../../../fonts/NotoSans-Bold.ttf";
import NotoSansBold from "../../../fonts/NotoSans-Regular.ttf";
import { Utils } from "../../../utils";

Font.register({
  family: "Noto Sans",
  fonts: [
    {
      src: NotoSansRegular,
      fontWeight: 400,
    },
    {
      src: NotoSansBold,
      fontWeight: 700,
      fontStyle: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
    marginTop: "20px",
  },

  employeeBasicInfo: {
    display: "flex",
    flexDirection: "row",
  },
  boldText: {
    fontFamily: "Noto Sans",
    fontWeight: "bold",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid black",
    width: "100%",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottom: "1px solid black",
    justifyContent: "space-between",
    backgroundColor: "#ddd",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
});

// Create Document Component
function LeaveReportPdf({
  leaveData,
  valueArr,
  dateTo,
  dateFrom,
  headerArr,
  employeeLength,
  companyName,
  employeeNameArr,
  leaveListData,
}: {
  leaveData: any;
  valueArr: any;
  dateTo: string;
  dateFrom: string;
  headerArr: any;
  employeeLength: any;
  companyName: string;
  employeeNameArr: string[];
  leaveListData: any;
}) {
  const uniqueEmployeeNameArr = Array.from(new Set(employeeNameArr));

  return (
    <View>
      <View style={styles.header}>
        <Text style={[{ fontSize: "12px" }]}>{companyName}</Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0px 4px 20px 4px",
          width: "60%",
        }}
      >
        <View>
          <View style={styles.employeeBasicInfo}>
            <Text style={[{ fontSize: "12px" }]}>Leave Report</Text>
          </View>
          <View style={styles.employeeBasicInfo}>
            <Text style={[{ fontSize: "12px" }]}>Date:</Text>
            <Text style={[{ fontSize: "12px", marginLeft: "5px" }]}>
              {Utils._dateToShow(dateFrom)} to {Utils._dateToShow(dateTo)}
            </Text>
          </View>
          <View style={styles.employeeBasicInfo}>
            <Text style={[{ fontSize: "12px" }]}>Number of Employees: </Text>
            <Text style={[{ fontSize: "12px", marginLeft: "5px" }]}>
              {employeeLength}
            </Text>
          </View>
        </View>
      </View>
      {uniqueEmployeeNameArr.map((empName: any) => (
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: 20,
          }}
        >
          <Text style={{ fontSize: "12px" }}>{empName}</Text>
          {Object.keys(leaveData[empName]).map((leaveTypeData: any) => {
            return (
              <View>
                <Text style={{ marginBottom: "5px", fontSize: "10px" }}>
                  {
                    leaveListData[
                      leaveListData.findIndex(
                        (obj: any) => obj.id === parseInt(leaveTypeData)
                      )
                    ].name
                  }
                </Text>
                <View style={{ border: "1px solid black" }}>
                <View style={styles.tableHeader}>
                  {headerArr.map(
                    (header: any, index: any) =>
                      index > 1 && (
                        <Text
                          style={{
                            borderRight: "1px solid black",
                            minWidth: "0px",
                            flex:
                              header === "Available from Previous Cycle"
                                ? 3
                                : 1,
                            padding: "2px 5px",
                          }}
                        >
                          {header}
                        </Text>
                      )
                  )}
                </View>
                {leaveData[empName][leaveTypeData].map(
                  (individualLeaveData: any) => (
                    <View style={styles.row}>
                      <Text
                        style={{
                          borderRight: "1px solid black",
                          flex: 1,
                          minWidth: "0px",
                          padding: "2px 5px",
                        }}
                      >
                        {Utils._dateToShow(individualLeaveData?.date)}
                      </Text>
                      <Text
                        style={{
                          borderRight: "1px solid black",
                          flex: 1,
                          minWidth: "0px",
                          padding: "2px 5px",
                        }}
                      >
                        {individualLeaveData?.accrual || ""}
                      </Text>
                      <Text
                        style={{
                          borderRight: "1px solid black",
                          flex: 1,
                          minWidth: "0px",
                          padding: "2px 5px",
                        }}
                      >
                        {individualLeaveData?.entitlement || ""}
                      </Text>
                      <Text
                        style={{
                          borderRight: "1px solid black",
                          flex: 1,
                          minWidth: "0px",
                          padding: "2px 5px",
                        }}
                      >
                        {individualLeaveData?.movement || ""}
                      </Text>
                      <Text
                        style={{
                          borderRight: "1px solid black",
                          flex: 1,
                          minWidth: "0px",
                          padding: "2px 5px",
                        }}
                      >
                        {individualLeaveData?.adjustment || ""}
                      </Text>
                      <Text
                        style={{
                          borderRight: "1px solid black",
                          flex: 3,
                          minWidth: "0px",
                          padding: "2px 5px",
                        }}
                      >
                        {individualLeaveData?.balance || ""}
                      </Text>
                      <Text
                        style={{
                          borderRight: "1px solid black",
                          flex: 1,
                          minWidth: "0px",
                          padding: "2px 5px",
                        }}
                      >
                        {individualLeaveData?.balance || ""}
                      </Text>
                    </View>
                  )
                )}
                </View>
              </View>
            );
          })}
        </View>
      ))}
      {/* {Object.keys(payslipData).map((payslipDataHeading: any) => (
        <View>
          <View style={{ marginTop: "20px" }}>
            <Text>{payslipDataHeading}</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              {headerArr.map(
                (header: any, index: any) =>
                  index > 0 && (
                    <Text
                      style={{
                        borderRight: "1px solid black",
                        minWidth: "0px",
                        flex: 1,
                      }}
                    >
                      {header}
                    </Text>
                  )
              )}
            </View>
            {
              Object.keys(payslipData[payslipDataHeading]).sort().map((payslipDate) => <View style={styles.row}>
              <Text
                  style={{
                    borderRight: "1px solid black",
                    minWidth: "0px",
                    flex: 1,
                  }}
                >
                  {headerArr.length > 12 ?  Utils._dateToShow(payslipDate).split("-").join(" - ") : Utils._dateToShow(payslipDate)}
                </Text>
                {headerArr?.map(
                  (headerKey: any, index: any) =>
                    index > 1 && (
                      <Text
                        style={{
                          borderRight: "1px solid black",
                          flex: 1,
                          minWidth: "0px",
                          textAlign: "right",
                        }}
                      >
                        {payslipData[payslipDataHeading][payslipDate][headerKey] ? Utils.prependDollar(payslipData[payslipDataHeading][payslipDate][headerKey]) : ""}
                      </Text>
                    )
                )}
              </View>)
            }
          </View>
        </View>
      ))} */}
    </View>
  );
}
export default LeaveReportPdf;
