import styled from "styled-components";
import Spinner from "./Spinner";

const Loader = () => {
  return (
    <Wrapper>
      <Spinner color="#007bff" size="big" />
    </Wrapper>
  );
};

export default Loader;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  z-index: 999;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(.125rem);
  pointer-events: none;
`;
