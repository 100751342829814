import React from "react";

interface RegularHoursFieldsProps {
  dayChecked: boolean;
  day: string;
  partialDay: boolean;
  partialHours: string;
  setDayChecked: (val: boolean) => void;
  setPartialDay: (val: boolean) => void;
  setPartialHours: (val: string) => void;
}
const RegularHoursFields = (props: RegularHoursFieldsProps) => {
  const {
    day,
    dayChecked,
    partialDay,
    partialHours,
    setDayChecked,
    setPartialDay,
    setPartialHours,
  } = props;

  return (
    <div>
      <div className="new-employee regular-working-hours">
        <input
          type="checkbox"
          id="mon"
          checked={dayChecked}
          onChange={(e: any) => {
            setDayChecked(e.target.checked);
            if (e.target.checked === false) {
              setPartialHours("");
              setPartialDay(false);
            }
          }}
        />
        <label>{day}</label>
        {dayChecked && (
          <select
            value={partialDay ? "partial" : "normal"}
            id="monday"
            onChange={(e: any) => {
              if (e.target.value === "partial") {
                setPartialDay(true);
              } else {
                setPartialDay(false);
              }
            }}
            className="pay-frequency"
          >
            <option value="normal">Normal Day</option>
            <option value="partial">Partial Day</option>
          </select>
        )}
        {dayChecked && partialDay && (
          <>
            <label className="hours-label">Hours</label>
            <input
              value={partialHours}
              type="number"
              className="add-partial-hours"
              onChange={(e: any) => setPartialHours(e.target.value)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RegularHoursFields;
