import apiClient from "./BaseService";
import { URL } from "../config";

const _getRoles = async () => apiClient.get(URL.GET_USER_ROLES);

const _addRole = async (name: string) => {
  const data = { name };
  return apiClient.post(URL.ADD_USER_ROLE, data);
};

const _editRole = async (id: any, name: string) => {
  const data = { name };
  return apiClient.patch(`${URL.ADD_USER_ROLE}${id}/`, data);
};

const _getAllPermissions = async () => apiClient.get(URL.GET_ALL_PERMISSIONS);

const _getAllSections = async () => apiClient.get(URL.GET_ALL_SECTIONS);

const _deleteGroup = async (id: any) =>
  apiClient.delete(`${URL.GET_USER_ROLES}${id}`);

const _editGroupPermissions = async (data: any) =>
  apiClient.post(URL.EDIT_GROUPS, data);

export const RoleService = {
  _getRoles,
  _addRole,
  _getAllPermissions,
  _getAllSections,
  _editRole,
  _editGroupPermissions,
  _deleteGroup,
};
