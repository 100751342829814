import { useEffect, useState } from "react";
import Calender from "react-calendar";
import styled from "styled-components";

import "./Calendar.css";
import {
  CustomNumberField,
  CustomSelectField,
  CustomeCkeckboxField,
} from "../../../components/customFormField";
import { Notify, Utils } from "../../../utils";
import { CustomButton } from "../../../components/customButton";
import {
  LeaveService,
  PayrollService,
  S3Service,
  UserService,
} from "../../../_service";
import { useAppSelector } from "../../../_app";
import { LeaveDatePicker } from "../component";
import { Constant } from "../../../config";
import { LeaveRecordTakenText } from "./LeaveStyles";
import { useLocation, useNavigate } from "react-router-dom";

interface SelectedListProps {
  date: string;
  is_partial: boolean;
  factor: string;
  hours: string;
}

// Helper functions
const isWeekend = (date: Date) => [0, 6].includes(date.getDay());
const getDatesInRange = (startDate: any, endDate: any) => {
  const _date = new Date(startDate.getTime());
  const dates = [];
  while (_date <= endDate) {
    dates.push(new Date(_date));
    _date.setDate(_date.getDate() + 1);
  }
  return dates;
};
const isGreater = (d1: Date, d2: Date) => {
  let date1 = new Date(d1).getTime();
  let date2 = new Date(d2).getTime();

  if (date1 < date2) {
    return false;
  } else if (date1 > date2) {
    return true;
  } else {
    return false;
  }
};
const checkDateEquality = (date1: any, date2: any): boolean =>
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate();

interface LeaveBalance {
  last_updated: string;
  leave_balance: number;
  leave_name: string;
  leave_type: number;
}
const LeaveRecordTaken = () => {
  let empId = useAppSelector(
    (state) => state.employee.employee_info.id || state.user.employee_id
  );
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);

  const [holidayList, setHolidayList] = useState<string[]>([]);
  const [date, setDate] = useState<any>();
  const [currentSelected, setCurrentSelected] = useState<{
    startDate?: string;
    endDate?: string;
    currentYear: string;
  }>(() => ({
    currentYear: `${new Date().getFullYear()}`,
  }));
  const [selectedList, setSelectedList] = useState<SelectedListProps[]>([]);
  const [hoursPerDay, setHoursPerDay] = useState<number>(8);
  const [leaveType, setLeaveType] = useState<string>();
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [blackoutDates, setBlackoutDates] = useState<string[]>([]);
  const [calenderData, setCalenderData] = useState([]);
  const [updateData, setUpdateData] = useState({ is: false, id: "" });
  const [error, setError] = useState(0);
  const [leaveBalancesList, setLeaveBalancesList] = useState<LeaveBalance[]>(
    []
  );
  const [isLeaveApproved, setIsLeaveApproved] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();

  // check date include in holiday list
  const isHolidayLeave = (date: Date): boolean =>
    holidayList.includes(Utils._date(date));

  // disable date eg. holiday and weekend
  const isDisable = (date: Date) =>
    holidayList.includes(Utils._date(date)) || isWeekend(date);

  // Customize tile content based on the date (view month) and Return null for other dates
  const tileContent = ({ date, view }: any): React.ReactNode =>
    view === "month" && isHolidayLeave(date) ? (
      <div className="highlight-weekend">Public Holiday</div>
    ) : null;

  // get data by date
  const handleGetDataByDate = (date: string) => {
    let dateJson;
    if (blackoutDates.some((_) => _ === Utils._date(date))) {
      const _: any = calenderData.filter((item: any) => {
        return item.date_json.some((i: any) => i.date === Utils._date(date));
      });
      dateJson = _?.[0]?.date_json
        ?.filter((d_j: any) => d_j.date === Utils._date(date))
        ?.flat();
    } else {
      dateJson = {
        date: date,
        is_partial: false,
        factor: "0",
        hours: "0",
      };
    }
    return dateJson;
  };
  // handle on calender date change
  const handleCalenderDateChange = (date: any) => {
    setError(0);
    if (currentSelected.currentYear != Utils._date(date)) {
      setCurrentSelected((prev) => ({
        ...prev,
        currentYear: `${new Date(date).getFullYear()}`,
      }));
      handleLeaveRecordData("get");
    }
    if (blackoutDates.some((_) => _ === Utils._date(date))) {
      const _: any = calenderData
        .filter((item: any) => {
          return item.date_json.some((i: any) => i.date === Utils._date(date));
        })
        ?.flat();
      const dateJson = _?.[0]?.date_json,
        from = _?.[0]?.date_from,
        to = _?.[0]?.date_to,
        id = _?.[0]?.id;
      setIsLeaveApproved(() => (_[0].is_approved !== null ? true : false));
      setSelectedList(() => dateJson);
      setCurrentSelected((prev) => ({ ...prev, startDate: from, endDate: to }));
      setUpdateData((prev) => ({ ...prev, is: true, id: id }));
      setDate(() => Utils._date(from));
    } else {
      setCurrentSelected((prev) => ({
        ...prev,
        startDate: Utils._date(date) || "",
        endDate: Utils._date(date) || "",
      }));
      setIsLeaveApproved(() => false);
      setDate(() => Utils._date(date));
      setUpdateData((prev) => ({ ...prev, is: false, id: "" }));
    }
  };
  // update selected date list/info
  const updateSelectedList = (
    type: "hour" | "partial",
    item: SelectedListProps,
    value: string | boolean
  ) => {
    const _dataList = JSON.parse(JSON.stringify(selectedList));
    if (type === "hour") {
      _dataList?.map((prev: any) => {
        if (prev?.date === item.date && typeof value === "string") {
          return Object.assign(prev, {
            hours: value,
            factor: parseFloat(value) / hoursPerDay,
          });
        }
        return prev;
      });
    } else {
      _dataList?.map((prev: any) => {
        if (prev?.date === item.date) {
          if (value) {
            return Object.assign(prev, {
              is_partial: value,
              hours: hoursPerDay / 2,
              factor: hoursPerDay / 2 / hoursPerDay,
            });
          } else {
            return Object.assign(prev, {
              is_partial: value,
              hours: "0",
              factor: "0",
            });
          }
        }
        return prev;
      });
    }
    setSelectedList(() => _dataList);
  };
  // get date range and filter weekends
  const getFilteredDatesInRange = (startDate: any, endDate: any) => {
    const _date = new Date(startDate.getTime());
    const dates = [];
    while (_date <= endDate) {
      if (!isDisable(_date)) {
        dates.push(handleGetDataByDate(Utils._date(_date)));
      }
      _date.setDate(_date.getDate() + 1);
    }
    return dates?.flat();
  };

  // get weekends count in date range
  const getWeekendsCount = (startDate: any, endDate: any) => {
    const _date = new Date(startDate.getTime());
    let count: number = 0;
    while (_date <= endDate) {
      if (isDisable(_date)) {
        count += 1;
      }
      _date.setDate(_date.getDate() + 1);
    }
    return count;
  };

  useEffect(() => {
    if (
      isGreater(
        new Date(currentSelected?.startDate || ""),
        new Date(currentSelected?.endDate || "")
      )
    ) {
      setCurrentSelected((prev) => ({
        ...prev,
        endDate: currentSelected?.startDate,
      }));
      setSelectedList(() =>
        getFilteredDatesInRange(
          new Date(currentSelected?.startDate || ""),
          new Date(currentSelected?.startDate || "")
        )
      );
    } else {
      setSelectedList(() =>
        getFilteredDatesInRange(
          new Date(currentSelected?.startDate || ""),
          new Date(currentSelected?.endDate || "")
        )
      );
    }
    setError(0);
  }, [currentSelected?.startDate, currentSelected?.endDate]);

  const getHoursPerDay = async () => {
    try {
      const res = await PayrollService._getWorkingHours(empId, companyId);
      if (res.status === 200) {
        setHoursPerDay(() =>
          res?.data?.hours_per_day ? res?.data?.hours_per_day : 8
        );
      }
    } catch (e: any) {
      if (e?.response?.status === 401) {
        // disaptch(resetUser());
      }
    }
  };

  const getLeaveTypes = async () => {
    try {
      const res = await LeaveService._getLeaveTypes(parseInt(companyId));
      if (res.status === 200) {
        setLeaveType(() => res?.data?.data?.[0]?.id);
        setLeaveTypeList(() =>
          res?.data?.data?.map((item: any) => ({
            key: item?.name,
            value: item?.id,
          }))
        );
      }
    } catch (e: any) {
      if (e?.response?.status === 401) {
        // disaptch(resetUser());
      }
    }
  };
  const getLeaveBalance = async () => {
    try {
      const res = await LeaveService._getLeaveBalance(
        empId,
        `${currentSelected.currentYear}-01-01`,
        `${currentSelected.currentYear}-12-31`,
        companyId
      );
      if (res.status === 200) {
        const _leaveBalanceList: any = Object.entries(res.data.data).map(
          (item) => item[1]
        );
        setLeaveBalancesList(() => _leaveBalanceList.flat());
      }
    } catch (e: any) {
      console.error(e);
    }
  };
  const handleLeaveRecordData = async (
    type: "get" | "update" | "add" | "del",
    data?: any
  ) => {
    try {
      if (type === "get") {
        const res = await LeaveService._getLeaveRecordTaken(
          empId,
          `${currentSelected.currentYear}-01-01`,
          `${currentSelected.currentYear}-12-31`
        );
        if (res.status === 200) {
          setCalenderData(() => res.data.data);
          const _dates = res.data.data.map((item: any) =>
            item.date_json.map((_item: any) => _item.date)
          );
          setBlackoutDates(() => _dates?.flat());
        }
      }
      if (type === "add") {
        let errorCount = 0;
        data.date_json.forEach((item: any) => {
          if (parseFloat(item.hours) > hoursPerDay) {
            errorCount += 1;
          }
        });
        setError(() => errorCount);
        if (errorCount > 0) {
          return;
        }
        const res = await LeaveService._postLeaveRecordTaken(data);
        if (res.status === 201) {
          Notify("Leave add successfully", 1);
          if (location.pathname === "/dashboard/requests/leave") {
            navigate(-1);
          } else {
            handleLeaveRecordData("get");
            setDate(null);
            setCurrentSelected((prev) => ({
              ...prev,
              startDate: "",
              endDate: "",
            }));
          }
        }
      }
      if (type === "update") {
        let errorCount = 0;
        data.date_json.forEach((item: any) => {
          if (parseFloat(item.hours) > hoursPerDay) {
            errorCount += 1;
          }
        });
        setError(() => errorCount);
        if (errorCount > 0) {
          return;
        }
        const res = await LeaveService._patchLeaveRecordTaken(
          data,
          updateData.id
        );
        if (res.status === 200) {
          Notify("Leave updated successfully", 1);
          handleLeaveRecordData("get");
        }
      }
      if (type === "del") {
        const res = await LeaveService._deleteLeaveRecordTaken(
          data,
          updateData.id
        );
        if (res.status === 200) {
          Notify("Leave deleted successfully", 1);
          if (location.pathname === "/dashboard/requests/leave") {
            navigate(-1);
          } else {
            handleLeaveRecordData("get");
            setDate(null);
            setCurrentSelected((prev) => ({
              ...prev,
              startDate: "",
              endDate: "",
            }));
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLeaveList = async () => {
    try {
      const res = await S3Service._getCalenderLeaveList(
        currentSelected.currentYear
      );
      const _holidayList = res?.data?.map(
        (item: { Date: string }) => item.Date
      );
      setHolidayList(() => _holidayList);
    } catch (error) {
      console.error(error);
    }
  };
  const handleConformClick = (del: boolean = false) => {
    let data = {
      employee: empId,
      company: companyId,
      leave_type: leaveType,
      date_from: currentSelected.startDate,
      date_to: currentSelected.endDate,
      date_json: selectedList,
    };
    if (updateData.is) {
      if (del) {
        handleLeaveRecordData("del", data);
      } else {
        handleLeaveRecordData("update", data);
      }
    } else {
      handleLeaveRecordData("add", data);
    }
  };

  useEffect(() => {
    getHoursPerDay();
    getLeaveTypes();
    getLeaveList();
    handleLeaveRecordData("get");
    getLeaveBalance();
  }, []);

  // UI marker
  const WEEKENDS_COUNT = getWeekendsCount(
    new Date(currentSelected?.startDate || ""),
    new Date(currentSelected?.endDate || "")
  );

  return (
    <>
      <div className="calender-container">
        <p className="title">Record Leave</p>
        <div style={{ paddingLeft: 10 }}>
          <CustomSelectField
            title="Type:"
            value={leaveType}
            option={leaveTypeList}
            onChangeOption={(val) => setLeaveType(val)}
            width="300px"
          />
        </div>
        <LeaveRecordTakenText>
          Select a date from the calendar to begin adding leave days.
        </LeaveRecordTakenText>
        <div className="calender-wrapper">
          <div className="cal-wrap">
            <Calender
              tileContent={tileContent}
              value={date}
              calendarType="US"
              tileDisabled={({ date }) => isDisable(date)}
              tileClassName={({ date, view }) => {
                if (
                  blackoutDates.find((x) =>
                    checkDateEquality(new Date(x), new Date(date))
                  )
                ) {
                  return "highlight";
                }
                if (
                  getDatesInRange(
                    new Date(currentSelected?.startDate || ""),
                    new Date(currentSelected?.endDate || "")
                  ).find((x) => checkDateEquality(new Date(x), new Date(date)))
                ) {
                  return "current-highlight";
                }
              }}
              onChange={(date: any) => {
                handleCalenderDateChange(date);
              }}
            />
          </div>
          {date && (
            <div className="mp-1" style={{ marginLeft: "1rem" }}>
              {WEEKENDS_COUNT ? (
                <p className="red">
                  {WEEKENDS_COUNT} dates were excluded because they were holiday
                  or weekend days.
                </p>
              ) : null}
              <div className="flex">
                <div className="mep5">
                  <b>From:</b>
                  <LeaveDatePicker
                    date={currentSelected?.startDate || date}
                    setDate={(val: any) => {
                      setCurrentSelected((prev) => ({
                        ...prev,
                        startDate: val,
                      }));
                      setDate(() => val);
                    }}
                    margin="0.2rem"
                    filterDate={(date) => {
                      // Disable weekends (Saturday and Sunday)
                      return !isWeekend(date);
                    }}
                  />
                </div>
                <div>
                  <b>To:</b>
                  <LeaveDatePicker
                    date={currentSelected?.endDate || date}
                    minDate={
                      currentSelected.startDate
                        ? new Date(currentSelected.startDate)
                        : null
                    }
                    margin="0.2rem"
                    setDate={(val: any) =>
                      setCurrentSelected((prev) => ({ ...prev, endDate: val }))
                    }
                    filterDate={(date) => {
                      // Disable weekends (Saturday and Sunday)
                      return !isWeekend(date);
                    }}
                  />
                </div>
              </div>
              <div>
                {selectedList?.length > 0 && (
                  <Table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Partial</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedList?.map((item) => {
                        return (
                          <tr key={item.date}>
                            <td style={{ width: "150px" }}>
                              {Utils._dateToShow(item.date)}
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <CustomeCkeckboxField
                                  onChange={() =>
                                    updateSelectedList(
                                      "partial",
                                      item,
                                      !item.is_partial
                                    )
                                  }
                                  title=""
                                  value={item.is_partial}
                                  width="40px"
                                />
                                {item.is_partial && (
                                  <div className="number-field-wrap">
                                    <CustomNumberField
                                      value={item.hours}
                                      onChangeText={(value) => {
                                        updateSelectedList("hour", item, value);
                                      }}
                                      width="40px"
                                      marginTop="0"
                                    />
                                    <p className="text-wrap">
                                      of {hoursPerDay} hours
                                    </p>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                {error !== 0 && (
                  <>
                    <ErrorText>
                      You have selected invalid hours for {error} days.
                    </ErrorText>
                    <ErrorText>
                      Please correct the values before saving
                    </ErrorText>
                  </>
                )}
              </div>
              {!isLeaveApproved && (
                <div>
                  <div className="flex mp-1">
                    <CustomButton
                      onClick={() => handleConformClick()}
                      title="Confirm"
                      width="100px"
                    />
                    <CustomButton
                      type="secondary"
                      onClick={() => {
                        setDate(null);
                      }}
                      title="Cancel"
                      width="100px"
                    />
                  </div>
                  {updateData.is && (
                    <div className="mp-1">
                      <CustomButton
                        del
                        width="150px"
                        type="secondary"
                        onClick={() => handleConformClick(true)}
                        title="Remove Dates"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {leaveBalancesList.length > 0 && (
        <div className="leave-container">
          <p className="title">Leave Balances</p>
          <div style={{ padding: "1rem" }}>
            <Table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Days</th>
                  <th>Last Updated</th>
                </tr>
              </thead>
              <tbody>
                {leaveBalancesList.map((item) => {
                  return (
                    <tr>
                      <td>{item.leave_name}</td>
                      <td>{item.leave_balance}</td>
                      <td>{Utils._dateToShow(item.last_updated)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Span>
              These balances take into account days included in pending leave
              requests.
            </Span>
          </div>
        </div>
      )}
    </>
  );
};

export default LeaveRecordTaken;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  th {
    padding: 0.25rem;
  }
  td {
    height: 40px;
  }
  tr {
    background-color: #fff;
  }
  tbody {
    tr:nth-child(odd) {
      background-color: #f2f2f2;
    }
    tr:nth-child(even) {
      background-color: #fff;
    }
  }
`;
const ErrorText = styled.p`
  color: red;
`;
const Span = styled.span`
  font-size: 0.8rem;
  color: #6c757d;
`;
