import { useEffect, useState } from "react";
import { RoleService } from "../../../_service";
import { Notify } from "../../../utils";
import { LoaderContainer } from "../../../CommonStyles";
import { CustomButton, Loader } from "../../../components";
import { Heading } from "../../appSettings/AppSettingsStyles";
import { Constant } from "../../../config";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableHeader } from "../../../components/table";
import { TableRow } from "../../payslip/PayslipStyles";

const EditGroups = () => {
  const [allSections, setAllSections] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState<any>([]);
  const [tableHeader, setTableHeader] = useState<any>([{ width: "15%" }]);

  const getAllSections = async () => {
    try {
      const res = await RoleService._getAllSections();
      if (res.status === 200) {
        const sectionData = res.data.data.map((data: any) => ({
          id: data.id,
          name: data.name,
          tables: data.tables.map((table: any) => table.id),
        }));

        setAllSections(() => sectionData);
      }
    } catch (e: any) {
      Notify(Constant.common.something_went_wrong);
    }
  };

  const groupOrder = [
    "CSO",
    "Data Entry",
    "Finalise",
    "Finalizer",
    "Approver",
    "Leave",
    "Claims",
    "HR",
    "TMS",
    "Leave Approval",
    "TellBoss",
    "Employees",
  ];

  const getUserGroups = async () => {
    try {
      const { data, status } = await RoleService._getRoles();

      if (status === 200) {

        const len = data.length;
        const percent = 85 / len;

        let tempArr = data.map((dataObj: any) => {
              return {
                id: dataObj.id,
                name: dataObj.name,
                width: percent,
              };
            });

        let orderedGroup = groupOrder?.map((x:any) => tempArr?.filter((y:any) => y.name === x) ).flat();
        console.log("flatArr", orderedGroup);
        
        setGroups(orderedGroup);
        // const len = data.length;
        // const percent = 85 / len;
        // const updatedTableHeader = data
        //   .sort((a: any, b: any) => a.id - b.id)
        //   .map((dataObj: any) => ({
        //     name: dataObj.name,
        //     width: `${percent}%`,
        //   }));
        // setTableHeader((prevTable: any) => [
        //   ...prevTable,
        //   ...updatedTableHeader,
        // ]);
        setTableHeader([{ width: "15%" },...orderedGroup])
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const toggleGroup = async (groupId: any, sectionId: any) => {
    const index = allSections.findIndex(
      (section: any) => section.id === sectionId,
    );

    const sectionData = allSections;
    const groupIndex = sectionData[index].tables.indexOf(groupId);
    let tableData = sectionData[index].tables;

    if (groupIndex === -1) {
      tableData = [...tableData, groupId];
    } else {
      tableData.splice(groupIndex, 1);
    }
    sectionData[index].tables = tableData;

    setAllSections(() => sectionData);
  };

  useEffect(() => {
    setIsLoading(() => true);
    getUserGroups();
    getAllSections();
    setIsLoading(() => false);
  }, []);

  const submitRoleAccess = async (e: any) => {
    e.preventDefault();
    const dataToSend = allSections.map((section: any) => ({
      id: section.id,
      group: section.tables,
    }));

    try {
      const res = await RoleService._editGroupPermissions(dataToSend);
      if (res.status === 200) {
        Notify("Permissions Updated Successfully", 1);
        setIsLoading(() => true);
        getAllSections();
        setIsLoading(() => false);
      }
    } catch (error) {
      Notify("Some Error Occurred");
    }
  };

  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <Heading>Edit Group Access</Heading>
          <div>
            <form className="role-form">
              <IRASTable>
                <TableHeader
                  headerColumns={tableHeader}
                  variant="filled"
                  align="center"
                />
                <tbody>
                  {allSections.map((section: any) => (
                    <TableRow>
                      <td style={{ padding: 10 }}>{section.name}</td>
                      {groups.map((group: any) => (
                        <td style={{ textAlign: "center" }}>
                          <input
                            type="checkbox"
                            style={{ width: 20 }}
                            defaultChecked={section.tables.some(
                              (id: any) => id === group.id,
                            )}
                            onChange={() => toggleGroup(group.id, section.id)}
                          />
                        </td>
                      ))}
                    </TableRow>
                  ))}
                </tbody>
              </IRASTable>
              <div style={{ marginTop: 40 }}>
                <CustomButton onClick={submitRoleAccess} title="Save" />
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default EditGroups;
