import React from "react";
import { IconContext } from "react-icons";
import { IoCaretBackCircle, IoCaretForwardCircle } from "react-icons/io5";
import DatePicker from "react-datepicker";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getYear = (date: any) => {
  return new Date(date).getFullYear();
};

const getMonth = (date: any) => {
  return months[new Date(date).getMonth()];
};

const range = (a: any, b: any) => {
  let data = [];
  for (let i = a; i <= b; i++) {
    data.push(i);
  }
  return data;
};
const DateField = (props: { label: string; date: any; setDate: any }) => {
  const years = range(1900, new Date().getFullYear());

  return (
    <div className="new-employee">
      <label className="employee-label">{props.label}</label>
      <div>
        <DatePicker
          className="employee-input date-input"
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }): any => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                style={{ border: "none" }}
              >
                <IconContext.Provider value={{ color: "#000", size: "20px" }}>
                  <IoCaretBackCircle />
                </IconContext.Provider>
              </button>
              <select
                value={getYear(date)}
                onChange={({ target: { value } }: any) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={getMonth(date)}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                style={{ border: "none" }}
              >
                <IconContext.Provider value={{ color: "#000", size: "20px" }}>
                  <IoCaretForwardCircle />
                </IconContext.Provider>
              </button>
            </div>
          )}
          dateFormat="dd/MM/yyyy"
          selected={new Date(props.date) || new Date()}
          onChange={(date: any) =>
            props.setDate(date?.toISOString().split("T")[0])
          }
        />
      </div>
    </div>
  );
};

export default DateField;
