import apiClient from "./BaseService";

import { URL } from "../config";
import { CPFLimitDataProps, UpdateCpfCalDataProps } from "../_types";

const _getCpfCalCategory = async (queryString: string) =>
  apiClient.get(`${URL.GET_CPF_CALCULATOR_CATEGORY}${queryString}`);

const _getCpfCalDataByCategory = async (id: number) =>
  apiClient.get(URL.GET_CPF_CALCULATOR_DATA_BY_CATEGORY + id);

const _updateCpfCalData = async (params: UpdateCpfCalDataProps) =>
  apiClient.patch(
    URL.UPDATE_CPF_CALCULATOR_DATA_BY_CATEGORY + params.id + "/",
    params.data
  );

interface CalculateCPFProps {
  code: string;
  dob: string;
  yoc: string;
  emp_ow: string;
  emp_aw: string;
}
const _getCpfCal = async (props: CalculateCPFProps) => {
  const { code, dob, emp_aw, emp_ow, yoc } = props;
  return await apiClient.get(
    URL.GET_CPF_CALCULATOR +
      `?code=${code}&dob=${dob}&yoc=${yoc}&emp_ow=${emp_aw}&emp_aw=${emp_ow}`
  );
};

const _getCPfPolicy = async () => apiClient.get(URL.GET_CPF_POLICY);

const _getCPfLimit = async () => apiClient.get(URL.GET_CPF_LIMIT);

const _getAwLimit = async () => apiClient.get(URL.GET_AW_LIMIT);

const _addCpfPolicy = async (data: any) =>
  apiClient.post(URL.GET_CPF_CALCULATOR_CATEGORY, data);

const _addCpfLimit = async (data: any) =>
  apiClient.post(URL.GET_CPF_LIMIT, data);

const _addAwLimit = async (data: any) => apiClient.post(URL.GET_AW_LIMIT, data);

const _addMangeDeduction = async (data: any) => apiClient.post(URL.ADD_MANAGE_DEDUCTION, data);

const _deleteManageDeduction = async(id : string)=> apiClient.delete(URL.DELETE_MANAGE_DEDUCTION + id)

const _updateCpfLimitData = async (id: string, data: CPFLimitDataProps) =>
  apiClient.patch(URL.GET_CPF_LIMIT + id + "/", data);

const _updateAwData = async (id: string, data: any) =>
  apiClient.patch(URL.GET_AW_LIMIT + id + "/", data);

export const ManageCPFCal = {
  _getCpfCalCategory,
  _getCpfCalDataByCategory,
  _updateCpfCalData,
  _getCpfCal,
  _getCPfPolicy,
  _addCpfPolicy,
  _updateCpfLimitData,
  _getCPfLimit,
  _addCpfLimit,
  _getAwLimit,
  _addAwLimit,
  _updateAwData,
  _addMangeDeduction,
  _deleteManageDeduction,
};
