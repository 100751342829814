import apiClient from "./BaseService";

import { URL } from "../config";
import { BasicSalaryProps } from "../_types";

const _getPayrollDashboard = async (id: string) =>
  apiClient.get(URL.GET_PAYROLL_DASHBOARD + id);

const _getBasicSalary = async (id: string) =>
  apiClient.get(URL.GET_BASIC_SALARY + id);

const _updateBasicSalary = async (data: BasicSalaryProps) =>
  apiClient.post(URL.UPDATE_BASIC_SALARY, data);

const _getEmployeeDataById = async (id: string) =>
  apiClient.get(URL.GET_EMPLOYEE + id + "/");

const _updateEmployeeData = async (params: any) =>
  apiClient.patch(URL.GET_EMPLOYEE + params.id + "/", params.data);

const _getWorkingHours = async (id: string, com_id: string) =>
  apiClient.get(URL.GET_WORKING_HOURS + `${id}&company_id=${com_id}`);

const _updateEmployeeWorkingHour = async (
  emp_id: string,
  com_id: string,
  data: any
) =>
  apiClient.post(
    URL.UPDATE_WORKING_HOURS + `?employee_id=${emp_id}&company_id=${com_id}`,
    data
  );

const _getPayslipCalculationPageData = async (id: string, date: string) =>
  apiClient.get(
    URL.GET_PAYSLIP_CALCULATION_DATA + `?employee_id=${id}&payslip_date=${date}`
  );

const _updatePayslipCalculationData = async (data: any) =>
  apiClient.patch(URL.UPDATE_PAYSLIP_CALCULATION_DATA + data.id, data.params);

const _getPayslipLog = async () => apiClient.get(URL.GET_PAYSLIP_LOG);

const _getRegularHoursLog = async () =>
  apiClient.get(URL.GET_REGULAR_HOURS_LOG);

const _getNotes = async (data: any) => apiClient.post(URL.GET_NOTES, data);

const _updateNotes = async (data: {
  payrollId: string;
  code: string;
  category: string;
  note: string;
}) =>
  apiClient.put(URL.UPDATE_NOTES + `${data?.payrollId}/update_notes/`, {
    code: data?.code || "",
    category: data?.category || "",
    note: data?.note || "",
  });

const _getEndServiceReasons = async () =>
  apiClient.get(URL.GET_END_SERVICE_REASON);

const _updateEndService = async (data: any) =>
  apiClient.post(
    URL.POST_END_SERVICE +
      `employee_id=${data.employee}&company_id=${data.company}`,
    data
  );

const _getEndService = async (data: any) =>
  apiClient.get(
    URL.POST_END_SERVICE +
      `employee_id=${data.employee}&company_id=${data.companyId}`
  );

const _getServicePeriodHistory = async (data: any) =>
  apiClient.get(
    URL.GET_SERVICE_PERIOD_HISTORY +
      `employee_id=${data.employee}&company_id=${data.companyId}`
  );

const _undoTermination = async (
  id: any,
) =>
  apiClient.delete(
    URL.UNDO_TERMINATION +
      `id=${id}`
  );

const _getRegularInputItems = async (emp_id: any, com_id: any, date: any) =>
  apiClient.get(
    URL.GET_REGULAR_INPUT_ITEM +
      `employee_id=${emp_id}&company_id=${com_id}&payslip_date=${date}`
  );

const _updateRegularInputItem = async (
  emp_id: any,
  com_id: any,
  date: any,
  data: any
) =>
  apiClient.post(
    URL.UPDATE_REGULAR_INPUT_ITEM +
      `employee_id=${emp_id}&company_id=${com_id}&payslip_date=${date}`,
    data
  );

const _deleteRegularInputItem = async (
  emp_id: any,
  com_id: any,
  date: any,
  data: any
) =>
  apiClient.delete(
    URL.UPDATE_REGULAR_INPUT_ITEM +
      `employee_id=${emp_id}&company_id=${com_id}&payslip_date=${date}`,
    {
      data,
    }
  );

  const _getServicePeriod= async (employee: any) =>
    apiClient.get(
    URL.GET_SERVICE_PERIOD_INFO +`?employee_id=${employee}`
    );

    const _editServicePeriod= async (employee: any,data:any) =>
      apiClient.patch(
      URL.EDIT_SERVICE_PERIOD_INFO +`${employee}/`,data
      );

const _payslipFinalizeState = async (id: string, data: any) =>
  apiClient.put(
    URL.UPDATE_FINALIZE_STATUS + `${id}/update_finalize_status/`,
    data
  );

const _resetPayslipData = async (data: any) =>
  apiClient.post(URL.RESET_PAYROLL, data);

const _getMonthlyRate = async (empId: any, amount: any) => {
  apiClient.get(`${URL.GET_EMPLOYEE}${empId}/monthly_rated/?amount=${amount}`)
}

export const PayrollService = {
  _getPayrollDashboard,
  _getBasicSalary,
  _updateBasicSalary,
  _getEmployeeDataById,
  _updateEmployeeData,
  _getWorkingHours,
  _updateEmployeeWorkingHour,
  _getPayslipCalculationPageData,
  _updatePayslipCalculationData,
  _getPayslipLog,
  _getRegularHoursLog,
  _getNotes,
  _updateNotes,
  _getEndServiceReasons,
  _updateEndService,
  _getRegularInputItems,
  _updateRegularInputItem,
  _deleteRegularInputItem,
  _payslipFinalizeState,
  _getEndService,
  _resetPayslipData,
  _getMonthlyRate,
  _getServicePeriodHistory,
  _undoTermination,
  _getServicePeriod,
  _editServicePeriod
};
