import { useState } from "react";
import Modal from "react-modal";
import { AiOutlineCloseCircle } from "react-icons/ai";

import "../header/style.css";
import { Notify } from "../../utils";
import { TextInput } from "../formField";
import { IconContext } from "react-icons";
import { useAppDispatch, useAppSelector } from "../../_app";
import { resetUser, updateMessage } from "../../_features";
import { CompanyService } from "../../_service";
import { Link, NavLink, useNavigate } from "react-router-dom";
import LogoutModal from "./LogoutModal";

interface SubmenuModalProps {
  open: boolean;
  close: () => void;
}

const customStyles = {
  content: {
    top: "50px",
    left: "auto",
    right: "0px",
    bottom: "auto",
    border: "1px solid rgba(0,0,0,.15)",
    borderRadius: "0 0 0px 10px",
    backgroundColor: "#f5f5f5",
  },
};

const SubmenuModal = (props: SubmenuModalProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { open, close } = props;

  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  const user = useAppSelector((state) => state.user);
  const menu = useAppSelector((state) => state.user.menu);

  const handleLogout = () => {
    close();
    setOpenLogoutModal(true);
    // // disaptch(resetUser());
    // // Notify("Logout Succesfully");
    // dispatch(
    // 	updateMessage({
    // 		message: "Logged out successfully",
    // 		type: "success",
    // 		page: "login",
    // 	})
    // );
    // navigate("/");
    // close();
  };

  const closeLogoutModal = () => {
    setOpenLogoutModal(false);
  };

  const logout = () => {
    dispatch(resetUser());
    navigate("/");
  };

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={close}
        style={customStyles}
        contentLabel="Add Company"
        ref={(e) => e}
      >
        <a href="/dashboard/user-profile">See your Roles</a>
        <h6
          style={{
            width: "200px",
            color: "#6c757d",
            fontSize: "1rem",
          }}
        >
          {user.email}
        </h6>
        <nav>
          {menu?.includes("manage_company") && (
            <div className="nav-item" onClick={close}>
              <NavLink className="item" to="manage-company">
                Manage Companies
              </NavLink>
            </div>
          )}
          <div className="nav-item" onClick={close}>
            <NavLink className="item" to="user-profile">
              User Profile
            </NavLink>
          </div>
          {menu?.includes("manage_user") && (
            <div className="nav-item" onClick={close}>
              <NavLink className="item" to="manage-users">
                Manage Users
              </NavLink>
            </div>
          )}
          {/* <div className="nav-item" onClick={close}>
						<NavLink className="item" to="manage-roles">
							Manage Roles
						</NavLink>
					</div> */}
          <hr />
          <div className="nav-item" onClick={handleLogout}>
            Logout
          </div>
        </nav>
      </Modal>
      <LogoutModal
        closeLogoutModal={closeLogoutModal}
        onLogout={logout}
        open={openLogoutModal}
      />
    </>
  );
};

export default SubmenuModal;
