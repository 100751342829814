import { useState } from "react";
import Modal from "react-modal";
import { IconContext } from "react-icons";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { Notify } from "../../utils";
import { CompanyService } from "../../_service";
import { useAppDispatch } from "../../_app";
import { resetUser } from "../../_features";
import styled from "styled-components";
import CommonModal from "./CommonModal";

interface CompanyDataProps {
  id: string;
  name: string;
  reg_id: string;
  address: string;
}

interface DeleteCompanyConformModalProps {
  open: boolean;
  handleClose: () => void;
  updateData: () => void;
  data: CompanyDataProps;
}

const CompanyDeleteConformModal = (props: DeleteCompanyConformModalProps) => {
  const { handleClose, open, data, updateData } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const handleDeleteComapany = async () => {
    try {
      setLoading(true);
      const res = await CompanyService._deleteCompany(data.id);
      handleClose();
      updateData();
      setLoading(false);

      if (res.status === 200) {
        Notify("Company Delete Successfully", 1);
      } else {
        Notify("Company Delete Failed", 0);
      }
    } catch (e: any) {
      setLoading(false);
    }
  };
  return (
    <CommonModal
      handleClose={handleClose}
      open={open}
      loading={loading}
      updateData={handleDeleteComapany}
      body={`Are you sure want to archive ${data.name}`}
      heading="Archive Company"
      conformButtonText="Archive"
      cancelButtonText="Cancel"
    />
  );
};

export default CompanyDeleteConformModal;
