import { CustomTextField } from "../../../components";

const AddDownloadDetails = (props: any) => {
  const {
    serialNo,
    advancedCode,
    handleAdvancedCodeChange,
    handleSerialNoChange,
  } = props;
  return (
    <div>
      <CustomTextField
        title="Serial No"
        value={serialNo}
        onChangeText={(val) => handleSerialNoChange(val)}
      />
      <CustomTextField
        title="Advice Code"
        value={advancedCode}
        onChangeText={(val) => handleAdvancedCodeChange(val)}
      />
    </div>
  );
};

export default AddDownloadDetails;
