import apiClient from "./BaseService";

import { URL } from "../config";
import { PaymentTypeInterface, FinalizedPayrunInterface } from "../_types";

const _bulkPayslipFinalize = async (data: any) => {
  return apiClient.put(URL.BULK_PAYSLIP_FINALIZE, data);
};
const _getPayrunByCompany = async (
  id: number | string,
  year: number | string,
): Promise<{
  payrun_finalized_payslips: {
    [date: string]: FinalizedPayrunInterface;
  }[];
  pending_payslips: {
    [date: string]: {
      finalized: PaymentTypeInterface;
      pending: PaymentTypeInterface;
    };
  }[];
}> => {
  const response = await apiClient.get(URL.GET_PAYRUN_BY_COMPANY + `${id}` + `&year=${year}`);
  return response.data;
};

const _createPayrun = async (data: {
  company: string;
  payslip_date: string;
}) => {
  return await apiClient.post(URL.CREATE_PAYRUN, data);
};

const _getAccountingInfo = async (id: string, payslipDate: any) =>
  apiClient.get(URL.GET_ACCOUNTING_INFO_BY_COMPANY + id + `&payslip_date=${payslipDate}`);

const _getAllAccountingInfo = async (id: string,) =>
  apiClient.get(URL.GET_ALL_ACCOUNTING_INFO_BY_COMPANY + id);

export const PayrunService = {
  _bulkPayslipFinalize,
  _getPayrunByCompany,
  _createPayrun,
  _getAccountingInfo,
  _getAllAccountingInfo,
};
