import { useLocation } from "react-router-dom";
import styled from "styled-components";

const CompanyInfoModal = () => {
  const { pathname } = useLocation();
  const path = pathname.split("/").pop();

  return (
    <Container>
      <Header>Some tasks to get you up and running:</Header>
      <SubContainer>
        <Card
          success={
            path === "employer-details" || path === "employer-filing-details"
          }
        >
          <h2>1. Employer Details</h2>
          <p>
            You can access this page again later by going to{" "}
            <b>Settings {`->`} Employer Details</b>
          </p>
        </Card>
        <Card success={path === "pay-frequencies"}>
          <h2>2. Pay Frequencies</h2>
          <p>
            You can access this page again later by going to{" "}
            <b>Settings {`->`} Pay Frequencies</b>{" "}
          </p>
        </Card>
        <Card success={path === "add-employee"}>
          <h2>3. Add Employees</h2>
          <p>
            You can access this page again later by going to <b>Employees</b>
          </p>
        </Card>
      </SubContainer>
    </Container>
  );
};

export default CompanyInfoModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  border: 1px solid #333;
  border-radius: 0.25rem;
`;
const Header = styled.div`
  background-color: #4a5e71;
  color: #f8f9fa;
  padding: 0.75rem 1.25rem;
`;
const SubContainer = styled.div`
  display: flex;
`;

const Card = styled.div<{ success: boolean }>`
  margin: 1rem;
  border: 1px solid gray;
  border-radius: 0.25rem;
  h2 {
    padding: 0.75rem 1.25rem;
    background-color: ${(props) => (props.success ? "#28a745" : "#dc3545")};
    font-size: 1rem;
    color: #f8f9fa;
  }
  p {
    padding: 1.25rem;
  }
`;

// #28a745 !important
// #dc3545 !important
// #dc3545 !important
