import { useEffect, useState } from "react";
import { useAppSelector } from "../../_app";
import { useLocation, useNavigate } from "react-router-dom";
import { PayrollService } from "../../_service";
import { Notify, Utils } from "../../utils";
import { CustomButton } from "../customButton";
import { Constant } from "../../config";

const EmployeePayslipAddField = () => {
  let location = useLocation();
  const navigate = useNavigate();

  const payrollId = useAppSelector((state) => state.payroll.payrollId);
  const [data, setData] = useState<any>();
  const [note, setNote] = useState("");

  const updateData = async () => {
    try {
      if ((250 - note?.length || 0) < 0) {
        Notify("Ensure the field has no more than 250 characters.", 0);
        return;
      }
      if (Utils.isEmpty(note)) {
        Notify("Note field cannot be blank.", 0);
        return;
      }
      const res = await PayrollService._updateNotes({
        payrollId: payrollId,
        code: data.code,
        category: data.category,
        note,
      });
      if (res.status === 200) {
        Notify("Notes added successfully", 1);
        handleNavigateBack();
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    } catch (e: any) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };
  useEffect(() => {
    setData(() => location?.state);
    setNote(() => location?.state?.note);
  }, []);

  const handleNavigateBack = () => {
    navigate("/dashboard/notes");
  };

  return (
    <div>
      <h2 style={{ color: "#476575" }}>Add Note to {data?.name}</h2>
      <div style={{ width: "100%", display: "flex" }}>
        <textarea
          style={{
            minWidth: "500px",
            height: "150px",
            padding: ".5rem",
            fontSize: "1rem",
          }}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <p
          style={{
            color: (250 - note?.length || 0) > -1 ? "grey" : "red",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            marginLeft: "1rem",
          }}
        >
          {250 - note?.length || 0}
        </p>
      </div>
      <div style={{ display: "flex", marginTop: "1rem" }}>
        <CustomButton
          type="secondary"
          title="Cancel"
          onClick={handleNavigateBack}
        />
        <CustomButton title="Save" onClick={updateData} />
      </div>
    </div>
  );
};

export default EmployeePayslipAddField;
