import React from "react";
import styled from "styled-components";

const Tooltip = ({
  children,
  text,
  big = false,
  del = false,
  ...rest
}: any) => {
  const [show, setShow] = React.useState(false);

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {show && (
        <TooltipContainer big={big} del={del}>
          {text}
          <Arrow del={del} />
        </TooltipContainer>
      )}
      <div {...rest}>{children}</div>
    </div>
  );
};
export default Tooltip;
const TooltipContainer = styled.div<any>`
  position: absolute;
  background: ${(props) => (props.del ? "#dc3545" : "#087ca7")};
  color: white;
  top: -110%;
  width: ${(props) => (props.big ? "115px" : "auto")};
  left: ${(props) => (props.big ? "-55px" : "-50%")};
  padding: 5px;
  border-radius: 5px;
`;

const Arrow = styled.span<any>`
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: ${(props) => (props.del ? "#dc3545" : "#087ca7")} transparent
    transparent;
`;
