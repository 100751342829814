import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// User data types
interface Props {
  cpfCalCatogry:
    | {
        id: string;
        name: string;
        code: string;
      }[]
    | null;
}

// initial state of user's data
const initialState: Props = {
  cpfCalCatogry: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // reset the state
    resetDashboard: () => initialState,
    updateCpfCalCatogary: (state, action: PayloadAction<any>) => {
      state.cpfCalCatogry = action.payload;
    },
  },
});

export const { resetDashboard, updateCpfCalCatogary } = dashboardSlice.actions;
export default dashboardSlice.reducer;
