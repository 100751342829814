import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import Logo from "../../../assets/Logo.png";
import { Utils } from "../../../utils";

const styles = StyleSheet.create({
  page: {
    display: "flex",
  },
  fullPage: {
    color: "black",
    fontSize: "10px",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "12px",
    justifyContent: "center",
  },

  employeeBasicInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  boldText: {
    fontFamily: "Noto Sans",
    fontWeight: "bold",
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "black",
    marginBottom: 10,
  },
  tableRow: {
    width: "100%",
    flexDirection: "row",
  },
  tableCell: {
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: "black",
    padding: 2,
    flex: 1,
    fontSize: "7px",
  },
  headerCell: {
    color: "black",
    fontSize: "7px",
    backgroundColor: "#DDDDDD",
  },
  alignRight: {
    textAlign: "right",
  },
});

const PdfDocument = (props: any) => {
  const { documents, company, period } = props;

  documents?.employee_data?.forEach((x: any)=>{
    
    let shg = parseFloat(x.CDAC) || parseFloat(x['ECF eurasian']) || parseFloat(x.MBMF) || parseFloat(x.SINDA) || 0
    console.log('shg',x.Name, x. shg, parseFloat(x.CPFB)) 
    x.CPFB = parseFloat(x.CPFB) + parseFloat(`${shg}`);
  });

  const sortedDocuments = documents?.employee_data?.sort((a: any, b: any) =>
    a.Name.localeCompare(b.Name)
  );
  
  documents?.non_cpf_employee_dat?.forEach((x: any)=>{
    
    let shg = parseFloat(x.CDAC) || parseFloat(x['ECF eurasian']) || parseFloat(x.MBMF) || parseFloat(x.SINDA) || 0
    console.log('shg',x.Name, x. shg, parseFloat(x.CPFB)) 
    x.CPFB = parseFloat(x.CPFB) + parseFloat(`${shg}`);
  });

  const sortedDocuments_nonCPF = documents?.non_cpf_employee_dat?.sort((a: any, b: any) =>
    a.Name.localeCompare(b.Name)
  );

  console.log('theSorted', sortedDocuments)

  const contributionObject = {
    totalMbmfDonor: documents?.employee_data?.filter(
      (empData: any) =>
        !!parseFloat(empData["MBMF"]) && parseFloat(empData["MBMF"]) !== 0
    ).length,
    totalSindaDonor: documents?.employee_data?.filter(
      (empData: any) =>
        !!parseFloat(empData["SINDA"]) && parseFloat(empData["SINDA"]) !== 0
    ).length,
    totalCdacDonor: documents?.employee_data?.filter(
      (empData: any) =>
        !!parseFloat(empData["CDAC"]) && parseFloat(empData["CDAC"]) !== 0
    ).length,
    totalEcfDonor: documents?.employee_data?.filter(
      (empData: any) =>
        !!parseFloat(empData["ECF"]) && parseFloat(empData["ECF"]) !== 0
    ).length,
  } as any;

  const tableHeaders = [
    "Name",
    "CPF Ref",
    "Join Date",
    "Resign Date",
    "OW",
    "AW",
    "TW",
    "Bank to Pay",
    "CPF",
    "MBMF",
    "SINDA",
    "CDAC",
    "ECF",
    "SDL",
    "To Pay CPFB",
  ];

  const toBeFormatted = [
    "OW",
    "AW",
    "TW",
    "Bank to Pay",
    "CPF",
    "MBMF",
    "SINDA",
    "CDAC",
    "ECF",
    "SDL",
    "To Pay CPFB",
  ]

  const totalTableRows = [
    {
      name: "CPF",
      value: "total_cpf",
      donorVal: "totalCpfDonor",
    },
    {
      name: "Skills Development Levy (SDL)",
      value: "total_sdl",
      donorVal: "totalSdlDonor",
    },
    {
      name: "MBMF Contribution",
      value: "total_mbmf",
      donorVal: "totalMbmfDonor",
    },
    {
      name: "SINDA Contribution",
      value: "total_sinda",
      donorVal: "totalSindaDonor",
    },
    {
      name: "CDAC Contribution",
      value: "total_cdac",
      donorVal: "totalCdacDonor",
    },
    {
      name: "ECF Contribution",
      value: "total_ecf",
      donorVal: "totalEcfDonor",
    },
  ];

  let netTotals = 0;

  totalTableRows?.map((rowData) => {
    if (rowData.value === "total_sdl") {
      if (documents[rowData.value] !== "") {
        netTotals += parseFloat(documents[rowData.value].replace("$", ""));
      }
    } else {
      if (documents[rowData.value] !== "") {
        netTotals += documents[rowData.value];
      }
    }
  });

  console.log("insidePDF-Doc", documents)

  return (
    <Document>
      <Page size="A4" style={styles.fullPage} orientation="landscape">
        <View style={styles.page}>
          <View>
            <View style={styles.header}>
              <Text>{company}</Text>
            </View>
            <View style={{ marginBottom: "10px" }}>
              <Text>CPF Report</Text>
              <Text>Period:{period} </Text>
            </View>
          </View>
          <View style={styles.table}>
            {/* Table Header */}
            <View style={styles.tableRow}>
              {tableHeaders.map((header, index) => (
                <View
                  key={index}
                  style={{
                    ...styles.tableCell,
                    ...styles.headerCell,
                    flex:
                      header === "Name"
                        ? 2
                        : ["CPF Ref", "Join Date", "Resign Date"].includes(
                            header
                          )
                        ? 1
                        : 1,
                  }}
                >
                  <Text>{header}</Text>
                </View>
              ))}
            </View>
            {sortedDocuments?.map((rowData: any, rowIndex: any) => (
              <View key={rowIndex} style={[styles.tableRow]}>
                <View style={{ ...styles.tableCell, flex: 2 }}>
                  <Text>{rowData?.Name}</Text>
                </View>
                <View style={{ ...styles.tableCell, flex: 1 }}>
                  <Text>{rowData["CPF Ref"]}</Text>
                </View>
                <View style={{ ...styles.tableCell, flex: 1 }}>
                  <Text>
                    {rowData["Join Date"]
                      ? Utils._dateToShow(rowData["Join Date"])
                      : ""}
                  </Text>
                </View>
                <View style={{ ...styles.tableCell, flex: 1 }}>
                  <Text>
                    {rowData["Resign Date"]
                      ? Utils._dateToShow(rowData["Resign Date"])
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["OW"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["OW"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["AW"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["AW"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["TW"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["TW"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["Net_pay"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["Net_pay"].replace(",","")).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["CPF"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["CPF"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["MBMF"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["MBMF"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["SINDA"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["SINDA"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["CDAC"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["CDAC"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["ECF eurasian"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["ECF eurasian"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {/* {!!parseFloat(rowData["ECF eurasian"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["ECF eurasian"]).toFixed(2)
                        )
                      : ""} */}
                    {!!parseFloat(rowData["Sdl"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["Sdl"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>

                <>
                {console.log("rowData CPFB", rowData)}
                </>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["CPFB"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["CPFB"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>


              </View>
            ))}
          </View>

          {/* Non CPF table */}

          <View style={{ marginTop: 20 }}>
            <Text>Non CPF holder accounts but must pay SHG donations and SDL</Text>
          </View>

          <View style={{...styles.table, marginTop: 10}}>
            {/* Table Header */}
            <View style={styles.tableRow}>
              {tableHeaders.map((header, index) => (
                <View
                  key={index}
                  style={{
                    ...styles.tableCell,
                    ...styles.headerCell,
                    flex:
                      header === "Name"
                        ? 2
                        : ["CPF Ref", "Join Date", "Resign Date"].includes(
                            header
                          )
                        ? 1
                        : 1,
                  }}
                >
                  <Text>{header}</Text>
                </View>
              ))}
            </View>
            {sortedDocuments_nonCPF?.map((rowData: any, rowIndex: any) => (
              <View key={rowIndex} style={[styles.tableRow]}>
                <View style={{ ...styles.tableCell, flex: 2 }}>
                  <Text>{rowData?.Name}</Text>
                </View>
                <View style={{ ...styles.tableCell, flex: 1 }}>
                  <Text>{rowData["CPF Ref"]}</Text>
                </View>
                <View style={{ ...styles.tableCell, flex: 1 }}>
                  <Text>
                    {rowData["Join Date"]
                      ? Utils._dateToShow(rowData["Join Date"])
                      : ""}
                  </Text>
                </View>
                <View style={{ ...styles.tableCell, flex: 1 }}>
                  <Text>
                    {rowData["Resign Date"]
                      ? Utils._dateToShow(rowData["Resign Date"])
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["OW"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["OW"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["AW"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["AW"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["TW"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["TW"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["Net_pay"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["Net_pay"].replace(",","")).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["CPF"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["CPF"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["MBMF"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["MBMF"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["SINDA"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["SINDA"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["CDAC"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["CDAC"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["ECF eurasian"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["ECF eurasian"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {/* {!!parseFloat(rowData["ECF eurasian"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["ECF eurasian"]).toFixed(2)
                        )
                      : ""} */}
                    {!!parseFloat(rowData["Sdl"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["Sdl"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>

                <>
                {console.log("rowData CPFB", rowData)}
                </>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!parseFloat(rowData["CPFB"])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(rowData["CPFB"]).toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>


              </View>
            ))}
          </View>
          <View style={{ marginTop: 20 }}>
            <Text>Totals</Text>
          </View>
          <View style={{ ...styles.table, marginTop: 20, width: "60%" }}>
            {/* Table Header */}
            <View style={styles.tableRow}>
              {["Description", "Total", "Donor"].map((header, index) => (
                <View
                  key={index}
                  style={{
                    ...styles.tableCell,
                    ...styles.headerCell,
                    flex: header === "Description" ? 2 : 1,
                  }}
                >
                  <Text>{header}</Text>
                </View>
              ))}
            </View>
            {totalTableRows?.map((rowData: any, rowIndex: any) => (
              <View key={rowIndex} style={[styles.tableRow]}>
                <View style={{ ...styles.tableCell, flex: 2 }}>
                  <Text>{rowData.name}</Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {rowData.value === "total_sdl"
                      ? parseFloat(documents[rowData.value]?.replace("$", ""))
                        ? Utils.getCommanSepartedNumericStringWithDollar(
                            parseFloat(
                              documents[rowData.value]?.replace("$", "")
                            ).toFixed(2)
                          )
                        : ""
                      : parseFloat(documents[rowData.value])
                      ? Utils.getCommanSepartedNumericStringWithDollar(
                          parseFloat(documents[rowData.value])?.toFixed(2)
                        )
                      : ""}
                  </Text>
                </View>
                <View style={[styles.tableCell, styles.alignRight]}>
                  <Text>
                    {!!contributionObject[rowData.donorVal]
                      ? contributionObject[rowData.donorVal]
                      : ""}
                  </Text>
                </View>
              </View>
            ))}
            <View style={[styles.tableRow]}>
              <View style={{ ...styles.tableCell, flex: 2 }}>
                <Text>Grand Total</Text>
              </View>
              <View style={{ ...styles.tableCell, ...styles.alignRight }}>
                <Text>{Utils.getCommanSepartedNumericStringWithDollar(netTotals.toFixed(2))}</Text>
              </View>
              <View style={{ ...styles.tableCell }}>
                <Text></Text>
              </View>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Image src={Logo} style={{ width: "80px", marginTop: "20px" }} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfDocument;
