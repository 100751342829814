import styled from "styled-components";
import { Utils } from "../../../utils";

const LeaveActivityTable = (props: any) => {
  let { data } = props;

  let bal = 0;
  data = data.map((item: any, index: number) => {
    if (index === 0) {
      bal = item.entitlement + item.movement;
    } else {
      bal += item.movement;
    }
    return { ...item, balance: bal };
  });

  return (
    data &&
    data?.length > 0 && (
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>Entitlement</th>
            <th>Movement</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any) => (
            <tr key={item.id}>
              <td>{Utils._dateToShow(item.date)}</td>
              <td>{item.description}</td>
              <td>{item.entitlement}</td>
              <td>{item.movement}</td>
              <td>{item.balance}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  );
};

export default LeaveActivityTable;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  margin-top: 1rem;
  thead tr {
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
  }
  th {
    padding: 6px 12px;
  }
  td {
    padding: 6px 12px;
  }
  tbody tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
    border-top: "1px solid #dee2e6";
  }
`;
