import styled from "styled-components";

export const RequestDetailContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 10px 20px;
`;

export const RequestDetailInputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 5px;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    
  }
`;


export const RequestDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CardContainer = styled.div`
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  margin: 10px;
`;

export const CardWrapper = styled.div`
  box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11),
    0 4px 16px 0 rgba(167, 175, 183, 0.33);
  border-radius: 5px;
  padding-bottom: 10px;
`;

export const CardHeadingContainer = styled.div`
  font-size: 20px;
  font-weight: 500;
  padding: 10px 20px;
`;

export const CardButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CardHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #defaf1;
`;
