import { useLocation } from "react-router-dom";
import {
  AllEmployeesHeading,
  ReportFilterBody,
  ReportFilterBodyHeading,
  ReportFilterContainer,
  ReportHeading,
  ReportHeadingContainer,
  ReportHorizontalSeparator,
  ReportSectionHeading,
} from "../ReportFilterPageStyles";
import {
  CommonModal,
  CustomButton,
  CustomDateField,
  Loader,
  NavigateBack,
} from "../../../components";
import { Constant } from "../../../config";
import { useEffect, useState } from "react";
import { Notify, Utils } from "../../../utils";
import { useAppSelector } from "../../../_app";
import { EmployeeService } from "../../../_service";
import { LoaderContainer } from "../../../CommonStyles";
import styled from "styled-components";
import EmployeeListModal from "../EmployeeListModal/EmployeeListModal";
import { H3Heading } from "../../appSettings/AppSettingsStyles";
import { ReportService } from "../../../_service/ReportService";
import ReactPDF from "@react-pdf/renderer";
import PayslipExportPdf from "../ReportPdf/PayslipExportPdf";
import JSZip from "jszip";

const pathToHeading = {
  "/dashboard/reports/employee_basic_info": "Employee Basic Info",
  "/dashboard/reports/payslip_export": "Payslip Exports",
  "/dashboard/reports/leave_expiry": "Leave Expiry",
} as any;

const PayslipExportExtraComp = (props: any) => {
  let currentDate = new Date(
    useAppSelector((state) => state?.user?.current_payrun_date)
  );

  if (!currentDate) {
    currentDate = new Date();
  }
  currentDate.setDate(1);
  // Move back one day to the last day of the last month
  currentDate.setDate(0);
  const lastDayOfLastMonth = Utils._date(currentDate);

  // Set the date to the first day of the last month
  currentDate.setDate(1);
  const firstDayOfLastMonth = Utils._date(currentDate);

  const { downloadFunc } = props;
  const { pathname } = useLocation();
  const [fromDate, setFromDate] = useState<string>(firstDayOfLastMonth);
  const [toDate, setToDate] = useState<string>(lastDayOfLastMonth);
  const [employeeList, setEmployeeList] = useState<any>({});
  const [filteredEmployeeList, setFilteredEmployeeList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState<any>([]);
  const [statusValue, setStatusValue] = useState<string>("0");
  const [combinePayslip, setCombinePayslip] = useState<boolean>(false);
  const [payslipData, setPayslipData] = useState<any>([])

  const user = useAppSelector((state) => state.user);

  const handleSelectAll = () => {
    const allEmpIds = employeeList.map((emp: any) => emp.id);
    setSelectedEmployeeList(() => allEmpIds);
  };

  const handleSelectEmployee = (id: any) => {
    if (selectedEmployeeList.includes(id)) {
      let interArr = selectedEmployeeList;
      interArr = interArr.filter((em: any) => em !== id);
      setSelectedEmployeeList(() => interArr);
    } else {
      setSelectedEmployeeList(() => [...selectedEmployeeList, id]);
    }
  };

  const handleEmployeeSelectNone = () => {
    setSelectedEmployeeList(() => []);
  };

  const generatePdf = async (
    query: string,
    combinePayslip: boolean = false
  ) => {
    const pattern = /date_from=(\d{4}-\d{2}-\d{2})&date_to=(\d{4}-\d{2}-\d{2})/;
    const match = pattern.exec(query) as string[];
    const dateFrom = match[1];
    const dateTo = match[2];
    const zip = new JSZip();

    const data = payslipData.filter((payslip: any) => selectedEmployeeList.includes(payslip.employee.id))

    if (combinePayslip) {
      const pdfBlob = await ReactPDF.pdf(
        <PayslipExportPdf
          documents={data}
          query={`${Utils._dateToShow(dateFrom)} to ${Utils._dateToShow(
            dateTo
          )}`}
        />
      ).toBlob();
      const url = URL.createObjectURL(pdfBlob as any);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${
        user.defaultCompany.split(" ")[0]
      }_payslip_export_report.pdf`; // Set the desired filename for the downloaded file
      document.body.appendChild(a);
      a.click();
    } else {
      for (let i = 0; i < data.length; i++) {
        const dataToSend = [data[i]];
        const pdfContent = await ReactPDF.pdf(
          <PayslipExportPdf
            documents={dataToSend}
            query={`${Utils._dateToShow(dateFrom)} to ${Utils._dateToShow(
              dateTo
            )}`}
          />
        ).toBlob();
        zip.file(
          `document_${data[i].employee.user.name.split(" ")[0]}.pdf`,
          pdfContent
        );
      }
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const url = URL.createObjectURL(zipBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${user.defaultCompany.split(" ")[0]}_payslip_export.zip`;

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document body
      document.body.removeChild(link);
    }
  };

  const getPayslipExportData = async () => {
    const searchQuery = `company=${user.defaultCompnayId}&date_from=${fromDate}&date_to=${toDate}`;
    setIsLoading(() => true)

    try {
      const { status, data } = await ReportService._payslipExportData(
        searchQuery
      );

      if (status === 200) {
        setEmployeeList(() => data.data.employee_list);
        setPayslipData(() => data.data.payslips)
      }
    } catch (err) {
      console.log(err);
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false)
  };

  useEffect(() => {
    if (Object.keys(employeeList).length > 0) {
      if (statusValue === "0") {
        setFilteredEmployeeList(() => [
          ...employeeList.active,
          ...employeeList.inactive,
        ]);
        setSelectedEmployeeList(() => [
          ...employeeList.active?.map((emp: any) => emp.id),
          ...employeeList.inactive?.map((emp: any) => emp.id),
        ]);
      } else if (statusValue === "1") {
        setFilteredEmployeeList(() => [...employeeList.active]);
        setSelectedEmployeeList(() =>
          employeeList.active?.map((emp: any) => emp.id)
        );
      } else {
        setFilteredEmployeeList(() => [...employeeList.inactive]);
        setSelectedEmployeeList(() =>
          employeeList.inactive?.map((emp: any) => emp.id)
        );
      }
    }
  }, [statusValue, employeeList]);

  useEffect(() => {
    getPayslipExportData();
  }, [fromDate, toDate]);

  return isLoading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <>
      <ReportFilterContainer>
        <ReportHeadingContainer>
          <ReportHeading>{pathToHeading[pathname]}</ReportHeading>
        </ReportHeadingContainer>
        <ReportFilterBody>
          <div>
            <ReportSectionHeading>Filters</ReportSectionHeading>
            <div style={{ display: "flex", gap: 50, marginTop: 20 }}>
              <div style={{ display: "flex" }}>
                <Container>
                  <Text>Current Status </Text>
                  <Select
                    value={statusValue}
                    onChange={(e) => setStatusValue(e.target.value)}
                  >
                    <option value={"0"}>{Constant.common.all}</option>
                    <option value={"2"}>{Constant.common.inactive}</option>
                    <option value={"1"}>{Constant.common.active}</option>
                  </Select>
                </Container>
              </div>
              <div style={{ display: "flex" }}>
                <Container style={{ marginBottom: 20 }}>
                  <Text>Employees </Text>
                  <Information onClick={() => setShowEmployeeModal(() => true)}>
                    {selectedEmployeeList.length === 0
                      ? "None"
                      : selectedEmployeeList.length ===
                        employeeList.active.length +
                          employeeList.inactive.length
                      ? "All"
                      : `${selectedEmployeeList.length} of ${
                          employeeList.active.length +
                          employeeList.inactive.length
                        }`}
                  </Information>
                </Container>
              </div>
            </div>
          </div>
          <ReportHorizontalSeparator style={{ marginTop: 6 }} />
          <div>
            <ReportSectionHeading>Date Range</ReportSectionHeading>
            <div style={{ display: "flex", width: "50%" }}>
              <CustomDateField
                label="From"
                date={fromDate}
                setDate={(date: any) => setFromDate(() => date)}
                labelWidth="40px"
              />
              <CustomDateField
                label="To"
                date={toDate}
                setDate={(date: any) => setToDate(() => date)}
                labelWidth="20px"
              />
            </div>
          </div>
          <ReportHorizontalSeparator />
          <div>
            <ReportSectionHeading>Employees</ReportSectionHeading>
            <AllEmployeesHeading>
              {selectedEmployeeList.length === 0
                ? "No "
                : selectedEmployeeList.length === employeeList.active.length + employeeList.inactive.length
                ? `All (${selectedEmployeeList.length}) `
                : `${selectedEmployeeList.length} of ${employeeList.active.length + employeeList.inactive.length} `}
              employee(s) selected
            </AllEmployeesHeading>
          </div>
          <ReportHorizontalSeparator />
          {pathname === "/dashboard/reports/payslip_export" && (
            <>
              <div>
                <ReportSectionHeading>
                  Combine payslips into a single PDF file
                </ReportSectionHeading>
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    alignItems: "center",
                    gap: 20,
                  }}
                >
                  <input
                    type="checkbox"
                    checked={combinePayslip}
                    onChange={() => setCombinePayslip(() => !combinePayslip)}
                  />
                  <p>Combine payslips into a single PDF file</p>
                </div>
              </div>
              <ReportHorizontalSeparator />
            </>
          )}
          <div style={{ display: "flex", gap: 20 }}>
            {pathname !== "/dashboard/reports/payslip_export" && (
              <CustomButton
                width="150px"
                title="Show Excel"
                onClick={() => {
                  if (selectedEmployeeList) {
                    generatePdf(
                      `company=${user.defaultCompnayId}&emp_ids=[${selectedEmployeeList}]&date_from=${fromDate}&date_to=${toDate}&action_type=${statusValue}`
                    );
                  } else {
                    Notify("Please Select atleast one employee", 0);
                  }
                }}
              />
            )}

            {pathname === "/dashboard/reports/payslip_export" && (
              <CustomButton
                width="150px"
                title="Download Zip"
                onClick={() => {
                  if (selectedEmployeeList) {
                    generatePdf(
                      `company=${user.defaultCompnayId}&emp_ids=[${selectedEmployeeList}]&date_from=${fromDate}&date_to=${toDate}&action_type=${statusValue}`,
                      combinePayslip
                    );
                  } else {
                    Notify("Please Select atleast one employee", 0);
                  }
                }}
              />
            )}
            {/* {pathname !== "/dashboard/reports/leave_expiry" && (
              <CustomButton
                width="150px"
                title="Show Pdf"
                onClick={() =>
                  downloadFunc(
                    `${user.defaultCompnayId}&from_date=${fromDate}&to_date=${toDate}&file_type=pdf&leave_types=[${leaveType}]`,
                    "pdf"
                  )
                }
              />
            )} */}
          </div>
        </ReportFilterBody>
      </ReportFilterContainer>
      <CommonModal
        handleClose={() => setShowEmployeeModal(false)}
        open={showEmployeeModal}
        updateData={() => setShowEmployeeModal(false)}
        body={
          <EmployeeListModal
            employeeList={filteredEmployeeList}
            handleSelectAll={handleSelectAll}
            handleSelectNone={handleEmployeeSelectNone}
            selectedEmployeeList={selectedEmployeeList}
            handleSelectEmployee={handleSelectEmployee}
          />
        }
        heading="Filter Employees"
        conformButtonText="Close"
        isCancelButton={false}
      />
    </>
  );
};

export default PayslipExportExtraComp;

const Container = styled.div`
  margin-bottom: 1rem;
  display: flex;
`;
const Text = styled.p`
  width: 110px;
  height: 30px;
  color: #fff;
  border: 1px solid #afb5ba;
  font-size: 0.8rem;
  background-color: #afb5ba;
  text-transform: none;
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-radius: 3px 0 0 3px;
`;
const Select = styled.select`
  max-width: 100px;
  height: 30px;
  background-color: #087ca7;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  padding: 0 5px;
  text-align: center;
`;

const Information = styled.div`
  max-width: 100px;
  height: 30px;
  background-color: #087ca7;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  padding: 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
`;
