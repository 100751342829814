import { useAppSelector } from "../../../_app";
import { PayrollService } from "../../../_service";
import { CommonModal, CustomTextField, Loader } from "../../../components";
import { CustomButton } from "../../../components/customButton";
import { useEffect, useState } from "react";
import { ServicePeriodInterface } from "../../../_types/AddEmployeeInterface";
import moment from "moment";
import { Notify } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { LoaderContainer } from "../../../CommonStyles";

const Probation = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [servicePeriodInfo, setServicePeriodInfo] =
    useState<ServicePeriodInterface>({
      company: "",
      employee: "",
      is_probation_confirmed: false,
      probation_confirmed_by: "",
      probation_confirmed_date: "",
      probation_last_date: ``,
      probation_months: "",
      service_end_date: "",
      service_start_date: "",
    });

  const employeeId = useAppSelector((state) => state.employee.employee_info.id);

  const getServicePeriodInfo = async () => {
    try {
      setLoading(true);
      let res:any = await  PayrollService._getServicePeriod(employeeId);
      
      if (res.status >= 200 && res.status < 300) {
        setServicePeriodInfo({ ...res?.data?.data });
      }

      setLoading(false);

    } catch (error: any) {
      Notify('Something Went Wrong', 0);
      setLoading(false);
    }
  };

  console.log("state after storing data", servicePeriodInfo);
  useEffect(() => {
    getServicePeriodInfo();
  }, [JSON.stringify(servicePeriodInfo)]);

  const checkDate = () => {
    setOpen(false);
    if(servicePeriodInfo?.probation_last_date) {
      const dateToCheck = moment(servicePeriodInfo?.probation_last_date);
      const currentDate = moment();
      const isEqualOrAfter = currentDate.isSameOrAfter(dateToCheck);

      console.log('isEqual', {dateToCheck, currentDate, isEqualOrAfter});
      if(isEqualOrAfter) {
        handleProbationConfirmation();
      } else {
        setShowMessage(true);
      }
    }
  }

  const handleProbationConfirmation = async () => {
    setServicePeriodInfo((prevState) => ({
      ...prevState,
      is_probation_confirmed: !prevState.is_probation_confirmed,
    }));

    try {
      let res = await PayrollService._editServicePeriod(servicePeriodInfo?.id, {
        is_probation_confirmed: true,
      });

      if (res?.data?.data?.is_probation_confirmed) {
        Notify("Probation Confirmed Successfully!", 1);
        setServicePeriodInfo({
          ...servicePeriodInfo,
          is_probation_confirmed: true,
          probation_confirmed_date: res?.data?.data?.probation_confirmed_date,
        });
      }
      setOpen(false);
      setShowMessage(false);
    } catch (e:any) {
      Notify("Something went wrong", 0);
      setOpen(false);
    }
  };
  console.log(servicePeriodInfo);

  if(loading) {
    return <LoaderContainer>
    <Loader />
  </LoaderContainer>
  }

  console.log('showMessage', showMessage)
  return (
    <div style={{ marginTop: "2rem", width: "100%" }}>
      {servicePeriodInfo?.probation_last_date ? (
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              marginBottom: "1rem",
              fontSize: "1.2rem",
              color: "rgb(71, 101, 117)",
            }}
          >
            <p
              style={{
                fontWeight: 700,
              }}
            >
              {"Probation Months: "}
            </p>
            <p
              style={{
                fontSize: "1.2rem",
                color: "#000",
                fontWeight: 500,
              }}
            >
              {servicePeriodInfo?.probation_months || ""}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              fontSize: "1.2rem",
              color: "rgb(71, 101, 117)",
            }}
          >
            <p
              style={{
                fontWeight: 700,
              }}
            >
              {"Last Date of Probation: "}
            </p>
            <p
              style={{
                fontSize: "1.2rem",
                color: "#000",
                fontWeight: 500,
              }}
            >
              {(servicePeriodInfo?.probation_last_date &&
                moment(
                  servicePeriodInfo?.probation_last_date?.split("T")?.[0]
                ).format("DD-MM-YYYY")) ||
                ""}
            </p>
          </div>

          {/* <CustomTextField
          title="Last Date of Probation: "
          value={
            (servicePeriodInfo?.probation_last_date &&
              moment(
                servicePeriodInfo?.probation_last_date?.split("T")?.[0]
              ).format("DD-MM-YYYY")) ||
            ""
          }
          disabled
          require={false}
        /> */}

          {servicePeriodInfo?.probation_last_date &&
            !servicePeriodInfo?.is_probation_confirmed && (
              <div
                style={{
                  marginTop: "3rem",
                }}
              >
                <CustomButton
                  title="Confirm"
                  disable={
                    servicePeriodInfo?.is_probation_confirmed == true ||
                    !servicePeriodInfo?.probation_last_date
                  }
                  onClick={() =>setOpen(true)}
                  styles={{margin: 0}}
                />
              </div>
            )}

          {servicePeriodInfo?.is_probation_confirmed &&
            servicePeriodInfo?.probation_confirmed_date && (
              <p
                style={{
                  fontSize: "1.2rem",
                  color: "#03b381",
                  fontWeight: 500,
                  marginTop: "3rem",
                }}
              >{`Probation confirmed on ${moment(
                servicePeriodInfo?.probation_confirmed_date?.split("T")?.[0]
              ).format("DD-MM-YYYY")}`}</p>
            )}

      <CommonModal
        handleClose={() => setOpen(false)}
        open={open}
        // loading={loading}
        // updateData={()=>handleProbationConfirmation()}
        updateData={()=>checkDate()}
        body={`Are you sure you want to confirm the probation date?`}
        heading="Confirm Probation"
        conformButtonText="Yes"
        cancelButtonText="No"
        onCancel={()=>setOpen(false)}  //called on confirm

      />

      

      {/* Show message if the date can be confirmed or not */}
      {showMessage && <div>
        <p
            style={{
              color: "red",
              fontWeight: 600,
              fontSize: "1.25rem",
              margin: '1.5rem 0'
            }}
          >{`Probation can only be confirmed on or after ${moment(servicePeriodInfo?.probation_last_date).format('DD MMMM YYYY')}.`}</p>
        
        </div>}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5rem",
            flexDirection: "column",
            gap: "0.75rem",
          }}
        >
          <p
            style={{
              color: "rgb(33, 37, 41)",
              fontWeight: 600,
              fontSize: "1.25rem",
            }}
          >{`Probation period is NOT updated for this employee yet.`}</p>
          <p
            style={{
              color: "rgb(33, 37, 41)",
              fontWeight: 600,
              fontSize: "1.25rem",
              marginBottom: '1rem'
            }}
          >{`Please click the button below to be redirected to the Edit Info Page.`}</p>

          <CustomButton
            title="Edit Basic Info"
            onClick={() => navigate("/dashboard/payroll/basic-info")}
          />
        </div>
      )}
    </div>
  );
};

export default Probation;
