import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../_app";
import { openRoute, routeMap, routeMapWrite } from "../utils/routes";
import { Notify } from "../utils";

const ProtectedRoute = (props: any) => {
  const { children } = props;
  let { pathname } = useLocation();

  if (pathname.includes("RegularInputItemsAddField")) {
    pathname = "/dashboard/RegularInputItemsAddField";
  } else if (pathname.includes("EmployeePayslipAddField")) {
    pathname = "/dashboard/EmployeePayslipAddField";
  }
  const user = useAppSelector((state) => state.user);

  const routeValue = routeMap[pathname];
  const writeRouteValue = routeMapWrite[pathname];
  const isOpenRoute = openRoute.includes(pathname);

  let isAccessible = user?.permission !== "none" || pathname === "/dashboard/user-profile";

  if (isAccessible) {
    if (user?.permission === "read") {
      isAccessible =
        isOpenRoute ||
        user?.menu?.includes(routeValue) ||
        pathname === "/dashboard/user-profile" ||
        pathname === "/";
    } else if (user?.permission === "full") {
      isAccessible =
        isOpenRoute ||
        user?.menu?.includes(routeValue) ||
        user?.menu?.includes(writeRouteValue) ||
        pathname === "/dashboard/user-profile" ||
        pathname === "/";
    }
  }

  if (!isAccessible && user?.email !== "") {
    Notify(
      "You do not have access to perform this operation. Contact your manager for access. You are redirected to User profile page for now",
      0
    );
  }

  return isAccessible ? children : <Navigate to="/dashboard/user-profile" />;
};

export default ProtectedRoute;
