import axios from "axios";

const API = axios.create({
  baseURL: "https://salarypay-sg.s3.ap-southeast-1.amazonaws.com/",
});

const END_POINT = {
  LEAVE_LIST_START: "holidays-sg/api/",
  LEAVE_LIST_END: "/data.json",
};

const _getCalenderLeaveList = async (date: string) => {
  return API.get(END_POINT.LEAVE_LIST_START + date + END_POINT.LEAVE_LIST_END);
};

export const S3Service = {
  _getCalenderLeaveList,
};
