import { SuccessButtonContainer } from "./StyledButton";

interface SuccessButtonProps {
  onClick: () => void;
  title: string;
}
const SuccessButton = (props: SuccessButtonProps) => {
  const { onClick, title } = props;

  return (
    <SuccessButtonContainer onClick={onClick}>{title}</SuccessButtonContainer>
  );
};

export default SuccessButton;
