import React from "react";
import styled from "styled-components";

const upperCaseAlp = [
  "All",
  // "0",
  // "1",
  // "2",
  // "3",
  // "4",
  // "5",
  // "6",
  // "7",
  // "8",
  // "9",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
interface FilterByCharPros {
  value: string;
  onChange: (val: string) => void;
}
const FilterByChar = (props: FilterByCharPros) => {
  return (
    <Container>
      {upperCaseAlp.map((item) => (
        <Button
          selected={item == props.value}
          onClick={() => props.onChange(item)}
          key={item}
        >
          {item}
        </Button>
      ))}
    </Container>
  );
};

export default FilterByChar;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
`;
const Button = styled.button<{ selected: boolean }>`
  display: inline-block;
  padding: 1px 3px;
  margin: 0.1rem 0.1rem;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: ${(props) => (props.selected ? "#059bbf" : "#fff")};
`;
