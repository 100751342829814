import moment from "moment";
import {
  LineChart,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";

const tempData = [
  {
    name: "Class A",
    posts: 40,
    pv: 24,
    amt: 24,
  },
  {
    name: "Class B",
    posts: 30,
    pv: 18,
    amt: 20,
  },
  {
    name: "Class C",
    posts: 20,
    pv: 90,
    amt: 90,
  },
  {
    name: "Class D",
    posts: 20,
    pv: 38,
    amt: 20,
  },
  {
    name: "Class E",
    posts: 18,
    pv: 40,
    amt: 21,
  },
  {
    name: "Class F",
    posts: 20,
    pv: 30,
    amt: 20,
  },
  {
    name: "Class G",
    posts: 30,
    pv: 40,
    amt: 20,
  },
];

const LineChartComponent = ({ data }: any) => {
  const lineChartData = data?.map((item: any) => {

    return {
      "Payslip Date": moment(item?.payslip_date, "YYYY-MM-DD").format('DD-MM-YYYY'),
      "Total Deductions":parseFloat(item?.total_deduction) ,
      "Total Income": parseFloat(item?.total_income),
    };
  });

  console.log("lineChart", lineChartData)

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={lineChartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey= "Payslip Date" />
        {/* <XAxis dataKey= "name" /> */}
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="Total Income" stroke="#0b16ef" />
        <Line type="monotone" dataKey="Total Deductions" stroke="#ed4d50" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
