import { Utils } from "../../utils";
import { CommentBox } from "./ApprovalStyles";

const ApprovalComment = (props: any) => {
  const { approvalComment, handleCommentChange, data } = props;

  const fromDate = new Date(data.date_from);
  const currentDate = new Date(Utils._date());

  return (
    <div>
      <h4>Request for leave approval by {data?.employee_name || ""}</h4>
      <div>
        <p style={{ color: "black" }}>
          <strong>Employee Name: </strong>
          {data?.employee_name}
        </p>
        <p style={{ color: "black" }}>
          <strong>Leave Type: </strong>
          {data?.leave_name}
        </p>
      </div>
      <div style={{ margin: "10px 0", color: "black" }}>
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            maxHeight: 100,
            overflow: "auto",
            display: "block",
          }}
        >
          <thead>
            <th style={{ textAlign: "left", width: 150 }}>Date</th>
            <th style={{ textAlign: "left" }}>Partial/Full</th>
          </thead>
          <tbody>
            {data.date_json.map((dateData: any) => (
              <tr>
                <td>{Utils._dateToShow(dateData.date)}</td>
                <td>{dateData.is_partial ? "Partial" : "Full"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {currentDate <= fromDate && (
        <CommentBox
          placeholder="Leave a Comment (optional)"
          value={approvalComment}
          onChange={(e) => handleCommentChange(e.target.value)}
        />
      )}
    </div>
  );
};

export default ApprovalComment;
