import { useState } from "react";
import { SearchField } from "../../dashboard/component";
import { MultiSelectButton } from "../ReportFilterPageStyles";

const EmployeeListModal = (props: any) => {
  const {
    employeeList,
    handleSelectAll,
    handleSelectNone,
    selectedEmployeeList,
    handleSelectEmployee,
  } = props;
  const [searchText, setSearchText] = useState<string>("");
  return (
    <div style={{ width: "600px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", gap: 20, marginBottom: 20 }}>
          <MultiSelectButton onClick={() => handleSelectAll()}>
            All
          </MultiSelectButton>
          <MultiSelectButton onClick={() => handleSelectNone()}>
            None
          </MultiSelectButton>
        </div>
        <SearchField value={searchText} onChangeText={setSearchText} />
      </div>
      <div style={{ height: "300px", overflowY: "auto" }}>
        {employeeList
          .filter(
            (em: any) =>
              em?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
              em?.user?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
          )
          .map((employee: any) => (
            <div style={{ display: "flex", gap: 20 }}>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <input
                  type="checkbox"
                  onChange={() =>
                    handleSelectEmployee(employee?.id || employee?.user.id)
                  }
                  checked={selectedEmployeeList?.includes(
                    employee?.id || employee?.user?.id
                  )}
                />
                <p style={{ fontSize: 18 }}>
                  {employee?.name || employee?.user?.name}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default EmployeeListModal;
