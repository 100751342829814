import styled from "styled-components";

const ChipButton = styled.button<any>`
  background: ${(props) => (props.isSelected ? "#059bbf" : "#fff")};
  color: ${(props) => (props.isSelected ? "#fff" : "#000")};
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #ced4da;
  border-color: ${(props) => (props.isSelected ? "#059bbf" : "#ced4da")};
`;

export { ChipButton };
