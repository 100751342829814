import React, { useEffect, useState } from "react";
import { PayrollService } from "../../_service";
import { useAppDispatch } from "../../_app";
import { resetUser } from "../../_features";
import { Utils } from "../../utils";
import styled from "@emotion/styled";

interface PayslipLogProps {
  pci_id: number;
  attr_name: string;
  attr_val: string;
  payslip_date: string;
  updated_date: string;
  serial_no: string;
  first_name: string;
  last_name: string;
  company_name: string;
  name_format: string;
  archive: boolean;
  employee: number;
  pc_name: string;
}

const PayslipLog = () => {
  const dispatch = useAppDispatch();

  const [logs, setLogs] = useState<PayslipLogProps[]>([]);

  const get = async () => {
    try {
      const response = await PayrollService._getPayslipLog();
      if (response.status === 200) {
        setLogs(() => response.data.records);
      }
    } catch (e: any) {
      if (e.response.status === 401) {
        // disaptch(resetUser());
      }
    }
  };

  useEffect(() => {
    get();
  }, []);
  return (
    <div>
      <div className="company-header-wrap">
        <h2>Payslip Input</h2>
      </div>
      <div className="table table-log">
        <table className="company-table company-table-log">
          <thead className="company-table-head company-table-head-log">
            <tr>
              <th>Serial No</th>
              <th>Company Name</th>
              <th>Employee Name(ID)</th>
              <th>Payslip Calculation Name</th>
              <th>Amount</th>
              {/* <th>Fixed Amount </th> */}
              {/* <th>override hourly rate</th> */}
              {/* <th>Override Rate </th> */}
              {/* <th>status </th> */}
              <th>updated date </th>
            </tr>
          </thead>
          <tbody>
            {logs?.map((item) => {
              return (
                <tr key={item.serial_no}>
                  <td>{item.serial_no}</td>
                  <Td>{item.company_name}</Td>
                  <Td>
                    {Utils.getEmployeeName(
                      item.name_format,
                      item.first_name,
                      item.last_name,
                    )}{" "}
                    ({item.employee})
                  </Td>
                  <td>{item.pc_name}</td>
                  <td>{item.attr_val}</td>
                  <td>{Utils.getFormatedDate(item.updated_date)}</td>
                  {/* <td>
                    <button
                      disabled={loading}
                      className="company-table-make-default-btn"
                      onClick={() => handleMakeDefaultCompany(item.id)}
                    >
                      Make Default
                    </button>
                  </td>
                  <td>
                    <button
                      disabled={loading}
                      className="company-table-make-default-btn"
                      onClick={() => handleEditCompany(item)}
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                    <button
                      disabled={loading}
                      className="company-table-delete-btn"
                      onClick={() => handleDeleteComapany(item)}
                    >
                      Archive
                    </button>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PayslipLog;

const Td = styled.td`
  max-width: 350px;
  word-wrap: break-word;
`;
