import React from "react";
import styled from "styled-components";
import { Container, Error } from "./Styles";

interface CkeckboxFieldProps {
  title: string;
  value?: boolean;
  width?: string;
  onChange: () => void;
  note?: string;
  disabled?: boolean;
  error?: string;
}
const CkeckboxField = ({
  onChange,
  title,
  value,
  note = "",
  width = "100%",
  disabled = false,
  error,
}: CkeckboxFieldProps) => {
  return (
    <Container width={width} margin=".2rem">
      <Input
        type="checkbox"
        checked={value}
        onChange={onChange}
        disabled={disabled}
      />
      {(title || error || note) && (
        <div style={{ width: "80%", display: "flex", alignItems: "center" }}>
          {title && <Label style={{ paddingBottom: 0}}>{title}</Label>}
          {error ? (
            <Error>{error || ""}</Error>
          ) : (
            <Error color="grey">{note}</Error>
          )}
        </div>
      )}
    </Container>
  );
};

export default CkeckboxField;

const Label = styled.label``;
const Input = styled.input`
  width: 1rem;
  height: 1rem;
  max-width: 250px;
  margin-right: 0.5rem;
  cursor: pointer;
`;
