import styled from "styled-components";

export const ReportFilterContainer = styled.div`
  border: 1px solid #00000020;
  border-radius: 5px 5px 0 0;
  width: 95%;
  /* margin: auto; */
`;
export const ReportHeadingContainer = styled.div`
  padding: 12px 20px;
  background-color: #4a5e71;
  color: white;
  border-radius: 5px 5px 0 0;
`;
export const ReportHeading = styled.h2`
  font-weight: 500;
  font-size: 16px;
`;
export const ReportFilterBody = styled.div`
  padding: 20px;
`;
export const ReportFilterBodyHeading = styled.h3`
  font-weight: 500;
  font-size: 28px;
  color: #476575;
`;
export const ReportHorizontalSeparator = styled.div`
  margin: 1rem 0;
  background-color: #0000001a;
  height: 1px;
`;
export const AllEmployeesHeading = styled.h3`
  margin-top: 20px;
  color: #333;
  font-size: 16px;
`;

export const MultiSelectButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: 10px 0;
`;
export const MultiSelectButton = styled.button`
  cursor: pointer;
  color: #0000ee;
  border: none;
  background: none;
  font-size: 20px;
`;
export const MultiSelectButtonSmall = styled.button`
  cursor: pointer;
  color: #0000ee;
  border: none;
  background: none;
  font-size: 16px;
`;
export const TransactionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1fr);
  gap: 10px;
`;
export const ReportSectionHeading = styled.h3`
  font-size: 1.75rem;
  color: #476575;
  font-weight: 500;
  text-transform: capitalize;
`;
