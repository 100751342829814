import React from "react";
import { AuthHeader } from "../../components/header";
import styled from "styled-components";

const SIgnin = () => {
  return (
    <div>
      <AuthHeader />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ marginTop: "2rem", color: "#25b481", fontSize: "25px" }}>
          SIGN UP
        </h1>
        <p style={{ marginTop: "1rem", fontSize: "1rem" }}>
          <b>Register here</b> for your free <b>30 day trial</b> of the
          <b> SimplePay online payroll system.</b>
        </p>
        <p
          style={{ marginTop: "2rem", marginBottom: "2rem", fontSize: "1rem" }}
        >
          You will be able to <b>enter your company name(s)</b> once you have{" "}
          <b>activated your account.</b>
        </p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InputContainer>
              <label>First name*: </label>
              <input />
            </InputContainer>
            <InputContainer>
              <label>Last name*: </label>
              <input />
            </InputContainer>
            <InputContainer>
              <label>Email*: </label>
              <input />
            </InputContainer>
            <InputContainer>
              <label>Phone:</label>
              <input />
            </InputContainer>
            <InputContainer>
              <label>Password (At least 8 characters long)*: </label>
              <input />
            </InputContainer>
          </div>
          <div>
            <InputContainer>
              <label>Confirm password*: </label>
              <input />
            </InputContainer>
            <p>I agree to SimplePay's Terms of Service and Privacy Policy</p>
            <p>Terms must be accepted before you can sign up</p>
            <p>*Required fields</p>
            <button>Register</button>
            <p>
              You will receive an activation e-mail shortly after registering.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SIgnin;
const InputContainer = styled.div`
  /* border: 1px solid red; */
  label {
    color: #333;
    margin-bottom: 5px;
    display: block;
    font-size: 1rem;
  }
  input {
    display: block;
    min-width: 500px;
    height: 50px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    /* border-radius: 4px; */
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
    margin-bottom: 1.2rem;
  }
`;
