import { AiFillDelete } from "react-icons/ai";
import { CustomButton, Tooltip } from "../../../components";
import { FaFileImport } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const ImportFileModal = (props: any) => {
  const { handleChange, file, fileInputRef, deleteFile, exportFile } = props;

  return (
    <div>
      {file && (
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: 20,
            marginBottom: 20,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{file.name}</span>
          <Tooltip text="Delete" del>
            <CustomButton
              type={"secondary"}
              size="small"
              del
              width="30px"
              title={""}
              onClick={() => deleteFile()}
              icon={<AiFillDelete />}
            />
          </Tooltip>
        </div>
      )}
      <div
        style={{
          display: "flex",
          gap: 20,
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <input
          type="file"
          accept=".csv"
          name="manual-file"
          onChange={() => handleChange()}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <CustomButton
          title={file ? "Change" : "Import"}
          onClick={() => fileInputRef.current.click()}
          type="secondary"
          icon={<FaFileImport />}
        />
        {/* <Tooltip text={file ? "Change" : "Import"}>
          <CustomButton
            type={"secondary"}
            size="small"
            width="30px"
            title={""}
            icon={<FaUpload />}
          />
        </Tooltip> */}
        <NavLink
          to=""
          onClick={(e) => {
            e.preventDefault();
            exportFile();
          }}
          style={{ color: "#007bff" }}
        >
          Click here to download the blank template for you to enter the data.
        </NavLink>
        {/* <p style={{ color: "black" }}>
          <a href="https://salarypay-sg.s3.ap-southeast-1.amazonaws.com/manual+payslip/sample_manual_payslip.csv">
            Click here
          </a>{" "}
          to download an example with sample data
        </p> */}
        {/* <CustomButton
          type="secondary"
          success
          title="Download Sample File"
          onClick={() => exportFile()}
        /> */}
      </div>
    </div>
  );
};

export default ImportFileModal;
