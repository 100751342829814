import styled from "styled-components";
import { DEVICE } from "../../GlobalStyles";

const Container = styled.div`
  margin-bottom: 20px;
  transition: 0.3s all;
`;
const Label = styled.label`
  display: block;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  transition: 0.3s all;
  color: #212529;
  font-weight: bolder;
  @media ${DEVICE.mobileL} {
    font-size: 14px;
    padding-bottom: 5px;
    transition: 0.3s all;
  }
  @media ${DEVICE.tablet} {
    font-size: 16px;
    padding-bottom: 7px;
    transition: 0.3s all;
  }
`;
const Required = styled.span`
  color: ${({ theme }) => theme.RED};
  transition: 0.3s all;
`;
const Input = styled.input`
  width: 100%;
  height: 45px;
  background: ${({ theme }) => theme.WHITE};
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  padding: 8px 12px;
  transition: 0.3s all;
  &:focus {
    border: 1px solid ${({ theme }) => theme.VOLLET};
    outline: none;
    box-shadow: 0px 0px 4px 2px #87ceeb;
  }
  @media ${DEVICE.mobileL} {
    height: 30px;
    transition: 0.3s all;
  }
  @media ${DEVICE.tablet} {
    width: 100%;
    height: 40px;
    transition: 0.3s all;
  }
`;

export { Container, Label, Required, Input };
