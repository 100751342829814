import styled from "styled-components";

export const NewRequestConainter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const ChangeDetailContainer = styled.div`
  width: 97%;
  margin: 10px auto;
  background-color: #fffacd;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
`;

export const ChangeDetailHeadingContainer = styled.div`
  padding: 10px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`;

export const ChangeDetailInfoContainer = styled.div`
  padding: 16px;
`;
