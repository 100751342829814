import { Typography, Box, Paper, Divider, styled } from "@mui/material";

import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import { FaFilePdf, FaRegFileExcel } from "react-icons/fa";
import { IconContext } from "react-icons";

import { Utils } from "../../utils";
import PayrunDocument from "../pdfs/PayrunDocuments";
import PaymentDocument from "./PaymentDocument";
import PaymentContent from "./PaymentContent";
import { Link } from "react-router-dom";
import { BsBoxArrowRight } from "react-icons/bs";
import { exportExcel } from "./exportExcel";
import { useAppSelector } from "../../_app";
import { PayrunService } from "../../_service";
import AccountingDocument from "./AccountingDocument";
import {saveAs} from "file-saver"

const NumberWrapper = styled(Typography)(() => ({
  padding: "6px 10px",
  background: "#6c757d",
  borderRadius: "2px",
  width: "20px",
  fontSize: "12px !important",
  lineHeight: "10px !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
}));

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "start",
}));

function FinalizedPayrun({ payrun, companyName }: any) {
  const user = useAppSelector((state:any) => state.user);

  console.log("accountingData inside finalized", payrun[0], payrun[1], )

  const companyId = useAppSelector((state:any) => state.user.defaultCompnayId);

  const monthYear = Utils.monthsPassed(
    user.current_payrun_date,
    payrun?.[0]
  ).monthYear;
  const day = payrun?.[0].split("-").pop();

  const payrunDate = `${day} ${monthYear}`;

  // const getAccountingData = async (payslipDate: string) => {
  //   try {
  //     const res = await PayrunService._getAccountingInfo(companyId, payslipDate)
  //     console.log("theRes accounting", res.data)
  //   } catch (e:any) {
  //     console.error(e);
  //   }
  // }

  const generateAccountingPdfDocument = async (data:any, date:any) => {
    const blob = await pdf((
      <AccountingDocument companyName={companyName} data={data} companyId={companyId} payslipDate={date} />
    )).toBlob();
    saveAs(blob, `Accounting Info ${date}`);
  }

  const generatePDF = async (data:any, name:any) => {
    const blob = await pdf((data)).toBlob();
    saveAs(blob, name);
  }

  

  return (
    <Box mt={4}>
      <Typography variant="h6" fontWeight={500}>
        {Utils.monthsPassed(user.current_payrun_date, payrun?.[0]).count
          ? `${
              Utils.monthsPassed(user.current_payrun_date, payrun?.[0]).count
            } Months ago`
          : "Last Month"}{" "}
        ({Utils.monthsPassed(user.current_payrun_date, payrun?.[0]).monthYear})
      </Typography>
      <Box mt={1}>
        <Paper elevation={3}>
          <Box sx={{ background: "#4a5e71", padding: "12px 20px" }}>
            <Typography color="white">
              {Utils._dateToShow(payrun?.[0])} Payment Info
            </Typography>
          </Box>
          <Box p={2}>
            <Divider />
            <table style={{ width: "100%" }}>
              <thead>
                <th style={{ width: "20%", textAlign: "left" }}>
                  <Typography
                    sx={{ flexGrow: 1 }}
                    variant="subtitle1"
                    fontWeight={700}
                    py={0.5}
                  >
                    Payslips
                  </Typography>
                </th>
                <th style={{ width: "20%", textAlign: "left" }}>
                  <Typography
                    sx={{ flexGrow: 1 }}
                    variant="subtitle1"
                    fontWeight={700}
                    py={0.5}
                  >
                    Pay Date
                  </Typography>
                </th>
                {(payrun?.[1]?.GIRO?.length || 0) +
                  (payrun?.[1]?.Cash?.length || 0) +
                  (payrun?.[1]?.Cheque?.length || 0) >
                  0 && (
                  <th style={{ width: "20%", textAlign: "left" }}>
                    <Typography
                      sx={{ flexGrow: 1 }}
                      variant="subtitle1"
                      fontWeight={700}
                    >
                      ALL
                    </Typography>
                  </th>
                )}
                {payrun?.[1]?.GIRO?.length > 0 && (
                  <th style={{ width: "20%", textAlign: "left" }}>
                    <Typography
                      sx={{ flexGrow: 1 }}
                      variant="subtitle1"
                      fontWeight={700}
                    >
                      GIRO
                    </Typography>
                  </th>
                )}
                {payrun?.[1]?.Cash?.length > 0 && (
                  <th style={{ width: "20%", textAlign: "left" }}>
                    <Typography
                      sx={{ flexGrow: 1 }}
                      variant="subtitle1"
                      fontWeight={700}
                    >
                      Cash
                    </Typography>
                  </th>
                )}
                {payrun?.[1]?.Cheque?.length > 0 && (
                  <th style={{ width: "20%", textAlign: "left" }}>
                    <Typography
                      sx={{ flexGrow: 1 }}
                      variant="subtitle1"
                      fontWeight={700}
                    >
                      Cheque
                    </Typography>
                  </th>
                )}
                {/* <th style={{ width: "20%", textAlign: "left" }}>
                  <Typography fontWeight={700}>Beneficiaries</Typography>
                </th> */}
              </thead>
              <tbody>
                <tr>
                  <td style={{ paddingTop: 10 }}>
                    {/* <PDFDownloadLink
                      style={{
                        color: "#059bbf",
                        textDecoration: "none",
                      }}
                      document={
                        <PayrunDocument
                          documents={(payrun?.[1]?.GIRO || [])
                            .concat(payrun?.[1]?.Cash || [])
                            .concat(payrun?.[1]?.Cheque || [])}
                          finalized={true}
                          job={true}
                        />
                      }
                      fileName="payslips.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <span style={{ fontWeight: "500" }}>
                            {`${
                              (payrun?.[1]?.Cash?.length ?? 0) +
                                (payrun?.[1]?.GIRO?.length ?? 0) +
                                (payrun?.[1]?.Cheque?.length ?? 0) || 0
                            } Payslip${
                              (payrun?.[1]?.Cash?.length ?? 0) +
                                (payrun?.[1]?.GIRO?.length ?? 0) +
                                (payrun?.[1]?.Cheque?.length ?? 0) >
                              1
                                ? "s"
                                : ""
                            }`}
                          </span>
                        )
                      }
                    </PDFDownloadLink> */}
                    <div
                      onClick={() =>
                        generatePDF(
                          <PayrunDocument
                            documents={(payrun?.[1]?.GIRO || [])
                              .concat(payrun?.[1]?.Cash || [])
                              .concat(payrun?.[1]?.Cheque || [])}
                            finalized={true}
                            job={true}
                          />,
                          "Payslips.pdf"
                        )
                      }
                    >
                      <span
                        style={{
                          fontWeight: "500",
                          color: "#059bbf",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                      >
                        {`${
                          (payrun?.[1]?.Cash?.length ?? 0) +
                            (payrun?.[1]?.GIRO?.length ?? 0) +
                            (payrun?.[1]?.Cheque?.length ?? 0) || 0
                        } Payslip${
                          (payrun?.[1]?.Cash?.length ?? 0) +
                            (payrun?.[1]?.GIRO?.length ?? 0) +
                            (payrun?.[1]?.Cheque?.length ?? 0) >
                          1
                            ? "s"
                            : ""
                        }`}
                      </span>
                    </div>
                  </td>
                  <td style={{ paddingTop: 10 }}>
                    {Utils._dateToShow(payrun?.[0])}{" "}
                  </td>
                  {(payrun?.[1]?.Cash?.length ?? 0) +
                    (payrun?.[1]?.GIRO?.length ?? 0) +
                    (payrun?.[1]?.Cheque?.length ?? 0) >
                    0 && (
                    <td style={{ paddingTop: 10 }}>
                      <FlexBox sx={{ flexGrow: 1 }}>
                        <NumberWrapper>
                          {(payrun?.[1]?.Cash?.length ?? 0) +
                            (payrun?.[1]?.GIRO?.length ?? 0) +
                            (payrun?.[1]?.Cheque?.length ?? 0) || 0}
                        </NumberWrapper>
                        {/* <PDFDownloadLink
                          document={
                            <PaymentDocument
                              GiroDocument={
                                payrun?.[1]?.GIRO?.length ? (
                                  <PaymentContent
                                    type={`GIRO`}
                                    cashes={payrun?.[1]?.GIRO}
                                  />
                                ) : null
                              }
                              CashDocument={
                                payrun?.[1]?.Cash?.length ? (
                                  <PaymentContent
                                    type={`CASH`}
                                    cashes={payrun?.[1]?.Cash}
                                  />
                                ) : null
                              }
                              ChequeDocument={
                                payrun?.[1]?.Cheque?.length ? (
                                  <PaymentContent
                                    type={`CHEQUE`}
                                    cashes={payrun?.[1]?.Cheque}
                                  />
                                ) : null
                              }
                            />
                          }
                          fileName="All.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              "Loading document..."
                            ) : (
                              <IconContext.Provider
                                value={{ color: "#059bbf", size: "24px" }}
                              >
                                <FaFilePdf />
                              </IconContext.Provider>
                            )
                          }
                        </PDFDownloadLink> */}

                        <IconContext.Provider
                          value={{ color: "#059bbf", size: "24px" }}
                        >
                          <FaFilePdf
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              generatePDF(
                                <PaymentDocument
                                  GiroDocument={
                                    payrun?.[1]?.GIRO?.length ? (
                                      <PaymentContent
                                        type={`GIRO`}
                                        cashes={payrun?.[1]?.GIRO}
                                      />
                                    ) : null
                                  }
                                  CashDocument={
                                    payrun?.[1]?.Cash?.length ? (
                                      <PaymentContent
                                        type={`CASH`}
                                        cashes={payrun?.[1]?.Cash}
                                      />
                                    ) : null
                                  }
                                  ChequeDocument={
                                    payrun?.[1]?.Cheque?.length ? (
                                      <PaymentContent
                                        type={`CHEQUE`}
                                        cashes={payrun?.[1]?.Cheque}
                                      />
                                    ) : null
                                  }
                                />,
                                "All.pdf"
                              )
                            }
                          />
                        </IconContext.Provider>

                        <a
                          onClick={() =>
                            exportExcel(
                              [
                                {
                                  paymentMethod: "GIRO",
                                  data: payrun?.[1]?.GIRO,
                                  periodEnding: payrun?.[0],
                                },
                                {
                                  paymentMethod: "Cash",
                                  data: payrun?.[1]?.Cash,
                                  periodEnding: payrun?.[0],
                                },
                                {
                                  paymentMethod: "Cheque",
                                  data: payrun?.[1]?.Cheque,
                                  periodEnding: payrun?.[0],
                                },
                              ],
                              "All"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <IconContext.Provider
                            value={{ color: "#059bbf", size: "24px" }}
                          >
                            <FaRegFileExcel />
                          </IconContext.Provider>
                        </a>
                      </FlexBox>
                    </td>
                  )}
                  {payrun?.[1]?.GIRO?.length > 0 && (
                    <td style={{ paddingTop: 10 }}>
                      <FlexBox sx={{ flexGrow: 1 }}>
                        {payrun?.[1]?.GIRO?.length > 0 && (
                          <NumberWrapper>
                            {payrun?.[1]?.GIRO?.length}
                          </NumberWrapper>
                        )}
                        {/* <PDFDownloadLink
                          document={
                            <PaymentDocument
                              GiroDocument={
                                <PaymentContent
                                  type={`GIRO`}
                                  cashes={payrun?.[1]?.GIRO}
                                />
                              }
                            />
                          }
                          fileName="Giro.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              "Loading document..."
                            ) : (
                              <IconContext.Provider
                                value={{ color: "#059bbf", size: "24px" }}
                              >
                                <FaFilePdf />
                              </IconContext.Provider>
                            )
                          }
                        </PDFDownloadLink> */}
                        <div onClick={()=>generatePDF(<PaymentDocument
                              GiroDocument={
                                <PaymentContent
                                  type={`GIRO`}
                                  cashes={payrun?.[1]?.GIRO}
                                />
                              }
                            />, "Giro.pdf")}>
                          <IconContext.Provider
                                  value={{ color: "#059bbf", size: "24px" }}
                                >
                            <FaFilePdf style={{ cursor: "pointer" }} />
                          </IconContext.Provider>
                        </div>
                        <a
                          onClick={() =>
                            exportExcel(
                              [
                                {
                                  paymentMethod: "GIRO",
                                  data: payrun?.[1]?.GIRO,
                                  periodEnding: payrun?.[0],
                                },
                              ],
                              "GIRO"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <IconContext.Provider
                            value={{ color: "#059bbf", size: "24px" }}
                          >
                            <FaRegFileExcel />
                          </IconContext.Provider>
                        </a>
                        <Link
                          to="/dashboard/giro-export"
                          state={{ payrunDate: payrunDate }}
                          title="Giro Submission"
                        >
                          <IconContext.Provider
                            value={{ color: "#059bbf", size: "24px" }}
                          >
                            <BsBoxArrowRight />
                          </IconContext.Provider>
                        </Link>
                      </FlexBox>
                    </td>
                  )}
                  {payrun?.[1]?.Cash?.length > 0 && (
                    <td style={{ paddingTop: 10 }}>
                      <FlexBox sx={{ flexGrow: 1 }}>
                        <NumberWrapper>
                          {payrun?.[1]?.Cash?.length}
                        </NumberWrapper>
                        {/* <PDFDownloadLink
                          document={
                            <PaymentDocument
                              CashDocument={
                                <PaymentContent
                                  type={`Cash`}
                                  cashes={payrun?.[1]?.Cash}
                                />
                              }
                            />
                          }
                          fileName="Cash.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              "Loading document..."
                            ) : (
                              <IconContext.Provider
                                value={{ color: "#059bbf", size: "24px" }}
                              >
                                <FaFilePdf />
                              </IconContext.Provider>
                            )
                          }
                        </PDFDownloadLink> */}

                      <div onClick={()=>generatePDF(<PaymentDocument
                              CashDocument={
                                <PaymentContent
                                  type={`Cash`}
                                  cashes={payrun?.[1]?.Cash}
                                />
                              }
                            />, "Cash.pdf")}>
                          <IconContext.Provider
                                  value={{ color: "#059bbf", size: "24px" }}
                                >
                            <FaFilePdf style={{ cursor: "pointer" }} />
                          </IconContext.Provider>
                        </div>
                        <a
                          onClick={() =>
                            exportExcel(
                              [
                                {
                                  paymentMethod: "Cash",
                                  data: payrun?.[1]?.Cash,
                                  periodEnding: payrun?.[0],
                                },
                              ],
                              "Cash"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <IconContext.Provider
                            value={{ color: "#059bbf", size: "24px" }}
                          >
                            <FaRegFileExcel />
                          </IconContext.Provider>
                        </a>
                      </FlexBox>
                    </td>
                  )}
                  {payrun?.[1]?.Cheque?.length > 0 && (
                    <td style={{ paddingTop: 10 }}>
                      <FlexBox sx={{ flexGrow: 1 }}>
                        {payrun?.[1]?.Cheque?.length > 0 && (
                          <NumberWrapper>
                            {payrun?.[1]?.Cheque?.length}
                          </NumberWrapper>
                        )}
                        {/* <PDFDownloadLink
                          document={
                            <PaymentDocument
                              GiroDocument={
                                <PaymentContent
                                  type={`Cheque`}
                                  cashes={payrun?.[1]?.Cheque}
                                />
                              }
                            />
                          }
                          fileName="Cheque.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              "Loading document..."
                            ) : (
                              <IconContext.Provider
                                value={{ color: "#059bbf", size: "24px" }}
                              >
                                <FaFilePdf />
                              </IconContext.Provider>
                            )
                          }
                        </PDFDownloadLink> */}
                        <div onClick={()=>generatePDF( <PaymentDocument
                              GiroDocument={
                                <PaymentContent
                                  type={`Cheque`}
                                  cashes={payrun?.[1]?.Cheque}
                                />
                              }
                            />, "Cheque.pdf")}>
                          <IconContext.Provider
                                  value={{ color: "#059bbf", size: "24px" }}
                                >
                            <FaFilePdf style={{ cursor: "pointer" }} />
                          </IconContext.Provider>
                        </div>
                        <a
                          onClick={() =>
                            exportExcel(
                              [
                                {
                                  paymentMethod: "Cheque",
                                  data: payrun?.[1]?.Cheque,
                                  periodEnding: payrun?.[0],
                                },
                              ],
                              "Cheque"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <IconContext.Provider
                            value={{ color: "#059bbf", size: "24px" }}
                          >
                            <FaRegFileExcel />
                          </IconContext.Provider>
                        </a>
                      </FlexBox>
                    </td>
                  )}
                  {/* <td style={{ paddingTop: 10 }}>
                    <PDFDownloadLink
                      document={
                        <BeneficiariesDocument
                          payslips={
                            payrun?.[1]?.Cash?.length ||
                            0 + payrun?.[1]?.GIRO?.length ||
                            0
                          }
                          period={`${
                            payrun?.[1]?.GIRO?.[0]?.payslip_info?.payslip_date
                              ?.start ||
                            payrun?.[1]?.Cash?.[0]?.payslip_info?.payslip_date
                              ?.start
                          } to ${
                            payrun?.[1]?.GIRO?.[0]?.payslip_info?.payslip_date
                              ?.end ||
                            payrun?.[1]?.Cash?.[0]?.payslip_info?.payslip_date
                              ?.end
                          }`}
                        />
                      }
                      fileName="beneficiaries.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <IconContext.Provider
                            value={{ color: "#059bbf", size: "24px" }}
                          >
                            <FaFilePdf />
                          </IconContext.Provider>
                        )
                      }
                    </PDFDownloadLink>
                  </td> */}
                </tr>

                {/* for accounting info */}
                {companyId && (
                  <tr>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: 500,
                          color: "#059bbf",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          generateAccountingPdfDocument(
                            payrun[1]?.accounting_info,
                            payrun[0]
                          )
                        }
                      >
                        <span>Accounting Info: </span>

                        <IconContext.Provider
                          value={{ color: "#059bbf", size: "24px" }}
                        >
                          <FaFilePdf style={{ cursor: "pointer" }} />
                        </IconContext.Provider>
                      </div>
                      {/* <PDFDownloadLink
                      document={<AccountingDocument companyName={companyName} data={payrun[1]?.accounting_info} companyId={companyId} payslipDate={payrun[0]  } />}
                      fileName={`Accounting Info ${payrun[0]}`}
                      style={{
                        color: "#059bbf",
                        textDecoration: "none",
                      }}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: 500,
                            }}
                          >
                            <span>Accounting Info: </span>

                            <IconContext.Provider
                              value={{ color: "#059bbf", size: "24px" }}
                            >
                              <FaFilePdf />
                            </IconContext.Provider>
                          </div>
                        )
                      }
                    </PDFDownloadLink> */}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default FinalizedPayrun;
