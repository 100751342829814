import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

import { Font } from "@react-pdf/renderer";
import NotoSansRegular from "../../fonts/NotoSans-Bold.ttf";
import NotoSansBold from "../../fonts/NotoSans-Regular.ttf";
import { useEffect, useState } from "react";
import { PayrunService } from "../../_service";
import { useAppSelector } from "../../_app";
import { Utils } from "../../utils";

Font.register({
  family: "Noto Sans",
  fonts: [
    {
      src: NotoSansRegular,
      fontWeight: 400,
    },
    {
      src: NotoSansBold,
      fontWeight: 700,
      fontStyle: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    // backgroundColor: "#d11fb6",
    color: "black",
    fontSize: "8px",
    padding: "20px 40px 40px 40px",
  },
  section: {
    margin: 10,
    padding: 10,
  },
});

// Create Document Component
const AccountingDocument = (props: any) => {
 


  const styles = StyleSheet.create({
    page: {
      display: "flex",
    },
    fullPage: {
      color: "black",
      fontSize: "10px",
      padding: "40px",
      display: "flex",
      justifyContent: "space-between",
    },
    section: {
      margin: 10,
      padding: 10,
    },
    header: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      marginBottom: "12px",
    },

    employeeBasicInfo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
    },
    boldText: {
      fontFamily: "Noto Sans",
      fontWeight: 700,
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.fullPage}>
        <View style={styles.page}>
          <View>
            <View style={styles.header}>
              <Text></Text>
              <Text>{props.companyName || ""}</Text>
            </View>

            <View>
              <Text>{`Accounting info for payment run ending ${props.payslipDate || ""}`}</Text>
              <Text>{`Pay Frequency: Monthly, ending on the 31st`}</Text>
              <Text>{`Number of payslips: ${props.data?.no_of_payslips || ""}`}</Text>
            </View>

            <View
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexBasis: "49%",
                  // padding: "8px",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    marginBottom: "5px",
                    // borderBottom: "1px solid #000",
                  }}
                >
                  <Text style={styles.boldText}>Debits</Text>
                </View>

                <View
                  style={{
                    border: "1px solid black",
                  }}
                >
                  {props.data &&
                    Object.entries(props.data?.debits)?.map((item: any, index) => {
                      return (
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            marginBottom: "8px",
                            borderBottom:
                              index === 0 ? "1px solid black" : "none",
                            padding: index === 0 ? "4px" : "0 4px",
                            backgroundColor: index === 0 ? "#dcdcdc" : "#FFF",
                          }}
                        >
                          <Text wrap style={{ width: "60%" }}>
                            {item[0]}
                          </Text>
                          <Text>
                            {Utils.getCommanSepartedNumericStringWithDollar(
                              item[1]
                            )}
                          </Text>
                        </View>
                      );
                    })}
                </View>

                <Text style={{ marginTop: 10 }}></Text>

                <View
                  style={{
                    border: "1px solid black",
                  }}
                >
                  {props.data &&
                    Object.entries(props.data?.other_expenses)?.map(
                      (item: any, index) => {
                        return (
                          <View
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              marginBottom: "8px",
                              borderBottom:
                                index === 0 ? "1px solid black" : "none",
                              padding: index === 0 ? "4px" : "0 4px",
                              backgroundColor: index === 0 ? "#dcdcdc" : "#FFF",
                            }}
                          >
                            <Text wrap style={{ width: "60%" }}>
                              {item[0]}
                            </Text>
                            <Text>
                              {Utils.getCommanSepartedNumericStringWithDollar(
                                item[1]
                              )}
                            </Text>
                          </View>
                        );
                      }
                    )}
                </View>

                <Text style={{ marginTop: 10 }}></Text>

                <View
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    marginBottom: "8px",
                    border: "1px solid black",
                    padding: "4px",
                  }}
                >
                  <Text wrap style={{ width: "60%" }}>
                    {"Total Debits"}
                  </Text>
                  <Text>
                    {Utils.getCommanSepartedNumericStringWithDollar(
                      props.data?.total_debit
                    )}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  flexBasis: "49%",
                  // padding: "8px",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    marginBottom: "5px",
                    // borderBottom: "1px solid #000",
                  }}
                >
                  <Text style={styles.boldText}>Credits</Text>
                </View>

                <View
                  style={{
                    border: "1px solid black",
                  }}
                >
                  {props.data &&
                    Object.entries(props.data?.credits)?.map((item: any, index) => {
                      return (
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            marginBottom: "8px",
                            borderBottom:
                              index === 0 ? "1px solid black" : "none",
                            padding: index === 0 ? "4px" : "0 4px",
                            backgroundColor: index === 0 ? "#dcdcdc" : "#FFF",
                          }}
                        >
                          <Text wrap style={{ width: "60%" }}>
                            {item[0]}
                          </Text>
                          <Text>
                            {Utils.getCommanSepartedNumericStringWithDollar(
                              item[1]
                            )}
                          </Text>
                        </View>
                      );
                    })}
                </View>

                <Text style={{ marginTop: "auto" }}></Text>

                <View
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    marginBottom: "8px",
                    border: "1px solid black",
                    padding: "4px",
                  }}
                >
                  <Text wrap style={{ width: "60%" }}>
                    {"Total Credits"}
                  </Text>
                  <Text>
                    {Utils.getCommanSepartedNumericStringWithDollar(
                      props.data?.total_credit
                    )}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

// const AccountingNDocument = (props: any) => {
//   useEffect(() => {
//     console.log("inside acc pdf doc", props.date);
//   }, [props.date]);

//   return (
//     <Document>
//       <Page size="A4" style={styles.page}>
//         <View>
//           <View>
//             <Text>{"some text here"}</Text>
//           </View>
//         </View>
//       </Page>
//     </Document>
//   );
// };

export default AccountingDocument;
