import axios from "axios";
import apiClient from "./BaseService";

import { URL } from "../config";
import { store } from "../_app";

interface UserLoginProps {
  username: string;
  password: string;
}

const _userLogin = async (data: UserLoginProps) =>
  axios.post(URL.LOGIN, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const _refressToken = async () =>
  axios.post(URL.REFRESH_TOKEN, {
    refresh: store.getState().user.refresh,
  });

const _logoutRegister = async () =>
  axios.patch(URL.LOGOUT_REGISTER + `?user_id=${store.getState().user.id}`);

const _forgotPassword = async (email: string) =>
  axios.post(
    URL.PASSWORD_RESET,
    { email },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

const _validateToken = async (token: string) => {
  return axios.get(URL.VALIDATE_TOKEN + token, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const _changePassword = async (token: string, password: string) =>
  axios.post(
    URL.CHANGE_PASSWORD,
    { token, password },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

const _setPassword = async (data: any) =>
  axios.post(URL.PASSWORD_SET, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const _changePasswordUser = async (data: any) =>
  apiClient.patch(URL.USER_CHANGE_PASSWORD, data);

const _loginAddRequest = async(data:any) => apiClient.post(URL.GET_EXTERNAL_COMPANY_LISTING,data);
   
const _companyAddRequest = async(query:any) => apiClient.get(URL.GET_COMPANY_NAME +`${query}` )

export const AuthService = {
  _userLogin,
  _refressToken,
  _forgotPassword,
  _changePassword,
  _changePasswordUser,
  _validateToken,
  _setPassword,
  _logoutRegister,
  _loginAddRequest,
  _companyAddRequest,
};
