import ReactDOM from "react-dom/client";

import App from "./App";
import { persistor, store } from "./_app";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import GlobaleStyle from "./GlobalStyles";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={<h1>Loading....</h1>} persistor={persistor}>
      <App />
      <GlobaleStyle />
    </PersistGate>
  </Provider>,
  // </React.StrictMode>
);
