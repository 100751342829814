import { useEffect, useState } from "react";
import { Constant } from "../../../../../config";
import { Notify } from "../../../../../utils";
import { useAppSelector } from "../../../../../_app";
import { LeaveService } from "../../../../../_service";
import { LoaderContainer } from "../../../../../CommonStyles";
import {
  CustomButton,
  CustomSelectField,
  CustomeCkeckboxField,
  Loader,
} from "../../../../../components";
import {
  AllNoneButton,
  AllNoneContainer,
  ButtonContainer,
  GeneralSettingsContainer,
  LeaveSettingsHeading,
  LeaveSettingsSubHeading,
} from "../../SelfServiceStyles";
import { useNavigate } from "react-router-dom";
import { SelfService } from "../../../../../_service/SelfService";

const LeaveSettings = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [leaveList, setLeaveList] = useState<any>([]);
  const [selectedLeave, setSelectedLeave] = useState<any>([]);
  const [approverCanSee, setApproverCanSee] = useState("No Employees");
  const [employeeCanSee, setEmployeeCanSee] = useState("No Employees");

  const canSeeOptions = [
    "No Employees",
    "All Employees",
    "Employees they can approve",
  ];

  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const toggleLeaveSelect = (leaveId: any) => {
    const leaveData = [...selectedLeave];
    const index = leaveData.indexOf(leaveId);

    if (index !== -1) {
      leaveData.splice(index, 1);
    } else {
      leaveData.push(leaveId);
    }
    console.log(leaveData);

    setSelectedLeave(() => leaveData);
  };

  const getLeaveTypes = async () => {
    try {
      if (user?.defaultCompnayId) {
        const { data, status } = await LeaveService._getLeaveTypes(
          parseInt(user.defaultCompnayId)
        );

        if (status === 200) {
          setLeaveList(() => data.data);
        }
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const getSelfServiceSettings = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await SelfService._getSelfServiceSettings(
        user.defaultCompnayId
      );
      if (status === 200) {
        const { approver_can_see, employee_can_see, leaves } = data.data;

        setApproverCanSee(() => approver_can_see);
        setEmployeeCanSee(() => employee_can_see);
        if (leaves) {
          setSelectedLeave(() => [...leaves]);
        }
      }
    } catch (err) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  const postSelfServiceSettings = async () => {
    setIsLoading(() => true);
    try {
      const dataToSend = {
        company: user.defaultCompnayId,
        approver_can_see: approverCanSee,
        employee_can_see: employeeCanSee,
        leaves: [...selectedLeave],
      };
      const { status } = await SelfService._postSelfServiceSettings(dataToSend);
      if (status === 201) {
        Notify("Settings updated Successfully", 1);
        getSelfServiceSettings();
      }
    } catch (err) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    getLeaveTypes();
    getSelfServiceSettings();
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <CustomSelectField
            title="Approvers can see overview for"
            value={approverCanSee}
            onChangeOption={(value) => setApproverCanSee(value)}
            option={canSeeOptions.map((optionData) => ({
              key: optionData,
              value: optionData,
            }))}
          />
          <CustomSelectField
            title="Employees can see overview for"
            value={employeeCanSee}
            onChangeOption={(value) => setEmployeeCanSee(value)}
            option={canSeeOptions.map((optionData) => ({
              key: optionData,
              value: optionData,
            }))}
          />
          <LeaveSettingsHeading>Display Leave Balances</LeaveSettingsHeading>
          <LeaveSettingsSubHeading>
            Minimum balance warnings will still be visible for all types, if
            enabled.
          </LeaveSettingsSubHeading>
          <AllNoneContainer>
            <AllNoneButton
              onClick={() =>
                setSelectedLeave(() => leaveList.map((leave: any) => leave.id))
              }
            >
              All
            </AllNoneButton>
            <AllNoneButton onClick={() => setSelectedLeave(() => [])}>
              None
            </AllNoneButton>
          </AllNoneContainer>
          <GeneralSettingsContainer>
            {leaveList.map((leave: any) => (
              <CustomeCkeckboxField
                title={leave.name}
                value={selectedLeave.includes(leave.id)}
                onChange={() => toggleLeaveSelect(leave.id)}
              />
            ))}
          </GeneralSettingsContainer>
          <ButtonContainer>
            <CustomButton
              type="secondary"
              title="Cancel"
              onClick={() => navigate("/dashboard/self-service/employee-users")}
            />
            <CustomButton
              title="Save"
              onClick={() => postSelfServiceSettings()}
            />
          </ButtonContainer>
        </>
      )}
    </div>
  );
};

export default LeaveSettings;
