import styled from "styled-components";

const OverviewCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20;
  background: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border: 1px solid rgba(0, 0, 0, 0.125);
  justify-content: center;
  min-width: 160px;
`;
const OverviewStats = styled.p`
  font-size: 20px;
  padding: 20px 10px;
  font-weight: bold;
  color: ${(props) => props.color};
`;
const OverviewHeading = styled.p`
  color: ${(props) => props.color};
  background: #e0e0e0;
  padding: 10px 30px;
  font-size: 20px;
  width: 100%;
  text-align: center;
`;
const TableRow = styled.tr`
  color: #333;
  font-size: .8125rem;

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.GRAY_5};
  }

  &:hover {
    background-color: #bee5eb;
    color: #000;
    font-weight: 500;
    transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -o-transition: 0.3s all;
    -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    -moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
`;

const RecordSeletedText = styled.p`
  font-size: 20px;
  display: inline;
  margin-right: 20px;
`;

const DisplayFlex = styled.div`
  display: flex;
  gap: 50px;
  margin-top: 20px;
  align-items: center;
  justify-content: flex-start;
`;

export {
  OverviewCard,
  OverviewStats,
  OverviewHeading,
  TableRow,
  RecordSeletedText,
  DisplayFlex,
};
