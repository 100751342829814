import styled from "styled-components";

const EditorContainer = styled.div`
  width: min(calc(250px + 80%), 98%);
  margin-top: 10px;
`;

const EditorLabel = styled.label`
  display: block;
  margin-bottom: 1px;
`;
const AddTemplateContainer = styled.div`
  margin-bottom: 10px;
`;
export { EditorContainer, EditorLabel, AddTemplateContainer };
