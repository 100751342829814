import React from "react";
import styled from "styled-components";
import { Constant } from "../../../config";
interface FilterBySelectProps {
  value: string;
  onChageOption: (e: string) => void;
  options?: any;
  marginBottom?: any;
  isDisabled?: boolean;
}

const FilterBySelect = ({
  onChageOption,
  value,
  options,
  marginBottom = 20,
  isDisabled = false,
}: FilterBySelectProps) => {
  return (
    <Container style={{ marginBottom: marginBottom }}>
      <Text>{Constant.company.current_status} </Text>
      {options ? (
        <Select disabled={isDisabled} value={value} onChange={(e) => onChageOption(e.target.value)}>
          {options.map((option: any) => (
            <option value={option.value}>{option.name}</option>
          ))}
        </Select>
      ) : (
        <Select disabled={isDisabled} value={value} onChange={(e) => onChageOption(e.target.value)}>
          <option value={"0"}>{Constant.common.all}</option>
          <option value={"2"}>{Constant.common.inactive}</option>
          <option value={"1"}>{Constant.common.active}</option>
        </Select>
      )}
    </Container>
  );
};

export default FilterBySelect;

const Container = styled.div`
  margin-bottom: 1rem;
  display: flex;
`;
const Text = styled.p`
  width: 110px;
  height: 30px;
  color: #fff;
  border: 1px solid #afb5ba;
  font-size: 0.8rem;
  background-color: #afb5ba;
  text-transform: none;
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-radius: 3px 0 0 3px;
`;
const Select = styled.select`
  max-width: 100px;
  height: 30px;
  background-color: #087ca7;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  padding: 0 5px;
  text-align: center;
`;
