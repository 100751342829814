import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  userReducer,
  employeeReducer,
  payrollReducer,
  messagesReducer,
  dashboardReducer,
} from "../_features";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whiteList: ["user", "employee", "payroll"],
  // blacklist: ["script", "luggage", "lang", "gallery", "user", "transport"],
};
const rootReducer = combineReducers({
  user: userReducer,
  employee: employeeReducer,
  payroll: payrollReducer,
  message: messagesReducer,
  dashboard: dashboardReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
