import { useLocation } from "react-router-dom";
import {
  AllEmployeesHeading,
  MultiSelectButton,
  MultiSelectButtonContainer,
  ReportFilterBody,
  ReportFilterBodyHeading,
  ReportFilterContainer,
  ReportHeading,
  ReportHeadingContainer,
  ReportHorizontalSeparator,
  ReportSectionHeading,
} from "../ReportFilterPageStyles";
import {
  CommonModal,
  CustomButton,
  CustomDateField,
  CustomeCkeckboxField,
  Loader,
  NavigateBack,
} from "../../../components";
import { Constant } from "../../../config";
import { useEffect, useState } from "react";
import { Notify, Utils } from "../../../utils";
import { useAppSelector } from "../../../_app";
import { EmployeeService, LeaveService } from "../../../_service";
import { LoaderContainer } from "../../../CommonStyles";
import { ReportService } from "../../../_service/ReportService";
import { H3Heading } from "../../appSettings/AppSettingsStyles";
import styled from "styled-components";
import EmployeeListModal from "../EmployeeListModal/EmployeeListModal";
import XLSX from 'xlsx';
import LeaveDaysReportPdfDoc from "../LeaveReportPdf/LeaveDaysReportPdfDoc";
import LeaveDaysReportPdf from "../LeaveReportPdf/LeaveDaysReportPdf";
import ReactPDF from "@react-pdf/renderer";

const pathToHeading = {
  "/dashboard/reports/leave_report": "Leave Report",
  "/dashboard/reports/leave_days_report": "Leave Days Report",
  "/dashboard/reports/leave_expiry": "Leave Expiry",
} as any;

const LeaveDaysReportExtraComp = (props: any) => {
  let currentDate = new Date(
    useAppSelector((state) => state?.user?.current_payrun_date)
  );

  if (!currentDate) {
    currentDate = new Date();
  }
  currentDate.setDate(1);

  // Move back one day to the last day of the last month
  currentDate.setDate(0);
  const lastDayOfLastMonth = Utils._date(currentDate);

  // Set the date to the first day of the last month
  currentDate.setDate(1);
  const firstDayOfLastMonth = Utils._date(currentDate);

  const { pathname } = useLocation();
  const [fromDate, setFromDate] = useState<string>(firstDayOfLastMonth);
  const [toDate, setToDate] = useState<string>(lastDayOfLastMonth);
  const [leaveList, setLeaveList] = useState<any>([]);
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [leaveType, setLeaveType] = useState<any>([]);
  const [statusValue, setStatusValue] = useState<string>("0");
  const [selectedEmployeeList, setSelectedEmployeeList] = useState<any>([]);
  const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false);
  const [filteredEmployeeList, setFilteredEmployeeList] = useState<any>([]);
  const [leaveListData, setLeaveListData] = useState<any>([])

  const user = useAppSelector((state) => state.user);

  const getLeaveTypes = async () => {
    setIsLoading(() => true);
    try {
      if (user?.defaultCompnayId) {
        const { data, status } = await LeaveService._getLeaveTypes(
          parseInt(user.defaultCompnayId)
        );

        if (status === 200) {
          setLeaveList(() => data.data);
        }
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const handleSelectAll = () => {
    const allLeaveIds = leaveList.map((leave: any) => leave.id);
    setLeaveType(() => allLeaveIds);
  };

  const getEmployeeList = async () => {
    try {
      const { data, status } = await EmployeeService._getEmployeeListByCompany(
        user.defaultCompnayId,
        "0"
      );

      if (status === 200) {
        setEmployeeList(() => data.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  const extractData = () => {
    const employeeCollatedList = [...employeeList.active, ...employeeList.inactive]
    const selectedEmployeesArray = employeeCollatedList.filter(obj => selectedEmployeeList.includes(obj.id));
    const selectedNamesArray = selectedEmployeesArray.map(obj => obj.name);

    let headerArr = [
      "Employee",
      "Leave Type",
      "Leave Date",
      "Amount Taken",
      "Balance"
    ];
    let valueArr: any = [];
    const employeeNameArr: string[] = []
    for (const employeeName of selectedNamesArray) {
      const employeeLeaveData = leaveListData[employeeName];

      // Iterate through dates for each employee
      for (const leaveId of leaveType) {
        const leaveNameIndex = leaveList.findIndex((obj: any) => obj.id === leaveId);
        const leaveName = leaveList[leaveNameIndex].name;

        const leaveDataOfIndividualType = employeeLeaveData[leaveId]

        if(leaveDataOfIndividualType && leaveDataOfIndividualType.length) {
          for (const individualLeaveData of leaveDataOfIndividualType) {
            employeeNameArr.push(employeeName);
            const rowData = [employeeName, leaveName, Utils._dateToShow(individualLeaveData?.date), individualLeaveData?.movement || "", individualLeaveData?.balance || ""]
            valueArr.push(rowData);
          }
        }
      }
    }

    employeeNameArr.sort();

    const firstColumnValues = valueArr.map((val: any) => val[0]);

    // Find the distinct values
    const distinctValues = [...new Set(firstColumnValues)];
    const employeeLength = distinctValues.length;

    return { valueArr, headerArr, employeeLength, employeeNameArr };
  };

  const generatePdf = async () => {
    const { valueArr, headerArr, employeeLength, employeeNameArr } =
      extractData();

    const pdfBlob = await ReactPDF.pdf(
      <LeaveDaysReportPdfDoc
        LeaveReportDocument={
          <LeaveDaysReportPdf
            leaveData={leaveListData}
            dateFrom={fromDate}
            dateTo={toDate}
            valueArr={valueArr}
            headerArr={headerArr}
            employeeNameArr={employeeNameArr}
            employeeLength={employeeLength}
            companyName={user.defaultCompany}
            leaveListData={leaveList}
          />
        }
      />
    ).toBlob();
    const url = URL.createObjectURL(pdfBlob as any);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${
      user.defaultCompany.split(" ")[0]
    }_leave_days_report.pdf`; // Set the desired filename for the downloaded file
    document.body.appendChild(a);
    a.click();
  };

  const downloadCsv = () => {
    const { valueArr, headerArr, employeeLength } =
      extractData();

    const csvTitle = [
      [`${user.defaultCompany}`],
      ["Leave Report"],
      [
        "Period",
        `${Utils._dateToShow(fromDate)} to ${Utils._dateToShow(toDate)}`,
      ],
      ["Number of Employees:", employeeLength],
    ];

    const combinedData = [
      ...csvTitle,
      [],
      [...headerArr],
      ...valueArr,
    ];

    const workbook = XLSX.utils.book_new();

    // Add a worksheet with combined data
    const combinedWorksheet = XLSX.utils.aoa_to_sheet(combinedData);
    XLSX.utils.book_append_sheet(workbook, combinedWorksheet, "CombinedData");

    const numCols = 28; // Get the number of columns
    combinedWorksheet["!cols"] = Array(numCols).fill({ width: 15 });

    XLSX.writeFile(
      workbook,
      `${user.defaultCompany.split(" ")[0]}_leave_days_report.xlsx`,
      {
        compression: true,
      }
    );
  };

  const handleExcelClick = (type: string = "excel") => {
    if (leaveType.length && selectedEmployeeList.length) {
      if (
        employeeList["active"].length > 0 ||
        employeeList["inactive"].length > 0
      ) {
        if (type === "excel") {
          downloadCsv()
        } else {
          generatePdf();
        }
      } else {
        Notify("No employees for this date range");
      }
    } else if (leaveType.length) {
      Notify("Please Select at least one field", 0);
    } else if (!selectedEmployeeList.length) {
      Notify("Please Select at least one employee", 0);
    }
  };

  const handleSelectLeaveType = (id: any) => {
    if (leaveType.includes(id)) {
      let interArr = leaveType;
      interArr = interArr.filter((leave: any) => leave !== id);
      setLeaveType(() => interArr);
    } else {
      setLeaveType(() => [...leaveType, id]);
    }
  };

  const handleSelectEmployee = (id: any) => {
    if (selectedEmployeeList.includes(id)) {
      let interArr = selectedEmployeeList;
      interArr = interArr.filter((em: any) => em !== id);
      setSelectedEmployeeList(() => interArr);
    } else {
      setSelectedEmployeeList(() => [...selectedEmployeeList, id]);
    }
  };

  const handleEmployeeSelectNone = () => {
    setSelectedEmployeeList(() => []);
  };

  const getLeaveDaysReportData = async () => {
    const searchQuery = `${user.defaultCompnayId}&from_date=${fromDate}&to_date=${toDate}`;
    setIsLoading(() => true);

    try {
      const { status, data } = await ReportService._leaveDaysReport(
        searchQuery
      );

      if (status === 200) {
        setEmployeeList(() => data.data.employee_list);
        setLeaveListData(() => data.data.leave_list)
      }
    } catch (err) {
      console.log(err);
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    if (Object.keys(employeeList).length > 0) {
      if (statusValue === "0") {
        setFilteredEmployeeList(() => [
          ...employeeList.active,
          ...employeeList.inactive,
        ]);
        setSelectedEmployeeList(() => [
          ...employeeList.active?.map((emp: any) => emp.id),
          ...employeeList.inactive?.map((emp: any) => emp.id),
        ]);
      } else if (statusValue === "1") {
        setFilteredEmployeeList(() => [...employeeList.active]);
        setSelectedEmployeeList(() =>
          employeeList.active?.map((emp: any) => emp.id)
        );
      } else {
        setFilteredEmployeeList(() => [...employeeList.inactive]);
        setSelectedEmployeeList(() =>
          employeeList.inactive?.map((emp: any) => emp.id)
        );
      }
    }
  }, [statusValue, employeeList]);

  useEffect(() => {
    getLeaveTypes();
    getLeaveDaysReportData();
  }, [fromDate, toDate]);

  return isLoading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <>
      <ReportFilterContainer>
        <ReportHeadingContainer>
          <ReportHeading>{pathToHeading[pathname]}</ReportHeading>
        </ReportHeadingContainer>
        <ReportFilterBody>
          <div>
            <ReportSectionHeading>Filters</ReportSectionHeading>
            <div style={{ display: "flex", gap: 50, marginTop: 20 }}>
              <div style={{ display: "flex" }}>
                <Container>
                  <Text>Current Status </Text>
                  <Select
                    value={statusValue}
                    onChange={(e) => setStatusValue(e.target.value)}
                  >
                    <option value={"0"}>{Constant.common.all}</option>
                    <option value={"2"}>{Constant.common.inactive}</option>
                    <option value={"1"}>{Constant.common.active}</option>
                  </Select>
                </Container>
              </div>
              <div style={{ display: "flex" }}>
                <Container style={{ marginBottom: 20 }}>
                  <Text>Employees </Text>
                  <Information onClick={() => setShowEmployeeModal(() => true)}>
                    {selectedEmployeeList.length === 0
                      ? "None"
                      : selectedEmployeeList.length ===
                        employeeList.active.length +
                          employeeList.inactive.length
                      ? "All"
                      : `${selectedEmployeeList.length} of ${
                          employeeList.active.length +
                          employeeList.inactive.length
                        }`}
                  </Information>
                </Container>
              </div>
            </div>
          </div>
          <ReportHorizontalSeparator style={{ marginTop: 6 }} />
          <div>
            <ReportSectionHeading>Date Range</ReportSectionHeading>
            <div style={{ display: "flex", width: "50%" }}>
              <CustomDateField
                label="From"
                date={fromDate}
                setDate={(date: any) => setFromDate(() => date)}
                labelWidth="45px"
              />
              <CustomDateField
                label="To"
                date={toDate}
                setDate={(date: any) => setToDate(() => date)}
                labelWidth="20px"
              />
            </div>
          </div>
          <ReportHorizontalSeparator />
          <div>
            <ReportSectionHeading>Employees</ReportSectionHeading>
            <AllEmployeesHeading>
              {selectedEmployeeList.length === 0
                ? "No "
                : selectedEmployeeList.length ===
                  employeeList.active.length + employeeList.inactive.length
                ? `All (${selectedEmployeeList.length}) `
                : `${selectedEmployeeList.length} of ${
                    employeeList.active.length + employeeList.inactive.length
                  } `}
              employee(s) selected
            </AllEmployeesHeading>
          </div>
          <ReportHorizontalSeparator />
          <div>
            <ReportSectionHeading style={{ marginBottom: 10 }}>
              Leave Types
            </ReportSectionHeading>
            <MultiSelectButtonContainer>
              <MultiSelectButton onClick={handleSelectAll}>
                All
              </MultiSelectButton>
              <MultiSelectButton onClick={() => setLeaveType(() => [])}>
                None
              </MultiSelectButton>
            </MultiSelectButtonContainer>

            {leaveList.map((leave: any) => (
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <input
                  type="checkbox"
                  onChange={() => handleSelectLeaveType(leave.id)}
                  checked={leaveType.includes(leave.id)}
                />
                <p style={{ fontSize: 18 }}>{leave.name}</p>
              </div>
            ))}
          </div>
          <ReportHorizontalSeparator />
          <div style={{ display: "flex", gap: 20 }}>
            <CustomButton
              width="150px"
              title="Show Excel"
              onClick={() => handleExcelClick()}
            />
            {pathname !== "/dashboard/reports/leave_expiry" && (
              <CustomButton
                width="150px"
                title="Show Pdf"
                onClick={() =>
                  handleExcelClick("pdf")
                }
              />
            )}
          </div>
        </ReportFilterBody>
      </ReportFilterContainer>
      <CommonModal
        handleClose={() => setShowEmployeeModal(false)}
        open={showEmployeeModal}
        updateData={() => setShowEmployeeModal(false)}
        body={
          <EmployeeListModal
            employeeList={filteredEmployeeList}
            handleSelectAll={handleSelectAll}
            handleSelectNone={handleEmployeeSelectNone}
            selectedEmployeeList={selectedEmployeeList}
            handleSelectEmployee={handleSelectEmployee}
          />
        }
        heading="Filter Employees"
        conformButtonText="Close"
        isCancelButton={false}
      />
    </>
  );
};

export default LeaveDaysReportExtraComp;

const Container = styled.div`
  margin-bottom: 1rem;
  display: flex;
`;
const Text = styled.p`
  width: 110px;
  height: 30px;
  color: #fff;
  border: 1px solid #afb5ba;
  font-size: 0.8rem;
  background-color: #afb5ba;
  text-transform: none;
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-radius: 3px 0 0 3px;
`;
const Select = styled.select`
  max-width: 100px;
  height: 30px;
  background-color: #087ca7;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  padding: 0 5px;
  text-align: center;
`;

const Information = styled.div`
  max-width: 100px;
  height: 30px;
  background-color: #087ca7;
  color: #fff;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  padding: 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
`;
