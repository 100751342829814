import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";

import "./add-company.css";
import { IconContext } from "react-icons";
import styled from "styled-components";
import { Utils } from "../../utils";

interface PayslipModalProps {
  open: boolean;
  handleClose: () => void;
  data: any;
}

const customStyles = {
  content: {
    // top: "45%",
    // left: "50%",
    // right: "auto",
    // bottom: "auto",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
    // border: "1px solid #283a44",
    // borderRadius: "10px",
  },
};

const PayslipModal = (props: PayslipModalProps) => {
  const { handleClose, open, data } = props;

  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Add Company"
      ref={(e) => e}
    >
      <div className="payslip-modal">
        <div className="header" style={{ justifyContent: "space-between" }}>
          <div>
            <p style={{ fontSize: "1.25rem" }}>Payslip Details</p>
            <p style={{ color: "#6c757d", fontSize: ".75rem" }}>
              Period: {data?.payslip_date?.start || ""} -{" "}
              {data?.payslip_date?.end || ""}
            </p>
          </div>
          <p className="close-icon" onClick={handleClose}>
            <IconContext.Provider value={{ color: "#6c757d", size: "30px" }}>
              <AiOutlineClose />
            </IconContext.Provider>
          </p>
        </div>
        <hr
          style={{
            color: "#6c757d",
            marginTop: ".25rem",
          }}
        />
        <div className="body">
          {Object.entries(props?.data || {}).map((item: any, index: number) => {
            return ["payslip_date", "net_pay", "cpf_details"].includes(
              item[0],
            ) ? null : (
              <Container>
                <Row>
                  <b>{Utils.formateStringWithUnderscore(item[0])}</b>
                  <b>{item[1]?.total}</b>
                </Row>
                <Hr />
                {item[1]?.data?.map((_item: any) => {
                  return (
                    <Row>
                      <p>{_item?.name || ""}</p>
                      <p>{_item?.value || ""}</p>
                    </Row>
                  );
                })}
              </Container>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default PayslipModal;

const Container = styled.div`
  width: 45%;
  margin-right: 5%;
  margin-top: 2rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 50%;
`;
const Hr = styled.hr`
  color: #000;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
`;
