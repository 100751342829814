import { useEffect, useState } from "react";
import {
  CommonModal,
  CustomButton,
  CustomNumberField,
  NavigateBack,
} from "../../../components";
import { H3Heading } from "../../appSettings/AppSettingsStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { CPFService } from "../../../_service/CPFService";
import { Notify, Utils } from "../../../utils";
import { Constant } from "../../../config";
import { useAppSelector } from "../../../_app";

const old_keyFieldMap = {
  ow: "OW (Salary OT)",
  bonus: "AW (Bonus)",
  commission: "AW (commission)",
  others: "AW (others)",
  aw: "AW (total)",
  total_income: "Income",
  transport: "Transport",
  entertainment: "Entertainment",
  other_allowance: "Other Allowances",
  total_allowance: "Total Allowances",
  cpf_employee: "CPF Employee",
  cdac: "CDAC",
  sinda: "Sinda",
  ecf: "Ecf",
  mbmf: "Mbmf",
  oth_deduction: "Other Deductions",
  total_deduction: "Total Deduction",
  total_reimburesement: "Total Reimbursement",
  net_pay: "Net Pay",
  cpf_employer: "CPF Employer",
  sdl: "SDL",
  total_cpf: "Total CPF",
} as any;

const keyFieldMap = {
  ow: "OW (Salary OT)",
  bonus: "AW (Bonus)",
  commission: "AW (commission)",
  // others: "AW (others)",
  // aw: "AW (total)",
  // total_income: "Income",
  director_fees: "Director's Fee",
  transport: "Transport",
  entertainment: "Entertainment",
  other_allowance: "Other Allowances",
  gross_salary: "Gross Salary",
  // total_allowance: "Total Allowances",
  cpf_employee: "CPF Employee",
  cdac: "CDAC",
  sinda: "Sinda",
  ecf: "ECF",
  mbmf: "Mendaki + MBMF",
  oth_deduction: "Other Deductions",
  // total_deduction: "Total Deduction",
  total_reimburesement: "Total Reimbursement",
  // mosque_building_fund: "Mosque Building Fund",
  net_pay: "Net Pay",
  // cpf_employer: "CPF Employer",
  // sdl: "SDL",
  // total_cpf: "Total CPF",
} as any;

const disabledKeys = [
  "total_cpf",
  "total_income",
  "total_aw",
  "total_allowances",
  "total_deduction",
  "net_pay",
  "gross_salary"
];

const EditFiling = () => {
  const [taxInfo, setTaxInfo] = useState<any>({
    total_income: "",
    total_deduction: "",
    net_pay: "",
    total_employer_contribution: "",
    total_reimburesement: "",
    total_benefit: "",
    total_allowance: "",
    bonus: "",
    transport: "",
    entertainment: "",
    other_allowance: "",
    commission: "",
    others: "",
    ow: "",
    aw: "",
    cpf_employee: "",
    cpf_employer: "",
    sinda: "",
    ecf: "",
    cdac: "",
    mbmf: "",
    sdl: "",
    // mosque_building_fund: "",
    director_fees: "",
    donations: "",
    oth_deduction:"",
  });

  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  const [open, setOpen] = useState<boolean>(false)

  const handleTaxInfoChange = (
    target: string,
    value: string | number | boolean
  ) => {
    setTaxInfo((prev: any) => ({ ...prev, [target]: value }));
  };

  const editIr8aDetails = async () => {
    try {
      const { status } = await CPFService._updateManualFiling(
        state.filing.id,
        taxInfo
      );

      if (status === 200) {
        Notify("Ir8A updated successfully", 1);
        navigate(-1);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
      navigate(-1);
    }
  };

  const { state } = useLocation();

  useEffect(() => {
    setTaxInfo(() => ({
      total_income: state.filing.total_income,
      total_deduction: state.filing.total_deduction,
      net_pay: state.filing.net_pay,
      total_employer_contribution: state.filing.total_employer_contribution,
      total_reimburesement: state.filing.total_reimburesement,
      total_benefit: state.filing.total_benefit,
      total_allowance: state.filing.total_allowance,
      bonus: state.filing.bonus,
      transport: state.filing.transport,
      entertainment: state.filing.entertainment,
      other_allowance: state.filing.other_allowance,
      commission: state.filing.commission,
      others: state.filing.others,
      ow: state.filing.ow,
      aw: state.filing.aw,
      cpf_employee: state.filing.cpf_employee,
      cpf_employer: state.filing.cpf_employer,
      sinda: state.filing.sinda,
      ecf: state.filing.ecf,
      cdac: state.filing.cdac,
      mbmf: state.filing.mbmf,
      sdl: state.filing.sdl,
      // mosque_building_fund: state.filing.mosque_building_fund,
      director_fees: state.filing.director_fees,
      donations: state.filing.donations,
      oth_deduction: state.filing.oth_deduction,
    }));
  }, []);

  const bulkApprove = async () => {
    const dataToApprove = {
      is_approved: true,
      manual_payslip_ids: [state.filing.id],
    };
    try {
      const { status } = await CPFService._bulkApproveIras(dataToApprove);
      if (status >= 200 && status < 300) {
        Notify("Payslip approved successfully", 1);
        navigate(-1);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const handleSaveAndApprove = async () => {
    const dataToApprove = {
      is_approved: true,
      manual_payslip_ids: [state.filing.id],
    };

    try {
      const res1 = await CPFService._updateManualFiling(
        state.filing.id,
        taxInfo
      );
      const res2 = await CPFService._bulkApproveIras(dataToApprove);

      if ((res1.status >= 200 && res1.status < 300) && (res2.status >= 200 && res2.status < 300) ) {
        Notify("Ir8A updated & Payslip approved successfully", 1);
        navigate(-1);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }

  }

  
  let SumA =
    (parseFloat(taxInfo?.ow) || 0) +
    (parseFloat(taxInfo?.bonus) || 0) +
    (parseFloat(taxInfo?.commission) || 0) +
    (parseFloat(taxInfo?.director_fees) || 0) +
    (parseFloat(taxInfo?.transport) || 0) +
    (parseFloat(taxInfo?.entertainment) || 0) +
    (parseFloat(taxInfo?.other_allowance) || 0);
  let SumB =
    (parseFloat(taxInfo?.cpf_employee) || 0) +
    (parseFloat(taxInfo?.cdac) || 0) +
    (parseFloat(taxInfo?.sinda) || 0) +
    (parseFloat(taxInfo?.ecf) || 0) +
    (parseFloat(taxInfo?.mbmf) || 0)+
    (parseFloat(taxInfo?.oth_deduction) || 0);
    // (parseFloat(taxInfo?.total_reimburesement) || 0) +
    // (parseFloat(taxInfo?.mosque_building_fund) || 0);
  

  useEffect(()=>{
    let calc = ((SumA - SumB) + (parseFloat(taxInfo?.total_reimburesement || 0)) ) || 0
    setTaxInfo((prev: any) => ({ ...prev, ['net_pay']: calc, ['gross_salary']: SumA }));
  },[SumA, SumB, taxInfo?.total_reimburesement])

  return (
    <div>
      <NavigateBack />
      <H3Heading>Edit Manual IR8A</H3Heading>
      <div
        style={{
          display: "flex",
          gap: 50,
          color: "#000000d9",
          marginBottom: 10,
        }}
      >
        <p>
          <b>Company Name:</b> {user?.defaultCompany || ""}
        </p>
        <p>
          <b>Employee Name:</b> {state?.filing?.employee_name || ""}
        </p>
        <p>
          <b>Payslip Date:</b> {Utils._dateToShow(state?.filing?.payslip_date)}
        </p>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        {Object.keys(keyFieldMap).map((key: any) => (
          <>
            <CustomNumberField
              title={keyFieldMap[key]}
              value={
                key === "total_cpf"
                  ? taxInfo["cpf_employer"] + taxInfo["cpf_employee"]
                  : taxInfo[key]
              }
              onChangeText={(value) => handleTaxInfoChange(key, value)}
              disabled={disabledKeys.includes(key)}
            />
            {/* {keyFieldMap[key] === "Other Allowances" && <br />}  */}
          </>
        ))}
      </div>
      <div style={{ display: "flex", gap: 20, marginTop: 20 }}>
        <CustomButton title="Save" onClick={() => setOpen(true)} />
        <CustomButton
          title="Approve"
          type="secondary"
          success
          onClick={() => bulkApprove()}
        />
      </div>
      <CommonModal
        handleClose={() => setOpen(false)}
        open={open}
        // loading={loading}
        updateData={() => {
          editIr8aDetails();
          setOpen(false);
        }}
        body={`Do you want to Save or Save & Approve the IR8A?`}
        heading="Save Mannual IR8A"
        conformButtonText="Save"
        cancelButtonText="Cancel"
        deleteButtonText="Save & Approve"
        deleteData={() => {
          handleSaveAndApprove();
          setOpen(false);
        }}
      />
    </div>
  );
};

export default EditFiling;
