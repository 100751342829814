import { Container, Input, Label, Required } from "./Styles";

interface TextInputProps {
  labal: string;
  value: string;
  onChange: (text: string) => void;
  type?: string;
  placeholder?: string;
  required?: boolean;
  pattern?: string;
  style?: any;
  containerStyle?: any;
  onSubmit?: () => any;
}

const TextInput = (props: TextInputProps) => {
  const {
    labal,
    value,
    type,
    onChange,
    placeholder,
    required,
    pattern,
    style,
    containerStyle,
    onSubmit,
  } = props;
  return (
    <Container style={containerStyle}>
      <Label>
        {labal}
        {required && <Required>*</Required>}
      </Label>
      <Input
        style={style}
        pattern={pattern}
        type={type || "text"}
        value={value}
        autoComplete={"new-password"}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            onSubmit && onSubmit();
          }
        }}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder ? placeholder : labal}
      />
    </Container>
  );
};

export default TextInput;
