import TransactionHistoryExtraComp from "./TransactionHistoryExtraComp";

const TransactionHistory = () => {
  return (
    <div>
      <TransactionHistoryExtraComp />
    </div>
  );
};

export default TransactionHistory;
