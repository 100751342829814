import React from "react";
import styled from "styled-components";
import Require from "./Require";
import { Container, Error, Label } from "./Styles";

interface GroupSelectFieldProps {
  title: string;
  defaultValue?: string;
  value?: string | number | undefined;
  empty?: boolean;
  placeholder?: any;
  option: any;
  onChangeOption?: (e: string) => void;
  require?: boolean;
  error?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
}
const GroupSelectField = ({
  defaultValue = "",
  option = [],
  title,
  value,
  empty = false,
  placeholder,
  onChangeOption,
  require = false,
  error,
  onBlur,
  onFocus,
  disabled = false,
}: GroupSelectFieldProps) => {
  return (
    <Container>
      {title && (
        <Label>
          {title}
          {require && <Require />}
        </Label>
      )}
      <div style={{ width: "60%" }}>
        <Select
          disabled={disabled}
          defaultValue={defaultValue}
          value={value || defaultValue}
          onChange={(e) => onChangeOption && onChangeOption(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {empty && <option key={""} value={""}></option>}
          {placeholder && (
            <option key={""} value={""} disabled>
              {placeholder}
            </option>
          )}
          <optgroup label="Most Common">
            {option
              ?.filter((item: any) => item?.category == "common")
              ?.map((item: any) => {
                return (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
          </optgroup>
          <optgroup label="Other">
            {option
              ?.filter((item: any) => item?.category == null)
              ?.map((item: any) => {
                return (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
          </optgroup>
        </Select>
        {error && <Error>{error || ""}</Error>}
      </div>
    </Container>
  );
};

export default GroupSelectField;

const Select = styled.select`
  width: 100%;
  border-radius: 0.2rem;
`;
