import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  CustomButton,
  CustomeCkeckboxField,
  CustomNumberField,
  CustomSelectField,
  CustomTextField,
} from "../../../components";
import { Constant } from "../../../config";
import { Notify } from "../../../utils";
import { useAppSelector } from "../../../_app";
import { LeaveService } from "../../../_service";
import {
  ButtonContainer,
  DefaultEntitlementButton,
  DefaultEntitlementMessage,
  H3Heading,
} from "../AppSettingsStyles";

const AddEntitlementPolicy = () => {
  const [isUseCustomName, setIsUseCustomName] = useState<boolean>(false);
  const [customName, setCustomName] = useState<string>("");
  const [isUseHoursWorked, setIsUseHoursWorked] = useState<boolean>(false);
  const [isUseUpfrontAccrual, setIsUseUpfrontAccrual] =
    useState<boolean>(false);
  const [isAllowLeaveToBeCarried, setIsAllowLeaveToBeCarried] =
    useState<boolean>(false);
  const [defaultEntitlement, setDefaultEntitlement] = useState<string>("0");
  const [entitlementAvailableAfter, setEntitlementAvailableAfter] =
    useState<string>("0");
  const [leaveExpiresAfter, setLeaveExpiresAfter] = useState<string>("");
  const [useProRated, setUseProRated] = useState<boolean>(false);
  const [leaveForHours, setLeaveForHours] = useState<string>("0");
  const [paidLeavesContribute, setPaidLeavesContribute] =
    useState<boolean>(false);
  const [limitType, setLimitType] = useState<string>("none");
  const [percentageBalance, setPercentageBalance] = useState<string>("0");
  const [percentageEntitlement, setPercentageEntitlement] =
    useState<string>("0");
  const [numberOfDays, setNumberOfDays] = useState<string>("0");
  const [showRules, setShowRules] = useState<Boolean>(false);
  const [inputRows, setInputRows] = useState<number[]>([]);

  const { state, pathname } = useLocation();
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const leaveLimitTypes = [
    {
      key: "No Limit",
      value: "none",
    },
    {
      key: "Percentage of balance",
      value: "percentage_balance",
    },
    {
      key: "Percentage of entitlement",
      value: "percentage_entitlement",
    },
    {
      key: "Fixed Number of days",
      value: "fixed_days",
    },
  ];

  /**
   * Function to add Entitlement policy
   */
  const addEntitlementPolicy = async () => {
    try {
      const formData = new FormData();

      formData.append("name", state?.leaveName);
      formData.append("is_custom_name", isUseCustomName ? "true" : "false");
      formData.append("is_deletable", "true");
      if (isUseCustomName) {
        formData.append("custom_name", customName);
      }
      formData.append(
        "is_use_hours_worked_aacrual",
        isUseHoursWorked ? "true" : "false"
      );
      if (isUseHoursWorked) {
        formData.append("leave_for_every_hours_worked", leaveForHours);
        formData.append(
          "is_paid_leave_contribute",
          paidLeavesContribute ? "true" : "false"
        );
      } else {
        formData.append("deafult_entitlement", defaultEntitlement);
        formData.append(
          "entitlement_available_after",
          entitlementAvailableAfter
        );
        formData.append(
          "is_use_upfront_accrual",
          isUseUpfrontAccrual ? "true" : "false"
        );
        if (isUseUpfrontAccrual) {
          formData.append(
            "is_round_pro_rated_leave",
            useProRated ? "true" : "false"
          );
        }
      }
      formData.append(
        "is_allowed_carried_forward",
        isAllowLeaveToBeCarried ? "true" : "false"
      );
      if (isAllowLeaveToBeCarried) {
        formData.append(
          "carry_forward_leave_expires_after",
          String(leaveExpiresAfter)
        );
        formData.append("limit_type", limitType);
        if (limitType === "percentage_balance") {
          formData.append("percentage_of_balance", String(percentageBalance));
        } else if (limitType === "percentage_entitlement") {
          formData.append(
            "percentage_of_entitlement",
            String(percentageEntitlement)
          );
        } else if (limitType === "fixed_days") {
          formData.append("fixed_number_of_days", String(numberOfDays));
        }
      }
      if (pathname.split("/").pop() === "edit") {
        const { status } = await LeaveService._editEntitlementPolicy(
          formData,
          state?.entitlementPolicyDetails?.id
        );

        if (status === 200) {
          Notify("Entitlement Policy edited successfully");
          navigate(-1);
        }
      } else {
        formData.append("leave_type", state?.leaveId);
        formData.append("company", user?.defaultCompnayId);
        const { status } = await LeaveService._addEntitlementPolicy(formData);

        if (status === 201) {
          Notify("Entitlement Policy added successfully");
          navigate(-1);
        }
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  /**
   * Function to add Default Active Entitlement policy in case of new leave type
   */
  const addDefaultActiveEntitlementPolicy = async (
    entitlementPolicyId: number
  ) => {
    const formData = new FormData();
    formData.append("entitlement_policy", `${entitlementPolicyId}`);
    formData.append("company", user?.defaultCompnayId);
    formData.append("leave_type", state?.leaveId);

    try {
      const { status } = await LeaveService._addActiveEntitlementPolicy(
        formData
      );
      if (status !== 201) {
        Notify(Constant.common.something_went_wrong, 0);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  /**
   * Function to add Default Entitlement policy in case of new leave type
   */
  const addDefaultPolicy = async () => {
    if (state?.default) {
      try {
        const formData = new FormData();

        formData.append("leave_type", state?.leaveId);
        formData.append("company", user?.defaultCompnayId);
        formData.append("name", state?.leaveName);
        formData.append("is_custom_name", "true");
        formData.append("is_deletable", "true");
        formData.append("custom_name", "0.0 days");

        const { data, status } = await LeaveService._addEntitlementPolicy(
          formData
        );

        if (status === 201) {
          await addDefaultActiveEntitlementPolicy(data.data.id);
          navigate("/dashboard/app-settings/leave/view", {
            state: {
              leaveId: state?.leaveId,
            },
          });
        }
      } catch (error) {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
  };

  console.log(state);
  /**
   * Set values to the states in case of edit Entitlement policy
   */
  useEffect(() => {
    if (state?.entitlementPolicyDetails) {
      setIsUseCustomName(
        () => state?.entitlementPolicyDetails?.is_custom_name || false
      );
      if (state?.entitlementPolicyDetails?.is_custom_name) {
        setCustomName(() => state?.entitlementPolicyDetails?.custom_name || "");
      }
      setIsUseHoursWorked(
        () =>
          state?.entitlementPolicyDetails?.is_use_hours_worked_aacrual || false
      );
      if (state?.entitlementPolicyDetails?.is_use_hours_worked_aacrual) {
        setLeaveForHours(
          () =>
            state?.entitlementPolicyDetails?.leave_for_every_hours_worked || "0"
        );
        setPaidLeavesContribute(
          () =>
            state?.entitlementPolicyDetails?.is_paid_leave_contribute || false
        );
      }
      setDefaultEntitlement(
        () => state?.entitlementPolicyDetails?.deafult_entitlement || "0"
      );
      setEntitlementAvailableAfter(
        () =>
          state?.entitlementPolicyDetails?.entitlement_available_after || "0"
      );
      setIsUseUpfrontAccrual(
        () => state?.entitlementPolicyDetails?.is_use_upfront_accrual || false
      );
      setUseProRated(
        () => state?.entitlementPolicyDetails?.is_round_pro_rated_leave || false
      );
      setIsAllowLeaveToBeCarried(
        () =>
          state?.entitlementPolicyDetails?.is_allowed_carried_forward || false
      );

      if (state?.entitlementPolicyDetails?.is_allowed_carried_forward) {
        setLeaveExpiresAfter(
          () =>
            state?.entitlementPolicyDetails
              ?.carry_forward_leave_expires_after || ""
        );
        setLimitType(() => state?.entitlementPolicyDetails?.limit_type);
        if (
          state?.entitlementPolicyDetails?.limit_type === "percentage_balance"
        ) {
          setPercentageBalance(
            () => state?.entitlementPolicyDetails?.percentage_of_balance || "0"
          );
        } else if (
          state?.entitlementPolicyDetails?.limit_type ===
          "percentage_entitlement"
        ) {
          setPercentageEntitlement(
            () =>
              state?.entitlementPolicyDetails?.percentage_of_entitlement || "0"
          );
        } else if (
          state?.entitlementPolicyDetails?.limit_type === "fixed_days"
        ) {
          setNumberOfDays(
            () => state?.entitlementPolicyDetails?.fixed_number_of_days || "0"
          );
        }
      }
    }
  }, []);

  const handleAddRule = () => {
    if(inputRows.length === 0) {
      setInputRows([1])
    } else {
      setInputRows([...inputRows, inputRows[inputRows.length - 1] + 1])
    }

    setShowRules(true);
  }

  return (
    <div>
      {state?.default && (
        <DefaultEntitlementMessage>
          This is a default Entitlement policy for the Leave type{" "}
          {state.leaveName}. If you want to edit it, please edit it in the form
          given below, if you dont want to change anything, click{" "}
          <DefaultEntitlementButton onClick={() => addDefaultPolicy()}>
            here
          </DefaultEntitlementButton>{" "}
          to create a default policy with 0 days
        </DefaultEntitlementMessage>
      )}
      <H3Heading>
        {!state?.entitlementPolicyDetails && "New "}Entitlement Policy
        {state?.entitlementPolicyDetails &&
          `: ${
            state.entitlementPolicyDetails?.custom_name ||
            state.entitlementPolicyDetails?.name
          }`}
      </H3Heading>
      <CustomTextField
        title={Constant.settings.entitlementPolicy.leave_type}
        value={state?.leaveName}
        disabled={true}
        onChangeText={() => {}}
      />
      <CustomeCkeckboxField
        title={Constant.settings.entitlementPolicy.custom_name}
        value={isUseCustomName}
        onChange={() => setIsUseCustomName(() => !isUseCustomName)}
      />
      {isUseCustomName && (
        <CustomTextField
          title={Constant.settings.entitlementPolicy.custom_name_detail}
          onChangeText={setCustomName}
          value={customName}
        />
      )}
      <CustomeCkeckboxField
        title={Constant.settings.entitlementPolicy.hours_worked}
        value={isUseHoursWorked}
        onChange={() => setIsUseHoursWorked(() => !isUseHoursWorked)}
      />
      {isUseHoursWorked ? (
        <>
          <CustomNumberField
            title={Constant.settings.entitlementPolicy.leave_for_hours}
            placehoder="0"
            value={leaveForHours}
            onChangeText={setLeaveForHours}
          />
          <CustomeCkeckboxField
            title={Constant.settings.entitlementPolicy.paid_leaves_contributes}
            value={paidLeavesContribute}
            onChange={() =>
              setPaidLeavesContribute(() => !paidLeavesContribute)
            }
          />
        </>
      ) : (
        <>
          <CustomNumberField
            title={Constant.settings.entitlementPolicy.default_entitlement}
            placehoder="0"
            value={defaultEntitlement}
            onChangeText={setDefaultEntitlement}
          />
          <CustomNumberField
            title={Constant.settings.entitlementPolicy.entitlement_after}
            placehoder="0"
            value={entitlementAvailableAfter}
            onChangeText={setEntitlementAvailableAfter}
          />
          <CustomeCkeckboxField
            title={Constant.settings.entitlementPolicy.upfront_accrual}
            value={isUseUpfrontAccrual}
            onChange={() => setIsUseUpfrontAccrual(() => !isUseUpfrontAccrual)}
          />
          {/* {isUseUpfrontAccrual && (
            <CustomeCkeckboxField
              title={Constant.settings.entitlementPolicy.pro_rated}
              value={useProRated}
              onChange={() => setUseProRated(() => !useProRated)}
            />
          )} */}
        </>
      )}

      <CustomeCkeckboxField
        title={Constant.settings.entitlementPolicy.leave_carried}
        value={isAllowLeaveToBeCarried}
        onChange={() =>
          setIsAllowLeaveToBeCarried(() => !isAllowLeaveToBeCarried)
        }
      />
      {isAllowLeaveToBeCarried && (
        <>
          <CustomNumberField
            title={
              Constant.settings.entitlementPolicy.carried_fwd_leave_expires
            }
            value={leaveExpiresAfter}
            onChangeText={setLeaveExpiresAfter}
          />
          <CustomSelectField
            title={Constant.settings.entitlementPolicy.limit_type}
            value={limitType}
            onChangeOption={(value) => setLimitType(() => value)}
            option={leaveLimitTypes?.map((option) => ({
              key: option.key,
              value: option.value,
            }))}
          />
          {limitType === "percentage_balance" && (
            <CustomNumberField
              title={Constant.settings.entitlementPolicy.percentage_balance}
              placehoder="0"
              value={percentageBalance}
              onChangeText={setPercentageBalance}
            />
          )}
          {limitType === "percentage_entitlement" && (
            <CustomNumberField
              title={Constant.settings.entitlementPolicy.percentage_entitlement}
              placehoder="0"
              value={percentageEntitlement}
              onChangeText={setPercentageEntitlement}
            />
          )}
          {limitType === "fixed_days" && (
            <CustomNumberField
              title={Constant.settings.entitlementPolicy.number_days}
              placehoder="0"
              value={numberOfDays}
              onChangeText={setNumberOfDays}
            />
          )}
        </>
      )}

      {/* Add the Cycle stuff here */}

      <div
        style={{
          display: "flex",
          // justifyContent: "space-between",
          // width: "34%",
          margin: "1rem 0",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "#495057",
            fontSize: "1rem",
            fontWeight: 700,
            marginRight: "7rem",
          }}
        >
          Cycle Specific Rules
        </p>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            padding: "0.5rem 0.75rem",
            background: "rgb(3, 179, 129)",
            color: "#ffffff",
            fontWeight: 600,
            fontSize: "1rem",
            borderRadius: "0.3125rem",
            width: "20%",
            cursor: "pointer",
          }}
          onClick={handleAddRule}
        >
          <p>Add</p>
        </div>
      </div>

      {showRules && (
        <div
          style={{
            width: "60%",
            paddingLeft: "1rem",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            justifyContent: "center",
            marginBottom: "0.5rem",
            textAlign: "center"
          }}
        >
          {["First Cycle", "Last Cycle", "Entitlement", ""].map((x: string) => (
            <p style={{ fontWeight: 700, fontSize: "1rem" }}>{x}</p>
          ))}
        </div>
      )}
      {showRules &&
        inputRows.map((x: number, rowIndex) => (
          <div
            style={{
              width: "40%",
              paddingLeft: "1rem",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              
            }}
          >
            {["alpha", "bravo", "charlie", "delta"].map((z: string, index) => (
              <div
                style={{
                  width: "40%",
                  paddingLeft: "1rem",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  alignContent: "center",
                }}
              >
                {index != 3 ? (
                  <input type="text" style={{marginBottom: "0.5rem"}} />
                ) : (
                  <CustomButton
                    title={"Delete"}
                    size="small"
                    // styles={{'marginBottom': "0.5rem"}}
                    del
                    type="secondary"
                    onClick={() => navigate(-1)}
                  />
                )}
              </div>
            ))}
          </div>
        ))}

      <ButtonContainer>
        <CustomButton
          title={Constant.common.cancel}
          type="secondary"
          onClick={() => navigate(-1)}
        />
        <CustomButton
          title={Constant.common.save}
          onClick={addEntitlementPolicy}
        />
      </ButtonContainer>
    </div>
  );
};

export default AddEntitlementPolicy;
