import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { FaUserCircle } from "react-icons/fa";
import { BsQuestionCircle } from "react-icons/bs";

import "./style.css";
import { useAppSelector } from "../../_app";
import { useState } from "react";
import SubmenuModal from "../modals/SubmenuModal";
import AuthHeader from "./AuthHeader";
import Logo from "../../assets/Logo.png";

const Header = () => {
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);

  const [open, setOpen] = useState(false);

  const handleSwitchCompany = () => {
    navigate("manage-company");
  };

  return (
    <div className="header-wrapper">
      <SubmenuModal open={open} close={() => setOpen(false)} />
      <div className="section">
        <p className="company-name">
          {user.defaultCompany ? user.defaultCompany : user.name}
        </p>
        <button className="company-btn" onClick={handleSwitchCompany}>
          switch
        </button>
      </div>
      <div className="section left">
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: ".5rem",
              width: 250,
            }}
          >
            <img
              style={{ width: "80%", margin: "auto" }}
              src={Logo}
              alt="salary pay logo"
            />
          </div>
          {/* <button className="company-btn" onClick={handleSwitchCompany} title="Switch Company">
            <p className="company-name">
              {user.defaultCompany ? user.defaultCompany : user.name}
            </p>
          </button> */}
        </div>
        <div className="navbar-icon-wrap">
          <IconContext.Provider value={{ color: "#BDBDBD", size: "1.5rem" }}>
            <BsQuestionCircle />
          </IconContext.Provider>
        </div>
        <div className="navbar-icon-wrap">
          <button className="no-btn" onClick={() => setOpen((_) => !_)}>
            <IconContext.Provider value={{ color: "#BDBDBD", size: "1.5rem" }}>
              <FaUserCircle />
            </IconContext.Provider>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
export { AuthHeader };
