import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { AppSettingService } from "../../../_service";
import "./style.css";
import { Notify, Utils } from "../../../utils";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  CustomNumberField,
  CustomSelectField,
  CustomTextField,
  CustomeCkeckboxField,
} from "../../../components/customFormField";
import { CustomButton } from "../../../components/customButton";
import { CommonModal, FormErrorMessage } from "../../../components";

const AddCustomeInputFields = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [update, setUpdate] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [errorCollection, setErrorCollection] = useState<{
    [key: string]: string;
  }>({});

  const [text, setText] = useState("");
  const [inputType, setInputType] = useState("");
  const [notTaxable, setNotTaxable] = useState(false);
  const [isProRate, setIsProRate] = useState(false);
  const [diffRayeForEmp, setDiffRayeForEmp] = useState(false);
  const [excludeFromAccounting, setExcludeFromAccounting] = useState(false);

  const [rateFactor, setRateFactor] = useState("");
  const [hoursWorkedFactor, setHoursWorkedFactor] = useState("");
  const [customRate, setCustomRate] = useState("");
  const [amount, setAmount] = useState("");
  const [finalizeCount, setFinalizeCount] = useState(0);

  const [ir8aCategory, setIr8aCategory] = useState("");
  const [cpfType, setCpfType] = useState("");
  const { data: InputTypeOption } = useQuery("input_option", () =>
    AppSettingService._getCustomInputOption()
  );
  const { data: ir8aList } = useQuery("ir8a", () =>
    AppSettingService._getCustomIR8AList(location.pathname.slice(27))
  );
  const { data: cpfList } = useQuery(
    "cpfList",
    () => AppSettingService._getCustomCpfList(),
    {
      onSuccess: (data) => {
        if (!location.state.data) {
          setCpfType(data?.data?.data?.cpf_list?.[0]?.item_code);
        }
      },
    }
  );

  const updatedErrorByKey = (key: string, value: string = "") => {
    if (value === "") {
      let err = errorCollection;
      delete err[`${key}`];
      setErrorCollection(err);
    } else {
      setErrorCollection((prev) => ({ ...prev, [key]: value }));
    }
  };
  const handleErrorValidate = () => {
    const error: { [key: string]: string } = {};
    if (Utils.isEmpty(text)) {
      error.text = "Name cannot be empty";
    }
    if (Utils.isEmpty(inputType)) {
      error.inputType = "Input type cannot be empty";
    }
    if (
      inputType === "hourly_rate_*_factor_*_hours" &&
      Utils.isEmpty(rateFactor)
    ) {
      error.rateFactor = "Rate Factor cannot be empty";
    }
    if (
      !diffRayeForEmp &&
      inputType === "custom_rate_*_quantity" &&
      Utils.isEmpty(customRate)
    ) {
      error.customRate = "Custom rate cannot be empty";
    }
    if (
      location.pathname.slice(27) === "income" ||
      location.pathname.slice(27) === "allowance"
    ) {
      if (!notTaxable && Utils.isEmpty(ir8aCategory)) {
        error.ir8aCategory = "IR8A Category cannot be empty";
      }
      if (location.pathname.slice(27) === "income") {
        if (
          !notTaxable &&
          ["gross_salary_etc", "bonus", "commission"].includes(ir8aCategory)
        ) {
          if (Utils.isEmpty(cpfType) || cpfType === "na") {
            error.cpfType = `CPF Type can't be blank or 'N/A' if IR8A Category is ${
              ir8aList?.data?.data?.ir8a_list?.filter(
                (_: any) => _.item_code === ir8aCategory
              )?.[0]?.name || ""
            }`;
          }
        }
        if (notTaxable && Utils.isEmpty(cpfType)) {
          error.cpfType = `CPF Type can't be blank`;
        }
      }
      if (location.pathname.slice(27) === "allowance") {
        if (!notTaxable && ["transport", "allowance"].includes(ir8aCategory)) {
          if (Utils.isEmpty(cpfType) || cpfType === "na") {
            error.cpfType = `CPF Type can't be blank or 'N/A' if IR8A Category is ${
              ir8aList?.data?.data?.ir8a_list?.filter(
                (_: any) => _.item_code === ir8aCategory
              )?.[0]?.name || ""
            }`;
          }
        }
        if (notTaxable && Utils.isEmpty(cpfType)) {
          error.cpfType = `CPF Type can't be blank`;
        }
      }
      if (
        !diffRayeForEmp &&
        inputType === "custom_rate_*_quantity" &&
        Utils.isEmpty(customRate)
      ) {
        error.customRate = "Custom rate cannot be empty";
      }
    }
    if (location.pathname.slice(27) === "benefit") {
    }
    if (location.pathname.slice(27) === "deduction") {
    }
    if (
      location.pathname.slice(27) === "reimbursement" ||
      location.pathname.slice(27) === "employer_contribution"
    ) {
    }
    if (Object.entries(error || {}).length > 0) {
      setErrorCollection(error);
      return true;
    }
    return false;
  };
  const handleSaveData = async () => {
    if (handleErrorValidate()) {
      return;
    }
    const data = {
      name: text || "",
      input_category: location.pathname.slice(27),
      input_type: inputType,
      ...((location.pathname.slice(27) === "income" ||
        location.pathname.slice(27) === "allowance") && {
        is_non_taxable: notTaxable,
        ...(!notTaxable && {
          ir8a_category: ir8aList?.data.data.ir8a_list.filter(
            (item: any) => item.item_code === ir8aCategory
          )[0]?.id,
        }),
        ...(ir8aCategory !== "director's_fees" &&
          !Utils.isEmpty(cpfType) && {
            cpf_type: cpfList?.data.data.cpf_list.filter(
              (item: any) => item.item_code === cpfType
            )[0]?.id,
          }),
        ...(inputType === "custom_rate_*_quantity" && {
          hours_worked_factor: hoursWorkedFactor || 0,
          is_different_rate_for_every_employee: diffRayeForEmp,
          ...(!diffRayeForEmp && {
            custom_rate: customRate || 0,
          }),
        }),
        ...(inputType === "fixed_amount" && {
          amount: amount || 0,
          is_pro_rata: isProRate,
        }),
        ...(inputType === "enter_amount_per_employee" && {
          is_pro_rata: isProRate,
        }),
        ...(inputType === "hourly_rate_*_factor_*_hours" && {
          hours_worked_factor: hoursWorkedFactor || 0,
          rate_factor: rateFactor || 0,
        }),
      }),
      ...((location.pathname.slice(27) === "benefit" ||
        location.pathname.slice(27) === "employer_contribution") && {
        ...(inputType === "fixed_amount" && {
          amount: amount || 0,
        }),
        ...(inputType === "hourly_rate_*_factor_*_hours" && {
          rate_factor: rateFactor || 0,
        }),
        ...(inputType === "custom_rate_*_quantity" && {
          ...(!diffRayeForEmp && {
            custom_rate: customRate || 0,
          }),
        }),
        ...(location.pathname.slice(27) === "benefit" && {
          is_exclude_from_account: excludeFromAccounting,
        }),
      }),
      ...(location.pathname.slice(27) === "deduction" && {
        ir8a_category:
          ir8aList?.data.data.ir8a_list.filter(
            (item: any) => item.item_code === ir8aCategory
          )[0]?.id || "",
        ...(inputType === "fixed_amount" && {
          amount: amount || 0,
          is_pro_rata: isProRate,
        }),
        ...(inputType === "enter_amount_per_employee" && {
          is_pro_rata: isProRate,
        }),
        ...(inputType === "hourly_rate_*_factor_*_hours" && {
          rate_factor: rateFactor || 0,
        }),
        ...(inputType === "custom_rate_*_quantity" && {
          is_different_rate_for_every_employee: diffRayeForEmp,
          ...(!diffRayeForEmp && {
            custom_rate: customRate || 0,
          }),
        }),
      }),
      ...(location.pathname.slice(27) === "reimbursement" && {
        ...(inputType === "fixed_amount" && {
          amount: amount || 0,
        }),
        ...(inputType === "hourly_rate_*_factor_*_hours" && {
          rate_factor: rateFactor || 0,
        }),
        ...(inputType === "custom_rate_*_quantity" && {
          is_different_rate_for_every_employee: diffRayeForEmp,
          ...(!diffRayeForEmp && {
            custom_rate: customRate || 0,
          }),
        }),
      }),
    };

    try {
      if (update) {
        await AppSettingService._updateCustomInputs(
          location?.state?.data?.id || 0,
          data
        );
        Notify("Data updated successfully", 1);
        navigate(-1);
      } else {
        await AppSettingService._addCustomInputs(data);
        Notify("Data updated successfully", 1);
        navigate(-1);
      }
    } catch (e: any) {
      Notify("Data updated failed", 0);
      console.error(e?.response?.data);
    }
  };
  const [openModal, setOpenModal] = useState(false);
  const handleDeleteInput = () => {
    setOpenModal(true);
  };
  const handleDataItemDelete = async () => {
    try {
      await AppSettingService._deleteCustomInputs(
        location?.state?.data?.id || 0
      );
      Notify("Data deleted successfully", 1);
      navigate(-1);
    } catch (e: any) {
      Notify("Data delete failed", 0);
      console.error(e?.response?.data);
    }
  };
  const setData = () => {
    const _data = location.state.data;
    if (_data) {
      setText(_data?.name || "");
      setInputType(_data?.input_type || "");
      setNotTaxable(_data?.is_non_taxable ? true : false);
      setIr8aCategory(_data?.ir8a_category?.item_code || "");
      setCpfType(_data?.cpf_type?.item_code || "");
      setIsProRate(_data?.is_pro_rata ? true : false);
      setHoursWorkedFactor(_data?.hours_worked_factor || 0);
      setDiffRayeForEmp(
        _data?.is_different_rate_for_every_employee ? true : false
      );
      setRateFactor(_data?.rate_factor || 0);
      setCustomRate(_data?.custom_rate || 0);
      setFinalizeCount(_data?.count_of_finalized_payslip || 0);
      setAmount(_data?.amount || "");
      setExcludeFromAccounting(_data?.is_exclude_from_account ? true : false);
    }
  };
  useEffect(() => {
    if (location?.state?.data) {
      setUpdate(true);
      setData();
    }
  }, []);

  const TooltipText = () => {
    return (
      <div style={{ margin: "1rem 2rem" }}>
        <ol>
          <li>
            Custom rate * quantity: This input comes under both Regular Input
            (add Rate) and Payslip Input (to add quantity)
          </li>
          <br />
          <li>
            Fixed Amount: The amount given in custom input will directly reflect
            in the payslip for all employees if this item is added from regular
            input
          </li>
          <br />
          <li>
            Enter Amount per employee: Regular input, every employee can be
            given a different amount, unlike Fixed Amount
          </li>
          <br />
          <li>
            Different on every payslip: For each employee/every month, you will
            add an amount in the Payslip input. (Regular input will repeat each
            month)
          </li>
          <br />

          <li>
            Once-off: For each employee/every month, you can add a payslip
            input. (No entry in regular input)
          </li>
          <br />
          <li>
            Hourly_rate * factor * hour: This input comes under both Regular
            Input and Payslip Input (to add the number of hours)
          </li>
        </ol>
      </div>
    );
  };

  return (
    <div>
      <h1 style={{ color: "#476575" }}>
        {update ? "Edit" : "Add"} Custom{" "}
        {location?.state?.name || location?.state || ""}
      </h1>
      <FormErrorMessage name="custom input" errorCollection={errorCollection} />
      <CommonModal
        handleClose={() => setOpenModal(false)}
        open={openModal}
        updateData={handleDataItemDelete}
        body={`Are you sure you want to delete the custom ${
          location?.state?.name || location?.state || ""
        }`}
      />
      <div style={{ marginTop: "1rem" }}>
        {location.pathname.slice(27) === "income" && (
          <div>
            <CustomTextField
              title="Name"
              value={text}
              onChangeText={setText}
              error={errorCollection.text}
              require
              onFocus={() => {
                updatedErrorByKey("text");
              }}
            />
            <CustomSelectField
              title="Input Type"
              value={inputType}
              onChangeOption={setInputType}
              empty
              option={InputTypeOption?.data.data.input_list.map(
                (item: { item_code: string; item_name: string }) => ({
                  key: item.item_name,
                  value: item.item_code,
                })
              )}
              error={errorCollection.inputType}
              require
              onFocus={() => {
                updatedErrorByKey("inputType");
              }}
              hasTooltip={true}
              tooltipText={<TooltipText />}
            />
            {inputType === "fixed_amount" && (
              <CustomNumberField
                title="Amount"
                value={amount}
                onChangeText={setAmount}
              />
            )}
            {(inputType === "enter_amount_per_employee" ||
              inputType === "fixed_amount") && (
              <div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: ".5rem",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  {finalizeCount > 0 ? (
                    <div>
                      Pro-rata is {isProRate ? "enabled" : "disabled"} for this
                      custom item.
                    </div>
                  ) : (
                    <CustomeCkeckboxField
                      title="Enable Pro-Rata"
                      value={isProRate}
                      onChange={() => setIsProRate((_) => !_)}
                      note="Can't be changed if used on a finalised payslip."
                    />
                  )}
                </div>
              </div>
            )}
            {inputType === "custom_rate_*_quantity" && (
              <div>
                <CustomNumberField
                  title="Hours worked factor"
                  value={hoursWorkedFactor}
                  onChangeText={setHoursWorkedFactor}
                  note="This should be 1 for most cases"
                  placehoder="1"
                />
                <CustomeCkeckboxField
                  title="Different rate for every employee"
                  value={diffRayeForEmp}
                  onChange={() => setDiffRayeForEmp((_) => !_)}
                />
                {!diffRayeForEmp && (
                  <CustomNumberField
                    title="Custom rate"
                    value={customRate}
                    onChangeText={setCustomRate}
                    require
                    error={errorCollection.customRate}
                    onFocus={() => updatedErrorByKey("customRate")}
                  />
                )}
              </div>
            )}
            {inputType === "hourly_rate_*_factor_*_hours" && (
              <div>
                <CustomNumberField
                  title="Rate factor"
                  value={rateFactor}
                  onChangeText={setRateFactor}
                  note="e.g. 1.5 for 1.5x hourly rate"
                  placehoder="1.5"
                  require
                  error={errorCollection.rateFactor}
                  onFocus={() => updatedErrorByKey("rateFactor")}
                />
                <CustomNumberField
                  title="Hours worked factor"
                  value={hoursWorkedFactor}
                  onChangeText={setHoursWorkedFactor}
                  note="This should be 1 for most cases"
                  placehoder="1"
                />
              </div>
            )}
            <CustomeCkeckboxField
              title="Non Taxable"
              value={notTaxable}
              onChange={() => setNotTaxable((_) => !_)}
            />
            {!notTaxable && (
              <CustomSelectField
                title="IR8A Category"
                value={ir8aCategory}
                onChangeOption={setIr8aCategory}
                empty
                option={ir8aList?.data.data.ir8a_list.map(
                  (item: { item_code: string; name: string; id: string }) => ({
                    key: item.name,
                    value: item.item_code,
                  })
                )}
                require
                error={errorCollection.ir8aCategory}
                onFocus={() => {
                  updatedErrorByKey("ir8aCategory");
                }}
              />
            )}
            {ir8aCategory !== "director's_fees" &&
              (!Utils.isEmpty(ir8aCategory) || notTaxable) && (
                <CustomSelectField
                  title="CPF Type"
                  value={cpfType}
                  onChangeOption={setCpfType}
                  option={cpfList?.data.data.cpf_list.map(
                    (item: {
                      item_code: string;
                      item_name: string;
                      id: string;
                    }) => ({ key: item.item_name, value: item.item_code })
                  )}
                  require
                  error={errorCollection.cpfType}
                  onFocus={() => {
                    updatedErrorByKey("cpfType");
                  }}
                />
              )}
          </div>
        )}
        {location.pathname.slice(27) === "deduction" && (
          <div>
            <CustomTextField
              title="Name"
              value={text}
              onChangeText={setText}
              require
              error={errorCollection.text}
              onBlur={() => updatedErrorByKey("text")}
            />
            <CustomSelectField
              title="Input Type"
              value={inputType}
              onChangeOption={setInputType}
              empty
              option={InputTypeOption?.data.data.input_list.map(
                (item: { item_code: string; item_name: string }) => ({
                  key: item.item_name,
                  value: item.item_code,
                })
              )}
              require
              error={errorCollection.inputType}
              onBlur={() => updatedErrorByKey("inputType")}
              hasTooltip={true}
              tooltipText={<TooltipText />}
            />
            {inputType === "fixed_amount" && (
              <CustomNumberField
                title="Amount"
                value={amount}
                onChangeText={setAmount}
              />
            )}
            {(inputType === "enter_amount_per_employee" ||
              inputType === "fixed_amount") && (
              <div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: ".5rem",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  {finalizeCount > 0 ? (
                    <div>
                      Pro-rata is {isProRate ? "enabled" : "disabled"} for this
                      custom item.
                    </div>
                  ) : (
                    <CustomeCkeckboxField
                      title="Enable Pro-Rata"
                      value={isProRate}
                      onChange={() => setIsProRate((_) => !_)}
                      note="Can't be changed if used on a finalised payslip."
                    />
                  )}
                </div>
              </div>
            )}

            {inputType === "custom_rate_*_quantity" && (
              <div>
                <CustomeCkeckboxField
                  title="Different rate for every employee"
                  value={diffRayeForEmp}
                  onChange={() => setDiffRayeForEmp((_) => !_)}
                />
                {!diffRayeForEmp && (
                  <CustomNumberField
                    title="Custom rate"
                    value={customRate}
                    onChangeText={setCustomRate}
                    require
                    error={errorCollection.customRate}
                    onFocus={() => updatedErrorByKey("customRate")}
                  />
                )}
              </div>
            )}

            {inputType === "hourly_rate_*_factor_*_hours" && (
              <div>
                <CustomNumberField
                  title="Rate factor"
                  value={rateFactor}
                  onChangeText={setRateFactor}
                  note="e.g. 1.5 for 1.5x hourly rate"
                  placehoder="1.5"
                  require
                  error={errorCollection.rateFactor}
                  onFocus={() => updatedErrorByKey("rateFactor")}
                />
                {/* <CustomNumberField
                  title="Hours worked factor"
                  value={hoursWorkedFactor}
                  onChangeText={setHoursWorkedFactor}
                  note="This should be 1 for most cases"
                  placehoder="1"
                /> */}
              </div>
            )}
            <CustomSelectField
              title="IR8A Category"
              value={ir8aCategory}
              onChangeOption={setIr8aCategory}
              empty
              option={ir8aList?.data.data.ir8a_list.map(
                (item: { item_code: string; name: string; id: string }) => ({
                  key: item.name,
                  value: item.item_code,
                })
              )}
              note="Optional, only if included in IR8A."
            />
          </div>
        )}
        {location.pathname.slice(27) === "allowance" && (
          <div>
            <CustomTextField
              title="Name"
              value={text}
              onChangeText={setText}
              require
              error={errorCollection.text}
              onBlur={() => updatedErrorByKey("text")}
            />
            <CustomSelectField
              title="Input Type"
              value={inputType}
              onChangeOption={setInputType}
              empty
              option={InputTypeOption?.data.data.input_list.map(
                (item: { item_code: string; item_name: string }) => ({
                  key: item.item_name,
                  value: item.item_code,
                })
              )}
              require
              error={errorCollection.inputType}
              onBlur={() => updatedErrorByKey("inputType")}
              hasTooltip={true}
              tooltipText={<TooltipText />}
            />
            {inputType === "fixed_amount" && (
              <CustomNumberField
                title="Amount"
                value={amount}
                onChangeText={setAmount}
              />
            )}
            {(inputType === "enter_amount_per_employee" ||
              inputType === "fixed_amount") && (
              <div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: ".5rem",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  {finalizeCount > 0 ? (
                    <div>
                      Pro-rata is {isProRate ? "enabled" : "disabled"} for this
                      custom item.
                    </div>
                  ) : (
                    <CustomeCkeckboxField
                      title="Enable Pro-Rata"
                      value={isProRate}
                      onChange={() => setIsProRate((_) => !_)}
                      note="Can't be changed if used on a finalised payslip."
                    />
                  )}
                </div>
              </div>
            )}

            {inputType === "custom_rate_*_quantity" && (
              <div>
                <CustomNumberField
                  title="Hours worked factor"
                  value={hoursWorkedFactor}
                  onChangeText={setHoursWorkedFactor}
                  note="This should be 1 for most cases"
                  placehoder="1"
                />
                <CustomeCkeckboxField
                  title="Different rate for every employee"
                  value={diffRayeForEmp}
                  onChange={() => setDiffRayeForEmp((_) => !_)}
                />
                {!diffRayeForEmp && (
                  <CustomNumberField
                    title="Custom rate"
                    value={customRate}
                    onChangeText={setCustomRate}
                    require
                    error={errorCollection.customRate}
                    onFocus={() => updatedErrorByKey("customRate")}
                  />
                )}
              </div>
            )}
            {inputType === "hourly_rate_*_factor_*_hours" && (
              <div>
                <CustomNumberField
                  title="Rate factor"
                  value={rateFactor}
                  onChangeText={setRateFactor}
                  note="e.g. 1.5 for 1.5x hourly rate"
                  placehoder="1.5"
                  require
                  error={errorCollection.rateFactor}
                  onFocus={() => updatedErrorByKey("rateFactor")}
                />
                <CustomNumberField
                  title="Hours worked factor"
                  value={hoursWorkedFactor}
                  onChangeText={setHoursWorkedFactor}
                  note="This should be 1 for most cases"
                  placehoder="1"
                />
              </div>
            )}
            <CustomeCkeckboxField
              title="Non Taxable"
              value={notTaxable}
              onChange={() => setNotTaxable((_) => !_)}
            />
            {!notTaxable && (
              <CustomSelectField
                title="IR8A Category"
                value={ir8aCategory}
                onChangeOption={setIr8aCategory}
                empty
                option={ir8aList?.data.data.ir8a_list.map(
                  (item: { item_code: string; name: string; id: string }) => ({
                    key: item.name,
                    value: item.item_code,
                  })
                )}
                error={errorCollection.ir8aCategory}
                require
                onBlur={() => updatedErrorByKey("ir8aCategory")}
              />
            )}
            <CustomSelectField
              title="CPF Type"
              value={cpfType}
              onChangeOption={setCpfType}
              option={cpfList?.data.data.cpf_list.map(
                (item: {
                  item_code: string;
                  item_name: string;
                  id: string;
                }) => ({ key: item.item_name, value: item.item_code })
              )}
              error={errorCollection.cpfType}
              require
              onBlur={() => updatedErrorByKey("cpfType")}
            />
          </div>
        )}
        {location.pathname.slice(27) === "benefit" && (
          <div>
            <CustomTextField
              title="Name"
              value={text}
              onChangeText={setText}
              require
              error={errorCollection.text}
              onBlur={() => updatedErrorByKey("text")}
            />
            <CustomSelectField
              title="Input Type"
              value={inputType}
              onChangeOption={setInputType}
              empty
              option={InputTypeOption?.data.data.input_list.map(
                (item: { item_code: string; item_name: string }) => ({
                  key: item.item_name,
                  value: item.item_code,
                })
              )}
              require
              error={errorCollection.inputType}
              onBlur={() => updatedErrorByKey("inputType")}
            />
            {inputType === "fixed_amount" && (
              <CustomNumberField
                title="Amount"
                value={amount}
                onChangeText={setAmount}
              />
            )}
            {inputType === "custom_rate_*_quantity" && (
              <div>
                <CustomeCkeckboxField
                  title="Different rate for every employee"
                  value={diffRayeForEmp}
                  onChange={() => setDiffRayeForEmp((_) => !_)}
                />
                {!diffRayeForEmp && (
                  <CustomNumberField
                    title="Custom rate"
                    value={customRate}
                    onChangeText={setCustomRate}
                    require
                    error={errorCollection.customRate}
                    onFocus={() => updatedErrorByKey("customRate")}
                  />
                )}
              </div>
            )}
            {inputType === "hourly_rate_*_factor_*_hours" && (
              <CustomNumberField
                title="Rate factor"
                value={rateFactor}
                onChangeText={setRateFactor}
                note="e.g. 1.5 for 1.5x hourly rate"
                placehoder="1.5"
                require
                error={errorCollection.rateFactor}
                onFocus={() => updatedErrorByKey("rateFactor")}
              />
            )}
            <CustomeCkeckboxField
              title="Exclude from Accounting"
              value={excludeFromAccounting}
              onChange={() => setExcludeFromAccounting((_) => !_)}
            />
          </div>
        )}
        {location.pathname.slice(27) === "employer_contribution" && (
          <div>
            <CustomTextField
              title="Name"
              value={text}
              onChangeText={setText}
              require
              error={errorCollection.text}
              onBlur={() => updatedErrorByKey("text")}
            />
            <CustomSelectField
              title="Input Type"
              value={inputType}
              onChangeOption={setInputType}
              empty
              option={InputTypeOption?.data.data.input_list.map(
                (item: { item_code: string; item_name: string }) => ({
                  key: item.item_name,
                  value: item.item_code,
                })
              )}
              require
              error={errorCollection.inputType}
              onBlur={() => updatedErrorByKey("inputType")}
              hasTooltip={true}
              tooltipText={<TooltipText />}
            />
            {inputType === "fixed_amount" && (
              <CustomNumberField
                title="Amount"
                value={amount}
                onChangeText={setAmount}
              />
            )}
            {inputType === "custom_rate_*_quantity" && (
              <div>
                <CustomeCkeckboxField
                  title="Different rate for every employee"
                  value={diffRayeForEmp}
                  onChange={() => setDiffRayeForEmp((_) => !_)}
                />
                {!diffRayeForEmp && (
                  <CustomNumberField
                    title="Custom rate"
                    value={customRate}
                    onChangeText={setCustomRate}
                    require
                    error={errorCollection.customRate}
                    onFocus={() => updatedErrorByKey("customRate")}
                  />
                )}
              </div>
            )}
            {inputType === "hourly_rate_*_factor_*_hours" && (
              <CustomNumberField
                title="Rate factor"
                value={rateFactor}
                onChangeText={setRateFactor}
                note="e.g. 1.5 for 1.5x hourly rate"
                placehoder="1.5"
                require
                error={errorCollection.rateFactor}
                onFocus={() => updatedErrorByKey("rateFactor")}
              />
            )}
          </div>
        )}
        {location.pathname.slice(27) === "reimbursement" && (
          <div>
            <CustomTextField
              title="Name"
              value={text}
              onChangeText={setText}
              require
              error={errorCollection.text}
              onBlur={() => updatedErrorByKey("text")}
            />
            <CustomSelectField
              title="Input Type"
              value={inputType}
              onChangeOption={setInputType}
              empty
              option={InputTypeOption?.data.data.input_list.map(
                (item: { item_code: string; item_name: string }) => ({
                  key: item.item_name,
                  value: item.item_code,
                })
              )}
              require
              error={errorCollection.inputType}
              onBlur={() => updatedErrorByKey("inputType")}
              hasTooltip={true}
              tooltipText={<TooltipText />}
            />
            {inputType === "fixed_amount" && (
              <CustomNumberField
                title="Amount"
                value={amount}
                onChangeText={setAmount}
              />
            )}
            {inputType === "custom_rate_*_quantity" && (
              <div>
                <CustomeCkeckboxField
                  title="Different rate for every employee"
                  value={diffRayeForEmp}
                  onChange={() => setDiffRayeForEmp((_) => !_)}
                />
                {!diffRayeForEmp && (
                  <CustomNumberField
                    title="Custom rate"
                    value={customRate}
                    onChangeText={setCustomRate}
                    require
                    error={errorCollection.customRate}
                    onFocus={() => updatedErrorByKey("customRate")}
                  />
                )}
              </div>
            )}
            {inputType === "hourly_rate_*_factor_*_hours" && (
              <CustomNumberField
                title="Rate factor"
                value={rateFactor}
                onChangeText={setRateFactor}
                note="e.g. 1.5 for 1.5x hourly rate"
                placehoder="1.5"
                require
                error={errorCollection.rateFactor}
                onFocus={() => updatedErrorByKey("rateFactor")}
              />
            )}
          </div>
        )}
      </div>
      <div style={{ marginTop: "1rem", marginBottom: "1rem", display: "flex" }}>
        <CustomButton
          title="Cancel"
          type="secondary"
          onClick={() => {
            navigate(-1);
          }}
        />
        <CustomButton title="Save" onClick={handleSaveData} />
      </div>
      {update && (
        <>
          <hr style={{ marginTop: "1.5rem", marginBottom: "1rem" }} />
          <div style={{ alignItems: "center" }}>
            <p style={{ fontSize: "1rem" }}>
              {finalizeCount > 0
                ? `This custom item has been used on ${finalizeCount} finalised payslip.`
                : `This custom item has not been used on any finalised payslips.`}
            </p>
            <div style={{ marginTop: "1rem" }}>
              <CustomButton
                title="Delete"
                type="secondary"
                del
                onClick={handleDeleteInput}
                icon={<RiDeleteBinLine />}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddCustomeInputFields;
