import React from "react";
import { IconContext } from "react-icons";
import { GrFormClose } from "react-icons/gr";
import styled from "styled-components";

interface SearchFieldProps {
  value: string;
  onChangeText: (e: string) => void;
}

const SearchField = ({ onChangeText, value }: SearchFieldProps) => {
  return (
    <Container>
      <Input
        type="text"
        placeholder="Search"
        value={value}
        onChange={(e) => onChangeText(e.target.value)}
      />
      <IconWrap onClick={() => onChangeText("")}>
        <IconContext.Provider value={{ color: "#495057", size: "25px" }}>
          <GrFormClose />
        </IconContext.Provider>
      </IconWrap>
    </Container>
  );
};

export default SearchField;

const Container = styled.div`
  margin-bottom: 1rem;
  margin-left: 1rem;
  display: flex;
`;
const Input = styled.input`
  width: 260px;
  color: #495057;
  border: 1px solid #ced4da;
  font: 1rem;
  background-color: #fff;
  text-transform: none;
  display: flex;
  align-items: center;
  padding: 0 5px;
  border-radius: 3px 0 0 3px;
`;
const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ced4da;
  width: 30px;
  background-color: #fff;
  cursor: pointer;
`;
