import { TableHeaderColumn, TableHeaderContainer } from "./TableHeaderStyles";

interface TableHeaderData {
  name?: string;
  width?: string;
}

interface TableHeaderProps {
  headerColumns: TableHeaderData[];
  variant?: "filled" | "outline";
  align?: "left" | "center" | "right";
  styles?:{}
}

const TableHeader = (props: TableHeaderProps) => {
  const { headerColumns, variant = "outline", align = "left", styles } = props;
  return (
    <TableHeaderContainer variant={variant}>
      {headerColumns.map((header) => (
        <TableHeaderColumn
          style={{ width: header?.width || "auto", textAlign: align, ...styles }}
          variant={variant}
        >
          {header?.name || ""}
        </TableHeaderColumn>
      ))}
    </TableHeaderContainer>
  );
};

export default TableHeader;
