import React from "react";
import styled from "styled-components";
import Require from "./Require";
import { Container, Error, Label } from "./Styles";
interface NumberFieldProps {
  title?: string;
  value: string;
  onChangeText: (e: string) => void;
  placehoder?: string;
  width?: string;
  error?: string;
  require?: boolean;
  note?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  removeNumIcon?: boolean;
  marginTop?: string;
  disabled?: boolean;
  isPositiveNumber?: boolean;
  isColumn?: boolean;
  inputWidth?: string;
}
const NumberField = ({
  onChangeText,
  title,
  value,
  placehoder = value || "0.00",
  note = "",
  width = "100%",
  error,
  require = false,
  onBlur,
  onFocus,
  removeNumIcon = false,
  marginTop = ".8rem",
  disabled = false,
  isPositiveNumber = false,
  isColumn = false,
  inputWidth = "",
}: NumberFieldProps) => {
  // value = value === "0.00" || value === "0" ? "" : value;
  value = value === "0.00" ? "" : value;
  return (
    <Container margin={marginTop} isColumn={isColumn}>
      {title && (
        <Label isColumn={isColumn}>
          {title}
          {require && <Require />}
        </Label>
      )}
      <div style={{ width: inputWidth || "60%" }}>
        <Input
          value={value}
          disabled={disabled}
          placeholder={placehoder || ""}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={(e: any) => {
            let isNum = isPositiveNumber
              ? /^\d*\.?\d*$/.test(e.target.value)
              : /^-?\d+(\.?\d*)?$/.test(e.target.value);
            if (
              isNum ||
              e.target.value.length === 0 ||
              (e.target.value.length === 1 && e.target.value === "-")
            ) {
              onChangeText(e.target.value);
            }
          }}
          removeNumIcon={removeNumIcon}
          width={width}
        />
        {error ? (
          <Error>{error || ""}</Error>
        ) : (
          note && <Error color="grey">{note}</Error>
        )}
      </div>
    </Container>
  );
};

export default NumberField;

const Input = styled.input<any>`
  width: ${(props) => props.width};
  border-radius: 0.2rem;
  -webkit-appearance: none;
  -moz-appearance: textfield;

  &:disabled {
    background: #e6e6e6;
  }
`;
