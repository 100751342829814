import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../_app";

const activeStyle = {
  color: "#495057 !important",
  borderBottom: "3px solid #25b481",
};

const EmployeeHeader = () => {
  const permission = useAppSelector((state) => state.user.permission);
  return (
    <div className="employee-header">
      <NavLink
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className="employee-sublink"
        to="/dashboard/employee-payroll"
      >
        Current Payroll
      </NavLink>

      <NavLink
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className="employee-sublink"
        to="/dashboard/employee-list"
      >
        Employees List
      </NavLink>

      {permission === "full" && (
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="/dashboard/add-employee"
        >
          Add New Employees
        </NavLink>
      )}

      <NavLink
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className="employee-sublink"
        to="/dashboard/self-service/employee-users"
      >
        Self Service
      </NavLink>
      <NavLink
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className="employee-sublink"
        to="/dashboard/leave-overview"
      >
        Leave Overview
      </NavLink>
    </div>
  );
};

export default EmployeeHeader;
