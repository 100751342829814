import { FaDownload } from "react-icons/fa";
import {
  CommonModal,
  CustomButton,
  CustomSelectField,
} from "../../../components";
import {
  CardButtonContainer,
  CardContainer,
  CardHeadingContainer,
  CardHeadingWrapper,
  CardWrapper,
  RequestDetail,
  RequestDetailContainer,
} from "./ViewRequestStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { Notify, Utils } from "../../../utils";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableHeader } from "../../../components/table";
import { TableRow } from "../../payslip/PayslipStyles";
import { TableColumn } from "../../users/styles";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../_app";
import { DashboardService } from "../../../_service/DashboardService";
import { Constant } from "../../../config";
import moment from "moment";

const ViewRequest = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [isStartModal, setIsStartModal] = useState<boolean>(false);
  const [isNextStageModal, setIsNextStageModal] = useState<boolean>(false);
  const [isCompleteModal, setIsCompleteModal] = useState<boolean>(false);
  const [showCompleteStatus, setShowCompleteStatus] = useState<boolean>(false);
  const user = useAppSelector((state) => state.user);
  const [nextStage, setNextStage] = useState<string>("");
  const [isStarted, setIsStarted] = useState<boolean>(false);

  const handleDownloadClick = (fileUrl: any) => {
    const a = document.createElement("a");

    a.href = fileUrl;
    a.download = fileUrl;

    a.click();
    a.remove();
  };

  const tableHeaders = [
    {
      name: "Field Name",
      width: "30%",
    },
    {
      name: "Old Value",
      width: "30%",
    },
    {
      name: "New Value",
      width: "30%",
    },
  ];

  const resignationTableHeaders = [
    {
      name: "Field",
      width: "40%",
    },
    {
      name: "Value",
      width: "60%",
    },
  ];

  const stages = ["CSO", "DataEntry", "Approver", "Finalizer"];

  const handleStartClick = async () => {
    try {
      const { status } = await DashboardService._startRequest(
        state.data.id,
        state.data?.stage
      );
      if (status === 200) {
        Notify("Request Status Updated Successfully", 1);
        setIsStarted(() => true);
        setIsStartModal(() => false);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const handleCompletedClick = async () => {
    try {
      const { status } = await DashboardService._completeRequest(
        state.data.id,
        "Completed"
      );
      if (status === 200) {
        setShowCompleteStatus(true);
        setIsCompleteModal(false);
        navigate("/dashboard/request/completed")
        Notify("Request Completed Successfully", 1);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const handleNextStageClick = async () => {
    try {
      const { status } = await DashboardService._moveRequest(
        state.data.id,
        nextStage
      );
      if (status === 200) {
        Notify(`Request moved to ${nextStage} successfully`, 1);
        navigate(-1);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  useEffect(()=>{
    if(state?.data?.stage === "DataEntry"){
      setNextStage('Approver');
    }
  },[])

  return (
    <div>
      <CardWrapper>
        <CardHeadingWrapper>
          <CardHeadingContainer>Card Assignment</CardHeadingContainer>
          <CardHeadingContainer style={{ color: "#dc3545" }}>
            {state?.data?.status !== "Completed" && <div style={{ display: "flex", alignItems: "center", gap: "1rem", }}>
              {(user.groups.includes(state.data.stage) ||
                user.groups.length === 0) &&
                (state.data?.claimed_by !== null || isStarted) &&
                <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  padding: "0.25rem 0.75rem",
                  background: "#ff6665", 
                  // background: "rgb(3, 179, 129)", 
                  color: "#ffffff", 
                  fontWeight: 600,
                  borderRadius: "0.3125rem"
                }}
              >
                <p>Move to next stage</p>
              </div>}
              {/* {state?.data?.stage} */}
              <CardButtonContainer>
                {(user.groups.includes(state.data.stage) ||
                  user.groups.length === 0) &&
                  state.data?.claimed_by === null &&
                  !isStarted && (
                    <CustomButton
                      title="Start"
                      width="100px"
                      onClick={() => setIsStartModal(() => true)}
                    />
                  )}
                {(user.groups.includes(state.data.stage) ||
                  user.groups.length === 0) &&
                  (state.data?.claimed_by !== null || isStarted) && (
                    <CustomSelectField
                      margin="0rem"
                      placeholder={`${state?.data?.stage}`}
                      value={nextStage}
                      onChangeOption={(value: any) => setNextStage(value)}
                      option={stages
                        .filter((stage) => stage !== state.data.stage)
                        .map((stage) => ({
                          key: stage,
                          value: stage,
                        }))}
                      width="150px"
                      disabled={state?.data?.status === "Completed" || showCompleteStatus}
                    />
                  )}
                {nextStage !== "" && (isStarted || state?.data?.claimed_by) && (
                  <CustomButton
                    title="Confirm"
                    width="100px"
                    onClick={() => setIsNextStageModal(() => true)}
                  />
                )}
                {/* <CustomButton
                  type="secondary"
                  title="Back"
                  width="100px"
                  onClick={() => navigate(-1)}
                /> */}
              </CardButtonContainer>
            </div>}
          </CardHeadingContainer>
        </CardHeadingWrapper>
        <CardContainer>
          <RequestDetailContainer>
            <RequestDetail>
              <p>
                <strong>Company Name:</strong>
              </p>
              <p>{state?.data?.company_name || ""}</p>
            </RequestDetail>
            <RequestDetail>
              <p>
                <strong>Card ID:</strong>
              </p>
              <p>{state?.data?.card_id || `CARD00${state?.data?.id}`}</p>
            </RequestDetail>
            <RequestDetail>
              <p>
                <strong>Request Type:</strong>
              </p>
              <p>
                {Object.keys(state?.data?.request_type).map(
                  (key) => state?.data?.request_type[key] || ""
                )}
              </p>
            </RequestDetail>
            <RequestDetail>
              <p>
                <strong>Created on:</strong>
              </p>
              {/* <p>{Utils._dateToShow(state?.data?.created_at)  || ""}</p> */}
              <p>{ moment(state?.data?.created_at).format('DD/MM/YYYY hh:mm A')  || ""}</p>
            </RequestDetail>
          </RequestDetailContainer>
          <RequestDetailContainer>
            <RequestDetail>
              <p>
                <strong>Contact Person:</strong>
              </p>
              <p>{state?.data?.name || ""}</p>
            </RequestDetail>
            <RequestDetail>
              <p>
                <strong>Contact Number:</strong>
              </p>
              <p>{state?.data?.contact_number || "N/A"}</p>
            </RequestDetail>
            <RequestDetail>
              <p>
                <strong>Email Address:</strong>
              </p>
              <p>{state?.data?.email || "N/A"}</p>
            </RequestDetail>
            <RequestDetail>
              <p>
                <strong>Completed on:</strong>
              </p>
              <p>{ state?.data?.status.toLowerCase() === "completed" ? moment(state?.data?.updated_at).format('DD/MM/YYYY hh:mm A') : ""}</p>
            </RequestDetail>
          </RequestDetailContainer>
          {state?.data?.message && (
            <RequestDetail style={{ padding: 20 }}>
              <p>
                <strong>Description/Notes:</strong>
              </p>
              <div
                dangerouslySetInnerHTML={{
                  __html: state?.data?.message || "<p>N/A</p>",
                }}
              />
            </RequestDetail>
          )}

          {state?.data?.file_upload && (
            <RequestDetailContainer>
              <RequestDetail>
                <p>
                  <strong>File:</strong>
                </p>
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <CustomButton
                    onClick={() =>
                      handleDownloadClick(state?.data?.file_upload)
                    }
                    title=""
                    icon={<FaDownload />}
                    width="30px"
                    size="small"
                  />
                  <p>{state?.data?.file_upload.split("/").pop()}</p>
                </div>
              </RequestDetail>
            </RequestDetailContainer>
          )}
          {state?.data?.json_data &&
            Object.keys(state?.data?.json_data).length !== 0 &&
            Object.keys(state?.data?.request_type)[0] === "CP" && (
              <RequestDetail style={{ padding: 20 }}>
                <p>
                  <strong>Data for Changes in Payroll of employee:</strong>
                </p>
                <p>
                  <span>
                    <strong>Employee Name: </strong>
                    {state?.data?.json_data
                      ? state?.data?.json_data[0]["employee_name"]
                      : ""}
                    ,{" "}
                  </span>{" "}
                  <span>
                    <strong>Employee Number: </strong>
                    {state?.data?.json_data
                      ? state?.data?.json_data[0]["employee_number"]
                      : ""}
                  </span>
                  {state?.data?.json_data ? (
                    <span>
                      <strong>Payslip Date: </strong>
                      {state?.data?.json_data
                        ? state?.data?.json_data[0]["payslip_date"]
                        : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
                <IRASTable style={{ width: "60%" }}>
                  <TableHeader headerColumns={tableHeaders} variant="filled" />
                  <tbody>
                    {state?.data?.json_data.map(
                      (data: any, index: any) =>
                        index !== 0 && (
                          <TableRow>
                            <TableColumn>
                              <p>
                                <strong>{data.name}</strong>
                              </p>
                              <p style={{ marginTop: 10 }}>
                                {data?.field_name}
                              </p>
                            </TableColumn>
                            <TableColumn>
                              <p style={{ marginTop: 20 }}>
                                {data.is_amount
                                  ? Utils.prependDollar(data.value)
                                  : data.value}
                              </p>
                            </TableColumn>
                            <TableColumn>
                              <p style={{ marginTop: 20 }}>
                                {data.is_amount
                                  ? Utils.prependDollar(data.new_value)
                                  : data.new_value}
                              </p>
                            </TableColumn>
                          </TableRow>
                        )
                    )}
                  </tbody>
                </IRASTable>
              </RequestDetail>
            )}
          {state?.data?.json_data &&
            Object.keys(state?.data?.json_data).length !== 0 &&
            Object.keys(state?.data?.request_type)[0] === "ES" && (
              <RequestDetail style={{ padding: 20 }}>
                <p>
                  <strong>
                    {" "}
                    {Object.keys(state?.data?.request_type)[0] === "ES"
                      ? "Data for End service of employee"
                      : "Data for Changes in Payroll of employee"}
                  </strong>
                </p>
                <IRASTable style={{ width: "60%" }}>
                  <TableHeader
                    headerColumns={resignationTableHeaders}
                    variant="filled"
                  />
                  <tbody>
                    {state.data.json_data.map(
                      (data: any, index: any) =>
                        index !== 0 && (
                          <TableRow>
                            <TableColumn>
                              <strong>{data.name}</strong>
                            </TableColumn>
                            <TableColumn>{data.value}</TableColumn>
                          </TableRow>
                        )
                    )}
                  </tbody>
                </IRASTable>
              </RequestDetail>
            )}
          {state?.data?.json_data &&
            Object.keys(state?.data?.json_data).length !== 0 &&
            Object.keys(state?.data?.request_type)[0] === "IU" && (
              <RequestDetail style={{ padding: 20 }}>
                <p>
                  <strong>
                    {" "}
                    {Object.keys(state?.data?.request_type)[0] === "IU"
                      ? "Data for Info Update of Employee"
                      : "Data for Changes in Payroll of employee"}
                  </strong>
                </p>
                <IRASTable style={{ width: "60%" }}>
                  <TableHeader headerColumns={tableHeaders} variant="filled" />
                  <tbody>
                    {state.data.json_data.map(
                      (data: any, index: any) =>
                        index !== 0 &&
                        index !== 1 && (
                          <TableRow>
                            <TableColumn>
                              <strong>{data.name}</strong>
                            </TableColumn>
                            <TableColumn>{data.old}</TableColumn>
                            <TableColumn>{data.new}</TableColumn>
                          </TableRow>
                        )
                    )}
                  </tbody>
                </IRASTable>
              </RequestDetail>
            )}
        </CardContainer>

        <CardButtonContainer>
          {/* {(user.groups.includes(state.data.stage) ||
            user.groups.length === 0) &&
            state.data?.claimed_by === null &&
            !isStarted && (
              <CustomButton
                title="Start"
                width="100px"
                onClick={() => setIsStartModal(() => true)}
              />
            )}
          {(user.groups.includes(state.data.stage) ||
            user.groups.length === 0) &&
            (state.data?.claimed_by !== null || isStarted) && (
              <CustomSelectField
                margin="0rem"
                placeholder="Move to stage"
                value={nextStage}
                onChangeOption={(value: any) => setNextStage(value)}
                option={stages
                  .filter((stage) => stage !== state.data.stage)
                  .map((stage) => ({
                    key: stage,
                    value: stage,
                  }))}
                width="150px"
              />
            )}
          {nextStage !== "" && (
            <CustomButton
              title="Confirm"
              width="100px"
              onClick={() => setIsNextStageModal(() => true)}
            />
          )} */}
          {(user.groups.includes(state.data.stage) ||
            user.groups.length === 0) &&
            (state.data?.claimed_by !== null || isStarted) &&
            ((state?.data?.status === "Completed" || showCompleteStatus) ? (
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  padding: "0.5rem 0.75rem",
                  // background: "#ff6665", 
                  background: "rgb(3, 179, 129)", 
                  color: "#ffffff", 
                  fontWeight: 600,
                  borderRadius: "0.3125rem"
                }}
              >
                <p>Request Completed</p>
              </div>
            ) : (state?.data?.stage === "Approver" || state?.data?.stage === "Finalizer") ? (
              <CustomButton
                type="secondary"
                title="Complete Request"
                // width="100px"
                onClick={() => setIsCompleteModal(true)}
                styles={{whiteSpace: 'nowrap', background: 'rgb(5, 155, 191)', color:'white'}}
              />
            ) : null)}
          <CustomButton
            type="secondary"
            title="Back"
            width="100px"
            onClick={() => navigate(-1)}
            styles={{
              background: 'black',
              color: 'white'
            }}
          />
        </CardButtonContainer>
      </CardWrapper>
      <CommonModal
        handleClose={() => setIsStartModal(() => false)}
        open={isStartModal}
        updateData={() => handleStartClick()}
        body="Are you sure you want to start working on this request?"
        heading="Start Request"
        conformButtonText="Confirm"
      />
      <CommonModal
        handleClose={() => setIsCompleteModal(false)}
        open={isCompleteModal}
        updateData={handleCompletedClick}
        body="Are you sure you want to complete this request?"
        heading="Complete Request"
        conformButtonText="Confirm"
      />
      <CommonModal
        handleClose={() => setIsNextStageModal(() => false)}
        open={isNextStageModal}
        updateData={() => handleNextStageClick()}
        body={`Are you sure you want to send the request to ${nextStage}?`}
        heading="Move Request"
        conformButtonText="Confirm"
      />
    </div>
  );
};

export default ViewRequest;
