import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";

// User data types
interface Props {
  regularInputs: [];
  payrollId: string;
}

// initial state of user's data
const initialState: Props = {
  regularInputs: [],
  payrollId: "",
};

const payrollSlice = createSlice({
  name: "payroll",
  initialState,
  reducers: {
    // reset data
    resetPayroll: () => initialState,
    // update intial state of user data
    updateRegularInputLists: (state: Props, action: PayloadAction<any>) => {
      current(state);
      state.regularInputs = action.payload;
    },
    updatePayrollId: (state: Props, action: PayloadAction<any>) => {
      current(state);
      state.payrollId = action.payload;
    },
  },
});

export const { updateRegularInputLists, updatePayrollId, resetPayroll } =
  payrollSlice.actions;
export default payrollSlice.reducer;
