import { useEffect, useState } from "react";

import "./payroll.css";
import { PayrollService } from "../../../_service";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../_app";
import { Loader, NavigateBack } from "../../../components";
import { updatePayrollId } from "../../../_features";
import styled from "styled-components";

const Notes = () => {
  const dispatch = useAppDispatch();
  const employee = useAppSelector((state) => state.employee);
  const [loading, setLoading] = useState<boolean>(false);
  const defaultCompnayId = useAppSelector(
    (state) => state.user.defaultCompnayId,
  );

  const [data, setData] = useState<any>();

  const get = async () => {
    try {
      setLoading(() => true);
      const res = await PayrollService._getNotes({
        employee: employee.employee_info.id,
        company: defaultCompnayId,
        payslip_date: employee.last_date_of_current_month,
      });
      dispatch(updatePayrollId(res?.data?.payslip_id || ""));
      setData(res?.data);
      setLoading(() => false);
    } catch (e: any) {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <div style={{ marginBottom: "5rem" }}>
      <Heading style={{ color: "#476575" }}>
        <NavigateBack url="/dashboard/payroll/emp" />
        ADD Notes
        {` - `}
        {employee.currentEmployee.name}
        {`, `}
        {employee.last_date_of_current_month}
      </Heading>
      <h2 style={{ color: "#476575", paddingBottom: "1rem" }}>
        Select an Item to Add a Note
      </h2>
      {loading && <Loader />}
      {Object.keys(data?.data || {})
        ?.filter(
          (item) =>
            !["payslip_date", "net_pay", "cpf_details", "benefit"].includes(
              item,
            ),
        )
        ?.map((item) => {
          return item === "payslip_date" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              key={item}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "0.3rem",
                }}
              >
                <b style={{ textTransform: "capitalize", color: "#333" }}>
                  {item?.replaceAll("_", " ")}
                </b>
                <b>
                  {data?.data[item]?.start}
                  {" to "}
                  {data?.data[item]?.end}
                </b>
              </div>
            </div>
          ) : item !== "employer_contribution" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
              key={item}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "0.3rem",
                }}
              >
                <b style={{ textTransform: "capitalize", color: "#333" }}>
                  {item?.replaceAll("_", " ")}
                </b>
                <b>
                  ${" "}
                  {item == "net_pay"
                    ? data?.data[item]
                    : data?.data[item]?.total}
                </b>
              </div>
              {data?.data[item]?.data?.map((_: any) => {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "0.2rem",
                      }}
                      key={_.name}
                    >
                      <NavLink
                        className="preview-link"
                        to={{
                          pathname: "/dashboard/notes-edit/",
                        }}
                        state={{ ..._, category: item }}
                        key={_.name}
                      >
                        {_.name}
                      </NavLink>
                      <p>$ {_.value}</p>
                    </div>
                    <p style={{ wordWrap: "break-word" }}>{_.note}</p>
                  </>
                );
              })}
            </div>
          ) : null;
        })}
    </div>
  );
};

export default Notes;

const Heading = styled.h2`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;
