import { IconContext } from "react-icons";
import styled from "styled-components";
import Spinner from "../Spinner";
import CustomLoader from "../CustomLoader";

interface ButtonProps {
  title: string;
  type?: "primary" | "secondary" | "onbackground";
  del?: boolean;
  success?: boolean;
  width?: string;
  size?: "small" | "big";
  onClick: (e?: any) => void;
  styles?: any;
  icon?: any;
  disable?: any;
  loading?: any;
  popoverText?: string;
}

const Button = ({
  onClick,
  title,
  del = false,
  success = false,
  width = "200px",
  type = title.toLowerCase() === "back" ? "secondary" : "primary",
  size = "big",
  disable = false,
  loading = false,
  styles,
  icon,
  popoverText = "",
}: ButtonProps) => {
  return (
    <ButtonContainer
      typ={type}
      del={del}
      success={success}
      width={width}
      onClick={onClick}
      style={styles}
      size={size}
      disabled={disable || loading}
      title={popoverText}
    >
      {icon && !loading && (
        <span style={{ marginRight: "0rem", fontSize: 0 }}>
          <IconContext.Provider
            value={{
              size: "1rem",
            }}
          >
            {icon}
          </IconContext.Provider>
        </span>
      )}
      {/* {loading && <Spinner color={getColor(type, del, success)} />} */}
      {loading && <CustomLoader color={getColor(type, del, success)} />}
      {title}
    </ButtonContainer>
  );
};

export default Button;
const getColor = (typ: string, del: boolean, succ: boolean) =>
  typ === "primary"
    ? "#fff"
    : del
    ? "#dc3545"
    : succ
    ? "#28a745"
    : typ === "onbackground"
    ? "#fff"
    : "#059bbf";
const getHoverColor = (typ: string, del: boolean, succ: boolean) =>
  typ === "primary"
    ? "#059bbf"
    : del
    ? "#fff"
    : succ
    ? "#fff"
    : typ === "onbackground"
    ? "transparent"
    : "#fff";
const getBackgroundColor = (typ: string, del: boolean, succ: boolean) =>
  typ === "primary"
    ? "#059bbf"
    : del
    ? "#fff"
    : succ
    ? "#fff"
    : typ === "onbackground"
    ? "#000"
    : "#fff";

const getBorderColor = (typ: string, del: boolean, succ: boolean) =>
  typ === "primary"
    ? "#059bbf"
    : del
    ? "#dc3545"
    : succ
    ? "#28a745"
    : typ === "onbackground"
    ? "#fff"
    : "#059bbf";

const ButtonContainer = styled.button<{
  typ: string;
  del: boolean;
  width: string;
  size: string;
  success: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) =>
    props.width ? props.width : props.size === "small" ? "100%" : "200px"};
  min-height: ${(props) => (props.size === "small" ? "30px" : "40px")};
  border-radius: 5px;
  outline: none;
  text-align: center;
  padding: ${(props) => (props.size === "small" ? "3px 6px" : "6px 12px")};
  margin-right: ${(props) => (props.size === "small" ? ".1rem" : "1rem")};
  margin-left: ${(props) => (props.size === "small" ? ".1rem" : "1rem")};
  font-size: 1rem;
  color: ${(props) => getColor(props.typ, props.del, props.success)};
  background: ${(props) => getHoverColor(props.typ, props.del, props.success)};
  border: 1px solid
    ${(props) => getBorderColor(props.typ, props.del, props.success)};
  cursor: pointer;
  transition: 0.3s all;
  &:hover:enabled {
    color: ${(props) =>
      getBackgroundColor(props.typ, props.del, props.success)};
    background: ${(props) => getColor(props.typ, props.del, props.success)};
    border: 1px solid
      ${(props) => getBackgroundColor(props.typ, props.del, props.success)};
    transition: 0.3s all;
  }
  &:disabled {
    border: 1px solid gray;
    cursor: auto;
  }
`;
