import { Text, View, StyleSheet } from "@react-pdf/renderer";

import { Font } from "@react-pdf/renderer";
import NotoSansRegular from "../../../fonts/NotoSans-Bold.ttf";
import NotoSansBold from "../../../fonts/NotoSans-Regular.ttf";
import { Utils } from "../../../utils";
import moment from "moment";

Font.register({
  family: "Noto Sans",
  fonts: [
    {
      src: NotoSansRegular,
      fontWeight: 400,
    },
    {
      src: NotoSansBold,
      fontWeight: 700,
      fontStyle: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
    marginTop: "20px",
  },

  employeeBasicInfo: {
    display: "flex",
    flexDirection: "row",
  },
  boldText: {
    fontFamily: "Noto Sans",
    fontWeight: "bold",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid black",
    width: "100%",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    justifyContent: "space-between",
    backgroundColor: "#ddd",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    // minHeight: "30rem",
  },
  
});

const pattern = new RegExp("\\(custom\\)", "gi");

// Create Document Component
function TransactionHistoryPdf({
  payslipData,
  valueArr,
  dateTo,
  dateFrom,
  headerArr,
  employeeLength,
  companyName,
  reportTitle = "Transaction History Report",
  totalArr,
}: {
  payslipData: any;
  valueArr: any;
  dateTo: string;
  dateFrom: string;
  headerArr: any;
  employeeLength: any;
  companyName: string;
  reportTitle?: string;
  totalArr?:any
}) {

  {console.log("insidePDFcomp", headerArr, payslipData, totalArr)}
  return (
    <View>
      <View style={styles.header}>
        <Text style={[{ fontSize: "12px" }]}>{companyName}</Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "0px 4px 20px 4px",
          width: "60%",
        }}
      >
        <View>
          <View style={styles.employeeBasicInfo}>
            <Text style={[{ fontSize: "12px" }]}>{reportTitle}</Text>
          </View>
          <View style={styles.employeeBasicInfo}>
            <Text style={[{ fontSize: "12px" }]}>Date:</Text>
            <Text style={[{ fontSize: "12px", marginLeft: "5px" }]}>
              {Utils._dateToShow(dateFrom)} to {Utils._dateToShow(dateTo)}
            </Text>
          </View>
          <View style={styles.employeeBasicInfo}>
            <Text style={[{ fontSize: "12px" }]}>Number of Employees: </Text>
            <Text style={[{ fontSize: "12px", marginLeft: "5px" }]}>
              {employeeLength}
            </Text>
          </View>
        </View>
      </View>
      {Object.keys(payslipData).map((payslipDataHeading: any, index: any) => (
        <View>
          {/* <View style={{ marginTop: "20px" }}>
            <Text>{payslipDataHeading}</Text>
          </View> */}
          <View style={styles.table}>
            {index == 0 && (
              <View
                style={{
                  ...styles.tableHeader,
                  borderRight:
                    Object.keys(payslipData).length - 1
                      ? "1px solid black"
                      : "none",
                }}
              >
                {headerArr.map(
                  (header: any, index: any) =>
                    (
                      <Text
                        style={{
                          borderLeft: "1px solid black",
                          minWidth: "0px",
                          flex: 1,
                          padding: '2px'
                        }}
                      >
                        {header.replace(pattern, "")}
                      </Text>
                    )
                )}
              </View>
            )}
            {Object.keys(payslipData[payslipDataHeading])
              .sort()
              .map((payslipDate, dateIndex) => (
                <View style={styles.row}>
                  {/* <Text
                  style={{
                    borderRight: "1px solid black",
                    minWidth: "0px",
                    flex: 1,
                  }}
                >
                  {headerArr.length > 12 ?  Utils._dateToShow(payslipDate).split("-").join(" - ") : Utils._dateToShow(payslipDate)}
                </Text> */}
                  {headerArr?.map(
                    (headerKey: any, index: any) =>
                       (
                        <Text
                          style={{
                            borderLeft: "1px solid black",
                            flex: 1,
                            minWidth: "0px",
                            textAlign: index < 2 ? "left" : "right",
                            padding: '2px',
                            borderRight:
                              index === headerArr.length - 1
                                ? "1px solid black"
                                : "none",
                          }}
                        >
                          {dateIndex === 0 && index === 0 && payslipDataHeading}
                          {/* {index === 1 && console.log("dateCode",moment(payslipDate).format("MMMM"),payslipData[payslipDataHeading])} */}
                          {index === 1 && moment(payslipDate).format("MMMM YYYY")}
                          {index > 1 &&
                          payslipData[payslipDataHeading][payslipDate][
                            headerKey
                          ]
                            ? payslipData[payslipDataHeading][payslipDate][
                                headerKey
                              ] !== "0.00" &&
                              payslipData[payslipDataHeading][payslipDate][
                                headerKey
                              ] !== "0"
                              ? Utils.getCommanSepartedNumericStringWithDollar(
                                  payslipData[payslipDataHeading][payslipDate][
                                    headerKey
                                  ]
                                )
                              : " "
                            : " "}
                        </Text>
                      )
                  )}
                </View>
              ))}
          </View>
        </View>
      ))}

      <View style={styles.row}>
        {totalArr?.map((x:any, index:any) => <Text
        style={{
          borderLeft: "1px solid black",
          borderBottom: "1px solid black",
          flex: 1,
          minWidth: "0px",
          textAlign: index < 2 ? "left" : "right",
          borderRight: index === totalArr?.length - 1 ? "1px solid black": "none",
          padding: '2px',
          backgroundColor: index === 0 ? "#ddd" : "#fff",
        }}
        >{x}</Text>)}
      </View>
    </View>
  );
}
export default TransactionHistoryPdf;
