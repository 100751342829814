import {
  CardButtonContainer,
  CardContainer,
  CardHeadingContainer,
  CardWrapper,
  RequestDetail,
  RequestDetailContainer,
} from "../../requestdashboard/ViewRequest/ViewRequestStyles";
import { Utils } from "../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomButton } from "../../../components";

const ViewHRTemplate = () => {
  const { state: { hrTemplate } } = useLocation();
  const navigate = useNavigate();

  return (
    <CardWrapper>
      <CardHeadingContainer>HR Template</CardHeadingContainer>
      <CardContainer>
        <RequestDetailContainer>
          <RequestDetail>
            <p>
              <strong>Template Code:</strong>
            </p>
            <p>{hrTemplate?.code}</p>
          </RequestDetail>
          <RequestDetail>
            <p>
              <strong>Created at:</strong>
            </p>
            <p>{Utils._dateToShow(hrTemplate?.created_at) || ""}</p>
          </RequestDetail>
        </RequestDetailContainer>
        <RequestDetail style={{ padding: 20 }}>
          <p>
            <strong>Email:</strong>
          </p>
          <div>
            <p>
              <strong>Subject: </strong>
              {hrTemplate?.email_subject}
            </p>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: hrTemplate?.email_body || "<p>N/A</p>",
            }}
          />
        </RequestDetail>
      </CardContainer>
      <CardButtonContainer>
      <CustomButton
          title="Edit"
          width="100px"
          onClick={() => {
            navigate("/dashboard/manage-template/hr/edit", {
              state: {
                hrTemplate,
              },
            });
          }}
        />
        <CustomButton
          type="secondary"
          title="Back"
          width="100px"
          onClick={() => navigate(-1)}
        />
      </CardButtonContainer>
    </CardWrapper>
  );
};

export default ViewHRTemplate;
