import styled, { keyframes } from "styled-components";

interface SpinnerProps {
  color?: string;
  size?: "big" | "small";
}

const Spinner = (props: SpinnerProps) => {
  const { color = "#fff", size = "small" } = props;
  return <Span color={color} size={size} />;
};

export default Spinner;

const spin = keyframes`
  0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
`;
const Span = styled.span<any>`
  display: inline;
  position: relative;
  margin-right: ${(props) => (props.size === "big" ? "1rem" : "0rem")};
  &::after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 0;
    box-sizing: border-box;
    border: ${(props) => (props.size === "big" ? "1.5rem" : "0.8rem")} solid
      ${(props) => props.color};
    border-color: ${(props) => props.color} transparent
      ${(props) => props.color} transparent;
    animation: ${spin} ${(props) => (props.size === "big" ? ".7s" : "1.2s")}
      infinite linear;
  }
`;
