import { useLocation } from "react-router-dom";
import {
  AllEmployeesHeading,
  MultiSelectButton,
  MultiSelectButtonContainer,
  ReportFilterBody,
  ReportFilterBodyHeading,
  ReportFilterContainer,
  ReportHeading,
  ReportHeadingContainer,
  ReportHorizontalSeparator,
  ReportSectionHeading,
} from "./ReportFilterPageStyles";
import {
  CustomButton,
  CustomDateField,
  CustomeCkeckboxField,
  Loader,
  NavigateBack,
} from "../../components";
import { Constant, URL } from "../../config";
import { useEffect, useState } from "react";
import { Notify, Utils } from "../../utils";
import { useAppSelector } from "../../_app";
import { EmployeeService, LeaveService } from "../../_service";
import { LoaderContainer } from "../../CommonStyles";
import { ReportService } from "../../_service/ReportService";
import { H3Heading } from "../appSettings/AppSettingsStyles";

const pathToHeading = {
  "/dashboard/reports/leave_report": "Leave Report",
  "/dashboard/reports/leave_days_report": "Leave Days Report",
  "/dashboard/reports/leave_expiry": "Leave Expiry",
} as any;

const ReportFilterPage = (props: any) => {
  let currentDate = new Date(
    useAppSelector((state) => state?.user?.current_payrun_date)
  );

  if (!currentDate) {
    currentDate = new Date();
  }
  currentDate.setDate(1);

  // Move back one day to the last day of the last month
  currentDate.setDate(0);
  const lastDayOfLastMonth = Utils._date(currentDate);

  // Set the date to the first day of the last month
  currentDate.setDate(1);
  const firstDayOfLastMonth = Utils._date(currentDate);

  const { downloadFunc, baseUrl } = props;
  const { pathname } = useLocation();
  const [fromDate, setFromDate] = useState<string>(firstDayOfLastMonth);
  const [toDate, setToDate] = useState<string>(lastDayOfLastMonth);
  const [leaveList, setLeaveList] = useState<any>([]);
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [leaveType, setLeaveType] = useState<any>([]);

  const user = useAppSelector((state) => state.user);

  const getLeaveTypes = async () => {
    setIsLoading(() => true);
    try {
      if (user?.defaultCompnayId) {
        const { data, status } = await LeaveService._getLeaveTypes(
          parseInt(user.defaultCompnayId)
        );

        if (status === 200) {
          setLeaveList(() => data.data);
        }
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const handleSelectAll = () => {
    const allLeaveIds = leaveList.map((leave: any) => leave.id);
    setLeaveType(() => allLeaveIds);
  };

  const getEmployeeList = async () => {
    try {
      const { data, status } = await EmployeeService._getEmployeeListByCompany(
        user.defaultCompnayId,
        "0"
      );

      if (status === 200) {
        setEmployeeList(() => data.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  const handleSelectLeaveType = (id: any) => {
    if (leaveType.includes(id)) {
      let interArr = leaveType;
      interArr = interArr.filter((leave: any) => leave !== id);
      setLeaveType(() => interArr);
    } else {
      setLeaveType(() => [...leaveType, id]);
    }
  };

  useEffect(() => {
    // getLeaveTypes();
    // getEmployeeList();
    downloadFunc(
      `${user.defaultCompnayId}&from_date=${fromDate}&to_date=${toDate}`
    );
  }, [fromDate, toDate]);

  return isLoading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <>
      <ReportFilterContainer>
        <ReportHeadingContainer>
          <ReportHeading>{pathToHeading[pathname]}</ReportHeading>
        </ReportHeadingContainer>
        <ReportFilterBody>
          <div>
            <ReportSectionHeading>Date Range</ReportSectionHeading>
            <div style={{ display: "flex", width: "50%" }}>
              <CustomDateField
                label="From"
                date={fromDate}
                setDate={(date: any) => setFromDate(() => date)}
                labelWidth="45px"
              />
              <CustomDateField
                label="To"
                date={toDate}
                setDate={(date: any) => setToDate(() => date)}
                labelWidth="20px"
              />
            </div>
          </div>
          <ReportHorizontalSeparator />
          <div>
            <ReportSectionHeading>Employees</ReportSectionHeading>
            <AllEmployeesHeading>
              All ({employeeList?.length || 0}) employee selected
            </AllEmployeesHeading>
          </div>
          <ReportHorizontalSeparator />
          <div>
            <ReportSectionHeading style={{ marginBottom: 10 }}>
              Leave Types
            </ReportSectionHeading>
            <MultiSelectButtonContainer>
              <MultiSelectButton onClick={handleSelectAll}>
                All
              </MultiSelectButton>
              <MultiSelectButton onClick={() => setLeaveType(() => [])}>
                None
              </MultiSelectButton>
            </MultiSelectButtonContainer>

            {leaveList.map((leave: any) => (
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <input
                  type="checkbox"
                  onChange={() => handleSelectLeaveType(leave.id)}
                  checked={leaveType.includes(leave.id)}
                />
                <p style={{ fontSize: 18 }}>{leave.name}</p>
              </div>
            ))}
          </div>
          <ReportHorizontalSeparator />
          <div style={{ display: "flex", gap: 20 }}>
            <CustomButton
              width="150px"
              title="Show Excel"
              onClick={
                () => {
                  const tempAnchor = document.createElement("a");

                  // Set the attributes for the anchor link
                  tempAnchor.href = `${baseUrl}${user.defaultCompnayId}&from_date=${fromDate}&to_date=${toDate}&file_type=excel&leave_types=[${leaveType}]`; // Replace with your desired URL
                  tempAnchor.target = "_blank"; // Optional: Open link in a new tab/window

                  // Append the anchor element to the document
                  document.body.appendChild(tempAnchor);

                  // Trigger a click on the anchor link
                  tempAnchor.click();

                  // Remove the temporary anchor element from the document
                  document.body.removeChild(tempAnchor);
                }
                // downloadFunc(
                //   `${user.defaultCompnayId}&from_date=${fromDate}&to_date=${toDate}&file_type=excel&leave_types=[${leaveType}]`,
                //   "excel"
                // )
              }
            />
            {pathname !== "/dashboard/reports/leave_expiry" && (
              <CustomButton
                width="150px"
                title="Show Pdf"
                onClick={() =>
                  downloadFunc(
                    `${user.defaultCompnayId}&from_date=${fromDate}&to_date=${toDate}&file_type=pdf&leave_types=[${leaveType}]`,
                    "pdf"
                  )
                }
              />
            )}
          </div>
        </ReportFilterBody>
      </ReportFilterContainer>
    </>
  );
};

export default ReportFilterPage;
