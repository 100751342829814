import { useState } from "react";
import Modal from "react-modal";
import { AiOutlineCloseCircle } from "react-icons/ai";

import "./add-company.css";
import { Notify } from "../../utils";
import { TextInput } from "../formField";
import { IconContext } from "react-icons";
import { useAppDispatch } from "../../_app";
import { resetUser } from "../../_features";
import { CompanyService } from "../../_service";
import styled from "styled-components";
import { DEVICE } from "../../GlobalStyles";

interface AddCompanyModalProps {
  open: boolean;
  handleClose: () => void;
  updateData: () => void;
}

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #283a44",
    borderRadius: "10px",
  },
};

const AddCompanyModal = (props: AddCompanyModalProps) => {
  const disptch = useAppDispatch();
  const { handleClose, open, updateData } = props;

  const [registrationId, setRegistrationId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");

  const [imageLink, setImageLink] = useState(
    "https://cdn.pixabay.com/photo/2016/11/30/21/37/logo-1873902_960_720.png",
  );
  const handleImageChange = (e: any) => {
    setImageLink(URL.createObjectURL(e.target.value));
  };

  const handleAddCompany = async () => {
    if (companyAddress !== "" && companyName !== "" && registrationId !== "") {
      handleClose();
      try {
        const res = await CompanyService._addCompany({
          name: companyName.toUpperCase(),
          address: companyAddress.toUpperCase(),
          reg_id: registrationId.toUpperCase(),
        });
        if (res.status === 201) {
          setRegistrationId("");
          setCompanyAddress("");
          setCompanyName("");
          Notify("Company Added Successfully", 1);
          updateData();
        } else {
          Notify("Company Adding Failed", 0);
        }
      } catch (e: any) {
        if (e.response.status === 401) {
          disptch(resetUser());
        }
      }
    } else {
      Notify("Field cannot be empty", 0);
    }
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Add Company"
      ref={(e) => e}
      ariaHideApp={false}
    >
      <div className="add-company-modal">
        <div className="header">
          <h2>Add Company</h2>
          <p className="close-icon" onClick={handleClose}>
            <IconContext.Provider value={{ color: "#f00", size: "30px" }}>
              <AiOutlineCloseCircle />
            </IconContext.Provider>
          </p>
        </div>
        <div className="line" />
        <div className="body">
          <TextInput
            labal="Company Registration ID"
            value={registrationId}
            onChange={setRegistrationId}
          />
          <TextInput
            labal="Company Name"
            value={companyName}
            onChange={setCompanyName}
          />
          <Label>Company Address</Label>
          <textarea
            className="textarea"
            rows={3}
            value={companyAddress}
            placeholder="[Block Number], [Street Number], #[Unit Level]-[Unit Number] [Building Name] Singapore [Postal Code]"
            onChange={(e) => setCompanyAddress(e.target.value)}
          />
          {/* <div className="img-preview">
            <label>Company Logo</label>
            <input accept="image/" type="file" />
            <img
              src={imageLink}
              alt="company logo"
              onChange={handleImageChange}
            />
          </div> */}
        </div>
        <div className="line" />
        <div className="footer">
          <button className="btn-rev modal-btn" onClick={handleClose}>
            Close
          </button>
          <button className="btn modal-btn" onClick={handleAddCompany}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddCompanyModal;

const Label = styled.label`
  display: block;
  padding-bottom: 10px;
  font-size: 20px;
  transition: 0.3s all;
  @media ${DEVICE.mobileL} {
    font-size: 14px;
    padding-bottom: 5px;
    transition: 0.3s all;
  }
  @media ${DEVICE.tablet} {
    font-size: 16px;
    padding-bottom: 7px;
    transition: 0.3s all;
  }
`;
