import EmployeeHeader from "./employeeHeader";

import "./manageEmployee.css";

const SelfService = () => {
  return (
    <div>
      <div className="hero_header">
        <h2 className="employee-header-h2">Self Service</h2>
      </div>
      <EmployeeHeader />

      <h2>Coming Soon</h2>
    </div>
  );
};

export default SelfService;
