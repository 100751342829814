import { useEffect, useState } from "react";
import XLSX from "xlsx";
import { ReportSectionHeading } from "../reports/ReportFilterPageStyles";

import { CustomDateField, NavigateBack } from "../../components";
import {
  CustomButton,
  CustomSelectField,
  CustomeCkeckboxField,
  Loader,
  ReportModal,
  Tooltip,
} from "../../components";
import { TableHeader } from "../../components/table";
import { Constant } from "../../config";
import { H3Heading, Heading } from "../appSettings/AppSettingsStyles";
import { IRASTable, IRASTableCol } from "../filing/CPFFilingStyles";
import { OverviewCardItem } from "./components";
import { useLocation, useNavigate } from "react-router-dom";
import { DisplayFlex, RecordSeletedText, TableRow } from "./PayslipStyles";
import { PayslipReportService } from "../../_service";
import { useAppSelector } from "../../_app";
import { Notify, Utils } from "../../utils";
import {
  PayslipDetails,
  PayslipFrontendDetails,
  PayslipSummary,
} from "../../_types/ReportTypes";
import { LoaderContainer } from "../../CommonStyles";
import { MdFileDownload } from "react-icons/md";
import { SearchField, TablePaginationHeader } from "../dashboard/component";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import { IconContext } from "react-icons";
import { BiArrowBack } from "react-icons/bi";

const IndividualPayslipReports = () => {
  const date = new Date();

  let currentDate = new Date(
    useAppSelector((state) => state?.user?.current_payrun_date)
  );
  const lastDayOfLastMonth = Utils._date(currentDate);
  const firstDayOfLastMonth = Utils._date(currentDate);
const [toDate, setToDate] = useState<string>(lastDayOfLastMonth);
  const [fromDate, setFromDate] = useState<string>(firstDayOfLastMonth);
  const [month, setMonth] = useState<string>(`${date.getMonth() + 1}`);
  const [year, setYear] = useState<string>(`${date.getFullYear() - 1}`);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [isSelectAllOnThisPage, setIsSelectAllOnThisPage] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [payslipSummary, setPayslipSummary] = useState<PayslipSummary>();
  const [payslipDetails, setPayslipDetails] = useState<
    any[]
  >([]);
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const [pdfDataToShow, setPdfDataToShow] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");

  const [pageInfo, setPageInfo] = useState<any>({
    currentPageNumber: 1,
    dataPerPage: 10,
    totalLength: 0,
  });
  const [filteredPayslips, setFilteredPayslips] = useState<any>();

  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split("/").pop();

  console.log("theLocStateData", location.state );

  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const companyName = useAppSelector((state) => state.user.defaultCompany);
  const currentPayrunDate = useAppSelector(
    (state) => state.user.current_payrun_date
  );


  const payrunDateYear = currentPayrunDate?.split("-")[0] || date.getFullYear()
  
  const [fromMonth, setFromMonth] = useState<string>(
    `${1 || date.getMonth() + 1}`
  );
  const [toMonth, setToMonth] = useState<string>(
    `${parseInt(currentPayrunDate?.split("-")[1] || 0) - 1 || date.getMonth() + 1}`
  );

  const currentReportMonthsOptions = Utils.MONTH_OPTIONS?.slice(0, parseInt(currentPayrunDate?.split('-')[1]) - 2);

  const [totalIncome, setTotalIncome] = useState(0);
  const [totalAllowance, setTotalAllowance] = useState(0);
  const [totalReimbursement, setTotalReimbursement] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const [cpfb, setTotalCpfb] = useState(0);
  const [totalNetPay, setTotalNetPay] = useState(0);


  /** Array for Header columns of Table */
  const tableHeader = [
    {
      width: "5%",
    },
    {
      name: "Emp Name",
      width: "25%",
    },
    {
      name: "Emp No",
      width: "7%",
    },
    {
      name: "Income",
      width: "11%",
          
    },
    {
      name: "Allowance",
      width: "11%",
    },
    {
      name: "Reimburse",
      width: "11%",
    },
    {
      name: "Deductions",
      width: "11%",
    },
    {
      name: "CPFB",
      width: "11%",
    },
    {
      name: "Net Pay",
      width: "11%",
    },
    {
      name: "Action",
    },
  ];

  /** Array for Options of month */
  const monthsOptions = Utils.MONTH_OPTIONS;

  // -------- get Grouped Reports -----------

  const getCurrentPayslipReportDetails = () => {
    try {
      if (path === "individual") {
        // setPayslipSummary(() => data.data.summary);
        let newTotalIncome = 0;
        let newTotalAllowance = 0;
        let newTotalReimbursement = 0;
        let newTotalDeduction = 0;
        let newTotalCpfb = 0;
        let newTotalNetPay = 0;

        const payslipIndividualData = location?.state?.payslipData.map(
          (payslip: any) => {
            newTotalIncome =
              newTotalIncome +
              parseFloat(
                payslip?.payslip_info?.income?.total?.replace(/,/g, "") || "0"
              );
            newTotalAllowance =
              newTotalAllowance +
              parseFloat(
                payslip?.payslip_info?.allowance?.total?.replace(/,/g, "") ||
                  "0"
              );
            newTotalReimbursement =
              newTotalReimbursement +
              parseFloat(
                payslip?.payslip_info?.reimbursement?.total?.replace(
                  /,/g,
                  ""
                ) || "0"
              );
            newTotalDeduction =
              newTotalDeduction +
              parseFloat(
                payslip?.payslip_info?.deductions?.total?.replace(/,/g, "") ||
                  "0"
              );
              newTotalCpfb =
              newTotalCpfb +
              parseFloat(
                payslip?.payslip_info?.cpf_details?.cpfb?.replace(/,/g, "") ||
                  "0"
              );
            newTotalNetPay =
              newTotalNetPay +
              parseFloat(
                payslip?.payslip_info?.net_pay?.replace(/,/g, "") || "0"
              );

            return {
              income: payslip?.payslip_info?.income?.total || "0",
              deduction: payslip?.payslip_info?.deductions?.total || "0",
              cpfb: payslip?.payslip_info?.cpf_details?.cpfb || "0",
              net_pay: payslip?.payslip_info?.net_pay || "0",
              id: payslip?.employee?.emp_number || "NA",
              full_name: payslip?.employee?.user?.name || "",
              isFinalised: payslip?.is_finalized || false,
              allowance: payslip?.payslip_info?.allowance?.total || "0",
              reimbursement: payslip?.payslip_info?.reimbursement?.total || "0",
              employeeId: payslip?.employee?.id,
              payslipDocument: payslip,
              isSelected: false,
              payslipId: payslip.id || uuidv4(),
              payslipDate: payslip?.payslip_date              
            };
          }
        );

        setTotalIncome(() => newTotalIncome);
        setTotalAllowance(() => newTotalAllowance);
        setTotalDeduction(() => newTotalDeduction);
        setTotalCpfb(() => newTotalCpfb);
        setTotalReimbursement(() => newTotalReimbursement);
        setTotalNetPay(() => newTotalNetPay);
        setPayslipDetails(() => payslipIndividualData);
        setFilteredPayslips(() => payslipIndividualData);
        setPageInfo((info: any) => ({
          ...info,
          totalLength: location?.state?.payslipData.length || 0,
        }));
      }
    } catch (error) {
      Notify("Something went wrong", 1);
    }
  };

  console.log("TheCurrent", {filteredPayslips})


  const downloadCsv = () => {
    // const monthName = Object.keys(monthsOptions).find((key:any) => monthsOptions[key] === month)
    const monthName = monthsOptions.find((obj) => obj.value === month);

    const csvTitle = [
      [`${companyName}`],
      [`${location?.state?.employeeData?.user?.name} - YTD Payslip Reports`],
      [
        `${path === "history" ? "Month and Year:" : "Period:"}`,
        path === "history" ? `${monthName?.key} ${year}` : `${moment(fromDate).format("DD-MM-YYYY")} to ${moment(toDate).format("DD-MM-YYYY")}`,
      ],
      // ["Number of Employees:", payslipDetails.length],
    ];

    const csvTableHeaders = [
      "Payslip Date",
      "Emp No",
      "Income",
      "Allowance",
      "Reimbursement",
      "Deductions",
      "CPFB",
      "Net Pay",
      // "Status",
    ];

    const csvPayslipData = payslipDetails.map((payslip) => {
      
      return [
        payslip.payslipDate,
        payslip.id,
        {
          v: Utils.prependDollar(payslip?.income || "0"),
          s: { alignment: { horizontal: "right" } },
        },
        {
          v: Utils.prependDollar(payslip?.allowance || "0"),
          s: { alignment: { horizontal: "right" } },
        },
        {
          v: Utils.prependDollar(payslip?.reimbursement || "0"),
          s: { alignment: { horizontal: "right" } },
        },
        {
          v: Utils.prependDollar(payslip?.deduction || "0"),
          s: { alignment: { horizontal: "right" } },
        },
        {
          v: Utils.prependDollar(payslip?.cpfb|| "0"),
          s: { alignment: { horizontal: "right" } },
        },
        {
          v: Utils.prependDollar(payslip?.net_pay || "0"),
          s: { alignment: { horizontal: "right" } },
        },
        // payslip?.isFinalised ? "Finalised" : "Unfinalised",
      ];
    });

    const csvTotalRow = [
      "Total",
      "",
      {
        v: Utils.prependDollar(
          Utils.getNumberWithCommas(totalIncome.toFixed(2))
        ),
        s: { alignment: { horizontal: "right" } },
      },
      {
        v: Utils.prependDollar(
          Utils.getNumberWithCommas(totalAllowance.toFixed(2))
        ),
        s: { alignment: { horizontal: "right" } },
      },
      {
        v: Utils.prependDollar(
          Utils.getNumberWithCommas(totalReimbursement.toFixed(2))
        ),
        s: { alignment: { horizontal: "right" } },
      },
      {
        v: Utils.prependDollar(
          Utils.getNumberWithCommas(totalDeduction.toFixed(2))
        ),
        s: { alignment: { horizontal: "right" } },
      },
      {
        v: Utils.prependDollar(
          Utils.getNumberWithCommas(cpfb.toFixed(2))
        ),
        s: { alignment: { horizontal: "right" } },
      },
      {
        v: Utils.prependDollar(
          Utils.getNumberWithCommas(totalNetPay.toFixed(2))
        ),
        s: { alignment: { horizontal: "right" } },
      },
    ];
    const combinedData = [
      ...csvTitle,
      [],
      csvTableHeaders,
      ...csvPayslipData,
      [],
      csvTotalRow,
    ];

    const workbook = XLSX.utils.book_new();

    // Add a worksheet with combined data
    const combinedWorksheet = XLSX.utils.aoa_to_sheet(combinedData);
    XLSX.utils.book_append_sheet(workbook, combinedWorksheet, "CombinedData");

    const numCols = 8; // Get the number of columns
    combinedWorksheet["!cols"] = Array(numCols).fill({ width: 20 });

    XLSX.writeFile(workbook, `${companyName.split(" ")[0]}_report.xlsx`, {
      compression: true,
    });
  };

  /** Function to send individual reports to download */
  const handleIndividualReportDownload = (payslip: any) => {
    setShowPdf(() => true);
    setPdfDataToShow(() => [payslip]);
  };

  const handleSelectChange = (
    payslipId: number,
    isCurrentItemSelected: boolean,
    payslipDocument: any
  ) => {
    if (isCurrentItemSelected) {
      setSelectedCount(() => selectedCount - 1);
      const updatedPdfDataToShow = pdfDataToShow.filter(
        (pdfData: any) => pdfData.id !== payslipId
      );
      setPdfDataToShow(() => updatedPdfDataToShow);
    } else {
      if (selectedCount <= 0) {
        setPdfDataToShow(() => [payslipDocument]);
      } else {
        setPdfDataToShow(() => [...pdfDataToShow, payslipDocument]);
      }
      setSelectedCount(() => selectedCount + 1);
    }
    setFilteredPayslips((prevPayslips: any) =>
      prevPayslips.map((payslip: any) =>
        payslip.payslipId === payslipId
          ? { ...payslip, isSelected: !payslip.isSelected }
          : payslip
      )
    );
  };

  /** Function to change of clicking select all button */
  // const handleSelectAllOnThisPage = () => {
  //   if (!isSelectAllOnThisPage) {
  //     setFilteredPayslips((prevPayslips: any) =>
  //       prevPayslips.map((payslip: any) => ({ ...payslip, isSelected: true }))
  //     );
  //     const updatedPdfDataToShow = filteredPayslips.map(
  //       (payslip: any) => payslip.payslipDocument
  //     );
  //     setSelectedCount(() => updatedPdfDataToShow.length);
  //     setPdfDataToShow(() => updatedPdfDataToShow);
  //   } else {
  //     setFilteredPayslips((prevPayslips: any) =>
  //       prevPayslips.map((payslip: any) => ({ ...payslip, isSelected: false }))
  //     );
  //     setSelectedCount(() => 0);
  //     setPdfDataToShow(() => []);
  //   }
  //   setIsSelectAllOnThisPage(() => !isSelectAllOnThisPage);
  // };

  const handleSelectAll = () => {
    if (!isSelectAll) {
      setPayslipDetails((prevPayslips: any) =>
        prevPayslips.map((payslip: any) => ({ ...payslip, isSelected: true }))
      );
      setFilteredPayslips((prevPayslips: any) =>
        prevPayslips.map((payslip: any) => ({ ...payslip, isSelected: true }))
      );

      const updatedPdfDataToShow = payslipDetails.map(
        (payslip) => payslip.payslipDocument
      );
      setSelectedCount(() => updatedPdfDataToShow.length);
      setPdfDataToShow(() => updatedPdfDataToShow);
    } else {
      setPayslipDetails((prevPayslips: any) =>
        prevPayslips.map((payslip: any) => ({ ...payslip, isSelected: false }))
      );
      setFilteredPayslips((prevPayslips: any) =>
        prevPayslips.map((payslip: any) => ({ ...payslip, isSelected: false }))
      );
      setSelectedCount(() => 0);
      setPdfDataToShow(() => []);
    }
    setIsSelectAll(() => !isSelectAll);
  };

  const changeSearchText = (value: string) => {
    setSearchText(() => value);
    const allPayslips = payslipDetails;
    const applyFilter = allPayslips.filter((payslip: any) =>
      payslip.full_name.toLowerCase().includes(value.toLowerCase().trim())
    );
    setPageInfo((info: any) => ({
      ...info,
      totalLength: applyFilter.length,
    }));
    setFilteredPayslips(() => applyFilter);
  };

  const handlePageChange = (pageNo: number) => {
    setPageInfo((info: any) => ({
      ...info,
      currentPageNumber: pageNo,
    }));
  };

  useEffect(() => {
    const startIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage - pageInfo.dataPerPage;
    const endIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage > payslipDetails.length
        ? payslipDetails.length
        : pageInfo.currentPageNumber * pageInfo.dataPerPage;
    const payslipDetailsObj = payslipDetails;
    const applyFilter = payslipDetailsObj.filter((payslipData) =>
      payslipData.full_name
        .toLowerCase()
        .includes(searchText.toLowerCase().trim())
    );
    setFilteredPayslips(() => applyFilter.slice(startIndex, endIndex));
  }, [pageInfo]);

  // const yearOptions = Utils.generateYearArray(2022);
  const yearOptions = Utils.getDefaultYearRange();

  useEffect(() => {
    getCurrentPayslipReportDetails();
  }, [month, year, path, fromMonth, toMonth]);

  useEffect(() => {
    const { previousMonth } = Utils.getPreviousMonthYear(currentPayrunDate);
    setMonth(`${previousMonth}`);
  }, []);


// To reset the state if the path is changed from "current" to "history" or back
  useEffect(()=>{
    setSelectedCount(0);
    setIsSelectAll(false);
    setSearchText("");
  }, [path])

  console.log('theMonths', {fromMonth, toMonth})
  console.log("individual location", location?.state)

  return (
    <div>
      <section id="heading" style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
      {/* <p>Go Back</p> */}

      <IconContext.Provider value={{ color: "#059bbf", size: "56px", }}>
        <BiArrowBack style={{cursor:'pointer'}} onClick={()=>navigate(`/dashboard/payslip/${location?.state?.path}`, {
        state : {
          isPreviousPath: true,
          path: location?.state?.path,
          year: location?.state?.year,
          fromMonth: location?.state?.fromMonth,
          toMonth: location?.state?.toMonth,
        }
      })}/>
      </IconContext.Provider>
        
        <Heading>
          {`${location?.state?.employeeData?.user?.name} - YTD Payslip Reports`}
        </Heading>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            width: '40%',
            marginLeft: 'auto'
          }}
          >
          
          {payslipDetails.length > 0 && (
            <div style={{ display: "flex", alignItems: "center", gap: 20, marginLeft: 'auto' }}>
              <div style={{ display: "flex", gap: 10, marginBottom: 10 }}>
                <input
                  type="checkbox"
                  name="selectall"
                  id="selectall"
                  onChange={() => handleSelectAll()}
                  checked={isSelectAll}
                />
                <label
                  htmlFor="selectAll"
                  style={{ paddingBottom: 0, width: 70 }}
                >
                  {Constant.reports.select_all}
                </label>
              </div>
              <SearchField value={searchText} onChangeText={changeSearchText} />
            </div>
          )}
          {payslipDetails.length > 0 && (
            <CustomButton
              title="Download CSV"
              onClick={() => downloadCsv()}
              styles={{ marginBottom: 20 }}
            />
          )}
        </div>
      </section>

      <H3Heading style={{marginLeft: '4rem', textTransform: 'lowercase'}}>{`${Utils.getStartDateOfMonth(location?.state?.year, location?.state?.fromMonth).split('-').reverse().join('-')} to ${Utils.getLastDateOfMonth(location?.state?.year, location?.state?.toMonth).split('-').reverse().join('-')}`}</H3Heading>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          {/* <section id="overview" style={{ marginTop: 20 }}>
            <H3Heading>Overview</H3Heading>
            <DisplayFlex>
              <OverviewCardItem
                title="Gross Salary"
                description={Utils.prependDollar(payslipSummary?.gross || 0)}
                color="#059bbf"
              />
              <OverviewCardItem
                title="Net Pay"
                description={Utils.prependDollar(payslipSummary?.net_pay || 0)}
                color="#28a745"
              />
              <OverviewCardItem
                title="CPF Total"
                description={Utils.prependDollar(payslipSummary?.cpf || 0)}
                color="#dc3545"
              />
            </DisplayFlex>
          </section> */}
          <section id="report-table" style={{ marginTop: 20 }}>
            {payslipDetails.length === 0 ? (
              <p>{`${
                path === "history"
                  ? "No payslips occur for this month"
                  : "No payslips occur for the selected date range"
              }`}</p>
            ) : (
              <>
                <IRASTable style={{ width: "100%" }}>
                  <TableRow
                    style={{ backgroundColor: "lightgreen", width: "100%" }}
                  >
                    <IRASTableCol
                      style={{
                        textAlign: "left",
                        width: "33%",
                        paddingLeft: "3%",
                      }}
                    >
                      <strong>Total</strong>
                    </IRASTableCol>
                    <IRASTableCol style={{ textAlign: "right", width: "10%" }}>
                      {Utils.prependDollar(
                        Utils.getNumberWithCommas(totalIncome.toFixed(2))
                      )}
                    </IRASTableCol>
                    <IRASTableCol style={{ textAlign: "right", width: "10%" }}>
                      {Utils.prependDollar(
                        Utils.getNumberWithCommas(totalAllowance.toFixed(2))
                      )}
                    </IRASTableCol>
                    <IRASTableCol style={{ textAlign: "right", width: "10%" }}>
                      {Utils.prependDollar(
                        Utils.getNumberWithCommas(totalReimbursement.toFixed(2))
                      )}
                    </IRASTableCol>
                    <IRASTableCol style={{ textAlign: "right", width: "10%" }}>
                      {Utils.prependDollar(
                        Utils.getNumberWithCommas(totalDeduction.toFixed(2))
                      )}
                    </IRASTableCol>
                    <IRASTableCol style={{ textAlign: "right", width: "10%" }}>
                      {Utils.prependDollar(
                        Utils.getNumberWithCommas(totalNetPay.toFixed(2))
                      )}
                    </IRASTableCol>

                    <IRASTableCol style={{ textAlign: "right", width: "10%" }}>
                      {Utils.prependDollar(
                        Utils.getNumberWithCommas(cpfb.toFixed(2))
                      )}
                    </IRASTableCol>
                    <IRASTableCol style={{ width: "7%" }}></IRASTableCol>
                  </TableRow>
                </IRASTable>
                <div style={{ width: "100%" }}>
                  <TablePaginationHeader
                    pageInfo={pageInfo}
                    name="Reports"
                    handlePageValueChange={handlePageChange}
                  />
                </div>
                <IRASTable
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  {/* <TableHeader headerColumns={tableHeader} variant="filled" /> */}
                  <thead className="company-table-head">
                    <tr>
                      <th
                        style={{
                          textAlign: "left",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontWeight: "bold",
                          width: "3%",
                        }}
                      >
                        {""}{" "}
                      </th>
                      <th
                        style={{
                          textAlign: "left",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "20%",
                        }}
                      >
                        Payslip Date
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "10%",
                        }}
                      >
                        Emp No
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "10%",
                        }}
                      >
                        Incomes
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "10%",
                        }}
                      >
                        Allowances
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "10%",
                        }}
                      >
                        Reimbursements
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "10%",
                        }}
                      >
                        Deductions
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "10%",
                        }}
                      >
                        Net Pay
                      </th>
                      <th
                        style={{
                          textAlign: "right",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "10%",
                        }}
                      >
                        CPFB
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          backgroundColor: "#4a5e71",
                          color: "#f8f9fa",
                          border: "none",
                          fontSize: "0.8125rem",
                          padding: "4.8px",
                          fontWeight: "bold",
                          width: "7%",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPayslips
                      .sort((a: any, b: any) =>
                        a.payslipDate.localeCompare(b.payslipDate)
                      )
                      .map(
                        (payslip: any) =>
                          payslip.full_name
                            .toLowerCase()
                            .includes(searchText.toLowerCase().trim()) && (
                            <TableRow>
                              <IRASTableCol
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "1rem",
                                  minWidth: "3%",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  style={{ width: 15 }}
                                  onChange={() =>
                                    handleSelectChange(
                                      payslip.payslipId,
                                      payslip.isSelected,
                                      payslip.payslipDocument
                                    )
                                  }
                                  checked={payslip.isSelected}
                                />
                              </IRASTableCol>
                              <IRASTableCol
                                style={{ textAlign: "left", minWidth: "15%" }}
                              >
                                {moment(payslip.payslipDate,"YYYY-MM-DD").format("MMMM YYYY")}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right" }}>
                                {payslip.id}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right" }}>
                                {Utils.prependDollar(payslip.income)}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right" }}>
                                {Utils.prependDollar(payslip.allowance)}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right" }}>
                                {Utils.prependDollar(payslip.reimbursement)}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right" }}>
                                {Utils.prependDollar(payslip.deduction)}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right" }}>
                                {Utils.prependDollar(payslip.net_pay)}
                              </IRASTableCol>
                              <IRASTableCol style={{ textAlign: "right" }}>
                                {Utils.prependDollar(payslip.cpfb)}
                              </IRASTableCol>
                              <IRASTableCol style={{ paddingLeft: "3%" }}>
                                <Tooltip text="Download">
                                  <CustomButton
                                    type={"secondary"}
                                    size="small"
                                    width="30px"
                                    title=""
                                    onClick={() =>
                                      handleIndividualReportDownload(
                                        payslip.payslipDocument
                                      )
                                    }
                                    icon={<MdFileDownload />}
                                  />
                                </Tooltip>
                              </IRASTableCol>
                            </TableRow>
                          )
                      )}

                    {/* <TableRow style={{ backgroundColor: "lightgreen" }}>
                      <IRASTableCol></IRASTableCol>
                      <IRASTableCol>
                        <strong>Total</strong>
                      </IRASTableCol>
                      <IRASTableCol></IRASTableCol>
                      <IRASTableCol style={{ textAlign: "right" }}>
                        {Utils.prependDollar(
                          Utils.getNumberWithCommas(totalIncome.toFixed(2))
                        )}
                      </IRASTableCol>
                      <IRASTableCol style={{ textAlign: "right" }}>
                        {Utils.prependDollar(
                          Utils.getNumberWithCommas(totalAllowance.toFixed(2))
                        )}
                      </IRASTableCol>
                      <IRASTableCol style={{ textAlign: "right" }}>
                        {Utils.prependDollar(
                          Utils.getNumberWithCommas(
                            totalReimbursement.toFixed(2)
                          )
                        )}
                      </IRASTableCol>
                      <IRASTableCol style={{ textAlign: "right" }}>
                        {Utils.prependDollar(
                          Utils.getNumberWithCommas(totalDeduction.toFixed(2))
                        )}
                      </IRASTableCol>
                      <IRASTableCol style={{ textAlign: "right" }}>
                        {Utils.prependDollar(
                          Utils.getNumberWithCommas(cpfb.toFixed(2))
                        )}
                      </IRASTableCol>
                      <IRASTableCol style={{ textAlign: "right" }}>
                        {Utils.prependDollar(
                          Utils.getNumberWithCommas(totalNetPay.toFixed(2))
                        )}
                      </IRASTableCol>
                      <IRASTableCol></IRASTableCol>
                    </TableRow>
                  </tbody> */}
                  </tbody>
                </IRASTable>
                <div style={{ marginTop: 20 }}>
                  {selectedCount > 0 && (
                    <DisplayFlex>
                      <RecordSeletedText>
                        {selectedCount} record(s) selected{" "}
                      </RecordSeletedText>
                      <CustomButton
                        title={Constant.reports.download_bulk}
                        onClick={() => setShowPdf(() => true)}
                      />
                    </DisplayFlex>
                  )}
                </div>
                {showPdf && (
                  <ReportModal
                    isOpen={showPdf}
                    handleClose={() => setShowPdf(false)}
                    documents={pdfDataToShow}
                    startDate={Utils.getStartDateOfMonth(currentPayrunDate.split('-')[0],fromMonth)}
                    lastDate={Utils.getLastDateOfMonth(currentPayrunDate.split('-')[0],toMonth)}
                    type={path || ""}
                  />
                )}
              </>
            )}
          </section>
        </>
      )}
    </div>
  );
};

export default IndividualPayslipReports;
