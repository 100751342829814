import { useNavigate } from "react-router";
import {
  CustomButton,
  CustomeCkeckboxField,
  Loader,
} from "../../../../../components";
import { ButtonContainer } from "../../../../appSettings/AppSettingsStyles";
import { GeneralSettingsContainer } from "../../SelfServiceStyles";
import { useEffect, useState } from "react";
import { Notify } from "../../../../../utils";
import { Constant } from "../../../../../config";
import { SelfService } from "../../../../../_service/SelfService";
import { useAppSelector } from "../../../../../_app";
import { LoaderContainer } from "../../../../../CommonStyles";

const GeneralSettings = () => {
  const user = useAppSelector((state) => state.user);
  const [settingsOption, setSettingsOption] = useState<any>({
    is_attach_payslip: false,
    is_allow_tax_certificate: false,
    is_attach_certifcate: false,
    is_disable_leave_request: false,
    is_info_update_request: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleSettingsChange = (key: string) => {
    setSettingsOption((prevState: any) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const getSelfServiceSettings = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await SelfService._getSelfServiceSettings(
        user.defaultCompnayId
      );
      if (status === 200) {
        const {
          is_attach_payslip,
          is_allow_tax_certificate,
          is_attach_certifcate,
          is_disable_leave_request,
          is_info_update_request,
        } = data.data;

        setSettingsOption(() => ({
          is_attach_payslip,
          is_allow_tax_certificate,
          is_attach_certifcate,
          is_disable_leave_request,
          is_info_update_request,
        }));
      }
    } catch (err) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  const postSelfServiceSettings = async () => {
    setIsLoading(() => true);
    try {
      const dataToSend = {
        company: user.defaultCompnayId,
        ...settingsOption,
      };
      const { status } = await SelfService._postSelfServiceSettings(dataToSend);
      if (status === 201) {
        Notify("Settings updated Successfully", 1);
        getSelfServiceSettings();
      }
    } catch (err) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    getSelfServiceSettings();
  }, []);
  const navigate = useNavigate();
  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <GeneralSettingsContainer>
            <CustomeCkeckboxField
              title="Attach payslips to emails on Self-Service release"
              value={settingsOption.is_attach_payslip}
              onChange={() => handleSettingsChange("is_attach_payslip")}
            />
            <CustomeCkeckboxField
              title="Allow tax certificates to be released to Self-Service"
              value={settingsOption.is_allow_tax_certificate}
              onChange={() => handleSettingsChange("is_allow_tax_certificate")}
            />
            {settingsOption.is_allow_tax_certificate && (
              <div style={{ marginLeft: 40 }}>
                <CustomeCkeckboxField
                  title="Attach certificates when notifying employees of release via email"
                  value={settingsOption.is_attach_certifcate}
                  onChange={() => handleSettingsChange("is_attach_certifcate")}
                />
              </div>
            )}
            <h4>Self-Service Request Types</h4>
            <CustomeCkeckboxField
              title="Disable Leave requests?"
              value={settingsOption.is_disable_leave_request}
              onChange={() => handleSettingsChange("is_disable_leave_request")}
            />
            <CustomeCkeckboxField
              title="Disable Info Update requests?"
              value={settingsOption.is_info_update_request}
              onChange={() => handleSettingsChange("is_info_update_request")}
            />
          </GeneralSettingsContainer>
          <ButtonContainer>
            <CustomButton
              type="secondary"
              title="Cancel"
              onClick={() => navigate("/dashboard/self-service/employee-users")}
            />
            <CustomButton
              title="Save"
              onClick={() => postSelfServiceSettings()}
            />
          </ButtonContainer>
        </>
      )}
    </div>
  );
};

export default GeneralSettings;
