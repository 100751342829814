import {
  CommonModal,
  CustomButton,
  CustomDateField,
  Loader,
  Tooltip,
} from "../../components";
import { TableHeader } from "../../components/table";
import { Heading } from "../appSettings/AppSettingsStyles";
import { IRASTable } from "../filing/CPFFilingStyles";
import { TableRow } from "../payslip/PayslipStyles";
import { TableColumn } from "../users/styles";
import { useEffect, useState } from "react";
import { Notify, Utils } from "../../utils";
import { useAppSelector } from "../../_app";
import { Constant } from "../../config";
import { LeaveService } from "../../_service";
import { LoaderContainer } from "../../CommonStyles";
import { FilterBySelect, TablePaginationHeader } from "../dashboard/component";
import ApprovalComment from "./ApprovalComment";
import { MdRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const LeaveApproval = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();

  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);

  const [fromDate, setFromDate] = useState<any>(Utils._date(firstDay));
  const [toDate, setToDate] = useState<any>(Utils._date(lastDay));
  const [leaves, setLeaves] = useState<any>([]);
  const [approvalModal, setApprovalModal] = useState<boolean>(false);
  const [leaveToApprove, setLeaveToApprove] = useState<any>();
  const [approvalComment, setApprovalComment] = useState("");
  const [approvalStatusToSend, setApprovalStatusToSend] =
    useState<boolean>(false);
  const [filteredLeaves, setFilteredLeaves] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeSection, setActiveSection] = useState<string>("0");
  const [pageInfo, setPageInfo] = useState<any>({
    currentPageNumber: 1,
    dataPerPage: 10,
    totalLength: 0,
  });

  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate()

  const selectOptions = [
    {
      name: "All",
      value: "0",
    },
    {
      name: "Approved",
      value: "1",
    },
    {
      name: "Rejected",
      value: "2",
    },
    {
      name: "Pending",
      value: "3",
    },
  ];

  const getLeaves = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await LeaveService._getPendingLeaves(
        user.defaultCompnayId,
        fromDate,
        toDate,
        activeSection
      );
      if (status === 200) {
        let leavesMapData: any = [];
        leavesMapData = data.data;
        setLeaves(() => leavesMapData);
        setFilteredLeaves(() => leavesMapData);
        setPageInfo((info: any) => ({
          ...info,
          totalLength: leavesMapData.length,
        }));
      }
    } catch (error) {}
    setIsLoading(() => false);
  };

  const leaveApprovalTableHeader = [
    {
      name: "Name",
      width: "30%",
    },
    {
      name: "From Date",
      width: "15%",
    },
    {
      name: "To Date",
      width: "15%",
    },
    {
      name: "Leave Type",
      width: "20%",
    },
    {
      name: "Leave Status",
      width: "15%",
    },
    {
      name: "Actions",
      width: "15%",
    },
  ];

  const handlePageChange = (pageNo: number) => {
    setPageInfo((info: any) => ({
      ...info,
      currentPageNumber: pageNo,
    }));
  };

  const handleApproveReject = async (approveStatus: number) => {
    try {
      const updatedData = {
        is_approve: approveStatus,
        comment: approvalComment,
      };

      const { status } = await LeaveService._approveLeave(
        updatedData,
        leaveToApprove.id
      );

      if (status === 200) {
        getLeaves();
        Notify(
          `Leave ${approveStatus ? "Appoved" : "Rejected"} successfully`,
          1
        );
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setApprovalModal(() => false);
  };

  useEffect(() => {
    const startIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage - pageInfo.dataPerPage;
    const endIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage > leaves.length
        ? leaves.length
        : pageInfo.currentPageNumber * pageInfo.dataPerPage;
    const leavesObj = leaves;
    const applyFilter = leavesObj.filter((leaveData: any) => leaveData);
    setFilteredLeaves(() => applyFilter.slice(startIndex, endIndex));
  }, [pageInfo]);

  useEffect(() => {
    getLeaves();
  }, [fromDate, toDate, activeSection]);

  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <div>
          <Heading>Approve Leaves</Heading>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 20,
              gap: 50,
            }}
          >
            <FilterBySelect
              value={activeSection}
              onChageOption={setActiveSection}
              options={selectOptions}
              marginBottom={0}
            />
            <CustomDateField
              label="From"
              date={fromDate}
              setDate={(value: any) => setFromDate(() => value)}
              margin="0rem"
              labelWidth="10%"
              width="120px"
            />
            <CustomDateField
              label="To"
              date={toDate}
              setDate={(value: any) => setToDate(() => value)}
              margin="0rem"
              labelWidth="10%"
              width="120px"
            />
          </div>
          <div style={{ width: "98%", marginTop: 20 }}>
            <TablePaginationHeader
              pageInfo={pageInfo}
              name="Leaves"
              handlePageValueChange={handlePageChange}
            />
          </div>
          <IRASTable>
            <TableHeader
              headerColumns={leaveApprovalTableHeader}
              variant="filled"
            />
            <tbody>
              {filteredLeaves.map((leaveData: any) => (
                <TableRow>
                  <TableColumn>{leaveData.employee_name || ""}</TableColumn>
                  <TableColumn>
                    {Utils?._dateToShow(leaveData?.date_from) || ""}
                  </TableColumn>
                  <TableColumn>
                    {Utils?._dateToShow(leaveData?.date_to) || ""}
                  </TableColumn>
                  <TableColumn>{leaveData?.leave_name || ""}</TableColumn>
                  <TableColumn
                    style={{
                      color: leaveData?.is_approved
                        ? "#28a745"
                        : leaveData?.is_approved === null
                        ? "#059bbf"
                        : "#dc3545",
                    }}
                  >
                    {leaveData?.is_approved
                      ? "Approved"
                      : leaveData?.is_approved === null
                      ? "Pending"
                      : "Rejected"}
                  </TableColumn>
                  <TableColumn>
                    <div style={{ display: "flex", gap: 20 }}>
                      <Tooltip text="Approve">
                        <CustomButton
                          type="secondary"
                          size="small"
                          width="30px"
                          title={""}
                          onClick={() => {
                            navigate("details", {state: { data: leaveData, from: fromDate, to: toDate }})
                            // setApprovalModal(() => true);
                            // setLeaveToApprove(() => leaveData);
                            // setApprovalStatusToSend(() => true);
                          }}
                          icon={<MdRemoveRedEye />}
                        />
                      </Tooltip>

                      {/* <Tooltip text="Reject" del>
                                                    <CustomButton
                                                        type="secondary"
                                                        size="small"
                                                        del
                                                        width="30px"
                                                        title={""}
                                                        onClick={() => {
                                                            setApprovalModal(() => true)
                                                            setLeaveToApprove(() => leaveData.id)
                                                            setApprovalStatusToSend(() => false)
                                                        }}
                                                        icon={<AiOutlineClose />}
                                                    />
                                                </Tooltip> */}
                    </div>
                  </TableColumn>
                </TableRow>
              ))}
              {filteredLeaves.length === 0 && <p>No records found</p>}
            </tbody>
          </IRASTable>
        </div>
      )}
      <CommonModal
        heading="Leave Request"
        open={approvalModal}
        handleClose={() => setApprovalModal(() => false)}
        body={
          <ApprovalComment
            comment={approvalComment}
            handleCommentChange={setApprovalComment}
            data={leaveToApprove}
          />
        }
        conformButtonText={
          new Date(Utils._date()) <= new Date(leaveToApprove?.date_from)
            ? "Approve"
            : ""
        }
        isCancelButton={false}
        deleteButtonText={
          new Date(Utils._date()) <= new Date(leaveToApprove?.date_from)
            ? "Reject"
            : ""
        }
        updateData={() => handleApproveReject(1)}
        deleteData={() => handleApproveReject(0)}
        padding={20}
      />
    </div>
  );
};

export default LeaveApproval;
