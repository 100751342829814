import { Outlet, useLocation } from "react-router-dom";
import { SelfServiceHeader } from "../../self-service";

const routeToHeading = {
  leave: "Self Service Leave Settings",
  general: "Self Service General Settings",
  "employee-users": "Self Service",
} as any;

const SelfServiceHome = () => {
  const { pathname } = useLocation();
  return (
    <div>
      <div className="hero_header">
        <h2>{routeToHeading[pathname.split("/").pop() as any]}</h2>
      </div>
      <SelfServiceHeader />
      <Outlet />
    </div>
  );
};

export default SelfServiceHome;
