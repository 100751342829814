import { useEffect, useState } from "react";
import Modal from "react-modal";
import { AiOutlineCloseCircle } from "react-icons/ai";

import "./add-company.css";
import { CompanyService } from "../../_service";
import { Notify } from "../../utils";
import { TextInput } from "../formField";
import { IconContext } from "react-icons";
import { useAppDispatch } from "../../_app";
import { resetUser } from "../../_features";

interface CompanyDataProps {
  id: string;
  name: string;
  reg_id: string;
  address: string;
}

interface AddCompanyModalProps {
  open: boolean;
  handleClose: () => void;
  updateData: () => void;
  data: CompanyDataProps;
}

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #283a44",
    borderRadius: "10px",
  },
};

const EditCompanyModal = (props: AddCompanyModalProps) => {
  const dispatch = useAppDispatch();
  const { handleClose, open, updateData, data } = props;

  const [registrationId, setRegistrationId] = useState(data.reg_id);
  const [companyName, setCompanyName] = useState(data.name);
  const [companyAddress, setCompanyAddress] = useState(data.address);

  useEffect(() => {
    setRegistrationId(data.reg_id);
    setCompanyName(data.name);
    setCompanyAddress(data.address);
  }, [data.reg_id, data.name, data.address]);

  const handleEditCompany = async () => {
    if (companyAddress !== "" && companyName !== "" && registrationId !== "") {
      handleClose();
      try {
        const res = await CompanyService._updateCompany({
          id: data.id,
          data: {
            name: companyName.toUpperCase(),
            address: companyAddress.toUpperCase(),
            reg_id: registrationId.toUpperCase(),
          },
        });
        if (res.status === 200) {
          setRegistrationId("");
          setCompanyAddress("");
          setCompanyName("");
          Notify("Company Updated Successfully", 1);
          updateData();
        } else {
          Notify("Company Adding Failed", 0);
        }
      } catch (e: any) {}
    } else {
      Notify("Field cannot be empty", 0);
    }
  };
  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Add Company"
      ref={(e) => e}
      ariaHideApp={false}
    >
      <div className="add-company-modal">
        <div className="header">
          <h2>Edit Company</h2>
          <p className="close-icon" onClick={handleClose}>
            <IconContext.Provider value={{ color: "#f00", size: "30px" }}>
              <AiOutlineCloseCircle />
            </IconContext.Provider>
          </p>
        </div>
        <div className="line" />
        <div className="body">
          <TextInput
            labal="Company Registration ID"
            value={registrationId}
            onChange={setRegistrationId}
          />
          <TextInput
            labal="Company Name"
            value={companyName}
            onChange={setCompanyName}
          />
          <div className="wrapper">
            <label>Company Address</label>
            <textarea
              className="textarea"
              rows={3}
              value={companyAddress}
              placeholder="[Block Number], [Street Number], #[Unit Level]-[Unit Number] [Building Name] Singapore [Postal Code]"
              onChange={(e) => setCompanyAddress(e.target.value)}
            />
          </div>
        </div>
        <div className="line" />
        <div className="footer">
          <button className="btn-rev modal-btn" onClick={handleClose}>
            Close
          </button>
          <button className="btn modal-btn" onClick={handleEditCompany}>
            Update
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditCompanyModal;
