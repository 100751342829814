import { useState, useEffect } from "react";
import {
    CustomButton,
    CustomNumberField,
    CustomSelectField,
    NavigateBack,
} from "../../components";
import { Notify, Utils } from "../../utils";
import { H3Heading } from "../appSettings/AppSettingsStyles";
import { ManageCPFCal, ManageDeductionService } from "../../_service";
import { useNavigate } from "react-router-dom";
import { Constant } from "../../config";
import { DeductionTypeProps } from "../../_types";

const AddManageDeduction = () => {
    const [deductionTypeData, setDeductionTypeData] = useState<DeductionTypeProps[]>([]);
    const [minAmount, setMinAmount] = useState("");
    const [maxAmount, setMaxAmount] = useState("");
    const [amount, setAmount] = useState("");
    const [otherAmount, setOtherAmount] = useState("0.00");
    const [loading, setLoading] = useState(false);
    const [deductionType, setDeductionType] = useState(-1);
    const [mbmfValue,setMbmfValue] = useState('0.00')
    console.log(deductionType);

    const navigate = useNavigate();

    useEffect(() => {
        const getDeductionCategory = async () => {
            setLoading(true);
            try {
                const res = await ManageDeductionService._getDeductionCategory();
                if (res.status === 200) {
                    setDeductionTypeData(res.data);
                    if (res.data.length > 0) setDeductionType(res.data[0].id);
                }
            } catch (e: any) {
                if (e?.response?.status === 401) {
                    Notify("Unauthorized access", 0);
                } else {
                    Notify("Failed to load deduction categories", 0);
                }
            } finally {
                setLoading(false);
            }
        };
        getDeductionCategory();
    }, []);


    const addManageDeduction = async () => {
        try {
            const { status } = await ManageCPFCal._addMangeDeduction({
                deduction_name: deductionType,
                amount: amount,
                other: otherAmount,
                min_amount: minAmount,
                max_amount: maxAmount,
            });


            if (status === 201) {
                Notify("Service code added successfully", 1);
                navigate("/dashboard/manage-deduction");
            }
        } catch (error: any) {
            if (error?.response?.data?.name?.message) {
                Notify(error.response.data.name.message, 0);
            } else {
                Notify(Constant.common.something_went_wrong, 0);
            }
        }
    };

    useEffect(() => {
        setMbmfValue((parseFloat(otherAmount) - parseFloat(amount)).toString());
    }, [amount, otherAmount]);
    

    return (
        <div>
            <NavigateBack />
            <H3Heading>Add Deduction</H3Heading>

            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <CustomSelectField
                        title="Select Deduction Category"
                        value={deductionType}
                        onChangeOption={(text: string) => setDeductionType(parseInt(text))}
                        option={deductionTypeData.map((item) => ({
                            key: item.race,
                            value: item.id,
                        }))}
                    />
                    <CustomNumberField
                        title="Minimum Amount"
                        value={minAmount}
                        onChangeText={setMinAmount}
                    />
                    <CustomNumberField
                        title="Maximum Amount"
                        value={maxAmount}
                        onChangeText={setMaxAmount}
                    />
                    <CustomNumberField
                        title="Amount"
                        value={amount}
                        onChangeText={setAmount}
                    />
                     {deductionType === 3 && (
                        <>
                            <CustomNumberField
                                title="Mendaki"
                                value={otherAmount}
                                onChangeText={setOtherAmount}
                            />
                            <CustomNumberField
                                onChangeText={setMbmfValue}
                                title="MBMF"
                                value={mbmfValue}
                                disabled={true}
                            />
                        </>
                    )}
                    <CustomButton
                        title="Add Deduction"
                        onClick={addManageDeduction}
                        styles={{ marginTop: 50, marginLeft: 0 }}
                        width="full"
                    />
                </>
            )}
        </div>
    );
};

export default AddManageDeduction;
