import { IconContext } from "react-icons";
import { IoCaretBackCircle, IoCaretForwardCircle } from "react-icons/io5";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import Require from "../../../components/customFormField/Require";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getYear = (date: any) => {
  return new Date(date).getFullYear();
};

const getMonth = (date: any) => {
  return months[new Date(date).getMonth()];
};

const range = (a: any, b: any) => {
  let data = [];
  for (let i = a; i <= b; i++) {
    data.push(i);
  }
  return data;
};
const LeaveDatePicker = (props: {
  label?: string;
  date: any;
  setDate: any;
  error?: string;
  note?: string;
  require?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  filterDate: (date: any) => any;
  minDate?: Date | null;
  margin?: string;
}) => {
  const years = range(1900, new Date().getFullYear());

  return (
    <Container margin={props?.margin || ".8rem"}>
      {props?.label && (
        <Label>
          {props.label}
          {props.require && <Require />}
        </Label>
      )}

      <div style={{ width: props?.label ? "60%" : "100%" }}>
        <Select>
          <DatePicker
            minDate={props.minDate}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            filterDate={(date) => props.filterDate(date)}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }): any => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  style={{ border: "none" }}
                >
                  <IconContext.Provider value={{ color: "#000", size: "20px" }}>
                    <IoCaretBackCircle />
                  </IconContext.Provider>
                </button>
                <select
                  value={getYear(date)}
                  onChange={({ target: { value } }: any) => changeYear(value)}
                  onFocus={props.onFocus}
                  onBlur={props.onBlur}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={getMonth(date)}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  style={{ border: "none" }}
                >
                  <IconContext.Provider value={{ color: "#000", size: "20px" }}>
                    <IoCaretForwardCircle />
                  </IconContext.Provider>
                </button>
              </div>
            )}
            dateFormat="dd/MM/yyyy"
            selected={props.date ? new Date(props.date) : null}
            onChange={(date: any) =>
              props.setDate(date.toISOString().split("T")[0])
            }
          />
        </Select>
        {props.error ? (
          <Error>{props.error || ""}</Error>
        ) : (
          props.note && <Note>{props.note}</Note>
        )}
      </div>
    </Container>
  );
};

export default LeaveDatePicker;

const Container = styled.div<any>`
  width: 100%;
  display: flex;
  margin-top: ${(props) => props.margin};
`;
const Label = styled.label`
  width: 30%;
  max-width: 250px;
  margin-right: 0.25rem;
`;
const Select = styled.div`
  width: 100%;
`;
const Error = styled.p`
  font-size: 14px;
  line-height: 14px;
  color: red;
  height: 14px;
`;
const Note = styled.p`
  font-size: 14px;
  line-height: 14px;
  color: grey;
  min-height: 14px;
`;
