import React, { useEffect, useState } from "react";
import { LeaveService } from "../../../_service";
import { useAppSelector } from "../../../_app";
import { CustomButton } from "../../../components/customButton";
import { BsPlusCircleFill } from "react-icons/bs";
import styled from "styled-components";
import { LeaveAdjustmentRow } from "../component";
import { Loader } from "../../../components";

const LeaveAdjustments = () => {
  const id = useAppSelector((state) => state.employee.employee_info.id);
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);

  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    try {
      setLoading(() => true);
      const res = await LeaveService._getAdjustmentActivity(companyId, id);
      setData(() => res.data.data);
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
      console.error(error);
    }
  };
  const addData = async (
    leave_type: any,
    date: any,
    days: any,
    description: any,
    name: any,
    _id: any,
    expiry_date: any,
  ) => {
    try {
      const data = {
        company: companyId,
        employee: id,
        leave_type,
        date,
        days,
        description,
        expiry_date,
      };
      const res = await LeaveService._postAdjustmentActivity(data);
      return { res: res.data.data, type: leave_type, name, id: _id };
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const deleteData = async (
    leave_type: any,
    date: any,
    days: any,
    description: any,
    name: any,
    _id: any,
    expiry_date: any,
  ) => {
    try {
      const data = {
        company: companyId,
        employee: id,
        leave_type,
        date,
        days,
        description,
        expiry_date,
      };
      const res = await LeaveService._deleteAdjustmentActivity(data, _id);
      return { res: res.data, type: leave_type, name, id: _id };
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const updateData = async (
    leave_type: any,
    date: any,
    days: any,
    description: any,
    name: any,
    _id: any,
    expiry_date: any,
  ) => {
    try {
      const data = {
        company: companyId,
        employee: id,
        leave_type,
        date,
        days,
        description,
        expiry_date,
      };
      const res = await LeaveService._editAdjustmentActivity(data, _id);
      return { res: res.data, type: leave_type, name, id: _id };
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  const handleAddLeave = (item: any) => {
    let temp = JSON.parse(JSON.stringify(data));
    const _ = temp?.[`${item[0]}`]?.list;
    let _id = _.length > 0 ? Math.max(..._.map((item: any) => item.id)) : 0;
    _.push({ edit: true, leave_type: item[1].id, id: _id + 5, name: item[0] });
    setData(() => temp);
  };
  const handleCancelAdd = (item: any, update: boolean = false) => {
    let temp = JSON.parse(JSON.stringify(data));
    const _ = temp?.[`${item.name}`]?.list;
    Array.from(Array(_.length).keys())
      .reverse()
      .forEach((index) => {
        _[index].id === item.id && _.splice(index, 1);
      });
    setData(() => temp);
    if (update) {
      handleAddItemByLeaveName(temp, item.name, item.res);
    }
  };
  const handleAddItemByLeaveName = (_list: any, name: string, _data: any) => {
    let temp = JSON.parse(JSON.stringify(_list));
    const _ = temp?.[`${name}`]?.list;
    _.push(_data);
    setData(() => temp);
  };
  const handleDelete = async (data: any) => {
    const res = await deleteData(
      data.leave_type,
      data.date,
      data.days,
      data.description,
      data.name,
      data.id,
      data.expiry_date,
    );
    if (JSON.stringify(res) === "{}") {
      return false;
    }
    handleCancelAdd(res);
    return true;
  };
  const handleAddData = async (data: any) => {
    const res = await addData(
      data.leave_type,
      data.date,
      data.days,
      data.description,
      data.name,
      data.id,
      data.expiry_date,
    );
    if (JSON.stringify(res) === "{}") {
      return false;
    }
    handleCancelAdd(res, true);
    return true;
  };
  const handleUpdate = async (data: any) => {
    const res = await updateData(
      data.leave_type,
      data.date,
      data.days,
      data.description,
      data.name,
      data.id,
      data.expiry_date,
    );
    if (JSON.stringify(res) === "{}") {
      return false;
    }
    return true;
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      {Object.entries(data || {})?.length < 1 && (
        <p>No Leave Adjustment found</p>
      )}
      {loading && <Loader />}
      {Object.entries(data || {}).map((item: any) => {
        return (
          <div>
            <Heading>{item[0] || ""}</Heading>
            <Table>
              <thead>
                <tr>
                  <th style={{width: '15%'}}>Date</th>
                  <th style={{width: '15%'}}>Number of Days</th>
                  <th style={{width: '45%'}}>Description</th>
                  <th style={{width: '20%'}}>Expiry Date (optional)</th>
                  <th style={{width: '5%'}}></th>
                </tr>
              </thead>
              {item?.[1]?.list?.map((list: any) => {
                return (
                  <LeaveAdjustmentRow
                    key={`${list.id} ${list.leave_type}`}
                    data={{ ...list, name: item[0] }}
                    onDelete={handleDelete}
                    onAddData={handleAddData}
                    onUpdate={handleUpdate}
                    cancelAdd={handleCancelAdd}
                  />
                );
              })}
            </Table>
            <div style={{ margin: "1rem 0rem" }}>
              <CustomButton
                type="secondary"
                size="small"
                width="100px"
                success={true}
                title="Add"
                onClick={() => handleAddLeave(item)}
                icon={<BsPlusCircleFill />}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LeaveAdjustments;

const Heading = styled.h5`
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.25rem;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  margin-top: 1rem;
  font-size: 0.9rem;
  thead tr {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    padding: 0.75rem;
  }
  th {
    padding: 6px 12px;
  }
  td {
    padding: 6px 12px;
  }
  tbody tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
    border-top: "1px solid #dee2e6";
  }
`;
