import { IconContext } from "react-icons";
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi";
import { TiMediaPlay, TiMediaPlayReverse } from "react-icons/ti";

interface TableFilterHeaderProps {
  name: string;
  pageInfo: {
    pageNumber: number;
    pagePerData: number;
    totalLength: number;
  };
  handlePageValueChange: (val: "next" | "previous" | "first" | "last") => void;
  setPageInfo: (prev: any) => any;
}
const TableFilterHeader = (props: TableFilterHeaderProps) => {
  const { handlePageValueChange, name, pageInfo, setPageInfo } = props;
  return (
    <div
      style={{
        display: "flex",
        alignContent: "space-between",
        width: "100%",
        border: "1px solid gray",
        padding: "3px .6rem",
        borderTopLeftRadius: ".3rem",
        borderTopRightRadius: ".3rem",
        fontSize: '0.8125rem'
      }}
    >
      <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
        {name}:{" "}
        {`${
          pageInfo.totalLength === 0
            ? 0
            : pageInfo.pageNumber * pageInfo.pagePerData -
              pageInfo.pagePerData +
              1
        }-${
          pageInfo.pageNumber * pageInfo.pagePerData > pageInfo.totalLength
            ? pageInfo.totalLength
            : pageInfo.pageNumber * pageInfo.pagePerData
        }`}{" "}
        / {pageInfo.totalLength}
      </div>
      <div
        style={{
          width: "55%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <span onClick={() => handlePageValueChange("first")} style={{cursor: 'pointer'}}>
          <IconContext.Provider value={{ color: "#000", size: "25px" }}>
            <BiSkipPrevious />
          </IconContext.Provider>
        </span>
        <span onClick={() => handlePageValueChange("previous")} style={{cursor: 'pointer'}}>
          <IconContext.Provider value={{ color: "#000", size: "20px" }}>
            <TiMediaPlayReverse />
          </IconContext.Provider>
        </span>
        Page
        <select
          style={{
            border: "none",
            padding: 0,
            margin: ".2rem",
            cursor: "pointer",
            fontSize: "0.8125rem"
          }}
          value={pageInfo.pageNumber}
          onChange={(e) => {
            setPageInfo((prev: any) => ({
              ...prev,
              pageNumber: parseInt(e.target.value),
            }));
          }}
        >
          {Array(Math.ceil(pageInfo.totalLength / pageInfo.pagePerData))
            .fill(0)
            .map((_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
        </select>
        <span>of</span>
        <span> {Math.ceil(pageInfo.totalLength / pageInfo.pagePerData)} </span>
        <span onClick={() => handlePageValueChange("next")} style={{cursor: 'pointer'}}>
          <IconContext.Provider value={{ color: "#000", size: "20px" }}>
            <TiMediaPlay />
          </IconContext.Provider>
        </span>
        <span onClick={() => handlePageValueChange("last")} style={{cursor: 'pointer'}}>
          <IconContext.Provider value={{ color: "#000", size: "25px" }}>
            <BiSkipNext />
          </IconContext.Provider>
        </span>
      </div>
      <div style={{ width: "100%" }}></div>
    </div>
  );
};

export default TableFilterHeader;
