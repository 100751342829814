import { useAppSelector } from "../../_app";
import { Heading } from "../../pages/appSettings/AppSettingsStyles";
import { CustomButton } from "../customButton";
import { HeadingContainer } from "./HeadingStyles";

interface ManageTableHeadingProps {
  title: string;
  buttonTitle?: string;
  onClick: () => void;
}

const ManageTableHeading = (props: ManageTableHeadingProps) => {
  const { title, buttonTitle, onClick } = props;

  const user = useAppSelector((state) => state.user);

  return (
    <HeadingContainer>
      <Heading>{title}</Heading>
      {buttonTitle && user.permission === "full" && (
        <CustomButton title={buttonTitle} type="secondary" onClick={onClick} />
      )}
    </HeadingContainer>
  );
};

export default ManageTableHeading;
