import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../_app";
import { PayrollService } from "../../../_service";
import { Notify, Utils } from "../../../utils";
import {
  CustomDateField,
  CustomSelectField,
  CustomeCkeckboxField,
} from "../../../components/customFormField";
import { CustomButton } from "../../../components/customButton";
import { FormErrorMessage } from "../../../components";
import { Constant } from "../../../config";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { updateCurrentEmployee } from "../../../_features/employee";

const ManageEndService = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const employee = useAppSelector((state) => state.employee.employee_info);
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const currentEmployee = useAppSelector((state) => state.employee.currentEmployee);

  const [reasons, setReasons] = useState([]);
  const [historyData, setHistoryData] = useState([]);

  const [reason, setReason] = useState("");
  const [lastDayOfService, setLastDayOfService] = useState();
  const [requireFormIr21, setRequireFormIr21] = useState<boolean>(false);
  const [eosId, setEosId] = useState<any>(null)

  const [errorCollection, setErrorCollection] = useState<any>({});
  const updateErrorByKey = (key: string, val?: string) => {
    if (val) {
      setErrorCollection((prev: any) => ({ ...prev, [key]: val || "" }));
      return;
    }
    delete errorCollection[key];
    setErrorCollection(errorCollection);
  };
  const handleDataUpdate = async () => {
    try {
      if (!lastDayOfService) {
        updateErrorByKey("last_day_of_service", "can't be empty");
        return;
      }
      if (reason === "") {
        updateErrorByKey("reason_for_termination", "can't be empty");
        return;
      }
      setErrorCollection({});
      const data = {
        employee: employee.id,
        company: companyId,
        end_service_reason: reason,
        last_date_of_service: Utils._date(lastDayOfService),
        require_form_ir21: requireFormIr21,
      };
      const res = await PayrollService._updateEndService(data);
      if (res.status === 200) {
        Notify("End Service updated successfully", 1);
        handleNavigateBack();
      }
    } catch (e: any) {
      if (e.response.status === 401) {
        // disaptch(resetUser());
      }
    }
  };

  const handleNavigateBack = () => {
    navigate("/dashboard/payroll/emp");
  };
  // const get = async () => {
  //   try {
  //     const { data } = await PayrollService._getEndServiceReasons();
  //     setReasons(() => data.data);
  //   } catch (e: any) {
  //     if (e.response.status === 401) {
  //       // disaptch(resetUser());
  //     }
  //   }
  // };

  const getEndServiceDetails = async () => {
    try {
      const { data, status } = await PayrollService._getEndService({
        employee: employee.id,
        companyId,
      });

      if (status >= 200 && status < 300) {
        if (data.data?.end_service_reason) {
          setLastDayOfService(() => data.data.last_date_of_service);
          setEosId(data?.data?.id);
        }
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const getServicePeriodHistory = async () => {
    try {
      const { data, status } = await PayrollService._getServicePeriodHistory({
        employee: employee.id,
        companyId,
      });

      if (status >= 200 && status < 300) {
        setHistoryData(data?.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const handleUndoTermination = async () => {
    try {
      const {status} = await PayrollService._undoTermination(eosId);
      if(status >= 200 && status < 300){
        Notify("Termination Cancelled",1);
        dispatch(
          updateCurrentEmployee({
            currentEmployee: {
              ...currentEmployee,
              eos: null,
            },
          })
        )
        // navigate("/dashboard/payroll/emp")
        navigate("/dashboard/employee-payroll")
        
      }
    } catch (error:any){
      Notify(Constant.common.something_went_wrong, 0);
    }
  }

  useEffect(() => {
    // get();
    getEndServiceDetails();
    getServicePeriodHistory();
  }, []);

  const listItems = [
    {
      id: 'cancel',
      title: 'Cancel / Undo Termination',
      content: 'This will remove all records of the termination and allow you to continue with payslips for the employee as normal.'
    },
    {
      id: 'update',
      title: 'Update Termination Date / Reason',
      content: 'Allows you to adjust this information if needed.'
    },
    {
      id: 'reinstate',
      title: 'Re-instate the Employee',
      content: 'Use this option if an employee was in fact previously terminated, but is now being employed again. The system will record a new service period for the employee and will ask you for a new appointment date. You should also use this for employees returning from long-term absences during which they were still employed.'
    }
  ];


  return (
    <div style={{ marginTop: "2rem",  }}>
      <div style={{marginBottom: '3rem'}}>
        <p style={{marginBottom:'1rem'}}>
         { `The employee's last day of service is ${lastDayOfService || ""}. You have the
          following options available:`}
        </p>

        <ul style={{marginLeft: '1.5rem'}}>
          {listItems.map((item, index) => (
            <li key={index} style={{marginBottom: '0.5rem'}}>
              <p onClick={()=>{
                if (item?.id === 'cancel') {
                  handleUndoTermination();
                }
                if (item?.id === 'update') {
                  // to be done
                  navigate("/dashboard/payroll/end-service")
                }
                if (item?.id === 'reinstate') {
                  // to be done
                }
              }} style={{color: `${item?.id === "reinstate" ? "#b3b3b3" : "#059bbf"}`, fontWeight: 'bold', cursor: `${item?.id === "reinstate" ? "not-allowed" : "pointer"}`}}>{item.title}</p>
              <p>{item.content}</p>
            </li>
          ))}
        </ul>
      </div>

      <p style={{fontSize: '1.75rem', color: '#476575', fontWeight: 600, marginBottom: '0.5rem'}}>Service Period History</p>
      
      <Table size="small" aria-label="full width table">
      <TableHead>
        <TableRow>
          <TableCell align="left" colSpan={1} sx={{fontWeight: 'bold', fontSize:  '1rem', padding:'0.8rem'}}>Service Period</TableCell>
          <TableCell align="left" colSpan={1} sx={{fontWeight: 'bold', fontSize:  '1rem', padding:'0.8rem'}}>Form Requirements</TableCell>
          <TableCell align="center" colSpan={1} sx={{fontWeight: 'bold', fontSize:  '1rem', padding:'0.8rem'}}>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {historyData?.length > 0 && historyData?.map((item:any) => <TableRow>
          <TableCell sx={{padding:'0.8rem', fontSize:  '1rem'}} align="left">{`From ${item?.service_start_date} to ${item?.service_end_date}`}</TableCell>
          <TableCell sx={{padding:'0.8rem', fontSize:  '1rem'}} align="left">{item?.require_form_ir21 ? "Form IR21 Required": "Form IR21 Not Required"}</TableCell>
          <TableCell sx={{padding:'0.8rem', fontSize:  '1rem'}} align="center"> &mdash; </TableCell>
        </TableRow>)}
        
      </TableBody>
    </Table>
      
    </div>
  );
};

export default ManageEndService;
