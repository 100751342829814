import apiClient from "./BaseService";

import { URL } from "../config";
import { UpdateDeductionDataProps } from "../_types";

const _getDeductionCategory = async () =>
  apiClient.get(URL.GET_DEDUCTION_CATEGORY);

const _getDeductionDataByCategory = async (id: number) =>
  apiClient.get(URL.GET_DEDUCTION_DATA_BY_CATEGORY + id);

const _updateDeductionData = async (params: UpdateDeductionDataProps) =>
  apiClient.patch(URL.UPDATE_DEDUCTION_DATA + params.id + "/", params.data);

const _deleteDeductionData = async (id : number)=> 
  apiClient.delete(URL.DELETE_MANAGE_DEDUCTION+ id + '/')

export const ManageDeductionService = {
  _getDeductionCategory,
  _getDeductionDataByCategory,
  _updateDeductionData,
  _deleteDeductionData,
};
