import { useAppSelector } from "../../../_app";
import { ReportService } from "../../../_service/ReportService";
import { Constant, URL } from "../../../config";
import { Notify } from "../../../utils";
import ReportFilterPage from "../ReportFilterPage";

const LeaveExpiry = () => {
  const user = useAppSelector((state) => state.user);

  const downloadFile = async (queryString: string, fileType: string) => {
    try {
      const { data, status } = await ReportService._leaveExpiryReport(
        queryString
      );
      if (status === 200) {
        const blob = new Blob([data], {
          type: fileType === "pdf" ? "application/pdf" : "text/csv",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${
          user.defaultCompany.split(" ")[0]
        }_leave_expiry_report.${fileType === "pdf" ? "pdf" : "csv"}`; // Set the desired filename for the downloaded file
        document.body.appendChild(a);
        a.click();
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };
  return (
    <div>
      <ReportFilterPage downloadFunc={downloadFile} baseUrl={URL.DOWNLOAD_LEAVE_EXPIRY_REPORT}/>
    </div>
  );
};

export default LeaveExpiry;
