import { useSelector } from "react-redux";
import { Notify, Utils } from "../../utils";
import { updateUserIntialState } from "../../_features";
import { AuthService } from "../../_service";
import { store } from "../../_app";
import LogoutModal from "./LogoutModal";

const LoginSessionExpiredModal = () => {
  const userState = useSelector((state: any) => state.user);
  const isModalOpen: boolean = userState.access && !userState.isSessionActive;

  const logoutUser = () => {
    Utils.resetStoreData();
  };

  const keepLoggedIn = async () => {
    try {
      const tokenResponse = await AuthService._refressToken();
      const payloadData = {
        access: tokenResponse.data.access,
        refresh: tokenResponse.data.refresh,
        isSessionActive: true,
        lastLoggedInTime: Date.now(),
      };
      store.dispatch(updateUserIntialState(payloadData));
      window.location.reload();
    } catch (error) {
      Notify("Some Error occured, logging out due to security reasons");
      logoutUser();
    }
  };

  return (
    <LogoutModal
      open={isModalOpen}
      closeLogoutModal={keepLoggedIn}
      onLogout={logoutUser}
      variant="timeout"
    />
  );
};

export default LoginSessionExpiredModal;
