import { useAppSelector } from "../../../_app";
import { TableHeader } from "../../../components/table";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableRow } from "../../payslip/PayslipStyles";
import styled from "styled-components";

const LeaveTakeOns = () => {
  const user = useAppSelector((state) => state.user);

  const tableHeaders = [
    {
      name: "Type",
      width: "8%",
    },
    {
      name: "End of take-on period",
      width: "8%",
    },
    {
      name: "Cycle Start",
      width: "8%",
    },
    {
      name: "Cycle End",
      width: "8%",
    },
    {
      name: "Accrual",
      width: "6%",
    },
    {
      name: "Brought Forward (previous cycle)",
      width: "10%",
    },
    {
      name: "Taken plus adjustments",
      width: "10%",
    },
    {
      name: "Taken (Not on calendar)",
      width: "10%",
    },
    {
      name: "Closing balance calculated",
      width: "10%",
    },
    {
      name: "Closing balance override",
      width: "10%",
    },
  ];

  return (
    <div>
      {/* <h2>Coming soon...</h2> */}
      {/* <h2>Leave Take ons for {user.name}</h2> */}
      <IRASTable style={{ marginTop: 10 }}>
        <TableHeader headerColumns={tableHeaders} variant="filled" />
        <tbody>
          {demoData?.map((item: any) => (
            <TableRow>
              <TableColumn width="8%">{item?.leave_type_name}</TableColumn>
              <TableColumn width="8%">{item?.end_of_take_on_period}</TableColumn>
              <TableColumn width="8%">{item?.cycle_start}</TableColumn>
              <TableColumn width="8%">{item?.cycle_end}</TableColumn>
              <TableColumn width="6%">{item?.accrual}</TableColumn>
              <TableColumn>{item?.allow_brought_forward}</TableColumn>
              <TableColumn>{item?.leave_taken_plus_adjustments}</TableColumn>
              <TableColumn>{item?.unrecorded_taken_leave}</TableColumn>
              <TableColumn>{item?.closing_balance}</TableColumn>
              <TableColumn>{item?.closing_balance_override}</TableColumn>
            </TableRow>
          ))}
        </tbody>
      </IRASTable>
    </div>
  );
};

const demoData = [
  {
    company: 1,
    employee: 1,
    leave_type: 1,
    leave_type_name: "Annual",
    end_of_take_on_period: "2024-12-31",
    cycle_start: "2025-01-01",
    cycle_end: "2025-12-31",
    accrual: 10.5,
    brought_forward: 5.0,
    allow_brought_forward: true,
    leave_taken_plus_adjustments: 2.5,
    unrecorded_taken_leave: 1.0,
    closing_balance: 12.0,
    closing_balance_override: null,
  },
  {
    company: 1,
    employee: 2,
    leave_type: 2,
    leave_type_name: "Annual",
    end_of_take_on_period: "2024-12-31",
    cycle_start: "2025-01-01",
    cycle_end: "2025-12-31",
    accrual: 12.0,
    brought_forward: 6.0,
    allow_brought_forward: false,
    leave_taken_plus_adjustments: 3.0,
    unrecorded_taken_leave: 1.5,
    closing_balance: 14.0,
    closing_balance_override: null,
  },
];

export default LeaveTakeOns;


const TableColumn = styled.td<{ alignment?: string, width? : string}>`
  text-align: ${props => props.alignment || 'left'};
  padding: .625rem;
  width: ${props => props.width || '10%'};
`;