import apiClient from "./BaseService";

import { URL } from "../config";
import { UpdateDeductionDataProps } from "../_types";

const _getSDL = async () => apiClient.get(URL.GET_SDL);

const _updateSDL = async (params: UpdateDeductionDataProps) =>
  apiClient.patch(URL.UPDATE_SDL + params.id + "/", params.data);

export const ManageSDLService = {
  _getSDL,
  _updateSDL,
};
