import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Heading } from "./AppSettingsStyles";
import { useAppSelector } from "../../_app";
import { CompanyInfoModal } from "../../components";
import { NavOptions } from "../dashboard/component";

const activeStyle = {
  color: "#212529",
  borderBottom: "3px solid #25b481",
};
const AppSettings = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const defaultCompany = useAppSelector((state) => state.user.defaultCompany);
  const id = useAppSelector((state) => state.employee.appSettingId);
  const permission = useAppSelector((state) => state.user.permission);

  const onClickLeaveOption = (props: any) => {
    navigate(props.navigate);
  };

  const showCompanyInfoModalPath = [
    "employer-details",
    "employer-filing-details",
    "pay-frequencies",
  ];

  return (
    <div>
      {id === "" &&
        showCompanyInfoModalPath.includes(pathname.split("/").pop() || "") && (
          <CompanyInfoModal />
        )}
      <div>
        <Heading>
          {pathname?.slice(24)?.replaceAll("-", " ") === ""
            ? "Settings"
            : pathname?.slice(24)?.replaceAll("-", " ")}
        </Heading>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          borderBottom: "1px solid #6c757d",
          marginBottom: "5px",
        }}
      >
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="custom-items"
        >
          Custom Items
        </NavLink>
        {permission === "full" && (
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            className="employee-sublink"
            to="employee-numbers"
          >
            Employee Numbers
          </NavLink>
        )}
        {permission === "full" && (
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            className="employee-sublink"
            to="employer-details"
          >
            Employer Details
          </NavLink>
        )}
        {permission === "full" && (
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            className="employee-sublink"
            to="employer-filing-details"
          >
            Employer Filing Details
          </NavLink>
        )}
        {permission === "full" && (
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            className="employee-sublink"
            to="pay-frequencies"
          >
            Pay Frequencies
          </NavLink>
        )}
        {permission === "full" && (
          <NavOptions
            label="Payroll Calculations"
            val={location.pathname.slice(24)}
            name={(_: any) => null}
            values={[
              {
                displayName: "basic",
                name: "Basic Pay (incl. Sundays and Public Holidays)",
                navigate: "payroll-calculations/basic",
              },
              {
                displayName: "cpf",
                name: "CPF (Central Provident Fund)",
                navigate: "payroll-calculations/cpf",
              },
            ]}
            onChange={onClickLeaveOption}
          />
        )}
        {permission === "full" && (
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            className="employee-sublink"
            to="giro-settings"
          >
            GIRO
          </NavLink>
        )}
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="leave"
        >
          Leave
        </NavLink>
        {/* <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="custom-reports"
        >
          Custom Reports
        </NavLink> */}
      </div>
      <div>
        {pathname?.slice(24)?.replaceAll("-", " ") === "" ? (
          <div
            style={{
              marginTop: "2rem",
              textAlign: "center",
            }}
          >
            Edit settings affecting <b>{defaultCompany}</b> by selecting from
            the list above.
          </div>
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  );
};

export default AppSettings;

export { default as AddCustomItems } from "./components/AddCustomItems";
export { default as AddCustomeInputFields } from "./components/AddCustomeInputFields";
export { default as AppSettingsCustomItems } from "./CustomItems";
export { default as AppSettingsEmployeeDetails } from "./EmployeeDetails";
export { default as AppSettingsEmployeeFilingDetails } from "./EmployeeFilingDetails";
export { default as AppSettingsEmployeeNumbers } from "./EmployeeNumbers";
export { default as AppSettingsPayFrequencies } from "./PayFrequencies";
export { default as AppSettingsPayroll } from "./Payroll";
export { default as AppSettingsPayrollCalculations } from "./PayrollCalculations";
