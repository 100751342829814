import styled from "styled-components";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const TableColumnHeading = styled.th`
  text-align: left;
  width: 125px;
  padding: 10px 0;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
`;

const ActionColumn = styled.td`
  width: 150px;
`;

const TableColumn = styled.td`
  text-align: left;
  padding: 0.3rem;
  max-width: 400px;
`;

const ActionFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const CopyText = styled.span`
  cursor: pointer;
  color: #059bbf;
  &:hover {
    text-decoration: underline;
  }
`;

export {
  InputContainer,
  TableColumnHeading,
  TableColumn,
  ActionColumn,
  ActionFlex,
  CopyText,
};
