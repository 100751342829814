import React from "react";

const FormErrorMessage = ({ errorCollection, name = "" }: any) => {
  return Object.keys(errorCollection)?.length > 0 ? (
    <div
      style={{
        backgroundColor: "#f8d7da",
        color: "#721c24",
        padding: ".75rem 1.25rem",
        borderRadius: ".25rem",
        marginBottom: "1rem",
      }}
    >
      <h4 style={{ fontSize: "1.5rem", color: "#721c24" }}>
        {Object.keys(errorCollection) && Object.keys(errorCollection)?.length}{" "}
        errors prohibited this {name} from being saved
      </h4>
      <p
        style={{
          lineHeight: "1.5rem",
          fontSize: "1.25rem",
        }}
      >
        There were problems with the following fields:
      </p>
      <ul
        style={{
          lineHeight: "1.5rem",
          marginLeft: "2.5rem",
          fontSize: "1rem",
        }}
      >
        {Object.keys(errorCollection) &&
          Object.entries(errorCollection).map((errorData: any) => {
            return (
              <li key={errorData[0]} style={{ lineHeight: "1.5rem" }}>
                <span
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {errorData[0]?.replaceAll("_", " ")}
                  {" - "}
                </span>
                {errorData?.[1]}
              </li>
            );
          })}
      </ul>
    </div>
  ) : null;
};

export default FormErrorMessage;
