import React, { useEffect, useState } from "react";
import { PayrollService } from "../../_service";
import { Utils } from "../../utils";
import { useAppDispatch } from "../../_app";
import { resetUser } from "../../_features";
import styled from "styled-components";

interface RegularHoursLogProps {
  fixed_amount: number;
  hourly_rate_overide_bool: boolean;
  hourly_amount: number;
  hourly_paid_bool: boolean;
  updated_date: string;
  serial_no: string;
  first_name: string;
  last_name: string;
  company_name: string;
  name_format: string;
  archive: boolean;
  employee: number;
  rate_overide_hourly_amount: number;
}

const RegularHoursLog = () => {
  const dispatch = useAppDispatch();

  const [logs, setLogs] = useState<RegularHoursLogProps[]>([]);

  const get = async () => {
    try {
      const response = await PayrollService._getRegularHoursLog();
      if (response.status === 200) {
        setLogs(() => response.data.records);
      }
    } catch (e: any) {
      if (e.response.status === 401) {
        // disaptch(resetUser());
      }
    }
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <div>
      <div className="company-header-wrap">
        <h2>Regular Hours Log</h2>
      </div>
      <div className="table table-log">
        <table className="company-table company-table-log">
          <thead className="company-table-head company-table-head-log">
            <tr>
              <th>Serial No</th>
              <th>Company Name</th>
              <th>Employee Name(ID)</th>
              <th>Hourly Paid</th>
              <th>Hourly Amount</th>
              <th>Fixed Amount </th>
              <th>Override Hourly Rate</th>
              <th>Override Rate </th>
              <th>status </th>
              <th>updated date </th>
            </tr>
          </thead>
          <tbody>
            {logs.map((item) => {
              return (
                <tr key={item.serial_no}>
                  <td>{item.serial_no}</td>
                  <Td>{item.company_name}</Td>
                  <Td>
                    {Utils.getEmployeeName(
                      item.name_format,
                      item.first_name,
                      item.last_name,
                    )}{" "}
                    ({item.employee})
                  </Td>
                  <td>{`${item.hourly_paid_bool}`}</td>
                  <td>{`$${item.hourly_amount}`}</td>
                  <td>{`$${item.fixed_amount}`}</td>
                  <td>{`${item.hourly_rate_overide_bool}`}</td>
                  <td>{`${item.rate_overide_hourly_amount}`}</td>
                  <td>{`${item.archive}`}</td>
                  <td>{Utils.getFormatedDate(item.updated_date)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RegularHoursLog;

const Td = styled.td`
  max-width: 350px;
  word-wrap: break-word;
`;
