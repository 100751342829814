import { createTheme } from "@mui/material/styles";

import palette from "./palette";

// A custom theme for this app
const theme = createTheme({
  palette: palette,
});

export default theme;

// #39c3f6 sky blue
// #28a745 green
