import React, { useEffect, useState } from "react";
import { CustomButton, Loader } from "../../../components";
import { LeaveService } from "../../../_service";
import { useAppSelector } from "../../../_app";
import {
  BackButtonContainer,
  EntitlmentPolicyTableColumn,
  EntitlmentPolicyTableHeading,
  EntitlmentPolicyTableRow,
} from "./LeaveStyles";
import { useNavigate } from "react-router-dom";

const LeaveEntitlementPolicy = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allLeaveTypes, setAllLeaveTypes] = useState<any>([]);

  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  /**
   * Function to extract entitlemen policy range
   */
  const extractEntitlementPolicy = (leave: any) => {
    const activeEntitlementId =
      leave?.active_entitlement_policy[0]?.entitlement_policy;
    if (!activeEntitlementId) {
      return "";
    }
    const entitlementPolicyIndex = leave.entitlement_policy.findIndex(
      (entitlementPolicy: any) => entitlementPolicy.id === activeEntitlementId
    );
    return leave.entitlement_policy[entitlementPolicyIndex]?.name;
  };

  const getLeaveTypes = async () => {
    try {
      setIsLoading(true);
      const { status, data } = await LeaveService._getLeaveTypes(
        parseInt(user.defaultCompnayId)
      );
      if (status === 200) {
        setAllLeaveTypes(() => data.data);
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getLeaveTypes();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <table>
          <thead>
            <EntitlmentPolicyTableHeading>
              Leave Type
            </EntitlmentPolicyTableHeading>
            <EntitlmentPolicyTableHeading>
              Current Policy
            </EntitlmentPolicyTableHeading>
          </thead>
          <tbody>
            {allLeaveTypes.map((leave: any) => (
              <EntitlmentPolicyTableRow key={leave.id}>
                <EntitlmentPolicyTableColumn>
                  {leave?.name || ""}
                </EntitlmentPolicyTableColumn>
                <EntitlmentPolicyTableColumn>
                  {extractEntitlementPolicy(leave) || "-"}
                </EntitlmentPolicyTableColumn>
                <EntitlmentPolicyTableColumn>
                  <CustomButton
                    size="small"
                    width="50px"
                    title="Edit"
                    onClick={() => {
                      navigate("edit", {
                        state: { leaveId: leave.id, leaveName: leave.name },
                      });
                    }}
                  />
                </EntitlmentPolicyTableColumn>
              </EntitlmentPolicyTableRow>
            ))}
          </tbody>
        </table>
      )}
      <BackButtonContainer>
        <CustomButton
          type="secondary"
          size="small"
          width="50px"
          title="Back"
          onClick={() => navigate(-1)}
        />{" "}
      </BackButtonContainer>
    </div>
  );
};

export default LeaveEntitlementPolicy;
