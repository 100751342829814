import { useEffect, useState } from "react";
import {
  CustomButton,
  CustomDateField,
  CustomNumberField,
  CustomSelectField,
  CustomTextField,
  CustomeCkeckboxField,
  NavigateBack,
} from "../../../components";
import { Constant } from "../../../config";
import { H3Heading } from "../../appSettings/AppSettingsStyles";
import { Notify, Utils } from "../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { CPFService } from "../../../_service/CPFService";

const EditIRAS = () => {
  const [taxInfo, setTaxInfo] = useState({
    tax_born_by_employer: "na",
    exempt_remission_income_indicator: "ocla",
    exempt_income: "",
    pension: "0",
    retirement_benefits: "0",
    retirement_benefits_from_1993: "0",
    retirement_benefits_fund_name: "",
    benefits_in_kind_value: "",
    bonus_declaration_date: Utils._date(),
    director_fees_approval_date: Utils._date(),
    additional_director_fees: "",
    is_exclude_from_submission: false,
    income_for_employer_tax: "0",
    employee_tax: "0",
  });

  const navigate = useNavigate();
  const {
    state: { ir8aDetail },
  } = useLocation();

  const handleTaxInfoChange = (
    target: string,
    value: string | number | boolean
  ) => {
    setTaxInfo((prev) => ({ ...prev, [target]: value }));
  };

  const taxBorneOptions = [
    {
      key: "Not Applicable",
      value: "na",
    },
    {
      key: "Tax fully borne by employer on employment income only",
      value: "full",
    },
    {
      key: "Tax partially borne by employer on certain employment income items",
      value: "partial",
    },
    {
      key: "A fixed amount of income tax liability borne by employee",
      value: "fixed",
    },
  ];

  const exemptIcomeIndicator = [
    {
      key: "Tax Remission on Overseas Cost of Living Allowance (OCLA)",
      value: "ocla",
    },
    {
      key: "Seaman",
      value: "seaman",
    },
    {
      key: "Exemption",
      value: "exemption",
    },
    {
      key: "Overseas Pension Fund with Tax Concession",
      value: "overseas_pf",
    },
    {
      key: "Income from Overseas Employment",
      value: "overseas_income",
    },
    {
      key: "Income from Overseas Employment and Overseas Pension Fund with Tax Concession",
      value: "overseas_pf_income",
    },
  ];

  const editTaxCertificate = async () => {
    try {
      const { status } = await CPFService._editIr8aFiling(
        taxInfo,
        ir8aDetail.id
      );
      if (status === 200) {
        Notify("Filing updated successfully", 1);
        navigate("/dashboard/filing/iras");
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  const approveTaxCertificate = async () => {

    try {
      const { status } = await CPFService._editIr8aFiling(
        {is_approved: true},
        ir8aDetail.id
      );
      if (status === 200) {
        Notify("Filing updated successfully", 1);
        navigate("/dashboard/filing/iras");
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  useEffect(() => {
    setTaxInfo(() => ({
      tax_born_by_employer: ir8aDetail?.tax_born_by_employer || "na",
      exempt_remission_income_indicator:
        ir8aDetail?.exempt_remission_income_indicator || "ocla",
      exempt_income: ir8aDetail?.exempt_income || "0",
      pension: ir8aDetail?.pension || "0",
      retirement_benefits: ir8aDetail?.retirement_benefits || "0",
      retirement_benefits_from_1993:
        ir8aDetail?.retirement_benefits_from_1993 || "0",
      retirement_benefits_fund_name:
        ir8aDetail?.retirement_benefits_fund_name || "",
      benefits_in_kind_value: ir8aDetail?.benefits_in_kind_value || "0",
      bonus_declaration_date:
        ir8aDetail?.bonus_declaration_date || Utils._date(),
      director_fees_approval_date:
        ir8aDetail?.director_fees_approval_date || Utils._date(),
      additional_director_fees: ir8aDetail?.additional_director_fees || "0",
      is_exclude_from_submission:
        ir8aDetail?.is_exclude_from_submission || false,
      income_for_employer_tax: ir8aDetail?.income_for_employer_tax || "0",
      employee_tax: ir8aDetail?.employee_tax || "0",
    }));
  }, []);
  return (
    <div>
      <NavigateBack />
      <H3Heading>
        Edit Tax Certificate - {ir8aDetail.user?.name || ""} -{" "}
        {Utils._dateToShow(ir8aDetail?.date)}
      </H3Heading>
      <div>
        <CustomSelectField
          title={Constant.filing.iras.tax_borne}
          value={taxInfo.tax_born_by_employer}
          onChangeOption={(value) =>
            handleTaxInfoChange("tax_born_by_employer", value)
          }
          option={taxBorneOptions.map((taxBorneOption) => ({
            key: taxBorneOption.key,
            value: taxBorneOption.value,
          }))}
        />
        {taxInfo.tax_born_by_employer === "partial" && (
          <CustomNumberField
            title="Income for employer tax"
            value={taxInfo.income_for_employer_tax}
            onChangeText={(value) =>
              handleTaxInfoChange("income_for_employer_tax", value)
            }
          />
        )}
        {taxInfo.tax_born_by_employer === "fixed" && (
          <CustomNumberField
            title="Employee tax"
            value={taxInfo.employee_tax}
            onChangeText={(value) => handleTaxInfoChange("employee_tax", value)}
          />
        )}
        <CustomSelectField
          title={Constant.filing.iras.exempt_income_indicator}
          value={taxInfo.exempt_remission_income_indicator}
          onChangeOption={(value) =>
            handleTaxInfoChange("exempt_remission_income_indicator", value)
          }
          option={exemptIcomeIndicator.map((exemptIcomeIndicator) => ({
            key: exemptIcomeIndicator.key,
            value: exemptIcomeIndicator.value,
          }))}
        />
        <CustomNumberField
          title={Constant.filing.iras.exempt_income}
          value={taxInfo.exempt_income}
          onChangeText={(value) => handleTaxInfoChange("exempt_income", value)}
        />
        <CustomNumberField
          title={Constant.filing.iras.pension}
          value={taxInfo.pension}
          onChangeText={(value) => handleTaxInfoChange("pension", value)}
        />
        <CustomNumberField
          title={Constant.filing.iras.retirement_benefits}
          value={taxInfo.retirement_benefits}
          onChangeText={(value) =>
            handleTaxInfoChange("retirement_benefits", value)
          }
        />
        <CustomNumberField
          title={Constant.filing.iras.retirement_benefits_from}
          value={taxInfo.retirement_benefits_from_1993}
          onChangeText={(value) =>
            handleTaxInfoChange("retirement_benefits_from_1993", value)
          }
        />
        <CustomTextField
          title={Constant.filing.iras.fund_name}
          value={taxInfo.retirement_benefits_fund_name}
          onChangeText={(value) =>
            handleTaxInfoChange("retirement_benefits_fund_name", value)
          }
        />
        <CustomNumberField
          title={Constant.filing.iras.benefits_kind}
          value={taxInfo.benefits_in_kind_value}
          onChangeText={(value) =>
            handleTaxInfoChange("benefits_in_kind_value", value)
          }
        />
        <CustomDateField
          label={Constant.filing.iras.bonus_declaration}
          date={taxInfo.bonus_declaration_date}
          setDate={(value: any) =>
            handleTaxInfoChange("bonus_declaration_date", value)
          }
        />
        <CustomDateField
          label={Constant.filing.iras.director_approval}
          date={taxInfo.director_fees_approval_date}
          setDate={(value: any) =>
            handleTaxInfoChange("director_fees_approval_date", value)
          }
        />
        <CustomNumberField
          title={Constant.filing.iras.additional_fee}
          value={taxInfo.additional_director_fees}
          onChangeText={(value) =>
            handleTaxInfoChange("additional_director_fees", value)
          }
        />
        <CustomeCkeckboxField
          title={Constant.filing.iras.exclude}
          value={taxInfo.is_exclude_from_submission}
          onChange={() =>
            handleTaxInfoChange(
              "is_exclude_from_submission",
              !taxInfo.is_exclude_from_submission
            )
          }
        />
        <div style={{ display: "flex", gap: 20}}>
        <CustomButton
          title={Constant.common.save}
          onClick={() => editTaxCertificate()}
        />
        {/* <CustomButton
          title="Approve"
          type="secondary"
          success
          onClick={() => approveTaxCertificate()}
        /> */}
        </div>
      </div>
    </div>
  );
};

export default EditIRAS;
