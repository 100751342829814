import { toast } from "react-toastify";

const Notify = (message: string, state?: number, op?: any) => {

  let options = {...op};
  switch (state) {
    case 0:
      return toast.error(message, options);
    case 1:
      return toast.success(message, options);
    default:
      return toast(message, options);
  }
};
const NotifyPromise = async (res: any, msg: string) => {
  toast.promise(res, {
    pending: `${msg} is pending`,
    success: `${msg} is successful 👌`,
    error: `${msg} is failed 🤯`,
  });
};

export { Notify, NotifyPromise };
