import { useEffect, useState } from "react";
import { useAppSelector } from "../../../_app";
import { LeaveService } from "../../../_service";
import {
  ActiveEntitlementPolicyHeader,
  ActiveEntitlementPolicyHeaderLarge,
  ActiveEntitlementText,
  AddActiveEntitlementColumn,
  ButtonContainer,
  H3Heading,
} from "../../appSettings/AppSettingsStyles";
import { Constant } from "../../../config";
import { Notify } from "../../../utils";
import { LoaderContainer } from "../../../CommonStyles";
import {
  CustomButton,
  CustomDateField,
  CustomSelectField,
  Loader,
} from "../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { AllEntitlementSubHeading } from "./LeaveStyles";
import {
  ActiveEntitlementPolicy,
  EntitlementPolicy,
} from "../../../_types/LeaveInterface";

const LeaveAllEntitlementPolicies = () => {
  const user = useAppSelector((state) => state.user);
  const [activeEntitlementPolicy, setActiveEntitlementPolicy] = useState<
    ActiveEntitlementPolicy[]
  >([]);
  const [entitlementPolicy, setEntitlementPolicy] = useState<
    EntitlementPolicy[]
  >([]);
  const [selectedEntitlement, setSelectedEntitlement] = useState<number>(0);
  const [addActiveEntitlement, setAddActiveEntitlement] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [effectiveFrom, setEffectiveFrom] = useState<string>("");
  const [effectiveUntil, setEffectiveUntil] = useState<string>("");

  const { state } = useLocation();
  const navigate = useNavigate();

  /**
   * Function to Get list of all entitlement plicy
   */
  const getAllEntitlementPolicies = async () => {
    try {
      const { data, status } = await LeaveService._getAllEntitlementPolicies(
        state.leaveId
      );
      if (status === 200) {
        setEntitlementPolicy(() => data.data);
        setSelectedEntitlement(() => data.data[0]?.id || 0);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  /**
   * Function to list of all active-entitlement-policy
   */
  const getAllActiveEntitlementPolicies = async () => {
    try {
      const { data, status } =
        await LeaveService._getAllActiveEntitlementPolicies(state.leaveId);
      if (status === 200) {
        setActiveEntitlementPolicy(() => data.data);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  /**
   * Function to add active-entitlement-policy
   */
  const addActiveEntitlementPolicy = async () => {
    const formData = new FormData();

    if (effectiveFrom) {
      formData.append("effective_from", effectiveFrom);
    }
    if (effectiveUntil) {
      formData.append("effective_until", effectiveUntil);
    }

    formData.append("entitlement_policy", `${selectedEntitlement}`);
    formData.append("company", user?.defaultCompnayId);
    formData.append("leave_type", state.leaveId);

    try {
      const { data, status } = await LeaveService._addActiveEntitlementPolicy(
        formData
      );
      if (status === 201) {
        Notify("Active Entitlement Policy Created successfully", 1);
        setActiveEntitlementPolicy(() => [
          ...activeEntitlementPolicy,
          data.data,
        ]);
        setAddActiveEntitlement(() => false);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
  };

  useEffect(() => {
    getAllEntitlementPolicies();
    getAllActiveEntitlementPolicies();
  }, []);

  return isLoading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <>
      <H3Heading>Active Entitlement Policies for {user.name}</H3Heading>
      <AllEntitlementSubHeading>{state.leaveName}</AllEntitlementSubHeading>
      <IRASTable style={{ width: "60%" }}>
        <thead>
          <ActiveEntitlementPolicyHeader>
            <ActiveEntitlementText>Effective From</ActiveEntitlementText>
          </ActiveEntitlementPolicyHeader>
          <ActiveEntitlementPolicyHeader>
            <ActiveEntitlementText>Effective Until</ActiveEntitlementText>
          </ActiveEntitlementPolicyHeader>
          <ActiveEntitlementPolicyHeaderLarge>
            <ActiveEntitlementText>Entitlement policy</ActiveEntitlementText>
          </ActiveEntitlementPolicyHeaderLarge>
        </thead>
        <tbody>
          {activeEntitlementPolicy.map((activePolicy: any) => (
            <tr>
              <AddActiveEntitlementColumn>
                {activePolicy.effective_from || "-"}
              </AddActiveEntitlementColumn>
              <AddActiveEntitlementColumn>
                {activePolicy.effective_until || "-"}
              </AddActiveEntitlementColumn>
              <AddActiveEntitlementColumn>
                {
                  entitlementPolicy[
                    entitlementPolicy?.findIndex(
                      (policy) => policy.id === activePolicy.entitlement_policy
                    )
                  ]?.name
                }
              </AddActiveEntitlementColumn>
              <AddActiveEntitlementColumn
                style={{
                  display: "flex",
                  gap: 10,
                }}
              >
                <p>(company default)</p>
                {/* <CustomButton
                  title="Edit"
                  onClick={() => addActiveEntitlementPolicy()}
                  width="50px"
                  size="small"
                />
                <CustomButton
                  title="Delete"
                  type="secondary"
                  del
                  onClick={() => {
                    setIsActiveEntitlementDeletePopupActive(() => true);
                    setActiveEntitlementPolicyToDelete(() => activePolicy.id);
                  }}
                  width="70px"
                  size="small"
                /> */}
              </AddActiveEntitlementColumn>
            </tr>
          ))}

          {addActiveEntitlement ? (
            <tr>
              <AddActiveEntitlementColumn>
                <CustomDateField
                  date={effectiveFrom}
                  setDate={(date: any) => setEffectiveFrom(() => date)}
                  margin="0"
                />
              </AddActiveEntitlementColumn>
              <AddActiveEntitlementColumn>
                <CustomDateField
                  date={effectiveUntil}
                  setDate={(date: any) => setEffectiveUntil(() => date)}
                  margin="0"
                />
              </AddActiveEntitlementColumn>
              <AddActiveEntitlementColumn>
                <CustomSelectField
                  value={selectedEntitlement}
                  onChangeOption={(value: any) =>
                    setSelectedEntitlement(() => value)
                  }
                  option={entitlementPolicy.map((policy) => ({
                    key: policy.name,
                    value: policy.id,
                  }))}
                  margin="0"
                />
              </AddActiveEntitlementColumn>
              <AddActiveEntitlementColumn
                style={{
                  display: "flex",
                  gap: 10,
                }}
              >
                <CustomButton
                  title="Create"
                  onClick={() => addActiveEntitlementPolicy()}
                  width="70px"
                  size="small"
                />
                <CustomButton
                  title="Cancel"
                  type="secondary"
                  onClick={() => setAddActiveEntitlement(() => false)}
                  width="70px"
                  size="small"
                />
              </AddActiveEntitlementColumn>
            </tr>
          ) : null}
          {/* <DeactivateModal
            open={isActiveEntitlementDeletePopupActive}
            closeModal={() =>
              setIsActiveEntitlementDeletePopupActive(() => false)
            }
            approveAction={() => deleteActiveEntitlementPolicy()}
            variant="deleteActiveEntitlement"
          />  */}
        </tbody>
      </IRASTable>
      <ButtonContainer>
        <CustomButton
          title="Add Entitlement Policy Range"
          onClick={() => setAddActiveEntitlement(() => true)}
          width="250px"
          size="small"
        />
        <CustomButton
          title="Back"
          type={"secondary"}
          onClick={() => navigate(-1)}
          width="50px"
          size="small"
        />
      </ButtonContainer>
    </>
  );
};

export default LeaveAllEntitlementPolicies;
