import styled from "styled-components";

export const CommentBox = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 10px;
`;

export const ApprovalDetailsContainer = styled.div`
  display: flex;
  gap: 20px;
  font-weight: 400;
`

export const LeaveDetailsContainer = styled.div`
  background-color: #fff;
  border-radius: 5px;
  margin-top: 2rem;
  border: 1px solid #00000020;
  flex: 1;
  height: fit-content;
`

export const LeaveContainerHeading = styled.h3`
  color: #f8f9fa;
  background-color: #4a5e71;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  font-weight: 400;
`
export const LeaveDetailWrapper = styled.div`
  padding: 0.75rem 1.25rem;
`
export const LeaveDetailBigHeading = styled.h2`
  font-size: 20px;
  color: #33333;
  font-weight: 400;
`
export const LeaveDetailContent = styled.p`
  color: #33333;
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
`
export const LeaveTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  th {
    padding: 0.25rem;
  }
  td {
    height: 40px;
  }
  tr {
    background-color: #fff;
  }
  tbody {
    tr:nth-child(odd) {
      background-color: #f2f2f2;
    }
    tr:nth-child(even) {
      background-color: #fff;
    }
  }
`;

export const LeaveRecordSpan = styled.span`
  font-size: 0.8rem;
  color: #6c757d;
`;

export const LeaveApprovalHeading = styled.h2`
  font-size: 1.5rem;
  color: #476575;
  font-weight: 400;
`;

export const LeaveApprovalOptions = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0;
  display: flex;
  gap: 10px;
`