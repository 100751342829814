import React, { useState } from "react";

import "./style.css";
import { useAppSelector } from "../../_app";
import { CustomTextField } from "../../components/customFormField";
import { CustomButton } from "../../components/customButton";
import { AuthService } from "../../_service";
import { Notify } from "../../utils";
import { Constant } from "../../config";

const UserProfile = () => {
  const user = useAppSelector((state) => state.user);

  const [change, setChange] = useState(false);

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const groups = useAppSelector((state) => state.user.groups);

  const [password, setPassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [passwordError, setPasswordError] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const handlePasswordChange = async () => {
    if (password.old_password === "") {
      setPasswordError((p) => ({ ...p, old_password: "can't be empty" }));
    } else if (password.new_password === "") {
      setPasswordError((p) => ({ ...p, new_password: "can't be empty" }));
    } else if (password.confirm_password === "") {
      setPasswordError((p) => ({ ...p, confirm_password: "can't be empty" }));
    } else if (password.confirm_password !== password.new_password) {
      setPasswordError((p) => ({
        ...p,
        confirm_password: "Confirm password do not match",
      }));
    } else {
      try {
        await AuthService._changePasswordUser(password);
        Notify("Password change successfully");
        setPassword({
          old_password: "",
          new_password: "",
          confirm_password: "",
        });
        setChange(false);
      } catch (error) {
        Notify(Constant.common.something_went_wrong, 0);
        setPasswordError((p) => ({
          ...p,
          old_password: "Old Password is Wrong",
        }));
      }
    }
  };
  return (
    <div className="user-profile">
      <div className="title">
        <h2>Edit My User Info</h2>
      </div>
      <CustomTextField title="Name" value={name} onChangeText={setName} />
      <CustomTextField
        type="email"
        title="Email"
        value={email}
        onChangeText={setEmail}
      />
      <CustomTextField
        type="text"
        title="Roles"
        value={
          groups?.map(
            (group: any, index: any) =>
              `${group.name} ${index + 1 !== groups.length ? ", " : ""}`,
          ) || ""
        }
        disabled={true}
      />
      {change ? (
        <div>
          <h2>Change Password</h2>
          <CustomTextField
            title="Old password"
            placeholder="Old password"
            value={password.old_password}
            onChangeText={(text) =>
              setPassword((p) => ({ ...p, old_password: text }))
            }
            onFocus={() =>
              setPasswordError((p) => ({ ...p, old_password: "" }))
            }
            error={passwordError.old_password}
            type="password"
          />
          <CustomTextField
            title="New password"
            placeholder="New password"
            value={password.new_password}
            onChangeText={(text) =>
              setPassword((p) => ({ ...p, new_password: text }))
            }
            onFocus={() =>
              setPasswordError((p) => ({ ...p, new_password: "" }))
            }
            error={passwordError.new_password}
            type="password"
          />
          <CustomTextField
            title="Confirm password"
            placeholder="Confirm password"
            value={password.confirm_password}
            onChangeText={(text) =>
              setPassword((p) => ({ ...p, confirm_password: text }))
            }
            onFocus={() =>
              setPasswordError((p) => ({ ...p, confirm_password: "" }))
            }
            error={passwordError.confirm_password}
            type="password"
          />
        </div>
      ) : null}
      <CustomButton
        type={change ? "secondary" : "primary"}
        title={change ? "Cancel" : "Change Password"}
        onClick={() => setChange((p) => !p)}
      />
      {change && (
        <CustomButton title={"Submit"} onClick={handlePasswordChange} />
      )}
    </div>
  );
};

export default UserProfile;
