import apiClient from "./BaseService";

import { URL } from "../config";

const _getSelfServiceEmployeeList = async (companyId: any) =>
  apiClient.get(`${URL.GET_SELF_SERVICE_EMPLOYEE_LIST}${companyId}`);

const _getSelfServiceSettings = async (companyId: any) =>
  apiClient.get(`${URL.GET_SELF_SERVICE_SETTINGS}${companyId}`);

const _postSelfServiceEmployeeUser = async (data: any) =>
  apiClient.post(URL.POST_EMPLOYEE_USER, data);

const _postSelfServiceSettings = async (data: any) =>
  apiClient.post(URL.GET_SELF_SERVICE_SETTINGS, data);

const _getInfoUpdateRequests = async (companyId: any, userId: any, empId: any) =>
  apiClient.get(`${URL.GET_INFO_UPDATE_REQUESTS}company=${companyId}&user=${userId}&employee=${empId}`);

export const SelfService = {
  _getSelfServiceEmployeeList,
  _postSelfServiceEmployeeUser,
  _getSelfServiceSettings,
  _postSelfServiceSettings,
  _getInfoUpdateRequests
};
