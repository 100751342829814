import { useState } from "react";

import "./payroll.css";
import { PayrollService } from "../../../_service";
import { Notify, Utils } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../_app";

const EditBasicSalary = () => {
  const navigate = useNavigate();

  const data = useAppSelector((state) => state.employee.salary_info);
  const employee_id = useAppSelector(
    (state) => state.employee.employee_info.id,
  );

  const [hourly_paid_bool, setHourly_paid_bool] = useState<boolean>(
    data.hourly_paid_bool || false,
  );
  const [rate_overide_hourly_amount, setRate_overide_hourly_amount] =
    useState<string>(
      Utils.getPriceFromString(data.rate_overide_hourly_amount) || "0.00",
    );

  const [reateOverride, setReateOverride] = useState<boolean>(
    data.hourly_rate_overide_bool || false,
  );
  const [fixed_amount, setFixed_amount] = useState<string>(
    Utils.getPriceFromString(data.fixed_amount) || "0.00",
  );
  const [hourly_amount, setHourly_amount] = useState<string>(
    Utils.getPriceFromString(data.hourly_amount) || "0.00",
  );

  const handleHourlyRateBlur = () => {
    if (hourly_amount) {
      setHourly_amount((prev) => parseFloat(prev).toFixed(2));
    }
  };

  const handleFixedAmountBlur = () => {
    if (fixed_amount) {
      setFixed_amount((prev) => parseFloat(prev).toFixed(2));
    }
  };

  const handleUpdateBasicSalary = async () => {
    const data = {
      hourly_amount: hourly_amount ? parseFloat(hourly_amount) : 0.0,
      hourly_paid_bool,
      rate_overide_hourly_amount: parseFloat(rate_overide_hourly_amount),
      hourly_rate_overide_bool: reateOverride ? reateOverride : false,
      fixed_amount: fixed_amount ? parseFloat(fixed_amount) : 0.0,
      employee_id: employee_id,
    };
    try {
      const res = await PayrollService._updateBasicSalary(data);
      if (res.status === 200) {
        // get();
        Notify("Basic Salary Updated Successfully", 1);
        handleNavigateBack(true);
      }
    } catch (e: any) {
      if (e.response.status === 401) {
        // disaptch(resetUser());
      }
    }
  };

  const handleNavigateBack = (reload: boolean = false) => {
    navigate("/dashboard/payroll/emp", { state: { reload } });
  };

  return (
    <div className="edit-basic-salary">
      <h2 className="payroll-header-h2">Edit Basic Salary</h2>
      <div>
        <div className="row">
          <input
            checked={hourly_paid_bool}
            onChange={() => setHourly_paid_bool((prev) => !prev)}
            type="checkbox"
          />
          <label>Hourly Paid</label>
        </div>
        {hourly_paid_bool && (
          <div className="row">
            <label>Hourly Rate</label>
            <input
              value={hourly_amount}
              step="0.01"
              onBlur={handleHourlyRateBlur}
              onChange={(e) => setHourly_amount(e.target.value)}
              type="number"
            />
          </div>
        )}
        {!hourly_paid_bool && (
          <div className="row">
            <label>Fixed Amount</label>
            <input
              value={fixed_amount}
              step="0.01"
              onBlur={handleFixedAmountBlur}
              onChange={(e) => setFixed_amount(e.target.value)}
              type="number"
            />
          </div>
        )}
        {!hourly_paid_bool && (
          <div className="row">
            <input
              checked={reateOverride}
              onChange={() => setReateOverride((prev) => !prev)}
              type="checkbox"
            />
            <label>Override calculated hourly rate</label>
          </div>
        )}
        {!!!hourly_paid_bool && !!reateOverride && (
          <div className="row">
            <label>Rate Override</label>
            <input
              value={rate_overide_hourly_amount}
              step="0.01"
              onChange={(e) => setRate_overide_hourly_amount(e.target.value)}
              type="number"
            />
          </div>
        )}
      </div>
      {hourly_paid_bool && (
        <p className="text">
          You will be prompted on every payslip for the Normal hours
        </p>
      )}
      <div className="row">
        <button
          className="btn-rev md-btn"
          onClick={() => handleNavigateBack(false)}
        >
          Cancel
        </button>
        <button className="btn md-btn" onClick={handleUpdateBasicSalary}>
          Save
        </button>
      </div>
    </div>
  );
};

export default EditBasicSalary;
