import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../_app";
import { EmployeeService } from "../../_service";
import { Notify, Utils } from "../../utils";
import { H3Heading, Heading } from "../appSettings/AppSettingsStyles";
import {
  CommonModal,
  CustomButton,
  CustomDateField,
  CustomNumberField,
  CustomSelectField,
  CustomTextField,
  Loader,
} from "../../components";
import { LoaderContainer } from "../../CommonStyles";
import { DisplayFlex, RecordSeletedText } from "../payslip/PayslipStyles";
import { TableFilterHeader, TablePaginationHeader } from "./component";
import { TableHeader } from "../../components/table";
import { TableRow } from "@mui/material";
import { IRASTable, IRASTableCol } from "../filing/CPFFilingStyles";
import { TableColumn } from "../users/styles";
import { Constant } from "../../config";
import CustomTooltip from "../../components/CustomTooltip";
import AnnualIncrementRow from "./AnnualIncrementRow";
import cloneDeep from "lodash.clonedeep";
import AnnualBonusRow from "./AnnualBonusRow";
import moment from "moment";

const AnnualBonus = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const date = new Date();

  const location = useLocation();
  const locationData = location.state;
  const isEdit = locationData?.isEdit;
  const isFinalized = locationData?.isFinalized;

  const user = useAppSelector((state) => state.user);
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const currentPayrunDate = useAppSelector(
    (state) => state.user.current_payrun_date
  );
  const [year, setYear] = useState<string>(
    currentPayrunDate?.split("-")[0] || date.getFullYear()
  );
  const [month, setMonth] = useState<string>(
    `${parseInt(currentPayrunDate?.split("-")[1] || 0) + 1 || date.getMonth() + 1}`
  );
  const [status, setStatus] = useState("1");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  
  const [commonBonusFactor, setCommonBonusFactor] =
    useState<any>('1.00');
  const [employeeData, setEmployeeData] = useState<any[]>([]);
  const [filteredEmployeeData, setFilteredEmployeeData] = useState<any[]>([]);

  const [workedFrom, setWorkedFrom] = useState<any>(
    `${date.getFullYear() - 1}-01-01`
  );
  const [workedTill, setWorkedTill] = useState<any>(
    `${date.getFullYear() - 1}-12-31`
  );

  // const monthsOptions = parseInt(year) > parseInt(currentPayrunDate?.split('-')[0]) ? Utils.MONTH_OPTIONS : Utils.MONTH_OPTIONS?.slice(parseInt(currentPayrunDate?.split('-')[1]));
  // const yearOptions = Utils.getDefaultYearRange();
  const yearOptions = getYearRange(currentPayrunDate?.split('-')[0]);
  const monthsOptions = Utils.MONTH_OPTIONS;

  const [pageInfo, setPageInfo] = useState<{
    pageNumber: number;
    totalLength: number;
    pagePerData: number;
  }>({
    pageNumber: 1,
    totalLength: employeeData?.length || 10,
    pagePerData: 10,
  });

  const [totalValues, setTotalValues] = useState<any>({
    totalSalary: 0,
    totalBonusAmount: 0,
    averageBonusMonth: 0
  });

  const getEmployeeBonus = async () => {
    const defaultCompanyId = user.defaultCompnayId;

    if (defaultCompanyId) {
      setIsLoading(() => true);
      try {
        const res = await EmployeeService._getInitialEmployeeAnnualBonusData(
            defaultCompanyId,
            workedFrom,
            workedTill
          );
        if (res.status === 200) {
          let data = res?.data?.data;
          console.log("bonus intial data", data);
          setEmployeeData(data);
        }
      } catch (e: any) {
        console.log("err", e);
      }
      setIsLoading(() => false);
    }
  };

  const getEmployeeBonusEditData = async () => {
    const defaultCompanyId = user.defaultCompnayId;

    if (defaultCompanyId) {
      setIsLoading(() => true);
      try {
        const res = await EmployeeService._getEmployeeAnnualBonusEditData(locationData?.id) ;

        if (res.status === 200) {
          let data = res?.data?.data;
          setEmployeeData(data?.bonus_records);
          setYear(data?.bonus?.year)
          setMonth(data?.bonus?.month_to_be_paid)
          // setWorkedFrom(data?.bonus?.period_from)
          setWorkedTill(data?.bonus?.period_to)
          setCommonBonusFactor(data?.bonus?.global_avg_bonus)
          setTotalValues({
            totalSalary: data?.bonus?.total_salary,
            totalBonusAmount: data?.bonus?.total_bonus_amount,
            averageBonusMonth:
              data?.bonus?.avg_bonus_month,
          });
        }
      } catch (e: any) {
        console.log("err", e);
      }
      setIsLoading(() => false);
    }
  };

  const calculateBonusAmount = (
    joiningDate: string,
    salary: string,
    name: string,
  ) => {
    let joiningDateObj = moment(joiningDate);
    let comparisonDateObj = moment(`${year}-01-01`);
    let diff = comparisonDateObj.diff(joiningDateObj, "days");

    // let daysWorked = moment(`${year}-12-31`).diff(joiningDateObj, "days");
    let daysWorked = moment(`${workedTill}`).diff(joiningDateObj, "days") + 1; // added one to inclue the joining date

    let status = daysWorked < 365 ? "partialYear" : "FullYear";

    let basicSalary = parseFloat(`${salary}`?.replace(/,/g, ""));

    let bonusAmount = 0;

    if (daysWorked < 365) {
      bonusAmount = Math.ceil((daysWorked / 365) * basicSalary * (commonBonusFactor));
    } else {
      bonusAmount = basicSalary * (commonBonusFactor);
    }

    if(joiningDateObj.isAfter(moment(workedTill,"YYYY-MM-DD"))) {
      bonusAmount = 0
    }

    console.log("gotDate", {
      name,
      joiningDate,
      year,
      diff,
      daysWorked,
      status,
      salary,
      bonusAmount,
      workedTill
    });
    return bonusAmount;
  };

  const calculateBonusMonths = (
    bonusAmount: string | number,
    salary: string
  ) => {
    let amount = parseFloat(`${bonusAmount}`);
    let basicSalary = parseFloat(`${salary}`?.replace(/,/g, "")) || 0.0;
    let bonusMonth = Number((amount / basicSalary).toFixed(2)) || 0.0;

    return bonusMonth;
  };

  useEffect(() => {
    if(isEdit || isFinalized){
      getEmployeeBonusEditData();
    } else {
      getEmployeeBonus();
    }

  }, [workedFrom, workedTill]);

  // useEffect(()=> {
  //   setMonth(monthsOptions[0].value)
  // }, [year])


  const updateTotal = (data: any) => {
    console.log("data inside updateTotal", data)
    let totalObj: any = {
      totalSalary: 0,
      totalBonusAmount: 0,
      averageBonusMonth: 0
    };

    console.log('before cal1 total', totalObj)
    data.forEach((item: any) => {
      totalObj.totalSalary =
        totalObj.totalSalary +
        (parseFloat(`${item?.basic_salary}`?.replace(/,/g, "")) || 0);
      totalObj.totalBonusAmount =
        totalObj.totalBonusAmount + parseFloat(item?.bonus_amount);
    });

    totalObj.averageBonusMonth = (
      ((totalObj?.totalBonusAmount / totalObj?.totalSalary) ) || 0
    ).toFixed(2);

    totalObj.totalSalary = parseFloat(totalObj.totalSalary); 
    totalObj.totalBonusAmount = parseFloat(totalObj.totalBonusAmount); 
    totalObj.averageBonusMonth = parseFloat(totalObj.averageBonusMonth); 

    console.log('total before update', totalObj)
    setTotalValues(totalObj);
    
  };

  const handleCommonBonusFactorChange = (): any => {
    let data = cloneDeep(employeeData);

    if(parseFloat(commonBonusFactor) < 0) {
      Notify("Average bonus month cannot be negative!", 0, {autoClose: false});
      return null;
    }

    data.forEach((item: any) => {
      item.bonusAmount = calculateBonusAmount(item?.date_joined, item?.basic_salary, item?.employee_name)
      item.bonus_amount = item?.bonusAmount;

      item.numberOfBonusMonths = calculateBonusMonths(item?.bonus_amount, item?.basic_salary)

      item.no_of_bonus_months = item.numberOfBonusMonths;

    });

    

    setEmployeeData(data);
    updateTotal(data);

    console.log("updated bonus data", data, );
  };

  const handlePageValueChange = (
    type: "next" | "previous" | "first" | "last"
  ) => {
    if (type === "next") {
      const isLast =
        pageInfo.pageNumber * pageInfo.pagePerData < employeeData.length;
      if (isLast) {
        setPageInfo((prev) => ({
          ...prev,
          pageNumber: prev.pageNumber + 1,
        }));
      }
    }
    if (type === "previous") {
      if (pageInfo.pageNumber > 1) {
        setPageInfo((prev) => ({
          ...prev,
          pageNumber: prev.pageNumber - 1,
        }));
      }
    }
    if (type === "first") {
      setPageInfo((prev) => ({
        ...prev,
        pageNumber: 1,
      }));
    }
    if (type === "last") {
      setPageInfo((prev) => ({
        ...prev,
        pageNumber: Math.ceil(pageInfo.totalLength / pageInfo.pagePerData),
      }));
    }
  };

  const handleSave = async (isDraft: boolean, goBack: boolean = false) => {
    console.log('theData inside handleSave', employeeData, totalValues)
    setOpen(false);

    let payload: any = {
      bonus_data: {},
      bonus_records: [],
    }

    let data = cloneDeep(employeeData);

    payload.bonus_records = data?.map((emp: any) => {
      let newEmp: any = {};
      Object.keys(emp).forEach((key: any) => {
        if (key === "bonusAmount") {
          newEmp.bonus_amount = emp[key];
        } else if (key === "numberOfBonusMonths") {
          newEmp.no_of_bonus_months = emp[key];
          } else if (key === "basic_salary") {
            newEmp[key] = `${emp[key]}`?.replace(/,/g, "");
        } 
        else {
          newEmp[key] = emp[key];
        }
      });
      return newEmp;
    });

    payload.bonus_data = {
      "company": companyId,
        "year": year,
        "month_to_be_paid": month,
        // "period_from": workedFrom,
        "period_to": workedTill,
        "global_avg_bonus": commonBonusFactor,
        "status": isDraft ? "preview" : "sent_for_approval",
        "total_salary": totalValues?.totalSalary,
        "total_bonus_amount": totalValues?.totalBonusAmount,
        "avg_bonus_month": totalValues?.averageBonusMonth,
    }

    if(isEdit){
      payload.bonus_data.id = locationData?.id
    }

    console.log("thePayload",  payload)

    try {
      const { status } = isEdit ? await EmployeeService._updateAnnualBonus(payload) : await EmployeeService._saveAnnualBonus(payload);

      if (status >= 200 && status < 300) {
        Notify(isEdit ? "Annual Bonus Updated" : "Annual Bonus Saved", 1);
        
        if(goBack){
          navigate(-1);
        }
      }
    } catch (error: any) {
      console.log("inside Error", error)
      Notify(error?.response?.data?.error?.message || "Something went wrong!", 0);
      
    }
  };



// Only when updating from row and isEdit=true
  const handleSaveRow = async (empData:any) => {
    console.log('handleSaveRow called')
    let totalObj: any = {
      totalSalary: 0,
      totalBonusAmount: 0,
      averageBonusMonth: 0
    };

    empData.forEach((item: any) => {
      totalObj.totalSalary =
        totalObj.totalSalary +
        (parseFloat(`${item?.basic_salary}`?.replace(/,/g, "")) || 0);
      totalObj.totalBonusAmount =
        totalObj.totalBonusAmount + parseFloat(item?.bonus_amount);
    });

    totalObj.averageBonusMonth = (
      ((totalObj?.totalBonusAmount / totalObj?.totalSalary) ) || 0
    ).toFixed(2);

    totalObj.totalSalary = parseFloat(totalObj.totalSalary); 
    totalObj.totalBonusAmount = parseFloat(totalObj.totalBonusAmount); 
    totalObj.averageBonusMonth = parseFloat(totalObj.averageBonusMonth); 

    // -----------

    let payload: any = {
      bonus_data: {},
      bonus_records: [],
    }

    payload.bonus_records = empData?.map((emp: any) => {
      let newEmp: any = {};
      Object.keys(emp).forEach((key: any) => {
        if (key === "bonusAmount") {
          newEmp.bonus_amount = emp[key];
        } else if (key === "numberOfBonusMonths") {
          newEmp.no_of_bonus_months = emp[key];
          } else if (key === "basic_salary") {
            newEmp[key] = `${emp[key]}`?.replace(/,/g, "");
        } 
        else {
          newEmp[key] = emp[key];
        }
      });
      return newEmp;
    });

    payload.bonus_data = {
      "company": companyId,
        "year": year,
        "month_to_be_paid": month,
        // "period_from": workedFrom,
        "period_to": workedTill,
        "global_avg_bonus": commonBonusFactor,
        "status": "preview",
        "total_salary": totalObj?.totalSalary,
        "total_bonus_amount": totalObj?.totalBonusAmount,
        "avg_bonus_month": totalObj?.averageBonusMonth,
    }

    if(isEdit){
      payload.bonus_data.id = locationData?.id
    }

    console.log("thePayload-RowUpdate",  payload)

    try {
      const { status } = await EmployeeService._updateAnnualBonus(payload);
      if (status >= 200 && status < 300) {
        Notify("Annual Bonus Updated", 1);
      }
    } catch (error: any) {
      console.log("inside Error", error)
      Notify(error?.response?.data?.error?.message || "Something went wrong", 0);
      
    }

  }

  const handleRowUpdate = (empId:any, amount:any, bonusMonthCount:any) => {
    console.log('inside onUpdate', empId, amount)
    let index = employeeData?.findIndex((user) => user.employee === empId);
    let data = cloneDeep(employeeData)

    if(index >= 0) {
      data[index].bonusAmount = parseFloat(amount);
      data[index].bonus_amount = parseFloat(amount);

      data[index].numberOfBonusMonths = parseFloat(bonusMonthCount);
      data[index].no_of_bonus_months = parseFloat(bonusMonthCount);
      
      setEmployeeData(data);
      updateTotal(data);
    }

    if(isEdit){
      handleSaveRow(data);
    }

  }

  // page change
  useEffect(() => {
    const startIndex =
      pageInfo.pageNumber * pageInfo.pagePerData - pageInfo.pagePerData;
    const endIndex =
      pageInfo.pageNumber * pageInfo.pagePerData > employeeData.length
        ? employeeData.length
        : pageInfo.pageNumber * pageInfo.pagePerData;

        setPageInfo((prev) => ({
          ...prev,
          totalLength: employeeData?.length,
        }));

    
    setFilteredEmployeeData(() => employeeData.slice(startIndex, endIndex));
  }, [pageInfo.pageNumber, employeeData]);


  {
    // console.log("theData", employeeData, locationData, isEdit, totalValues);
    console.log("theData", employeeData, filteredEmployeeData, totalValues);
    console.log("currentPayrunDate", {
      currentPayrunDate,
      year,
      month,
      monthsOptions,
      yearOptions
    });
  }

  return (
    <div style={{
      pointerEvents: isFinalized ? 'none' : 'auto'
    }}>
      <Heading>{"Annual Bonus"}</Heading>
      {/* {isLoading && <Loader />} */}
      {/* <hr/> */}
      <H3Heading>{user.defaultCompany}</H3Heading>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          justifyItems: "flex-start",
          width: "fit-content",
        }}
      >
        <span
          style={{
            margin: "1rem 0",
            marginTop: "1.2rem",
            color: "rgb(71, 101, 117)",
            fontWeight: 700,
            whiteSpace: "nowrap",
          }}
        >{`For Employees Who Worked till `}</span>

        {/* <CustomDateField
          date={workedFrom}
          setDate={setWorkedFrom}
          startYear={new Date().getFullYear() - 3}
          endYear={new Date().getFullYear() + 6}
          openAsModal={false}
          disabled={isEdit === true || isFinalized === true}
        />
        <span
          style={{
            color: "rgb(71, 101, 117)",
            fontWeight: 700,
          }}
        >
          to
        </span> */}

        <CustomDateField
          date={workedTill}
          setDate={setWorkedTill}
          startYear={new Date().getFullYear() - 3}
          endYear={new Date().getFullYear() + 6}
          openAsModal={false}
          disabled={isEdit === true || isFinalized === true}
        />
      </div>

      <div
        style={{
          display: "flex",
          gap: 20,
          width: "70%",
          alignItems: "center",
        }}
      >
        <p style={{ color: "#333" }}>Annual Bonus for the year: </p>
        <div>
          <CustomSelectField
            value={year}
            onChangeOption={(value) => setYear(value)}
            option={yearOptions.map((year) => ({
              key: year,
              value: year,
            }))}
            disabled={isLoading}
            isDate={true}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          gap: 20,
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
          <p style={{ color: "#333" }}>Bonus to be paid in: </p>
          <div>
            <CustomSelectField
              value={month}
              onChangeOption={(value) => setMonth(value)}
              option={monthsOptions.map((month) => ({
                key: month.key,
                value: month.value,
              }))}
              disabled={isLoading}
              isDate={true}
            />
          </div>
        </div>

        {!isFinalized && <CustomButton
          title="Ready to send these final records to pay month?"
          onClick={() => setOpen(true)}
          styles={{ marginRight: "2rem", width: "fit-content  " }}
        />}
      </div>

      <div
        style={{
          display: "flex",
          gap: 20,
          width: "70%",
          alignItems: "center",
        }}
      >
        <p style={{ color: "#333" }}>Average Bonus Month : </p>
        <div>
          <CustomNumberField
            value={commonBonusFactor}
            // value={`${commonBonusFactor || 0}`}
            placehoder=""
            removeNumIcon={false}
            onChangeText={(text) => {
              setCommonBonusFactor(text);
            }}
            onBlur={() => {
              if(parseFloat(commonBonusFactor) < 0) {
                Notify("Average bonus month cannot be negative!", 0, {autoClose: false});
              } else {
                setCommonBonusFactor(
                  `${(parseFloat(commonBonusFactor) || 0).toFixed(2)}`
                )
              }
            }
            }
            width="70%"
            disabled={isFinalized}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          gap: 20,
          marginTop: "1rem",
          width: "100%",
          alignItems: "center",
          color: "rgb(51, 51, 51)",
        }}
      >
        <p style={{ textTransform: "capitalize" }}>
          Calculate Annual Bonus for all active employees:{" "}
        </p>
        <CustomTooltip title={"Click to Start Calculating"} placement={"right"}>
          <img
            onClick={handleCommonBonusFactorChange}
            style={{ width: "2rem", cursor: "pointer" }}
            src="/refresh_blue.svg"
          />
        </CustomTooltip>
      </div>

      {/* <div
        style={{
          display: "flex",
          gap: 20,
          width: "70%",
          alignItems: "center",
        }}
      ></div> */}

      {/* For total table */}
      <table
        style={{ width: "100%", marginTop: "1rem" }}
        className="company-table"
      >
        <thead className="company-table-head" style={{ textAlign: "right" }}>
          <tr>
            {/* <th  style={{ width: "25%", textAlign: "center",  }}>Total:</th> */}
            <th style={{ width: "28%", textAlign: "left" }}>Total Employee</th>
            <th style={{ width: "36%", textAlign: "right" }}>Total Salary</th>
            <th style={{ width: "12%", textAlign: "right" }}>
              Total Bonus Amount
            </th>
            <th style={{ width: "12%", textAlign: "right" }}>
              Avg Bonus Month
            </th>
            <th style={{ width: "12%", textAlign: "left" }}>{""}</th>
          </tr>
        </thead>
        <tr>
        <td style={{  width:"28%",textAlign: "left" }}>
        {  employeeData?.length }
          </td>
          
          <td style={{  width:"36%",textAlign: "right" }}>
            {Utils.getCommanSepartedNumericStringWithDollar(totalValues?.totalSalary || 0)}
          </td>
          <td style={{  width:"12%",textAlign: "right" }}>
            {Utils.getCommanSepartedNumericStringWithDollar(totalValues?.totalBonusAmount || 0)}
          </td>
          <td style={{ width:"12%", textAlign: "right" }}>
            {/* {(
              (totalValues?.totalBonusAmount / totalValues?.totalSalary) *
                100 || 0
            ).toFixed(2)} */}
            {(totalValues?.averageBonusMonth || 0.00).toFixed(2)}
          </td>
          <td style={{  width:"12%",textAlign: "right" }}>
           {""}
          </td>
        </tr>
      </table>

      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <section id="report-table" style={{ marginTop: 20 }}>
            <>
              <div style={{ width: "100%" }}>
              <TableFilterHeader
                name="Employees"
                pageInfo={pageInfo}
                setPageInfo={setPageInfo}
                handlePageValueChange={(val) => handlePageValueChange(val)}
              />

                <div className="table">
                  <table className="company-table">
                    <thead className="company-table-head">
                      <tr>
                        <th  style={{ width:"28%", textAlign: "left" }}>Employee Name</th>
                        <th  style={{ width:"12%", textAlign: "left" }}>Date Joined</th>
                        <th  style={{ width:"12%", textAlign: "left" }}>Employee ID</th>
                        <th style={{ width:"12%", textAlign: "right" }}>Basic Salary</th>
                        <th style={{ width:"12%",textAlign: "right" }}>Bonus Amount</th>
                        <th style={{ width:"12%",textAlign: "right" }}>
                          No. of Bonus Months
                        </th>
                       {!isFinalized && <th style={{ textAlign: "center", width: "15%" }}>
                          Action
                        </th>}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredEmployeeData?.length > 0 ? (
                        filteredEmployeeData?.map((item, index) => (
                          <AnnualBonusRow
                            key={item?.employee}
                            data={item}
                            onUpdate={handleRowUpdate}
                            isEdit={isEdit}
                            isFinalized={isFinalized}
                          />
                        ))
                      ) : (
                        <p style={{ textAlign: "center", margin: "2rem" }}>
                          No records found
                        </p>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          </section>
        </>
      )}

      <CommonModal
        handleClose={() => setOpen(false)}
        open={open}
        // loading={loading}
        updateData={()=>handleSave(true, true)}
        body={`When you click “Save Draft”, the system will save Annual Bonus as draft for later confirmations. The system will also create a new "pay item - bonus” record for every employee in the list. 
When you click on Send to TellBoss” button, the Annual Bonus will be send to TellBoss for approval.
`}
        heading="Ready to send Payroll Master Records?"
        conformButtonText="Save Draft"
        cancelButtonText="Send to TellBoss"
        onCancel={()=>handleSave(false, true)}  //called on confirm

      />
    </div>
  );
};

export default AnnualBonus;


const getYearRange = (year:string) => {
  const startYear = parseInt(year);
  const yearsRange = [startYear];

  for (let i = 1; i < 10; i++) {
    yearsRange.push(startYear + i);
  }
  console.log("inside yearRange", {startYear, yearsRange});
  return yearsRange;
};
