import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../../_app";
import { PayrollService } from "../../../_service";
import { Notify, Utils } from "../../../utils";
import {
  CustomDateField,
  CustomSelectField,
  CustomeCkeckboxField,
} from "../../../components/customFormField";
import { CustomButton } from "../../../components/customButton";
import { FormErrorMessage } from "../../../components";
import { Constant } from "../../../config";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const EndService = () => {
  const navigate = useNavigate();

  const employee = useAppSelector((state) => state.employee.employee_info);
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);

  const [reasons, setReasons] = useState([]);
  const [historyData, setHistoryData] = useState([]);

  const [reason, setReason] = useState("");
  const [lastDayOfService, setLastDayOfService] = useState();
  const [requireFormIr21, setRequireFormIr21] = useState<boolean>(false);

  const [errorCollection, setErrorCollection] = useState<any>({});
  const updateErrorByKey = (key: string, val?: string) => {
    if (val) {
      setErrorCollection((prev: any) => ({ ...prev, [key]: val || "" }));
      return;
    }
    delete errorCollection[key];
    setErrorCollection(errorCollection);
  };
  const handleDataUpdate = async () => {
    try {
      if (!lastDayOfService) {
        updateErrorByKey("last_day_of_service", "can't be empty");
        return;
      }
      if (reason === "") {
        updateErrorByKey("reason_for_termination", "can't be empty");
        return;
      }
      setErrorCollection({});
      const data = {
        employee: employee.id,
        company: companyId,
        end_service_reason: reason,
        last_date_of_service: Utils._date(lastDayOfService),
        require_form_ir21: requireFormIr21,
      };
      const res = await PayrollService._updateEndService(data);
      if (res.status === 200) {
        Notify("End Service updated successfully", 1);
        handleNavigateBack();
      }
    } catch (e: any) {
      if (e.response.status === 401) {
        // disaptch(resetUser());
      }
    }
  };

  const handleNavigateBack = () => {
    // navigate("/dashboard/payroll/emp");
    navigate("/dashboard/employee-payroll");
  };
  const get = async () => {
    try {
      const { data } = await PayrollService._getEndServiceReasons();
      setReasons(() => data.data);
    } catch (e: any) {
      if (e.response.status === 401) {
        // disaptch(resetUser());
      }
    }
  };

  const getEndServiceDetails = async () => {
    try {
      const { data, status } = await PayrollService._getEndService({
        employee: employee.id,
        companyId,
      });

      if (status === 200) {
        if (data.data?.end_service_reason) {
          setReason(() => data.data.end_service_reason);
          setLastDayOfService(() => data.data.last_date_of_service);
          setRequireFormIr21(() => data.data.require_form_ir21);
        }
      }
    } catch (error) {}
  };

  const getServicePeriodHistory = async () => {
    try {
      const { data, status } = await PayrollService._getServicePeriodHistory({
        employee: employee.id,
        companyId,
      });

      if (status === 200) {
        setHistoryData(data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    get();
    getEndServiceDetails();
    getServicePeriodHistory();
  }, []);
  return (
    <div style={{ marginTop: "2rem", width: '100%' }}>
      <FormErrorMessage name="" errorCollection={errorCollection} />

      <div style={{ display: "flex", width: '100%', justifyContent: 'flex-start'  }}>
        <div style={{width: '50%',  }}>
          <CustomDateField
            label="Last Day of Service"
            date={lastDayOfService}
            setDate={setLastDayOfService}
            error={errorCollection.last_day_of_service}
            onFocus={() => updateErrorByKey("last_day_of_service")}
            require={true}
          />
          <CustomSelectField
            title="Reason for Termination tesla"
            value={reason}
            placeholder="Reason for Termination"
            option={reasons?.map((item: any) => ({
              key: item.reason,
              value: item.id,
            }))}
            onChangeOption={setReason}
            error={errorCollection.reason_for_termination}
            onFocus={() => updateErrorByKey("reason_for_termination")}
            require={true}
          />
          <div style={{ marginTop: 20 }}>
            <CustomeCkeckboxField
              title="Requires Form IR21 (exclude from IR8A e-submission file)"
              value={requireFormIr21}
              onChange={() => setRequireFormIr21((p) => !p)}
            />
          </div>
          <div style={{ display: "flex", marginTop: 40 }}>
            <CustomButton
              type="secondary"
              title="Cancel"
              onClick={handleNavigateBack}
            />
            <CustomButton title="Save" onClick={handleDataUpdate} />
          </div>
          {/* <h2 className="payroll-header-h2">Service Period History</h2>
      <p>
        From {employee?.date_of_appointment || ""}
        <span style={{ marginLeft: 40 }}>
          {requireFormIr21 ? "Require Form IR21" : "Does not require Form IR21"}{" "}
        </span>
      </p> */}
        </div>

        <div style={{ border: "1px solid rgb(5, 155, 191)", padding: "1rem 1rem", display: 'flex', flexDirection:'column', gap:'0.5rem',   }}>
          <p style={{fontSize: '1.15rem', fontWeight: 700, color: 'rgb(5, 155, 191)'}}>Questions to answers:</p>
          <p>1. Resignation or Termination of service in End of Service form</p>
          <p>
            2. Singapore Citizen / Singapore PR; or Foreigner with work pass
          </p>
          <p>3. Ending an Employee's Service.</p>
          <p>4. Reason for termination-choose the type.</p>
          <p>5. Balance of saving account.</p>
          <p>6. Balance of employer loans.</p>
          <p>7. Balance of earned annual leave.</p>
          <p>8. Termination notice period - fully serve or pay-in-lieu.</p>
          <p>9. Termination Lump sum, if any.</p>
          <p>10. Get IRAS Tax Clearance.</p>
        </div>
      </div>

      <p
        style={{
          fontSize: "1.75rem",
          color: "#476575",
          fontWeight: 600,
          marginBottom: "0.5rem",
          marginTop: "1.5rem",
        }}
      >
        Service Period History
      </p>

      <Table size="small" aria-label="full width table">
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              colSpan={1}
              sx={{ fontWeight: "bold", fontSize: "1rem", padding: "0.8rem" }}
            >
              Service Period
            </TableCell>
            <TableCell
              align="left"
              colSpan={1}
              sx={{ fontWeight: "bold", fontSize: "1rem", padding: "0.8rem" }}
            >
              Form Requirements
            </TableCell>
            <TableCell
              align="center"
              colSpan={1}
              sx={{ fontWeight: "bold", fontSize: "1rem", padding: "0.8rem" }}
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historyData?.length > 0 &&
            historyData?.map((item: any) => (
              <TableRow>
                <TableCell
                  sx={{ padding: "0.8rem", fontSize: "1rem" }}
                  align="left"
                >{`From ${item?.service_start_date} to ${item?.service_end_date}`}</TableCell>
                <TableCell
                  sx={{ padding: "0.8rem", fontSize: "1rem" }}
                  align="left"
                >
                  {item?.require_form_ir21
                    ? "Form IR21 Required"
                    : "Form IR21 Not Required"}
                </TableCell>
                <TableCell
                  sx={{ padding: "0.8rem", fontSize: "1rem" }}
                  align="center"
                >
                  {" "}
                  &mdash;{" "}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default EndService;
