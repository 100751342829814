import React from "react";
import styled from "styled-components";
import Require from "./Require";
import { Container, Error, Label } from "./Styles";
interface TextFieldProps {
  title?: string;
  value: string;
  type?: string;
  onChangeText?: (e: string) => void;
  placeholder?: string;
  width?: string;
  error?: string;
  disabled?: boolean;
  require?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  marginTop?: string;
  isColumn?: boolean;
  inputWidth?: string;
  inputSize?: string;
}
const TextField = ({
  onChangeText = () => {},
  title = "",
  value,
  error,
  placeholder = "",
  type = "text",
  width = "100%",
  disabled = false,
  require = false,
  onBlur,
  onFocus,
  marginTop = ".8rem",
  isColumn = false,
  inputWidth = "",
  inputSize = "1rem"
}: TextFieldProps) => {
  return (
    <Container margin={marginTop} isColumn={isColumn}>
      {title && (
        <Label isColumn={isColumn}>
          {title}
          {require && <Require />}
        </Label>
      )}
      <div style={{ width: inputWidth || "60%" }}>
        <Input
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={(e: any) => onChangeText(e.target.value)}
          onBlur={onBlur}
          onFocus={onFocus}
          type={type}
          width={width}
          style={{fontSize: inputSize}}
        />
        {error && <Error>{error || ""}</Error>}
      </div>
    </Container>
  );
};

export default TextField;

const Input = styled.input<any>`
  width: ${(props) => props.width};
  border-radius: 0.2rem;
  text-transform: none;
`;
